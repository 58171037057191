import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TableCell, TableRow, Tooltip } from '@mui/material';

import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer';

import { FlexTableCell } from '~/components/Content/SearchRanking/FlexTableCell';

import PublicOfferService from '~/services/PublicOfferService';
import SearchService from '~/services/SearchService';

interface IExpectedValueData {
  expectedValue: string;
  expectedValueInfo?: string;
  adjustedExpectedValue: string;
  adjustedExpectedValueInfo?: string;
  salesAmount: string;
  marginAmount: string;
  mpv: string;
  salesCount: string;
  views: string;
}

function SearchTourRow({
  offerId,
  region,
  variant,
  isManuallySorted,
}: {
  offerId: string;
  region: string;
  variant: string;
  isManuallySorted?: boolean;
}) {
  const isMountedRef = useRef(true);
  const tenant = useSelector((state: App.State) => state.tenant);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [offerDetails, setOfferDetails] = useState<PublicOfferV2.TourV2Offer | undefined>();
  const [offerScore, setOfferScore] = useState<App.OfferScore | undefined>();

  useEffect(() => {
    (async () => {
      try {
        setFetchingState('loading');
        const [score, offerDetails] = await Promise.all([
          SearchService.getOfferScoreForVariant({
            vertical: 'tour',
            offerId,
            region,
            variant,
          }),
          PublicOfferService.getTourV2OfferById(offerId, region, tenant.brand),
        ]);

        if (!isMountedRef.current) return;

        setOfferScore(score.result.score);
        setOfferDetails(offerDetails.result);
        setFetchingState('success');
      } catch (error) {
        setFetchingState('failed');
      }
    })();

    return () => {
      isMountedRef.current = false;
    };
  }, [variant, offerId, region, tenant.brand]);

  const expectedValueData: IExpectedValueData = useMemo(() => {
    if (offerScore) {
      return {
        views: offerScore.numberOfViews.toLocaleString(),
        salesCount: offerScore.salesCount.toLocaleString(),
        salesAmount: offerScore.totalSales.toLocaleString(),
        marginAmount: offerScore.totalMargin.toLocaleString(),
        mpv:
          offerScore.totalMargin && offerScore.numberOfViews
            ? (offerScore.totalMargin / offerScore.numberOfViews).toFixed(3)
            : '0',
        expectedValue: offerScore.expectedValue.toFixed(3),
        expectedValueInfo: offerScore.expectedValueInfo,
        adjustedExpectedValue: offerScore.adjustedExpectedValue.toFixed(3),
        adjustedExpectedValueInfo: offerScore.adjustedExpectedValueInfo,
      };
    }
  }, [offerScore]);

  return (
    <>
      {fetchingState == 'loading' && (
        <TableRow>
          <TableCell component="th" colSpan={10}>
            Loading...
          </TableCell>
        </TableRow>
      )}
      {fetchingState == 'success' && offerDetails && (
        <TableRow>
          <FlexTableCell component="th" scope="row" sx={{ maxWidth: 300 }}>
            <Link to={`/tours/details/${offerId}`}>{offerDetails.name}</Link>
            {isManuallySorted && (
              <Tooltip title="Commercial ranked" placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
          <TableCell align="right">{offerDetails.productType}</TableCell>
          <TableCell align="right">{expectedValueData.views}</TableCell>
          <TableCell align="right">{expectedValueData.salesCount}</TableCell>
          <TableCell align="right">{expectedValueData.salesAmount}</TableCell>
          <TableCell align="right">{expectedValueData.marginAmount}</TableCell>
          <TableCell align="right">{expectedValueData.mpv}</TableCell>
          <FlexTableCell align="right">
            {expectedValueData.expectedValue}
            {expectedValueData.expectedValueInfo && (
              <Tooltip title={expectedValueData.expectedValueInfo} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
          <FlexTableCell align="right">
            {expectedValueData.adjustedExpectedValue}
            {expectedValueData.adjustedExpectedValueInfo && (
              <Tooltip title={expectedValueData.adjustedExpectedValueInfo} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
        </TableRow>
      )}
      {fetchingState == 'failed' && (
        <TableRow>
          <TableCell component="th" colSpan={10}>
            Failed to load offer: {offerId}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
export default SearchTourRow;
