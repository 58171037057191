import delay from '~/utils/delay';

interface Arguments<T> {
  validateFunction: (result: T) => Promise<boolean>;
  apiCall: () => Promise<T>;
  maxTime: number;
  pollInterval?: number;
}

/**
 * calls apiCall function multiple times until validateFunction returns Promise<true>
 * @param apiCall function to be invoked until it returns a valid result
 * @param validateFunction function receives the return of the last apiCall invocation
 *    and should return Promise<true> if the result is valid so the asyncPoll can finish
 *    or Promise<false> if the result is not valid so asyncPoll needs to continue calling apiCall.
 *    If validateFunction throws an exception, asyncPoll will also throws the same exception.
 * @param maxTime maximum execution time
 * @param pollInterval time to wait between apiCall invocations
 * @returns last apiCall's result
 */
const asyncPoll = async <T>({ apiCall, validateFunction, maxTime, pollInterval = 1000 }: Arguments<T>) => {
  const startTime = new Date().getTime();
  let result: T | null = null;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const currentTime = new Date().getTime();
    if (currentTime - startTime >= maxTime) {
      return Promise.reject('asyncPoll timeout');
    }

    try {
      result = await apiCall();
    } catch (error) {
      await delay(pollInterval);
      continue;
    }

    const isValid = await validateFunction(result);
    if (isValid) {
      return result;
    }

    await delay(pollInterval);
  }
};

export default asyncPoll;
