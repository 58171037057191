import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Stack, Typography } from '@mui/material';

import type { MappingChange, SourceRoom } from './types';
import { generateOccupancyText, generateRoomSizeText, generateViewsText } from './util';

interface Props {
  mapping: MappingChange;
  appliedButNotSavedRooms: Array<SourceRoom>;
}

export default function ToBeMappedRoomElement({ mapping, appliedButNotSavedRooms }: Props) {
  const getAppliedButNotSavedRoomsItem = (roomId: string) => {
    return appliedButNotSavedRooms.find((room) => room.externalId === roomId);
  };

  const appliedRoomItem = getAppliedButNotSavedRoomsItem(mapping.externalId);

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        borderColor: 'info.light',
        borderWidth: '2px',
        borderStyle: 'solid',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" gap={1}>
          <Chip label="Unsaved" color="info" size="small" />
          {mapping.isVerified && <Chip label="Verified" color="success" variant="outlined" size="small" />}
          {!mapping.isVerified && <Chip label="Unverified" color="warning" variant="outlined" size="small" />}
          <span>
            ({mapping.supplier}) {getAppliedButNotSavedRoomsItem(mapping.externalId)?.name} - {mapping.externalId}
          </span>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <strong>Supplier | External ID: </strong> {`(${mapping.supplier}) ${mapping.externalId}`}
        </Typography>
        <Typography>
          <strong>Views:</strong> {generateViewsText(appliedRoomItem)}
        </Typography>
        <Typography>
          <strong>Room Size: </strong>
          {generateRoomSizeText(appliedRoomItem) || 'N/A'}
        </Typography>
        <Typography>
          <strong>Occupancy Age: </strong>
          {generateOccupancyText(appliedRoomItem).occupancyAge || 'N/A'}
        </Typography>
        <Typography>
          <strong>Occupancy Allowed: </strong>
          {generateOccupancyText(appliedRoomItem).occupancyAllowed || 'N/A'}
        </Typography>

        <Typography>
          <strong>Description: </strong>
          {getAppliedButNotSavedRoomsItem(mapping.externalId)?.description || 'N/A'}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
