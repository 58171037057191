import React from 'react';

import ExportLuxPlusMembersReportForm from '~/components/Common/Forms/ExportLuxPlusMembersReportForm';

import ContentWithSidebar from './ContentWithSidebar';

const DEFAULT_ITEMS = 'lux-plus-members';

const items = [
  {
    slug: 'lux-plus-members',
    title: 'Lux Plus Members Report',
    component: ExportLuxPlusMembersReportForm,
  },
];

export default function CruisePage() {
  return <ContentWithSidebar items={items} defaultItem={DEFAULT_ITEMS} />;
}
