import React, { Dispatch, Fragment, MouseEventHandler, forwardRef, useCallback, useEffect, useMemo } from 'react';

import { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Alert,
  AlertTitle,
  Box,
  IconButton,
  LinearProgress,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowIdGetter, GridRowsProp } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';

import fetchHeroPlannerOfferDetails from '~/actions/customerCommunication/fetchHeroPlannerOfferDetails';

import { useAppSelector } from '~/hooks/store';

import { EmptyArray, lastValueOf, takeFrom } from '~/utils/arrayUtils';
import { isRequestFulfilled, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

import ScheduleOfferVisibilityBlock from '../ScheduleOfferDetails/ScheduleOfferVisibilityBlock';

import ScheduleNewHeroOfferForm from './ScheduleNewHeroOfferForm';

export enum SCHEDULE_HERO_OFFERS_INPUT_NAMES {
  IDS = 'schedule_hero_offers_ids',
}

export function parseScheduleHeroOffersFormData(
  formData: FormData,
): Pick<CustomerCommunication.UnsavedHeroPlannerSchedule, 'emailHeroOfferIds'> {
  return {
    emailHeroOfferIds: String(formData.get(SCHEDULE_HERO_OFFERS_INPUT_NAMES.IDS)).split(','),
  };
}

const defaultGridRowIdGetter: GridRowIdGetter<CustomerCommunication.HeroPlannerOfferDetails> = (row) => row.offerId;
const defaultRowHeightGetter = () => 'auto' as const;
const defaultDetailPanelHeightGetter = () => 'auto' as const;
const defaultDetailPanelContentGetter = ({ row }) => {
  return (
    <Typography variant="caption" component="pre" py={1} px={2}>
      {JSON.stringify(row, null, 4)}
    </Typography>
  );
};
const dataGridCustomSx: SxProps<Theme> = {
  '.MuiDataGrid-cell': (theme) => ({ ...theme.typography.body1, paddingBlock: 2 }),
  '.MuiDataGrid-overlayWrapper': {
    minHeight: 96,
  },
};

interface Props {
  brand: string;
  countryGroupId: string;
  countryId: string;
  offerIds: Array<string>;
  sendDate: Dayjs;
  onOfferIdsChange: Dispatch<React.SetStateAction<Array<string>>>;
}

const ScheduleHeroOffersForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { brand, sendDate, offerIds, countryGroupId, countryId, onOfferIdsChange } = props;

  const offerIdsKeysParams = useMemo(() => {
    return offerIds.map((id) => {
      const params = [brand, countryGroupId, countryId, id] as const;
      return [getRecordKeyFor(...params), params] as const;
    });
  }, [brand, countryGroupId, countryId, offerIds]);

  const offerDetailsRecords = useAppSelector((state) => {
    return offerIdsKeysParams.map(([key]) => [key, state.customerCommunication.offerDetailsRecords[key]] as const);
  });
  const appDispatch = useDispatch();
  useEffect(() => {
    offerIdsKeysParams.forEach(([, params]) => {
      appDispatch(fetchHeroPlannerOfferDetails(...params));
    });
  }, [appDispatch, offerIdsKeysParams]);

  const rows = useMemo<GridRowsProp<CustomerCommunication.HeroPlannerOfferDetails>>(() => {
    const offerDetails = offerDetailsRecords.map(([, req]) => (isRequestFulfilled(req) ? req.result : undefined));

    if (offerDetails.every(Boolean)) {
      return offerDetails.map((offerDetail) => {
        return {
          id: offerDetail.offerId,
          ...offerDetail,
        };
      });
    }
    return EmptyArray;
  }, [offerDetailsRecords]);

  const handleOrderChange = useCallback<GridEventListener<'rowOrderChange'>>(
    (params) => {
      onOfferIdsChange((currOfferIds) => {
        const clonedRows = [...currOfferIds];
        clonedRows.splice(params.oldIndex, 1);
        clonedRows.splice(params.targetIndex, 0, params.row.offerId);
        return clonedRows;
      });
    },
    [onOfferIdsChange],
  );

  const maxOffersCap = useMemo(() => {
    switch (brand) {
      case 'treatmetravel':
        return 5;
      default:
        return 4;
    }
  }, [brand]);

  const serialisedOfferIds = useMemo<string>(() => {
    if (!rows?.length) {
      return '';
    }
    return takeFrom(rows, maxOffersCap)
      .map((r) => r.offerId)
      .join(',');
  }, [rows, maxOffersCap]);

  const handleReadyOfferDetails = useCallback(
    (offerDetails: CustomerCommunication.HeroPlannerOfferDetails) => {
      onOfferIdsChange((currOfferIds) => [...currOfferIds, offerDetails.offerId]);
    },
    [onOfferIdsChange],
  );

  const deleteRow = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      const offerId = String(event.currentTarget.dataset['offerId']);

      onOfferIdsChange((currOfferIds) => {
        const clonedRows = [...currOfferIds];
        const foundIndex = currOfferIds.findIndex((i) => i === offerId);
        clonedRows.splice(foundIndex, 1);
        return clonedRows;
      });
    },
    [onOfferIdsChange],
  );

  const columns = useMemo<Array<GridColDef>>(
    () => [
      {
        field: 'Actions',
        valueGetter: (_value, row: CustomerCommunication.HeroPlannerOfferDetails) => row.offerId,
        renderCell: (
          params: GridRenderCellParams<
            CustomerCommunication.HeroPlannerOfferDetails,
            CustomerCommunication.HeroPlannerOfferDetails['offerId']
          >,
        ) => (
          <Stack direction="row" flexWrap="wrap">
            <Tooltip title="Delete right away">
              <IconButton size="small" data-offer-id={params.value} onClick={deleteRow}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Open offer in new tab">
              <IconButton size="small" href={params.row.offerLink} target="_blank">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
        display: 'flex',
      },
      {
        field: 'offerId',
        width: 220,
        headerName: 'Offer ID',
        sortable: false,
        display: 'flex',
      },
      {
        field: 'name',
        headerName: 'Offer Name',
        minWidth: 150,
        flex: 1,
        sortable: false,
        display: 'flex',
      },
      {
        field: 'formattedPrice',
        headerName: 'Price',
        minWidth: 150,
        flex: 1,
        sortable: false,
        valueGetter: (_value, row: CustomerCommunication.HeroPlannerOfferDetails) =>
          [row.durationLabel, row.formattedPrice, row.roomType].filter(Boolean).join(' '),
        display: 'flex',
      },
      {
        field: 'visibilities',
        headerName: 'Visibilities',
        minWidth: 150,
        flex: 1.5,
        sortable: false,
        valueGetter: (_value, row: CustomerCommunication.HeroPlannerOfferDetails) => row.offerId,
        renderCell: (params: GridRenderCellParams<CustomerCommunication.HeroPlannerOfferDetails, string>) => (
          <ScheduleOfferVisibilityBlock
            scheduleSendDate={sendDate}
            countryGroupId={countryGroupId}
            countryId={countryId}
            offerId={params.value}
          />
        ),
        display: 'flex',
      },
    ],
    [countryGroupId, countryId, deleteRow, sendDate],
  );

  const isBusy = useMemo(() => {
    return offerDetailsRecords.some(([, req]) => isRequestUnresolved(req));
  }, [offerDetailsRecords]);

  return (
    <Stack direction="column" gap={2}>
      <Stack direction="column" gap={0.5} sx={{ '&:empty': { display: 'none' } }}>
        {offerDetailsRecords.map(([offerDetailsKey, offerDetailsRecord]) => (
          <Fragment key={offerDetailsKey}>
            {isRequestRejected(offerDetailsRecord) && (
              <Alert severity="error">
                <AlertTitle>
                  Could not fetch offer details for{' '}
                  <i>
                    <code>{lastValueOf(offerDetailsRecord?.params)}</code>
                  </i>
                </AlertTitle>
                {offerDetailsRecord.error}
              </Alert>
            )}
          </Fragment>
        ))}
      </Stack>
      <Box position="relative">
        <DataGridPro
          sx={dataGridCustomSx}
          rows={rows}
          getRowId={defaultGridRowIdGetter}
          columns={columns}
          autosizeOptions={{
            columns: ['Actions'],
          }}
          density="compact"
          rowReordering
          loading={isBusy}
          onRowOrderChange={handleOrderChange}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          hideFooter
          getRowHeight={defaultRowHeightGetter}
          getDetailPanelContent={defaultDetailPanelContentGetter}
          getDetailPanelHeight={defaultDetailPanelHeightGetter}
        />
        {isBusy && <LinearProgress sx={{ position: 'absolute', top: '0', width: '100%' }} />}
        <form ref={ref}>
          <input type="hidden" name={SCHEDULE_HERO_OFFERS_INPUT_NAMES.IDS} value={serialisedOfferIds} />
        </form>
      </Box>
      {rows.length > maxOffersCap && <Alert severity="warning">Maximum {maxOffersCap} offers allowed!</Alert>}
      {rows.length < maxOffersCap && (
        <ScheduleNewHeroOfferForm
          currentOfferIds={offerIds}
          countryGroupId={countryGroupId}
          countryId={countryId}
          onOfferDetailsReady={handleReadyOfferDetails}
        />
      )}
    </Stack>
  );
});
ScheduleHeroOffersForm.displayName = 'ScheduleHeroOffersForm';
export default ScheduleHeroOffersForm;
