import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Save } from '@mui/icons-material';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridFilterItem, GridPaginationModel, getGridStringOperators } from '@mui/x-data-grid';

import LereService from '~/services/LereService';
import ReservationService from '~/services/ReservationService';
import {
  formatDateFullDay,
  formatDateISO,
  formatDateSlashes,
  formatDateSlashesWithClock,
  isBetween,
  unixToDate,
} from '~/services/TimeService';

import { convertValueByFX } from '~/utils/convertValueByFX';
import currencyFormatter from '~/utils/currencyFormatter';

import GridPagination from '../Common/Elements/GridPagination';
import LoadingButton from '../Common/LoadingButton';

import { PaginatedSurchargeSuggestions, SurchargeSuggestionBQ, SurchargeSuggestionDB } from './RevenueManagement';
import NameAndIdFormatter from './formatters/NameAndIdFormatter';
import StatisticsFormatter from './formatters/StatisticsFormatter';

interface Props {
  onError: (error: Error | unknown) => void;
  prependChangeLog: (obj: SurchargeSuggestionDB) => void;
}

const ACCEPTED = 'accepted';
const INTERNAL_SURCHARGE_TYPE = 'internal';
const REJECTED = 'rejected';
const equalsOnlyFilter = getGridStringOperators().filter((operator) => operator.value === 'equals');

export default function EditableSurchargeSuggestionsTable({ onError, prependChangeLog }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [surchargeSuggestionData, setSurchargeSuggestionData] = useState<
    PaginatedSurchargeSuggestions<SurchargeSuggestionBQ>
  >({ result: [], total: 0 });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 5,
    page: 0,
  });
  const [isAggregate, setIsAggregate] = useState<boolean>(false);
  const [filters, setFilters] = useState<Array<GridFilterItem>>([]);
  const [actionedRows, setActionedRows] = useState<Array<string>>([]);
  const columns: Array<GridColDef<SurchargeSuggestionBQ>> = [
    {
      field: 'offer_id',
      headerName: 'Offer',
      display: 'flex',
      flex: 1,
      filterOperators: equalsOnlyFilter,
      renderCell: ({ row }) => (
        <NameAndIdFormatter name={row.offer_name} id={row.offer_id} to={`/offers/${row.offer_id}`} />
      ),
    },
    {
      field: 'property_id',
      headerName: 'Property',
      display: 'flex',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <NameAndIdFormatter
          name={row.property_name}
          id={row.property_id}
          to={`/vendors/${row.vendor_id}/properties/${row.property_id}`}
        />
      ),
    },
    {
      field: 'room_type_id',
      headerName: 'Room Type',
      display: 'flex',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <NameAndIdFormatter
          name={row.room_type_name}
          id={row.room_type_id}
          to={`/vendors/${row.vendor_id}/properties/${row.property_id}/room-types/${row.room_type_id}/room-rates/${row.room_rate_id}`}
        />
      ),
    },
    {
      field: 'actioned_start_date',
      headerName: 'Start Date',
      width: 120,
      type: 'date',
      editable: true,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (value, row) => value ?? row.start_date,
      valueSetter: (value, row) => ({ ...row, actioned_start_date: formatDateISO(value) }),
      valueFormatter: formatDateSlashes,
    },
    {
      field: 'actioned_end_date',
      headerName: 'End Date',
      width: 120,
      type: 'date',
      editable: true,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (value, row) => value ?? row.end_date,
      valueSetter: (value, row) => ({ ...row, actioned_end_date: formatDateISO(value) }),
      valueFormatter: formatDateSlashes,
    },
    {
      field: 'statistics',
      headerName: 'Statistics',
      display: 'flex',
      flex: 2,
      filterable: false,
      disableColumnMenu: true,
      renderCell: StatisticsFormatter,
    },
    {
      field: 'actioned_surcharge',
      headerName: 'Suggested Surcharge',
      width: 100,
      editable: true,
      type: 'number',
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (value, row) => value ?? row.after_price - row.before_price,
      valueFormatter: (value: number) => currencyFormatter('AUD', value, 2),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      type: 'singleSelect',
      valueOptions: [ACCEPTED, REJECTED],
      editable: true,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (value) => value ?? 'pending',
      valueFormatter: (value: string) => value.toUpperCase(),
    },
    {
      field: 'note',
      headerName: 'Note',
      flex: 1,
      display: 'flex',
      type: 'string',
      editable: true,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 80,
      renderCell: ({ row }) => {
        return (
          <LoadingButton
            DefaultIcon={Save}
            onSave={() => handleCreateSurcharge(row)}
            disabled={!(row.status === ACCEPTED || row.status === REJECTED) || actionedRows.includes(row.id)}
          />
        );
      },
    },
  ];

  async function getSurchargeDateBlockPrices(
    propertyId: string,
    roomTypeId: string,
    roomRateId: string,
    price: number,
  ): Promise<{ [currencyCode: string]: number }> {
    const surchargeSchema = await ReservationService.getSurchargeDateBlockSchema(propertyId, roomTypeId, roomRateId);
    const currencyCodes = surchargeSchema.post.body.schema.properties.prices.required;
    const fxRatesResponse = await ReservationService.getFXRates();
    const fxRates = fxRatesResponse.result.reduce((acc, fxRate) => ({ ...acc, [fxRate.currency]: fxRate.rate }), {});
    const prices = currencyCodes.reduce(
      (acc, currencyCode) => ({
        ...acc,
        [currencyCode]: convertValueByFX({
          fxRates,
          fromCurrency: 'AUD',
          toCurrency: currencyCode,
          value: price,
        }),
      }),
      {},
    );
    return prices;
  }

  async function handleCreateSurcharge(row: SurchargeSuggestionBQ): Promise<void> {
    const {
      property_id: propertyId,
      room_type_id: roomTypeId,
      room_rate_id: roomRateId,
      actioned_start_date: startDate,
      actioned_end_date: endDate,
      actioned_surcharge: surchargeAmount,
    } = row;

    if (row.status === ACCEPTED) {
      await ReservationService.createSurchargeDateBlock(
        {
          ranges: [{ start_date: formatDateISO(startDate), end_date: formatDateISO(endDate) }],
          cost_amount: 0,
          cost_currency: 'AUD',
          prices: await getSurchargeDateBlockPrices(propertyId, roomTypeId, roomRateId, surchargeAmount),
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
          surcharge_type: INTERNAL_SURCHARGE_TYPE,
        },
        propertyId,
        roomTypeId,
        roomRateId,
      );
    }
    await LereService.createSurchargeSuggestion(row);
    prependChangeLog(row);
    setActionedRows((prev) => [...prev, row.id]);
  }

  useEffect(() => {
    setActionedRows([]);
    LereService.getLatestSurchargeSuggestions(paginationModel.page + 1, paginationModel.pageSize, isAggregate, filters)
      .then(async (response: PaginatedSurchargeSuggestions<SurchargeSuggestionBQ>) => {
        // only enrich data if not aggregate for now
        if (isAggregate) return response;

        try {
          for (const obj of response.result) {
            const vendorSurcharge = await ReservationService.getSurchargeDates(
              obj.property_id,
              obj.room_type_id,
              obj.room_rate_id,
              'vendor',
            );
            const internalSurcharge = await ReservationService.getSurchargeDates(
              obj.property_id,
              obj.room_type_id,
              obj.room_rate_id,
              'internal',
            );
            const availability = await ReservationService.getAvailability(
              obj.property_id,
              obj.room_type_id,
              obj.room_rate_id,
            );

            const getSurchargeTotal = (surchargeData) => {
              const dayKey = formatDateFullDay(obj.start_date).toLowerCase();
              return (
                surchargeData.result.dates.find(
                  (dateBlock) =>
                    isBetween(dateBlock.start_date, dateBlock.end_date, obj.start_date, 'day', '[]') &&
                    !!dateBlock[dayKey],
                )?.prices.AUD ?? 0
              );
            };
            const getBookedAndAllocated = (key) =>
              availability.result.find(
                (dateBlock) =>
                  isBetween(dateBlock.range[0], dateBlock.range[1], obj.start_date, 'day', '[]') && dateBlock.available,
              )?.[key] ?? 0;

            obj.vendor_surcharge_total = getSurchargeTotal(vendorSurcharge);
            obj.internal_surcharge_total = getSurchargeTotal(internalSurcharge);
            obj.booked = getBookedAndAllocated('count');
            obj.allocated = getBookedAndAllocated('total');
            obj.before_price = obj.nightly_price_aud + obj.vendor_surcharge_total + obj.internal_surcharge_total;
            obj.after_price = (obj.nightly_price_aud + obj.vendor_surcharge_total) * obj.multiplier;
            obj.actioned_surcharge = Math.round(obj.after_price - obj.before_price);
          }
        } catch (error) {
          console.error(error);
          enqueueSnackbar('Some of the data on this page may not be updated. Please refresh your page.', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'warning',
          });
        }
        return response;
      })
      .then(setSurchargeSuggestionData)
      .catch(onError);
  }, [paginationModel, setSurchargeSuggestionData, onError, isAggregate, filters, enqueueSnackbar]);

  return (
    <Box>
      <Typography variant="h2" fontSize="2em" fontWeight="medium">
        Surcharge Suggestions
      </Typography>
      <Typography variant="body1">
        Latest surcharge suggestions calculated daily. Last sync:{' '}
        <i>{formatDateSlashesWithClock(unixToDate(surchargeSuggestionData.timestamp))}</i>
      </Typography>
      <ToggleButtonGroup
        color="primary"
        value={isAggregate}
        exclusive
        onChange={(_, value) => setIsAggregate(value)}
        aria-label="aggregate"
        sx={{ marginY: 2 }}
      >
        <ToggleButton value={false}>Granular</ToggleButton>
        <ToggleButton value>Aggregate</ToggleButton>
      </ToggleButtonGroup>
      <DataGrid
        autoHeight
        columns={columns}
        rows={surchargeSuggestionData.result}
        rowCount={surchargeSuggestionData.total}
        loading={surchargeSuggestionData.result.length === 0}
        pagination
        paginationMode="server"
        pageSizeOptions={[5, 10, 15]}
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        getRowHeight={() => 'auto'}
        slots={{ pagination: GridPagination }}
        onFilterModelChange={(model) => setFilters(model.items)}
        disableRowSelectionOnClick
        disableColumnSorting
      />
    </Box>
  );
}
