import React, { useCallback, useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';

import VendorsService from '~/services/VendorsService';

import { ManualPaymentsRecord } from './ManualPaymentsList';
import { uiSchema } from './ManualPaymentsPane';

interface Props {
  schema: RJSFSchema;
  offerId: string;
  vendorId: string;
  onManualPaymentAdded: (manualPayment: ManualPaymentsRecord) => void;
}

export function ManualPaymentsAddForm({ onManualPaymentAdded, schema, offerId, vendorId }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [formData] = useState<ManualPaymentsRecord>({
    date_of_manual_payment: new Date().toISOString().slice(0, 10),
    fk_offer: offerId,
    fk_vendor: vendorId,
    currency: 'AUD',
    amount: '0',
    le_entity_currency: 'AUD',
    le_entity_amount: '0',
    bank_transaction: '',
    description: '',
  });

  const validationHandler = useCallback((data, errors) => {
    const amount = Number.parseFloat(data.amount);

    if (!Number.isFinite(amount)) {
      errors.amount.addError('should be a number');
    }

    return errors;
  }, []);

  const submitHandler = useCallback(
    async (e: IChangeEvent) => {
      try {
        const res = await VendorsService.createManualPayment(e.formData);
        enqueueSnackbar(`Manual payment saved`, { variant: 'success' });
        onManualPaymentAdded(res.result);
      } catch (err) {
        enqueueSnackbar(`Can't save manual payment: ${err.message}`, { variant: 'error' });
      }
    },
    [onManualPaymentAdded, enqueueSnackbar],
  );

  return (
    <Box id="manualpayments-form" mb={2}>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={submitHandler}
        customValidate={validationHandler}
        validator={validator}
        formData={formData}
      />
    </Box>
  );
}
