import React, { useEffect, useState } from 'react';

import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';

import { RuleCondition } from '~/consts/agentHub';

import { getAffiliationList } from '~/services/AgentHub/AgentService';

import { Affiliation } from '~/types/services/agentHub';

interface Props {
  setCreatedConditions: (condition: RuleCondition) => void;
}

function AgentHubCommissionsAffiliationInput(props: Props) {
  const { setCreatedConditions } = props;
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [result, setResult] = useState<Array<Affiliation>>([]);
  const [selectedAffiliations, setSelectedAffiliations] = useState<Array<Affiliation>>([]);

  const handleSaveCondition = () => {
    setCreatedConditions({
      type: 'affiliation',
      value: selectedAffiliations.map((affiliation) => ({
        value: affiliation.name,
        label: `${affiliation.region} - ${affiliation.name}`,
      })),
    });
  };

  const handleChange = (_: React.ChangeEvent, value: Array<Affiliation>) => {
    setSelectedAffiliations(value);
  };

  const fetchAffiliationList = async () => {
    setLoadingState('loading');
    getAffiliationList()
      .then((result) => setResult(result))
      .finally(() => setLoadingState('success'));
  };

  useEffect(() => {
    fetchAffiliationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultProps = {
    options: result,
    getOptionLabel: (option: Affiliation) => `${option.region} - ${option.name}`,
  };

  return (
    <Stack spacing={2}>
      <div>
        <Autocomplete
          {...defaultProps}
          multiple
          loading={loadingState === 'loading'}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Affiliations" placeholder="Affiliations" />}
          value={selectedAffiliations}
          onChange={handleChange}
          noOptionsText={'No affiliations found'}
        />
        <Typography variant="caption" color="textSecondary">
          Start typing to search for agencies
        </Typography>
      </div>

      <Button onClick={handleSaveCondition} variant="contained">
        Add Condition
      </Button>
    </Stack>
  );
}

export default AgentHubCommissionsAffiliationInput;
