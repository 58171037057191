import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import CancelOutlined from '@mui/icons-material/CancelOutlined';
import EditIcon from '@mui/icons-material/Edit';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey, orange } from '@mui/material/colors';

import AIGeneratorButton from '~/components/AIGenerator/AIGenerateButton';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';

import BedbankService from '~/services/BedbankService';

import { generateOccupancyText, generateRoomSizeText, generateViewsText } from './RoomMapping/util';
import { RoomStatusStatusVisibility } from './RoomStatusVisibility';
import { RoomStatusEnum, chipColorByStatus } from './common';

interface RoomManagementProps {
  handleChange: (data: any) => void;
  property: App.Bedbank.PropertyEdit;
  promotions: App.Bedbank.PromotionsEdit[];
  onSubmit?: (form: any) => void;
}

export default function BedbankRoomManagement({ handleChange, property, promotions }: RoomManagementProps) {
  const { enqueueSnackbar } = useSnackbar();
  const rooms = property.rooms;
  const [toggleContentApproved, setToggleContentApproved] = useState(false);
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [mergeData, setMergeData] = useState<{ newParent: any; decoupledRoom: any } | null>();
  const [statusChange, setStatusChange] = useState<{ newStatus: string; room: any } | null>();

  const ASSIGNABLE_STATUSES = [RoomStatusEnum.contentApproved, RoomStatusEnum.decoupled, RoomStatusEnum.excluded];

  const displayRooms = useMemo(() => {
    return rooms
      .filter((room: any) => {
        if (toggleContentApproved) {
          return room.status === RoomStatusEnum.contentApproved;
        }
        return true;
      })
      .map((room: any) => {
        return {
          ...room,
          sourceContent: room.mappedRooms.find((mr) => mr.externalId === room.externalId)?.sourceContent,
        };
      });
  }, [rooms, toggleContentApproved]);

  const handleRoomChange = useCallback(
    (value: any, id: string, key: string) => {
      const newFormData = { ...formData };
      newFormData[id][key] = value;
      setFormData(newFormData);
      handleChange({ formData: Object.values(newFormData) });
    },
    [formData, handleChange],
  );

  const confirmRoomStatusChange = useCallback(async () => {
    if (!statusChange) return;
    const { newStatus, room } = statusChange;
    try {
      const resp = await BedbankService.patchRoom(property.id, room.id, {
        status: newStatus,
      });
      handleRoomChange(newStatus, room.id, 'status');
      setStatusChange(null);
      if (resp) {
        enqueueSnackbar('Room status updated successfully, reloading property data...', { variant: 'success' });
        setTimeout(() => {
          window.location.reload();
        }, 50);
      }
    } catch (err) {
      enqueueSnackbar('Failed to update room status', { variant: 'error' });
    }
  }, [enqueueSnackbar, handleRoomChange, property.id, statusChange]);

  const handlePromotionChange = useCallback(
    (roomId: string, promo: string) => {
      const newFormData = { ...formData };
      newFormData[roomId].promotionId = promo;
      setFormData(newFormData);
      handleChange({ formData: Object.values(newFormData) });
    },
    [formData, handleChange],
  );

  useEffect(() => {
    const roomMap = rooms.reduce((acc: any, room: any) => {
      acc[room.id] = room;
      return acc;
    }, {});
    setFormData(roomMap);
    setExpanded(
      Object.keys(roomMap).reduce((acc: any, key) => {
        acc[key] = false;
        return acc;
      }, {}),
    );
  }, [rooms]);

  const toggleExpand = useCallback((id: string) => {
    setExpanded((prev) => {
      return {
        ...prev,
        [id]: !prev[id],
      };
    });
  }, []);

  const handleRoomStatusChange = useCallback(
    (roomId: string) => (event: SelectChangeEvent<string>) => {
      const newStatus = event.target.value;
      handleRoomChange(newStatus, roomId, 'status');
    },
    [handleRoomChange],
  );

  const remapDecoupledRoom = useCallback(
    (e: any, id: string) => {
      const newParent = property.rooms.find((r: any) => r.id === e.target.value);
      const decoupledRoom = property.rooms.find((r) => r.id === id);
      setMergeData({
        newParent,
        decoupledRoom,
      });
    },
    [property.rooms],
  );

  const confirmMerge = useCallback(async () => {
    if (!mergeData) return;
    const { newParent, decoupledRoom } = mergeData;
    try {
      const resp = await BedbankService.remapDecoupledRoom(property.id, decoupledRoom.id, {
        targetRoomId: newParent.id,
      });
      setMergeData(null);
      if (resp) {
        enqueueSnackbar('Room merged successfully, reloading property data...', { variant: 'success' });
        setTimeout(() => {
          window.location.reload();
        }, 50);
      }
    } catch (err) {
      enqueueSnackbar('Failed to merge room', { variant: 'error' });
    }
  }, [enqueueSnackbar, mergeData, property.id]);

  const fakeHtmlEvent = (value: any): React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => {
    return { target: { value } } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Room Management</Typography>
        <Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1">All Room Statuses</Typography>
            <Switch checked={toggleContentApproved} onChange={(e) => setToggleContentApproved(e.target.checked)} />
            <Typography variant="body1">Only Content Approved</Typography>
          </Stack>
        </Box>
      </Stack>
      <Box>
        {displayRooms.map((room: any) => (
          <Accordion key={room.id} expanded={expanded[room.id]} onChange={() => toggleExpand(room.id)}>
            <AccordionSummary expandIcon={<EditIcon />}>
              <Grid container gap={1} alignItems="center">
                <Grid item xs={8}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography fontWeight="bold">{room.nameOverride ?? room.name}</Typography>
                    <Typography>{room.externalId}</Typography>
                    <Chip label={room.supplier} />
                  </Stack>
                </Grid>
                <Grid item xs={1}>
                  <Chip label={room.status} variant="outlined" color={chipColorByStatus[room.status]}></Chip>
                </Grid>
                <Grid item xs={2}>
                  <ButtonGroup>
                    {room.status === RoomStatusEnum.decoupled && (
                      <Tooltip title="Promote to Content Approved">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={(e) => {
                            e.stopPropagation();
                            setStatusChange({ newStatus: RoomStatusEnum.contentApproved, room });
                          }}
                        >
                          <UpgradeIcon />
                        </Button>
                      </Tooltip>
                    )}
                    {room.status !== RoomStatusEnum.excluded && (
                      <Tooltip title="Exclude Room from customer portal">
                        <Button
                          variant="outlined"
                          sx={{ color: grey[500], borderColor: grey[500] }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setStatusChange({ newStatus: RoomStatusEnum.excluded, room });
                          }}
                        >
                          <VisibilityOffIcon />
                        </Button>
                      </Tooltip>
                    )}
                    {room.status === RoomStatusEnum.excluded && (
                      <Tooltip title="Switch to decoupled">
                        <Button
                          variant="outlined"
                          sx={{ color: orange[500], borderColor: orange[500] }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setStatusChange({ newStatus: RoomStatusEnum.decoupled, room });
                          }}
                        >
                          <VisibilityIcon />
                        </Button>
                      </Tooltip>
                    )}
                  </ButtonGroup>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <FormControl>
                  <Typography variant="h6">Name</Typography>
                  <TextField
                    type="text"
                    value={formData[room.id]?.nameOverride ?? room.nameOverride ?? room.name}
                    onChange={(e) => handleRoomChange(e.target.value, room.id, 'nameOverride')}
                  />
                </FormControl>
                <FormControl>
                  <Typography variant="h6">Description</Typography>
                  <MarkdownEditor
                    value={formData[room.id]?.descriptionOverride}
                    initialValue={room.descriptionOverride ?? room.description}
                    placeholder={room.descriptionOverride ?? room.description}
                    onChange={(val) => handleRoomChange(val, room.id, 'descriptionOverride')}
                    multiline={true}
                  />
                  <AIGeneratorButton
                    context={{
                      propertyName: property.name,
                      roomName: room.nameOverride ?? room.name,
                      model: 'room-type',
                      field: 'description',
                    }}
                    content={room.descriptionOverride ?? room.description}
                    buttonLabel={`${room.descriptionOverride ? 'Rewrite' : 'Generate'} Description`}
                    updateContext={() => ({
                      propertyName: property.name,
                      roomName:
                        formData[room.id]?.nameOverride ?? formData[room.id].name ?? room.nameOverride ?? room.name,
                      model: 'room-type',
                      field: 'description',
                    })}
                    onChoose={(value) => handleRoomChange(value, room.id, 'descriptionOverride')}
                  />
                </FormControl>
                <FormControl>
                  <Typography variant="h6">Status</Typography>
                  <Select
                    id="roomStatus"
                    onChange={handleRoomStatusChange(room.id)}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    value={formData[room.id]?.status ?? room.status}
                    input={<OutlinedInput />}
                  >
                    {ASSIGNABLE_STATUSES.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <Typography variant="h6">Promotions</Typography>
                  <Select
                    value={formData[room.id]?.promotionId ?? room.promotionId}
                    onChange={(e) => handlePromotionChange(room.id, e.target.value)}
                    renderValue={(selected) => {
                      return promotions.find((p) => p.promoId === selected)?.promoName ?? selected;
                    }}
                  >
                    {promotions?.map((promotion: App.Bedbank.PromotionsEdit) => (
                      <MenuItem key={promotion.promoId} value={promotion.promoId}>
                        {promotion.promoName}
                      </MenuItem>
                    ))}
                    {promotions?.length === 0 && <MenuItem disabled>No active promotions</MenuItem>}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <Typography variant="h6">Room Mappings</Typography>
                  <Stack direction="row" spacing={1}>
                    {(room?.mappedRooms ?? []).map((item, index) => (
                      <Chip
                        key={index}
                        label={item.externalId}
                        icon={
                          !item.isVerified ? (
                            <CancelOutlined fontSize="small" sx={{ color: orange[300] }} titleAccess="Not Verified" />
                          ) : null
                        }
                      />
                    ))}
                    {room.mappedRooms.length === 0 && <em>No Mappings Found</em>}
                  </Stack>
                </FormControl>
                <Box my={2} p={2} border="solid 1px #eee">
                  <Typography variant="h6">Supplier Data</Typography>
                  <Typography>
                    <strong>Name:</strong> {room.name}
                  </Typography>
                  <Typography>
                    <strong>Description:</strong>{' '}
                    {room.description ?? room.sourceContent?.wildcards?.at(0)?.hotelRoomDescription?.content ?? 'N/A'}
                  </Typography>
                  <Typography>
                    <strong>Views:</strong> {generateViewsText(room.sourceContent)}
                  </Typography>
                  <Typography>
                    <strong>Room Size:</strong> {generateRoomSizeText(room)}
                  </Typography>
                  <Typography>
                    <strong>Occupancy Age:</strong> {generateOccupancyText(room).occupancyAge}
                  </Typography>
                  <Typography>
                    <strong>Occupancy Allowed:</strong> {generateOccupancyText(room).occupancyAllowed}
                  </Typography>
                </Box>
                {room.status === RoomStatusEnum.decoupled && (
                  <FormControl>
                    <Typography variant="h6">Merge With Content Approved Room</Typography>
                    <Select
                      onChange={(e) => remapDecoupledRoom(e, room.id)}
                      placeholder="Select an approved room to merge to:"
                    >
                      {rooms
                        .filter((r) => r.status === RoomStatusEnum.contentApproved)
                        .map((r: any) => (
                          <MenuItem key={r.id} value={r.id}>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Typography fontWeight="bold">{r.nameOverride ?? r.name}</Typography>
                              <Typography>{r.externalId}</Typography>
                              <Chip label={r.supplier} />
                            </Stack>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Dialog open={!!mergeData} onSubmit={() => confirmMerge()}>
        {mergeData && (
          <DialogContent>
            <Box my={2}>
              <Typography variant="h6">Merging a decoupled room</Typography>
              <Typography>
                Merging: <strong>{mergeData.decoupledRoom.nameOverride ?? mergeData.decoupledRoom.name}</strong> into
                the room: <strong>{mergeData.newParent.nameOverride ?? mergeData.newParent.name}</strong> <br />
                will move the following mappings to the new parent room:
              </Typography>
            </Box>
            <Box my={2}>
              {mergeData.decoupledRoom.mappedRooms.map((mappedRoom, i) => (
                <Stack key={i} direction="row" gap={1} alignItems="center">
                  {mappedRoom.isVerified && <Chip label="Verified" color="success" size="small" />}
                  {!mappedRoom.isVerified && <Chip label="Unverified" color="warning" size="small" />}
                  <span>
                    <Chip label={mappedRoom.supplier} />
                    {(mappedRoom.sourceContent?.description && mappedRoom.sourceContent?.description !== 'NA'
                      ? mappedRoom?.sourceContent?.description
                      : mappedRoom?.sourceContent?.name) ?? 'None Provided'}{' '}
                    - {mappedRoom.externalId}
                  </span>
                </Stack>
              ))}
              {mergeData.decoupledRoom.mappedRooms.length === 0 && <em>No mappings found for the decoupled room</em>}
            </Box>
            <DialogActions>
              <Button onClick={() => setMergeData(null)} color="error" variant="outlined">
                Cancel
              </Button>
              <Button onClick={confirmMerge} color="success" variant="contained" endIcon={<UpgradeIcon />}>
                Confirm Merge
              </Button>
            </DialogActions>
          </DialogContent>
        )}
      </Dialog>
      <Dialog maxWidth="lg" open={!!statusChange} onSubmit={() => confirmRoomStatusChange()}>
        {statusChange && (
          <DialogContent>
            <Box my={2}>
              <Typography variant="h5">Changing Room Status</Typography>
              <Typography>
                Changing the room status will affect the visibility of the room on the customer portal.
              </Typography>
            </Box>
            <Box my={2}>
              <Grid container>
                <Grid item xs={5} alignItems="center">
                  <Typography variant="h6">
                    Current Status{' '}
                    <Chip
                      label={statusChange.room.status}
                      color={chipColorByStatus[statusChange.room.status]}
                      variant="outlined"
                    />
                  </Typography>

                  <RoomStatusStatusVisibility roomStatus={statusChange.room.status} />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={6} alignItems="center">
                  <Typography variant="h6">
                    New Status{' '}
                    <Chip
                      label={statusChange.newStatus}
                      color={chipColorByStatus[statusChange.newStatus]}
                      variant="outlined"
                    />
                  </Typography>
                  <RoomStatusStatusVisibility roomStatus={statusChange.newStatus} />
                </Grid>
              </Grid>
            </Box>
            <DialogActions>
              <Button onClick={() => setStatusChange(null)} color="error" variant="outlined">
                Cancel
              </Button>
              <Button onClick={confirmRoomStatusChange} color="success" variant="contained" endIcon={<UpgradeIcon />}>
                Confirm Status Change
              </Button>
            </DialogActions>
          </DialogContent>
        )}
      </Dialog>
    </Box>
  );
}
