import React from 'react';

import { Box } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import useQuery from '~/hooks/useQuery';

import { DiscountItemInfo } from '~/services/PromoService';

import PromoItemResult from './PromoItemResult';

type PromoOrderTableCellProps = {
  discountItems: Array<DiscountItemInfo> | null;
  currency: string;
  region: string;
  meta: definitions['PromoMeta'];
};

const PromoItemResultTableCell = ({ discountItems, region, currency, meta }: PromoOrderTableCellProps) => {
  const query = useQuery();
  const isDevMode = query.get('dev') === 'true';

  return (
    <Box sx={{ width: '100%' }}>
      {discountItems?.map((discountItem, i) => (
        <PromoItemResult currency={currency} discountItem={discountItem} isDevMode={isDevMode} key={i} meta={meta} />
      ))}
    </Box>
  );
};

export { PromoItemResultTableCell };
