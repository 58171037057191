export const chipColorByStatus: Record<string, any> = {
  'content-approved': 'success',
  decoupled: 'warning',
  excluded: 'error',
  remapped: 'info',
};

export enum RoomStatusEnum {
  contentApproved = 'content-approved',
  remapped = 'remapped',
  decoupled = 'decoupled',
  excluded = 'excluded',
}

export const HB_ROOM_SIZE_FACILITY_CODE = 295;
