import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Button, Card, CardActions, CardContent, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import PageHeader from '~/components/Common/Elements/PageHeader';

type PageItem = {
  title: string;
  description: string;
  url: string;
};

const items: PageItem[] = [
  {
    title: 'Admin Users & Roles',
    description: 'Search, create and edit admin users and roles',
    url: '/system/admin-users',
  },
  {
    title: 'Reservation Remediation',
    description: 'See failed reservations & detailed logs',
    url: '/system/reservation-remediation',
  },
  {
    title: 'Account Deletions',
    description: 'Bulk delete accounts',
    url: '/system/account-deletions',
  },
  {
    title: 'Audit Logs',
    description: 'Search audit logs',
    url: '/system/audit-logs',
  },
  {
    title: 'Cancellation Modal Settings',
    description: 'Configure rules for the CS phone number cancellation modal in My Escapes',
    url: '/system/settings',
  },
];

export default function SystemPageContainer() {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>System | Admin | Users & Roles</title>
      </Helmet>

      <PageHeader title="System actions" />
      <Grid spacing={2} container>
        {items.map((item, i) => {
          return (
            <Grid xs={12} md={4} lg={3} key={i}>
              <Card variant="outlined">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="p">
                    {item.title}
                  </Typography>

                  <Typography variant="body1" color="text.secondary">
                    {item.description}
                  </Typography>
                </CardContent>

                <CardActions>
                  <Button component={Link} to={item.url} color="primary">
                    Open
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
