import React from 'react';

import Alert from '@mui/material/Alert';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import { BookingRequest } from '../types/types';

interface BookingRequestsTableProps {
  bookingRequests: Array<BookingRequest>;
}

const columns: Array<GridColDef> = [
  {
    field: 'type',
    headerName: 'Type',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value)),
    display: 'flex',
  },
  {
    field: 'status',
    headerName: 'Action/Status',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    renderCell: (params) => {
      return <Alert severity={params.row.statusSeverity}>{params.row.statusText}</Alert>;
    },
    display: 'flex',
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value)),
    display: 'flex',
  },
];

function BookingRequestsTable({ bookingRequests }: BookingRequestsTableProps) {
  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'created_at', sort: 'desc' }],
        },
      }}
      columns={columns}
      rows={bookingRequests}
      getRowId={(row) => `${row.type}-${row.id}`}
      autoHeight
      pageSizeOptions={[]}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
      getRowHeight={() => 'auto'}
    />
  );
}

export default BookingRequestsTable;
