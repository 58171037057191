import React, { useCallback, useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/material';

import { formatDateISO } from '~/services/TimeService';
import VendorsService from '~/services/VendorsService';

import { ManualPaymentsRecord } from './ManualPaymentsList';
import { uiSchema } from './ManualPaymentsPane';

interface Props {
  schema: RJSFSchema;
  id_manual_payment: string;
  date_of_manual_payment: string;
  fk_offer: string;
  fk_vendor: string;
  currency: string;
  amount: string;
  le_entity_currency: string;
  le_entity_amount: string;
  bank_transaction: string;
  description: string;
  onManualPaymentUpdated: (manualPayment: ManualPaymentsRecord) => void;
}

export function ManualPaymentsUpdateForm(props: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [formData] = useState<ManualPaymentsRecord>({
    id_manual_payment: props.id_manual_payment,
    date_of_manual_payment: formatDateISO(props.date_of_manual_payment),
    fk_offer: props.fk_offer,
    fk_vendor: props.fk_vendor,
    currency: props.currency,
    amount: props.amount,
    le_entity_currency: props.le_entity_currency,
    le_entity_amount: props.le_entity_amount,
    bank_transaction: props.bank_transaction,
    description: props.description,
  });

  const onManualPaymentUpdated = props.onManualPaymentUpdated;

  const validationHandler = useCallback((data, errors) => {
    const amount = Number.parseFloat(data.amount);

    if (!Number.isFinite(amount)) {
      errors.amount.addError('should be a number');
    }

    return errors;
  }, []);

  const submitHandler = useCallback(
    async (e: IChangeEvent) => {
      try {
        const res = await VendorsService.updateManualPayment(e.formData);
        enqueueSnackbar(`Manual payment updated`, { variant: 'success' });
        onManualPaymentUpdated(res.result);
      } catch (err) {
        enqueueSnackbar(`Can't update manual payment: ${err.message}`, { variant: 'error' });
      }
    },
    [onManualPaymentUpdated, enqueueSnackbar],
  );

  return (
    <Box id="manualpayments-form" mb={2}>
      <Form
        schema={props.schema}
        uiSchema={uiSchema}
        onSubmit={submitHandler}
        customValidate={validationHandler}
        validator={validator}
        formData={formData}
      />
    </Box>
  );
}
