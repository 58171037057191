import React from 'react';

import classNames from 'clsx';

import SellIcon from '@mui/icons-material/Sell';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';

import { Order } from '@luxuryescapes/contract-svc-order';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import RestrictedComponent from '~/components/Common/RestrictedComponent';

import {
  FULLY_REFUNDED_BY_BOOKING_PROTECTION,
  PARTIALLY_REFUNDED_BY_BOOKING_PROTECTION,
} from '~/consts/bookingProtection';
import { ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import { diffDays } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';

import PriceSummaryField from '../OrderItem/PriceSummaryField';

interface Props {
  order: App.Order;
  bookingProtectionItems: Array<Order.BookingProtectionItem>;
  showRefundModal;
  hasAllowedRefund: boolean;
}

const getStatusColorText = (status: string, rp_triggered_refund_status: string): { color: string; text: string } => {
  switch (status) {
    case 'cancelled':
      return { color: 'error.main', text: 'Cancelled' };
    case 'completed': {
      if (rp_triggered_refund_status === FULLY_REFUNDED_BY_BOOKING_PROTECTION) {
        return { color: 'success.main', text: 'Fully Refunded by Refund Protect' };
      } else if (rp_triggered_refund_status === PARTIALLY_REFUNDED_BY_BOOKING_PROTECTION) {
        return { color: 'success.main', text: 'Partially Refunded by Refund Protect' };
      } else {
        return { color: 'success.main', text: 'Completed' };
      }
    }
    default:
      return { color: 'warning.main', text: 'Unknown' };
  }
};

export default function BookingProtectionItem({
  order,
  bookingProtectionItems,
  showRefundModal,
  hasAllowedRefund,
}: Props) {
  const isWithin7days = diffDays(new Date(order.created_at)) <= 7;

  const refundInitiatedFromInsuranceProvider = bookingProtectionItems.some(
    (item) => item.status === 'completed' && item.refund_initiated_from_provider,
  );

  const canRefundBookingProtection = isWithin7days && hasAllowedRefund && !refundInitiatedFromInsuranceProvider;

  return (
    <Box mt={2}>
      <FoldableSection title="Cancellation Protection (Refund Protect)" initiallyExpanded>
        {bookingProtectionItems.map((item, index) => (
          <Box
            mt={2}
            display="grid"
            gap={3}
            gridTemplateColumns="1fr 1fr 150px"
            key={index}
            className={classNames('order-item', {
              cancelled: item.status === 'cancelled',
            })}
          >
            <Stack gap={1}>
              <Typography variant="subtitle1" fontWeight="bold">
                General information
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography>Status</Typography>
                <Typography
                  fontWeight="bold"
                  color={getStatusColorText(item.status, item.rp_triggered_refund_status).color}
                >
                  {getStatusColorText(item.status, item.rp_triggered_refund_status).text}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography>Number of tickets (no. of travellers)</Typography>
                <Typography fontWeight="bold">{item.number_of_tickets}</Typography>
              </Stack>
            </Stack>
            <Stack gap={1} mt={1}>
              <Typography variant="subtitle1" fontWeight="bold">
                Pricing
              </Typography>
              <Stack direction="row" spacing={1}>
                <SellIcon />
                <PriceSummaryField
                  boldPrice
                  label="Sell price"
                  value={currencyFormatter(item.currency_code, item.total, 2)}
                />
              </Stack>
              <Stack direction="row" spacing={1}>
                <SellIcon />
                <PriceSummaryField
                  boldPrice
                  label="Public price"
                  value={currencyFormatter(item.currency_code, item.public_price, 2)}
                />
              </Stack>
              <Stack direction="row" spacing={1}>
                <SellIcon />
                <PriceSummaryField
                  boldPrice
                  label="LuxPlus price"
                  value={currencyFormatter(item.currency_code, item.lux_plus_price, 2)}
                />
              </Stack>
            </Stack>
            <RestrictedComponent excludedRoles={[ROLE_EXPERIENCES_COORDINATOR]}>
              {canRefundBookingProtection && item.status !== 'cancelled' && (
                <Grid item xs={2} md={1}>
                  <Button
                    onClick={() => {
                      showRefundModal({ itemId: item.id_booking_protection_items });
                    }}
                    sx={{
                      width: 'max-content',
                    }}
                    className="T-issue-refund"
                    color="error"
                  >
                    Issue refund
                  </Button>
                </Grid>
              )}
            </RestrictedComponent>
          </Box>
        ))}
      </FoldableSection>
    </Box>
  );
}
