import React from 'react';

import { Box, Typography } from '@mui/material';

type Props = {
  texts: string[];
};

export default function WrapDetails({ texts }: Props) {
  return (
    <Box>
      {texts.map((text) => (
        <Typography>{text}</Typography>
      ))}
    </Box>
  );
}
