import React from 'react';

import { Stack, Typography } from '@mui/material';

type Props = {
  status: string;
  bookingNumber: string;
};

export default function CruisesOrderStatus({ status, bookingNumber }: Props) {
  const bookingNumberAndStatusText = bookingNumber ? `${bookingNumber} - ${status}` : status;

  return (
    <Stack direction="column">
      {status === 'cancelled' && (
        <Typography component="span" sx={{ textDecoration: 'line-through' }}>
          {bookingNumberAndStatusText}
        </Typography>
      )}

      {status !== 'cancelled' && <Typography component="span">{bookingNumberAndStatusText}</Typography>}
    </Stack>
  );
}
