import React, { useMemo } from 'react';

import { Box, Divider, Grid, Stack, Typography } from '@mui/material';

enum INCLUSION_STATUS {
  INCLUDED = 'INCLUDED',
  NOT_OFFERED = 'NOT_OFFERED',
  CHARGED = 'CHARGED',
}

interface Props {
  inclusions: App.BookingJourneyInclusion[];
}

export default function OrderFlightFareFamilyInclusions({ inclusions }: Props) {
  const allInclusions = useMemo(() => {
    return inclusions.map((fareFeature) => ({
      description: fareFeature.description,
      status:
        fareFeature.status === INCLUSION_STATUS.INCLUDED
          ? 'Included'
          : fareFeature.status === INCLUSION_STATUS.NOT_OFFERED
          ? 'Not Included'
          : 'Charged',
    }));
  }, [inclusions]);

  return (
    <Box mt={4} mb={4}>
      <Divider textAlign="left">Fare Family Inclusions</Divider>
      <Grid container spacing={2} mt={2} mb={2}>
        {allInclusions.map((feature, index) => (
          <Grid key={index} item xs={4} direction="row">
            <Stack direction="row" spacing={1}>
              <Typography fontWeight="bold">{feature.description}:</Typography>
              <Typography>{feature.status}</Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
