import * as React from 'react';

import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';

import { dateNowUtc, formatDateSlashes, formatDateSlashesWithClock } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';

import GridPagination from '../Common/Elements/GridPagination';

import { PaginatedSurchargeSuggestions, SurchargeSuggestionDB } from './RevenueManagement';
import NameAndIdFormatter from './formatters/NameAndIdFormatter';

interface Props {
  paginationModel: GridPaginationModel;
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>;
  data: PaginatedSurchargeSuggestions<SurchargeSuggestionDB>;
}

export default function SurchargeSuggestionsTable({ paginationModel, setPaginationModel, data }: Props) {
  const columns: Array<GridColDef<SurchargeSuggestionDB>> = [
    {
      field: 'offer_id',
      headerName: 'Offer',
      display: 'flex',
      flex: 1,
      renderCell: ({ row }) => (
        <NameAndIdFormatter name={row.offer_name} id={row.offer_id} to={`/offers/${row.offer_id}`} />
      ),
    },
    {
      field: 'property_id',
      headerName: 'Property',
      display: 'flex',
      flex: 1,
      renderCell: ({ row }) => (
        <NameAndIdFormatter
          name={row.property_name}
          id={row.property_id}
          to={`/vendors/${row.vendor_id}/properties/${row.property_id}`}
        />
      ),
    },
    {
      field: 'room_type_id',
      headerName: 'Room Type',
      display: 'flex',
      flex: 1,
      renderCell: ({ row }) => (
        <NameAndIdFormatter
          name={row.room_type_name}
          id={row.room_type_id}
          to={`/vendors/${row.vendor_id}/properties/${row.property_id}/room-types/${row.room_type_id}/room-rates/${row.room_rate_id}`}
        />
      ),
    },
    {
      field: 'suggested',
      headerName: 'Suggested',
      display: 'flex',
      flex: 2,
      renderCell: ({ row }) => (
        <Box>
          <Typography variant="body1">
            <b>Date Range:</b> {`${formatDateSlashes(row.start_date)} - ${formatDateSlashes(row.end_date)}`}
          </Typography>
          <Typography variant="body1">
            <b>Before Nightly Price:</b> {currencyFormatter('AUD', row.before_price, 2)}
          </Typography>
          <Typography variant="body1">
            <b>Nightly Price:</b> {currencyFormatter('AUD', row.after_price, 2)}
          </Typography>
          <Typography variant="body1">
            <b>Rev Surcharge:</b> {currencyFormatter('AUD', row.after_price - row.before_price, 2)}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'actioned',
      headerName: 'Actioned',
      display: 'flex',
      flex: 2,
      renderCell: ({ row }) => (
        <Box>
          <Typography variant="body1">
            <b>Date Range:</b>{' '}
            {`${formatDateSlashes(row.actioned_start_date)} - ${formatDateSlashes(row.actioned_end_date)}`}
          </Typography>
          <Typography variant="body1">
            <b>Rev Surcharge:</b> {currencyFormatter('AUD', row.actioned_surcharge, 2)}
          </Typography>
          {row?.note && (
            <Typography variant="body1">
              <b>Note:</b> {row.note}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      valueFormatter: (value: string) => value.toUpperCase(),
    },
    {
      field: 'created_at',
      headerName: 'Actioned At',
      width: 120,
      valueFormatter: (value: string) => formatDateSlashesWithClock(dateNowUtc(value)),
    },
  ];

  return (
    <DataGrid
      autoHeight
      columns={columns}
      rows={data.result}
      rowCount={data.total}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      pageSizeOptions={[5, 10, 15]}
      onPaginationModelChange={(model) => setPaginationModel(model)}
      getRowHeight={() => 'auto'}
      slots={{ pagination: GridPagination }}
      disableColumnFilter
      disableColumnMenu
      disableRowSelectionOnClick
      disableColumnSorting
      disableMultipleRowSelection
    />
  );
}
