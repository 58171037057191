import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { Box, Button, LinearProgress, MenuItem, Stack, Typography } from '@mui/material';

import { Input, Select } from '~/components/Common/Forms/fields';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import { CreatePromoUserPayload, createOrUpdatePromoUsers } from '~/services/PromoService';

import Spinner from '../../Common/Spinner';

interface UserBatchUploadProps {
  codeName: string;
  promoExpiresAt: string;
}

interface InputRow {
  userId: string;
}

interface PromoBatchUploadFormData {
  codeName: string;
  promoUserType: string;
  userList: string;
  expiryDate: string;
}

function PromoUserBatchUpload({ codeName, promoExpiresAt }: UserBatchUploadProps) {
  const { control, handleSubmit, getValues } = useForm<PromoBatchUploadFormData>({
    defaultValues: {
      promoUserType: 'LUX-USER',
      codeName: codeName || '',
      userList: '',
      expiryDate: new Date(promoExpiresAt).toISOString().split('T')[0],
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const { tenant } = useCurrentTenant();

  const sendBatches = async (batches: Array<Array<InputRow>>) => {
    setIsLoading(true);
    let currentBatch = 0;

    for (const batch of batches) {
      try {
        if (batch.length == 0) {
          continue;
        }
        const formValues = getValues();

        const usersPayload: CreatePromoUserPayload = batch.map((user): CreatePromoUserPayload['0'] => {
          const includeUserID = !user.userId.includes('@');

          const identifiers = includeUserID
            ? {
                user_id: user.userId,
              }
            : {};

          return {
            brand: tenant.brand as CreatePromoUserPayload['0']['brand'],
            code_name: formValues.codeName,
            promo_code_user_type: formValues.promoUserType as CreatePromoUserPayload['0']['promo_code_user_type'],
            expires_at: new Date(formValues.expiryDate).toISOString(),
            external_ids: [user.userId],
            ...identifiers,
          };
        });

        const usersRes = await createOrUpdatePromoUsers({
          promoUsers: usersPayload,
        }).catch((err) => {
          enqueueSnackbar(`Error uploading batch ${currentBatch + 1} ${JSON.stringify(err, null, 4)}`, {
            variant: 'error',
          });
        });
        const users = usersRes.result.promoUsers;
        if (users.length > 0) {
          enqueueSnackbar(
            ` ${users.length} user(s) uploaded to ${formValues.codeName} successfully ${
              batches.length > 1 ? `${currentBatch + 1}/${batches}` : ''
            }`,
            { variant: 'success' },
          );
        }
        if (users.length !== batch.length) {
          enqueueSnackbar(
            `Some users failed to be created. Requested: ${batch.length}, but only got ${users.length} in response`,
            {
              variant: 'error',
            },
          );
        }
      } catch (error) {
        enqueueSnackbar(`Error uploading batch ${currentBatch + 1} ${JSON.stringify(error, null, 4)}`, {
          variant: 'error',
        });
      }
      currentBatch += 1;
      setProgress((currentBatch / batches.length) * 100);
    }

    setIsLoading(false);
  };

  const validateForm = (formData: any) => {
    if (!formData.userList || !formData.codeName || !formData.expiryDate) {
      enqueueSnackbar(`Please fill out all fields. ${JSON.stringify(formData)}`, { variant: 'error' });
      return false;
    }

    return validateExpiryDate(formData.expiryDate, new Date(promoExpiresAt));
  };

  const validateExpiryDate = (expiryDate: string, promoExpireDate: Date) => {
    const expiry = new Date(expiryDate);
    if (expiry < new Date()) {
      enqueueSnackbar('Expiry date must be in the future', { variant: 'error' });
      return false;
    }

    if (expiry > promoExpireDate) {
      enqueueSnackbar('Expiry date must be before promo expiry date', { variant: 'error' });
      return false;
    }
    return true;
  };

  const onSubmit = async (formData: any) => {
    if (!validateForm(formData)) {
      return;
    }

    try {
      const users: Array<InputRow> = formData.userList
        .trim()
        .split('\n')
        .map((line: string) => ({
          userId: line.trim(),
        }))
        .filter(Boolean);

      const batches: Array<Array<InputRow>> = []; // Split users into batches of 300
      for (let i = 0; i < users.length; i += 300) {
        batches.push(users.slice(i, i + 300));
      }

      await sendBatches(batches);
    } catch (error) {
      enqueueSnackbar('Error processing user list.', { variant: 'error' });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" mt={2} mb={1}>
        Create Promo Users
      </Typography>
      <Stack direction="row" spacing={3} alignItems="center">
        <Input fullWidth control={control} name="codeName" muiLabel="Promo Code" required disabled />
        <Select control={control} name="promoUserType" muiLabel="Promo User Type">
          <MenuItem key="LUX-USER" value="LUX-USER">
            LUX-USER
          </MenuItem>
        </Select>
        <Input fullWidth control={control} name="expiryDate" muiLabel="Expiry Date" type="date" required />
      </Stack>
      <Input
        fullWidth
        control={control}
        name="userList"
        muiLabel="UserIds / Emails"
        helperText="one UserId/Email per line, no commas or spaces - enter as many users as required, submit the form and remain on the page - this form will create promo users in batches of 300 users at a time."
        rows={5}
        required
      />
      <Button fullWidth variant="contained" type="submit" disabled={isLoading}>
        Upload
      </Button>
      {isLoading && <Spinner />}
      {progress > 0 && (
        <Box mt={2}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </Box>
  );
}

export default PromoUserBatchUpload;
