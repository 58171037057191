import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Box, Button, Grid, Link, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { operations } from '@luxuryescapes/contract-svc-promo';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useQuery from '~/hooks/useQuery';

import { getGiftCards, updateGiftCardStatus } from '~/services/PromoService';

import dateFormatter from '~/utils/dateFormatter';
import isUUID from '~/utils/isUUID';

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 1,
};

const getColumns = ({
  handleCancelGiftCard,
}: {
  handleCancelGiftCard: ({ code }: { code: string }) => Promise<void>;
}): Array<GridColDef> => [
  {
    field: 'gift_card_code',
    headerName: 'Gift Card Code',
    ...sharedOpts,
  },
  {
    field: 'gift_card_status',
    headerName: 'Gift Card Status',
    ...sharedOpts,
  },
  {
    field: 'gift_card_value',
    headerName: 'Gift Card Value',
    ...sharedOpts,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    ...sharedOpts,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    renderCell: (params) => dateFormatter(params.row.created_at),
    ...sharedOpts,
  },
  {
    field: 'expires_at',
    headerName: 'Expires At',
    renderCell: (params) => dateFormatter(params.row.expires_at),
    ...sharedOpts,
  },
  {
    field: 'email',
    headerName: 'Email',
    ...sharedOpts,
  },
  {
    field: 'added_by',
    headerName: 'Added By',
    renderCell: (params) => {
      return (
        <Link href={`/users/${params.row.added_by}`} target="_blank">
          {params.row.added_by}
        </Link>
      );
    },
    ...sharedOpts,
  },
  {
    field: 'redeemed_by',
    headerName: 'Redeemed By',
    renderCell: (params) => {
      return (
        <Link href={`/users/${params.row.redeemed_by}`} target="_blank">
          {params.row.redeemed_by}
        </Link>
      );
    },
    ...sharedOpts,
  },
  {
    field: 'operation',
    headerName: 'Operation',
    renderCell: (params) => {
      const isCreated = params.row.gift_card_status === 'created';
      return (
        <Button
          variant="contained"
          color="secondary"
          disabled={!isCreated}
          onClick={() => {
            handleCancelGiftCard({ code: params.row.gift_card_code });
          }}
        >
          Cancel
        </Button>
      );
    },
    ...sharedOpts,
  },
];

const PAGE_SIZE = 20;
type GiftCardLogs = operations['getGiftCards']['responses']['200']['content']['application/json']['result']['logs'];
function GiftCardsContainer() {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [logData, setLogData] = useState<GiftCardLogs | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNum, setPageNum] = useState<number>(Number(query.get('page_num')) || 0);
  const [totalCount, setTotalCount] = useState(0);
  const [giftCardCode, setGiftCardCode] = useState<string>(query.get('code') || '');
  const [email, setEmail] = useState<string>(query.get('email') || '');
  const [addedBy, setAddedBy] = useState<string>(query.get('added_by') || '');
  const [redeemedBy, setRedeemedBy] = useState<string>(query.get('redeemed_by') || '');
  const [pageSize, setPageSize] = useState<number>(query.get('page_size') ? Number(query.get('page_size')) : PAGE_SIZE);
  const tenant = useCurrentTenant();

  const history = useHistory();

  const setQueryParams = useCallback(() => {
    const searchParams = qs.stringify({
      code: giftCardCode,
      email: email,
      added_by: addedBy,
      redeemed_by: redeemedBy,
      page_num: pageNum.toString(),
      page_size: pageSize.toString(),
    });
    history.push({
      search: `?${searchParams}`,
    });
  }, [addedBy, giftCardCode, history, pageNum, redeemedBy, pageSize, email]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    if (addedBy && !isUUID(addedBy)) {
      enqueueSnackbar('Invalid Added By ID - must be a uuid', { variant: 'error' });
      setIsLoading(false);
      return;
    }

    if (redeemedBy && !isUUID(redeemedBy)) {
      enqueueSnackbar('Invalid Redeemed By ID - must be a uuid', { variant: 'error' });
      setIsLoading(false);
      return;
    }

    try {
      const {
        result: { total, logs },
      } = await getGiftCards({
        code: giftCardCode,
        addedBy: addedBy,
        redeemedBy: redeemedBy,
        pageNum: pageNum,
        pageSize: pageSize,
        brand: tenant.tenant.brand,
        email: email,
      });
      setLogData(logs ?? []);
      setIsLoading(false);
      setTotalCount(Number(total));
      setQueryParams();
    } catch (error) {
      enqueueSnackbar(`Error fetching gift cards: ${error}`, { variant: 'error' });
      setIsLoading(false);
      return;
    }
  }, [
    addedBy,
    redeemedBy,
    giftCardCode,
    pageNum,
    pageSize,
    tenant.tenant.brand,
    setQueryParams,
    enqueueSnackbar,
    email,
  ]);

  const dataRows = logData.map((row, index) => ({
    id: index,
    ...row,
  }));

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNum]);

  const onSubmit = () => {
    fetchData();
  };

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      setPageNum(page);
      setPageSize(pageSize);
      query.set('page_num', page.toString());
      query.set('page_size', pageSize.toString());
      window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`);
    },
    [query],
  );

  const handleCancelGiftCard = useCallback(
    async ({ code }: { code: string }) => {
      try {
        await updateGiftCardStatus({ code: code, status: 'cancelled' });
        enqueueSnackbar('Gift card status updated successfully', { variant: 'success' });
        fetchData();
      } catch (error) {
        enqueueSnackbar(`Failed to update gift card status: ${error}`, { variant: 'error' });
      }
    },
    [enqueueSnackbar, fetchData],
  );

  return (
    <>
      <Helmet>
        <title>Gift Card</title>
      </Helmet>

      <PageSubheader title={`Search Gift Cards [${logData?.length} of ${totalCount}]`} />

      <Grid container spacing={2}>
        <Grid item sm={2}>
          <TextField
            fullWidth
            autoFocus
            type="text"
            value={giftCardCode}
            onChange={(e) => setGiftCardCode(e.target.value)}
            label="Gift Card Code"
            placeholder="Gift Card Code"
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            fullWidth
            autoFocus
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            placeholder="Email"
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            fullWidth
            type="text"
            value={addedBy}
            onChange={(e) => setAddedBy(e.target.value)}
            label="Added By"
            placeholder="Added By (User ID)"
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            fullWidth
            type="text"
            value={redeemedBy}
            onChange={(e) => setRedeemedBy(e.target.value)}
            label="Redeemed By"
            placeholder="RedeemedBy By (User ID)"
          />
        </Grid>
        <Grid item sm={1}>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Search
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page, pageSize }) => {
            onPageChange(page, pageSize);
          }}
          loading={isLoading}
          rows={dataRows}
          columns={getColumns({ handleCancelGiftCard })}
          autoHeight
          rowCount={totalCount}
          paginationMode="server"
          pageSizeOptions={[10, 20, 30, 50, 100]}
          paginationModel={{ page: pageNum, pageSize: pageSize }}
        />
      </Box>
    </>
  );
}

export default GiftCardsContainer;
