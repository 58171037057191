import * as Sentry from '@sentry/browser';
import 'isomorphic-fetch';

import ApiError from '~/types/ApiError';

import ReactStormpath from '../stormpath';

const tryParseJSON = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

export async function request(destination, options) {
  if (!options) {
    options = {};
  }
  if (options.bustCache) {
    destination = `${destination}?${Math.floor(Date.now() / 1000)}`;
  }

  let token = await ReactStormpath.getOrRefreshAccessToken();

  if (!token) {
    throw new ApiError({
      message: 'You are not logged in.',
      name: 'AuthorizationError',
    });
  }

  if (!options.headers) {
    options.headers = {};
  }

  if (options.credentials !== 'omit') {
    options.headers['Authorization'] = 'Bearer ' + token;
  }
  options.credentials = options.credentials || 'include';

  return fetch(destination, options).then(function (response) {
    if (response.ok) {
      if (response.status === 204) {
        return true;
      }
      if (options.download) {
        return response.blob();
      }
      if (options.renderHtml) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('text/html')) {
          return response.text();
        }
      }
      return options.ignoreResponse ? true : response.json();
    } else {
      if (response.status === 403) {
        return response
          .json()
          .catch(function () {
            throw new ApiError({
              message: "You don't have the permissions for this operation",
              name: 'PermissionError',
              status: response.status,
            });
          })
          .then(function (body) {
            throw new ApiError({
              message: body.message || "You don't have the permissions for this operation",
              name: 'PermissionError',
              status: response.status,
            });
          });
      } else if (response.status === 402) {
        if (options.ignoreResponse) {
          return true;
        }
        return response.json().then(function (body) {
          throw new ApiError({
            message: body.message,
            name: 'Payment failed',
            status: response.status,
            errors: extractValidationErrors(body),
          });
        });
      } else if (response.status === 400 && !options.ignoreResponse) {
        return response.json().then(function (body) {
          const validationErrors = extractValidationErrors(body);
          if (options.errorBodyCallback) {
            options.errorBodyCallback(body);
          }
          if (validationErrors) {
            throw new ApiError({
              message: 'Validation error',
              name: 'ValidationError',
              status: response.status,
              errors: validationErrors,
            });
          } else {
            throw new ApiError({
              message: '' + (body?.message || response.status),
              name: 'ValidationError',
            });
          }
        });
      } else {
        return response
          .json()
          .then(function (body) {
            if (options.errorBodyCallback) {
              options.errorBodyCallback(body);
            }
            if (body.message !== 'not found') {
              Sentry.captureException(new Error(body?.message || 'Unknown error'), {
                extra: {
                  body: tryParseJSON(options.body),
                },
              });
            }

            throw new ApiError({
              message:
                'Network error received (status code ' + response.status + '): ' + body.message || 'Unknown error',
              status: response.status,
              name: 'NetworkError',
              errors: body.errors,
            });
          })
          .catch(function (err) {
            if (err.name === 'NetworkError') {
              throw err;
            } else {
              throw new ApiError({
                message: 'Network error received (status code ' + response.status + '): Garbled response',
                name: 'NetworkError',
                status: response.status,
              });
            }
          });
      }
    }
  });
}

export const form_headers = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
};

export const json_headers = {
  'Content-Type': 'application/json;charset=UTF-8',
};

export function objectToFormData(object) {
  const values = [];

  Object.keys(object).forEach(function (key) {
    if (typeof object[key] != 'undefined' && object[key] != null) {
      values.push(encodeURIComponent(key) + '=' + encodeURIComponent(object[key]));
    }
  });

  return values.join('&');
}

function hasErrorShape(obj) {
  return (
    Object.prototype.hasOwnProperty.call(obj, 'status') &&
    obj.status >= 400 &&
    Object.prototype.hasOwnProperty.call(obj, 'message')
  );
}

function extractValidationErrors(responseBody) {
  if (typeof responseBody === 'object' && responseBody.errors) {
    return responseBody.errors;
  }
  if (typeof responseBody === 'object' && responseBody.body?.errors?.length > 0) {
    return responseBody.body.errors;
  }
  if (typeof responseBody === 'object' && responseBody.result?.errors) {
    return responseBody.result.errors;
  }
  if (hasErrorShape(responseBody)) {
    return [responseBody.message];
  }
  return null;
}

export const customerPortalHost = (tenant) => window.configs[`${tenant.value.toUpperCase()}_CUSTOMER_PORTAL`];

export const salesforceHost = window.configs.SALESFORCE_HOST;
