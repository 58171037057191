import React from 'react';

import { useHistory } from 'react-router';

import { Alert, Box, Grid, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import { PROMO_TYPE_FIXED, PROMO_TYPE_PERCENTAGE } from '~/consts/promo';

import { DiscountItemInfo, getPromoProductDisplayName } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';

import BorderedGridItem from '../Common/BorderedGridItem';
import TravellersList from '../Promo/PromoPage/TravellersList';

type PromoItemResultProps = {
  discountItem: DiscountItemInfo;
  currency: string;
  isDevMode: boolean;
  meta: definitions['PromoMeta'];
};

function PromoItemResult({ discountItem, isDevMode, currency, meta }: PromoItemResultProps) {
  const {
    item,
    discountType,
    warning,
    discountAmount,
    matchingDiscount,
    luxPlusDiscountHigher,
    doesNotQualifyWithMemberPricing,
    discountHasCombinedWithLuxPlusPricing,
  } = discountItem;
  const { poffer, reqItem } = item;
  const { discountableTotal, luxPlusPrice, offerId, itemId } = reqItem;
  const hasLuxPlusPrice = luxPlusPrice && luxPlusPrice > 0;
  const originalPrice = hasLuxPlusPrice ? luxPlusPrice : discountableTotal;

  const history = useHistory();

  const handleGridClick = (item: DiscountItemInfo) => {
    if (item.item.reqItem.categoryBK === 'subscription') {
      return;
    }
    history.push(`/offers/${item.item.reqItem.offerId}`);
  };

  return (
    <Box>
      <Grid
        container
        key={`${offerId}_${itemId}`}
        spacing={2}
        sx={{
          padding: '1px',
          margin: '1px',
        }}
        title={`${JSON.stringify(discountItem, null, 4)}`}
      >
        <BorderedGridItem item>
          <Stack direction="row" gap={1}>
            {discountAmount > 0 ? (
              <>
                <Typography sx={{ color: hasLuxPlusPrice ? 'blue' : '' }}>
                  {currencyFormatter(currency, originalPrice - discountAmount)}
                </Typography>

                <Typography sx={{ textDecoration: 'line-through', color: hasLuxPlusPrice ? 'blue' : '' }}>
                  {' '}
                  {currencyFormatter(currency, originalPrice)}
                </Typography>
                <Typography sx={{ color: 'green', marginLeft: 1 }}>
                  ({currencyFormatter(currency ?? 'AUD', discountAmount)})
                </Typography>
              </>
            ) : (
              <Typography>{currencyFormatter(currency, originalPrice)}</Typography>
            )}
            {warning && (
              <Typography variant="body2" component="div">
                {warning}
              </Typography>
            )}
            <Typography variant="body2" component="div">
              {discountType == PROMO_TYPE_PERCENTAGE && `${matchingDiscount.discount_value}%`}
              {discountType == PROMO_TYPE_FIXED && `${currencyFormatter(currency, matchingDiscount.discount_value)}`}
            </Typography>
            {luxPlusDiscountHigher && (
              <Typography variant="body2" component="div">
                <strong>luxPlusDiscountHigher: </strong>
              </Typography>
            )}
            {discountHasCombinedWithLuxPlusPricing && (
              <Typography variant="body2" component="div">
                <strong>(discountHasCombinedWithLuxPlusPricing)</strong>
              </Typography>
            )}
            {doesNotQualifyWithMemberPricing && (
              <Typography variant="body2" component="div">
                <strong>(doesNotQualifyWithMemberPricing)</strong>
              </Typography>
            )}
          </Stack>
          {matchingDiscount && matchingDiscount.discount_value > 0 && (
            <>
              <Typography variant="body2" component="div">
                <strong>Products: </strong>{' '}
                {matchingDiscount?.products && matchingDiscount?.products.length > 0 ? (
                  matchingDiscount.products.join(', ')
                ) : (
                  <Alert severity="warning">a matching discount? This is unexpected</Alert>
                )}
              </Typography>
              {matchingDiscount.min_spend && (
                <Typography variant="body2" component="div">
                  <strong>Minimum Spend: </strong> {currencyFormatter(currency, matchingDiscount.min_spend)}
                </Typography>
              )}
              {matchingDiscount.max_discount && (
                <Typography variant="body2" component="div">
                  <strong>Maximum Discount: </strong> {currencyFormatter(currency, matchingDiscount.max_discount)}
                </Typography>
              )}
              {matchingDiscount?.subscription_item_discount_type !== 'none' &&
                matchingDiscount?.subscription_item_discount_value > 0 && (
                  <Typography variant="body2" component="div">
                    <strong>Sub Discount: </strong>
                    {matchingDiscount.subscription_item_discount_type == 'fixed_amount'
                      ? currencyFormatter(currency, matchingDiscount.subscription_item_discount_value)
                      : `${matchingDiscount.subscription_item_discount_value}%`}
                  </Typography>
                )}
              <Typography variant="body2" component="div">
                <strong>Region: </strong> {matchingDiscount.region}
              </Typography>
            </>
          )}
        </BorderedGridItem>

        <BorderedGridItem>
          <Stack
            direction="column"
            onClick={() => handleGridClick(discountItem)}
            title={JSON.stringify(reqItem, null, 4)}
          >
            <Typography>
              <strong>Product Type: </strong>
              {meta && getPromoProductDisplayName(meta.promo_product_types, reqItem.categoryBK, reqItem.subCategoryBK)}
            </Typography>
            {isDevMode && (
              <Typography>
                <strong>(old)Product Type: </strong>
                {meta && getPromoProductDisplayName(meta.promo_product_types, poffer.categoryBK, poffer.productBK)}
              </Typography>
            )}
            <Typography>
              <strong>ReservationType: </strong>
              {reqItem.reservationType ? `${reqItem.reservationType}` : ''}
            </Typography>
            <Typography variant="body2">
              <strong>Travellers: </strong>
              <TravellersList travellers={reqItem.travellers} expectTravellers />
            </Typography>
          </Stack>
        </BorderedGridItem>
        <BorderedGridItem>
          <Stack
            direction="column"
            onClick={() => handleGridClick(discountItem)}
            title={JSON.stringify(reqItem, null, 4)}
          >
            <Typography>
              <strong>Item Country Code: </strong>
              {reqItem.itemCountryCode && reqItem.itemCountryCode}
            </Typography>
            <Typography>
              <strong>Number of Nights: </strong>
              {reqItem.numberOfNights && reqItem.numberOfNights}
            </Typography>
            <Typography>
              <strong>Adults: </strong>
              {reqItem.numberOfAdults ? `${reqItem.numberOfAdults}` : ''}
            </Typography>
            {reqItem.numberOfChildren > 0 && (
              <Typography>
                <strong>Children: </strong>
                {reqItem.numberOfChildren ? ` ${reqItem.numberOfChildren}` : ''}
              </Typography>
            )}
          </Stack>
        </BorderedGridItem>
      </Grid>
    </Box>
  );
}

export default PromoItemResult;
