import React from 'react';

import { Box } from '@mui/material';

import { ITEM_STATUS_CANCELLED } from '~/consts/order';

import CancellationPolicy from './CancellationPolicy';

type Props = {
  item: App.OrderItem;
};

const extractCancellationPolicy = (reservation: App.OrderItemReservation): App.CancellationPolicyDisplay => {
  if (!reservation) return;
  let policy;
  if (reservation.connection?.cancellation_policies && reservation.connection.cancellation_policies.length > 0) {
    policy = reservation.connection?.cancellation_policies[0];
    return {
      isRefundable: !policy.non_refundable,
      deadline: policy.deadline || '',
      description: policy.description,
      title: policy.non_refundable ? 'Non-refundable' : 'Cancellation policy',
    };
  } else if (reservation.cancellation_policies && reservation.cancellation_policies.length > 0) {
    policy = reservation.cancellation_policies[0];
    return {
      isRefundable: policy.cancellation_policy !== 'non-refundable',
      deadline: policy.start || '',
      description: policy.cancellation_policy_details,
      title: policy.cancellation_policy,
    };
  }

  return undefined;
};

const ReservationInfoCancellationPolicies = ({ item }: Props) => {
  const cancellationPolicy = extractCancellationPolicy(item.reservation);
  const noCancellationPolicies = !cancellationPolicy;

  const isItemCancelled = item.status === ITEM_STATUS_CANCELLED;

  if (noCancellationPolicies) {
    return <Box>None</Box>;
  }

  return (
    <Box>
      <CancellationPolicy policy={cancellationPolicy} isItemCancelled={isItemCancelled} />
    </Box>
  );
};

export default ReservationInfoCancellationPolicies;
