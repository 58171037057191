import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import Spinner from '~/components/Common/Spinner';

import { getAgency, listUsers } from '~/services/AgentHub/AgentService';

import GridPagination from '../../Common/Elements/GridPagination';

import EoiStatusChip from './EoiStatusChip';

const DEFAULT_SIZE_PER_PAGE = 10;

interface Props {
  id: string;
  onClose?: () => void;
  opened?: boolean;
}

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
  { field: 'email', headerName: 'Email', flex: 1, sortable: false, display: 'flex' },
  {
    field: 'status',
    headerName: 'Status',
    align: 'center',
    sortable: false,
    display: 'flex',
    renderCell: (params) => <EoiStatusChip status={params.row.status} />,
  },
  {
    field: '',
    headerName: '',
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          {params.row.customerId && (
            <Link target="_blank" rel="noreferrer" to={`/users/${params.row.customerId}`}>
              User
            </Link>
          )}
          {params.row.eoiSubmissionId && (
            <Box ml={2}>
              <Link target="_blank" rel="noreferrer" to={`/users-list/agency-submission/${params.row.eoiSubmissionId}`}>
                EOI
              </Link>
            </Box>
          )}
        </>
      );
    },
    display: 'flex',
  },
];

export default function AgencyDetail(props: Props) {
  const { id, onClose, opened = false } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(opened);
  const [data, setData] = useState(undefined);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [loadingUserState, setLoadingUserState] = useState<Utils.FetchingState>('idle');
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');

  const fetchUserList = useCallback(
    async (agencyId: string): Promise<void> => {
      try {
        setLoadingUserState('loading');
        const { rows, total } = await listUsers({
          agencyId: agencyId,
          page: page.toString(),
          pageSize: DEFAULT_SIZE_PER_PAGE.toString(),
        });

        setTotal(total);
        setUsers(rows);

        setLoadingUserState('success');
      } catch (e) {
        setLoadingUserState('failed');
        enqueueSnackbar('Failed to load data', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, page],
  );

  const closeModal = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, [onClose]);

  const openModal = useCallback(async () => {
    if (!id) {
      closeModal();
    }

    setOpen(true);

    try {
      setLoadingState('loading');

      const [result] = await Promise.all([getAgency(id), fetchUserList(id)]);

      setData(result);
      setLoadingState('success');
    } catch (err) {
      setLoadingState('failed');
    }
  }, [closeModal, id, fetchUserList]);

  useEffect(() => {
    if (opened) {
      openModal();
    }
  }, [openModal, opened]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Agency Details</DialogTitle>

        <DialogContent>
          {loadingState == 'loading' && <Spinner />}
          {loadingState == 'success' && (
            <>
              <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr" sx={{ minWidth: '500px' }}>
                <Typography>
                  Name: <br />
                  <b>{data.name}</b>
                </Typography>
                <Typography>
                  Identity: <br />
                  <b>
                    {data.businessCodeType} - {data.businessCodeValue}
                  </b>
                </Typography>

                <Typography sx={{ gridColumn: 'span 2' }}>
                  Affiliation: <br />
                  <b>{data.affiliation}</b>
                </Typography>

                <Typography mt={2} variant="h6" sx={{ gridColumn: 'span 2' }}>
                  Address
                </Typography>

                <Typography sx={{ gridColumn: 'span 2' }}>
                  Street: <br />
                  <b>{data.address}</b>
                </Typography>

                <Typography>
                  City: <br />
                  <b>{data.city}</b>
                </Typography>
                <Typography ml={2}>
                  Postal Code: <br />
                  <b>{data.postcode}</b>
                </Typography>

                <Typography>
                  State: <br />
                  <b>{data.state}</b>
                </Typography>
                <Typography ml={2}>
                  Country: <br />
                  <b>{data.country}</b>
                </Typography>
              </Box>

              <Typography mt={2} variant="h6">
                Agents
              </Typography>

              <DataGrid
                rows={users}
                rowCount={total}
                columns={columns}
                loading={loadingUserState == 'loading'}
                pagination
                paginationMode="server"
                paginationModel={{ page: page, pageSize: DEFAULT_SIZE_PER_PAGE }}
                pageSizeOptions={[DEFAULT_SIZE_PER_PAGE]}
                onPaginationModelChange={({ page }) => setPage(page)}
                slots={{ pagination: GridPagination }}
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                autoHeight
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={closeModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
