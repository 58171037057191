import React from 'react';

import { Typography } from '@mui/material';

import { formattedShortDate } from '@luxuryescapes/lib-refunds/build/lib/formatDate';

import { formatDateLongMonthWithMeridiem } from '~/services/TimeService';

const getDateColor = (date: Date) => (date > new Date() ? 'grey.800' : 'red');

type Props = {
  date: string;
  highlightIfPast?: boolean;
};

const dateFormatterDetailed = ({ date, highlightIfPast = false }: Props) =>
  date && (
    <Typography
      color={highlightIfPast ? getDateColor(new Date(date)) : ''}
      title={formatDateLongMonthWithMeridiem(new Date(date))}
    >
      {formattedShortDate(new Date(date))}
    </Typography>
  );

export default dateFormatterDetailed;
