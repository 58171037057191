import React, { useCallback, useState } from 'react';

import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';

import { PortWithId } from '@luxuryescapes/contract-svc-cruise/dist/types/cruises';

import DateWidget from '~/components/Common/Elements/DateWidget';
import InputCruiseLineSelect from '~/components/Cruises/components/Selects/InputCruiseLineSelect';
import InputDeparturePortSelect from '~/components/Cruises/components/Selects/InputDeparturePortSelect';

import { formatDateISO } from '~/services/TimeService';

import {
  SEARCH_FILTERS_DEFAULT,
  SELECT_HAS_CORE_CHANGES,
  SELECT_HAS_ITINERARY_CHANGES,
  SELECT_SAILING_STATUS,
  SearchFilters,
} from './constants';

type Props = {
  querySearchFilters: SearchFilters;
  onSearch: (params: SearchFilters) => void;
  cruiseLineRequired?: boolean;
};

export default function SailingSearchFilters(props: Props) {
  const { querySearchFilters, onSearch, cruiseLineRequired } = props;
  const [selectedDepartures, setSelectedDepartures] = useState<Array<PortWithId>>([]);

  const [searchFilters, setSearchFilter] = useState<SearchFilters>({
    ...SEARCH_FILTERS_DEFAULT,
    ...querySearchFilters,
  });

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.persist) event.persist();

      if (event?.target && event?.target?.name) {
        setSearchFilter((prev) => ({
          ...prev,
          [event.target.name]: event.target.value,
        }));
      }
    },
    [setSearchFilter],
  );

  const handleSearch = useCallback(() => {
    onSearch({
      name: searchFilters.name,
      offerId: searchFilters.offerId,
      vendorId: searchFilters.vendorId,
      sailingExternalId: searchFilters.sailingExternalId,
      sailingPlan: searchFilters.sailingPlan,
      departureDateStart: searchFilters.departureDateStart,
      departureDateEnd: searchFilters.departureDateEnd,
      hasCoreChanges: searchFilters.hasCoreChanges,
      hasItineraryChanges: searchFilters.hasItineraryChanges,
      shipName: searchFilters.shipName,
      shipExternalId: searchFilters.shipExternalId,
      sailingDurationMin: searchFilters.sailingDurationMin,
      sailingDurationMax: searchFilters.sailingDurationMax,
      ...(searchFilters.status && { status: searchFilters.status }),
      ...(!!selectedDepartures && {
        departurePortExternalIds: selectedDepartures.map((departure) => departure.externalId),
      }),
    });
  }, [
    onSearch,
    searchFilters.name,
    searchFilters.offerId,
    searchFilters.vendorId,
    searchFilters.sailingExternalId,
    searchFilters.sailingPlan,
    searchFilters.departureDateStart,
    searchFilters.departureDateEnd,
    searchFilters.hasCoreChanges,
    searchFilters.hasItineraryChanges,
    searchFilters.shipName,
    searchFilters.shipExternalId,
    searchFilters.sailingDurationMin,
    searchFilters.sailingDurationMax,
    searchFilters.status,
    selectedDepartures,
  ]);

  const checkValidDateRange = useCallback(() => {
    const { departureDateStart, departureDateEnd } = searchFilters;

    if (!departureDateStart || !departureDateEnd) {
      return !departureDateStart && !departureDateEnd;
    }

    return new Date(departureDateStart) <= new Date(departureDateEnd);
  }, [searchFilters]);

  const checkValidNumberRange = useCallback(() => {
    const { sailingDurationMin, sailingDurationMax } = searchFilters;

    if (!sailingDurationMin || !sailingDurationMax) {
      return !sailingDurationMin && !sailingDurationMax;
    }
    return Number(sailingDurationMin) <= Number(sailingDurationMax);
  }, [searchFilters]);

  return (
    <Box my={4} display="flex" flexDirection="column" gap={2}>
      <Stack display="flex" direction="row" gap={2}>
        <FormControl fullWidth>
          <InputCruiseLineSelect
            required={cruiseLineRequired}
            label="Cruise line"
            value={searchFilters.vendorId}
            onChange={(cruiseLine) => setSearchFilter((prev) => ({ ...prev, vendorId: cruiseLine.id }))}
          />
        </FormControl>
      </Stack>

      <Stack display="flex" direction="row" gap={2}>
        <TextField
          fullWidth
          name="offerId"
          label="Offer ID"
          onChange={handleChangeField}
          value={searchFilters.offerId}
        />
        <TextField fullWidth name="name" label="Sailing Name" onChange={handleChangeField} value={searchFilters.name} />

        <TextField
          fullWidth
          name="sailingExternalId"
          label="Sailing ID"
          onChange={handleChangeField}
          value={searchFilters.sailingExternalId}
        />
      </Stack>

      <Stack display="flex" direction="row" gap={2}>
        <TextField
          fullWidth
          type="number"
          name="sailingDurationMin"
          label="Sailing Duration Min"
          onChange={handleChangeField}
          value={searchFilters.sailingDurationMin}
          helperText={checkValidNumberRange() ? null : 'Invalid number range'}
        />
        <TextField
          fullWidth
          type="number"
          name="sailingDurationMax"
          label="Sailing Duration Max"
          onChange={handleChangeField}
          value={searchFilters.sailingDurationMax}
          helperText={checkValidNumberRange() ? null : 'Invalid number range'}
        />

        <TextField
          fullWidth
          name="sailingPlan"
          label="Sailing Plan"
          onChange={handleChangeField}
          value={searchFilters.sailingPlan}
        />
      </Stack>

      <Stack display="flex" direction="row" gap={2}>
        <TextField
          fullWidth
          name="shipExternalId"
          label="Ship External ID"
          onChange={handleChangeField}
          value={searchFilters.shipExternalId}
        />
        <TextField
          fullWidth
          name="shipName"
          label="Ship Name"
          onChange={handleChangeField}
          value={searchFilters.shipName}
        />

        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            fullWidth
            name="status"
            label="Status"
            onChange={handleChangeField}
            value={searchFilters.status}
            defaultValue={searchFilters.status}
          >
            {SELECT_SAILING_STATUS.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Has Core Changes</InputLabel>
          <Select
            fullWidth
            name="hasCoreChanges"
            label="Has Core Changes"
            onChange={handleChangeField}
            value={searchFilters.hasCoreChanges}
            defaultValue={searchFilters.hasCoreChanges}
          >
            {SELECT_HAS_CORE_CHANGES.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Has Itinerary Changes</InputLabel>
          <Select
            fullWidth
            name="hasItineraryChanges"
            label="Has Itinerary Changes"
            onChange={handleChangeField}
            value={searchFilters.hasItineraryChanges}
            defaultValue={searchFilters.hasItineraryChanges}
          >
            {SELECT_HAS_ITINERARY_CHANGES.map((item, i) => (
              <MenuItem key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Stack display="flex" direction="row" gap={2}>
        <DateWidget
          value={searchFilters.departureDateStart}
          slotProps={{
            textField: {
              fullWidth: true,
              helperText: checkValidDateRange() ? null : 'Invalid date range',
            },
            field: {
              clearable: true,
            },
          }}
          onChange={(date) =>
            setSearchFilter((prev) => ({ ...prev, departureDateStart: date.length ? formatDateISO(date) : '' }))
          }
          disablePast={false}
          format="DD/MM/YYYY"
          label="Departure Date Start"
        />
        <DateWidget
          value={searchFilters.departureDateEnd}
          slotProps={{
            textField: {
              fullWidth: true,
              helperText: checkValidDateRange() ? null : 'Invalid date range',
            },
            field: {
              clearable: true,
            },
          }}
          onChange={(date) =>
            setSearchFilter((prev) => ({ ...prev, departureDateEnd: date.length ? formatDateISO(date) : '' }))
          }
          disablePast={false}
          format="DD/MM/YYYY"
          label="Departure Date End"
        />

        <FormControl fullWidth>
          <InputDeparturePortSelect
            selectedValues={selectedDepartures}
            setSelectedValues={setSelectedDepartures}
            departurePorts={searchFilters.departurePortExternalIds}
          />
        </FormControl>

        <Box>
          <Button variant="contained" onClick={handleSearch}>
            Search
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
