import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { Alert, Divider, Typography } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { getFlightDetailsByOrderAndItem, updateFlightDetails } from '~/services/OrdersService';

import FlightRefundModal from '../../../Refund/FlightModal';

import FlightRefund from './FlightRefund';
import { FlightTravellers } from './FlightTravellers';
import OrderFlightItem, { getPerPersonBreakdownWithJourneyFares, mapPassengerPlural } from './Item';

interface Props {
  item: App.FlightItem;
  refreshData: () => void;
  showForceBundleAlert?: () => void;
}

export default function FlightDetails(props: Props) {
  const { item, refreshData, showForceBundleAlert } = props;
  const [flightDetails, setFlightDetails] = useState<any>();
  const [error, setError] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundTravellerId, setRefundTravellerId] = useState<string | undefined>();
  const [refundAmount, setRefundAmount] = useState(0);

  const closeRefundModal = () => {
    refreshData();
    setShowRefundModal(false);
    setRefundTravellerId(undefined);
    setRefundAmount(0);
  };

  const onRefund = (amount: number) => {
    setShowRefundModal(true);
    setRefundAmount(amount);
  };

  useEffect(() => {
    getFlightDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTravellerRefundStatus = async (orderId, itemId, payload) => {
    try {
      await updateFlightDetails(orderId, itemId, payload);
      setSuccessMessage(`Successfully updated traveller refund status to be ${payload.data.refund_status}`);
    } catch (error) {
      setError(
        `There has been an error updating traveller refund status. Please try again. Error message: ${error.message}`,
      );
      setSuccessMessage(undefined);
    }

    getFlightDetails();
  };

  const getFlightDetails = async () => {
    try {
      const flightDetails = await getFlightDetailsByOrderAndItem(item.fk_order_id, item.id);
      setFlightDetails(flightDetails);
      const shouldShowAlert = showForceBundleAlert && flightDetails.force_bundle_deal_name;
      if (shouldShowAlert) {
        showForceBundleAlert();
      }
    } catch (error) {
      setError(
        `There has been an error retrieving the flight details. Please try again. Error message: ${error.message}`,
      );
      setSuccessMessage(undefined);
    }
  };

  const bookingJourney: App.BookingJourney = flightDetails?.booking_journey;

  const addonsByTraveller = useMemo(() => {
    const data = {};

    bookingJourney?.legs.forEach((leg) => {
      leg.addons.forEach((addon) => {
        if (data[addon.travellerId]) {
          data[addon.travellerId].push(addon);
        } else {
          data[addon.travellerId] = [addon];
        }
      });
    });

    return data;
  }, [bookingJourney]);

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  if (!flightDetails) {
    return <Spinner />;
  }

  const perPersonPriceBreakdown = getPerPersonBreakdownWithJourneyFares(bookingJourney.legs, flightDetails.travellers);

  const currency = flightDetails.currency;
  const isMultiCity = bookingJourney.legs.length > 2;
  const isOpenJaw = flightDetails.trip_type === 'OpenJaw';
  const fareType = isMultiCity ? 'Multi City' : isOpenJaw ? 'Open Jaw' : '';
  const fees = flightDetails.booking_fees;

  return (
    <>
      {successMessage && (
        <Alert severity="success">
          <p>{successMessage}</p>
        </Alert>
      )}
      {bookingJourney.legs.map((leg, index) => (
        <Fragment key={leg.id}>
          <Divider>
            <Typography fontWeight="bold">Flight # {index + 1}</Typography>
          </Divider>

          <OrderFlightItem
            key={leg.id}
            orderId={item.fk_order_id}
            itemId={item.id}
            itemStatus={item.status}
            itemRefundStatus={item.refund_status}
            flightDetails={flightDetails}
            bookingJourneyLeg={leg}
            refreshData={refreshData}
            updateTravellerRefundStatus={updateTravellerRefundStatus}
            fareType={fareType || leg.fareType}
            fees={fees}
            currency={currency}
          />
        </Fragment>
      ))}
      <Divider textAlign="left">Flight Travellers</Divider>
      {flightDetails.travellers.map((traveller, index) => (
        <FlightTravellers
          key={index}
          traveller={traveller}
          index={index}
          currency={currency}
          status={flightDetails.state}
          cost={perPersonPriceBreakdown[mapPassengerPlural[traveller.type]]}
          onIssueRefund={(travellerId) => {
            setShowRefundModal(true);
            setRefundTravellerId(travellerId);
          }}
          updateTravellerRefundStatus={updateTravellerRefundStatus}
          orderId={item.fk_order_id}
          itemId={item.id}
          addons={addonsByTraveller[traveller.id] || []}
        />
      ))}
      <FlightRefundModal
        show={showRefundModal}
        travellerId={refundTravellerId}
        onClose={closeRefundModal}
        refundableAmount={refundAmount}
        orderId={item.fk_order_id}
        itemId={item.id}
        itemStatus={item.status}
        currency={currency}
      />
      <FlightRefund flightDetails={flightDetails} onIssueRefund={onRefund} />
    </>
  );
}
