import React, { useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';

import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';

import { RuleCondition } from '~/consts/agentHub';

import { listUsers } from '~/services/AgentHub/AgentService';

import { Agent, MappedAgent } from '~/types/services/agentHub';

const DEFAULT_SIZE_PER_PAGE = '10';

interface Props {
  setCreatedConditions: (condition: RuleCondition) => void;
}

function AgentHubCommissionsAgentInput(props: Props) {
  const { setCreatedConditions } = props;
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [result, setResult] = useState<Array<MappedAgent>>([]);
  const [search, setSearch] = useState('');
  const [selectedAgents, setSelectedAgents] = useState<Array<MappedAgent>>([]);
  const [noOptionsText, setNoOptionsText] = useState<string>('Start typing to search for agents');

  const debouncedGetAgencyList = useMemo(
    () =>
      debounce((search: string) => {
        if (!search) {
          setResult([]);
          return;
        }
        setLoadingState('loading');
        listUsers({ page: '0', pageSize: DEFAULT_SIZE_PER_PAGE, ...(search ? { search } : {}) })
          .then(({ rows, total }) => {
            if (total === 0) {
              setNoOptionsText('No agents found');
            }
            setResult(rows);
          })
          .finally(() => setLoadingState('success'));
      }, 500),
    [],
  );

  const handleSaveRule = () => {
    setCreatedConditions({
      type: 'agent',
      value: selectedAgents.map((agent) => ({ value: agent.id, label: agent.name })),
    });
  };

  const handleInputChange = (_: React.ChangeEvent, newInputValue: string) => {
    if (!newInputValue) {
      setNoOptionsText('Start typing to search for agents');
      return;
    }
    setSearch(newInputValue);
  };

  const handleChange = (_: React.ChangeEvent, value: Array<MappedAgent>) => {
    setSelectedAgents(value);
  };

  useEffect(() => {
    debouncedGetAgencyList(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const defaultProps = {
    options: result,
    getOptionLabel: (option: Agent) => option.name,
  };

  return (
    <Stack spacing={2}>
      <div>
        <Autocomplete
          {...defaultProps}
          multiple
          options={result}
          loading={loadingState === 'loading'}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Agents" placeholder="Agents" />}
          onInputChange={handleInputChange}
          value={selectedAgents}
          onChange={handleChange}
          noOptionsText={noOptionsText}
        />
        <Typography variant="caption" color="textSecondary">
          Start typing to search for agents
        </Typography>
      </div>

      <Button onClick={handleSaveRule} variant="contained">
        Add Condition
      </Button>
    </Stack>
  );
}

export default AgentHubCommissionsAgentInput;
