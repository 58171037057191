import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router';

import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { listEOI } from '~/services/AgentHub/AgentService';

import { addQuery, parseSearchString } from '~/utils/url';

import AgencySubmissionDetail from './AgencySubmissionDetail';
import EoiStatusChip from './EoiStatusChip';

const DEFAULT_SIZE_PER_PAGE = 10;

export default function AgencySubmissions() {
  const location = useLocation();
  const params = useParams<{ id?: string }>();

  const { push: setQueryString } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);
  const [details, setDetails] = useState({ id: null, opened: false });

  const [filter, setFilter] = useState({
    page: 1,
    status: null,
    order: null,
    search: null,
  });

  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setQueryString(addQuery(location, { page }));
    },
    [location, setQueryString],
  );

  const onStatusChange = useCallback(
    async (status: string) => {
      setQueryString(addQuery(location, { status }));
    },
    [location, setQueryString],
  );

  const onSearchChange = useCallback(
    (search: string) => setQueryString(addQuery(location, { search: search || undefined })),
    [location, setQueryString],
  );
  const onSearchChangeDebounced = debounce(onSearchChange, 500);

  useEffect(() => {
    const { status, order, page, search } = parseSearchString(location.search);
    setFilter((prev) => ({
      ...prev,
      order,
      status,
      search,
      page: parseInt((page as string) ?? '1'),
    }));
  }, [location.search]);

  useEffect(() => {
    if (params.id) {
      setDetails({ id: params.id, opened: true });
    }
  }, [params]);

  const fetchSubmissionsList = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const { rows, total } = await listEOI({
        page: filter.page.toString(),
        pageSize: DEFAULT_SIZE_PER_PAGE.toString(),
        ...(filter.status ? { status: filter.status } : {}),
        ...(filter.search ? { search: filter.search } : {}),
      });

      setTotal(total);
      setResult(rows);
    } catch (e) {
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [filter, setTotal, setResult, enqueueSnackbar]);

  const closeDetailsModal = useCallback(() => {
    setDetails({ id: null, opened: false });
    fetchSubmissionsList();
  }, [setDetails, fetchSubmissionsList]);

  useEffect(() => {
    fetchSubmissionsList();
  }, [filter, fetchSubmissionsList]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      { field: 'agencyName', headerName: 'Agency Name', flex: 1, sortable: false, display: 'flex' },
      { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
      {
        field: 'status',
        headerName: 'Status',
        align: 'center',
        flex: 1,
        sortable: true,
        display: 'flex',
        renderCell: (params) => <EoiStatusChip status={params.row.status} />,
      },
      { field: 'submittedAt', headerName: 'Submitted At', flex: 1, sortable: true, display: 'flex' },
      { field: 'updatedAt', headerName: 'Updated At', flex: 1, sortable: true, display: 'flex' },
      { field: 'onboardedAt', headerName: 'Onboarded At', flex: 1, sortable: true, display: 'flex' },
      {
        field: 'action',
        headerName: '',
        renderCell: (params) => {
          return (
            <Button variant="text" onClick={() => setDetails({ id: params.row.id, opened: true })}>
              Show
            </Button>
          );
        },
        display: 'flex',
      },
    ];
  }, []);

  return (
    <>
      <Grid columns={12} display="flex" justifyContent="end">
        <FormControl fullWidth>
          <TextField
            id="search-input-label"
            data-testid="search"
            type="text"
            name="search"
            label="Search"
            placeholder="Search"
            onChange={(e) => onSearchChangeDebounced(e.target.value as string)}
            fullWidth
          />
        </FormControl>
        <Grid ml={1}>
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              labelId="status-select-label"
              title="Status"
              label="Status"
              placeholder="Placeholder"
              onChange={(e) => onStatusChange(e.target.value as string)}
            >
              <MenuItem>All</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="onboarded">Onboarded</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid mt={1}>
        <DataGrid
          rows={result}
          rowCount={total}
          columns={columns}
          loading={loading}
          pagination
          paginationMode="server"
          paginationModel={{ page: filter.page - 1, pageSize: DEFAULT_SIZE_PER_PAGE }}
          pageSizeOptions={[DEFAULT_SIZE_PER_PAGE]}
          onPaginationModelChange={({ page }) => onPageChange(page + 1)}
          slots={{ pagination: GridPagination }}
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          autoHeight
        />
        <AgencySubmissionDetail onClose={closeDetailsModal} id={details.id} opened={details.opened} />
      </Grid>
    </>
  );
}
