import React from 'react';

import { withRouter } from 'react-router-dom';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import ErrorListDisplay from '~/components/Common/ErrorListDisplay';
import Spinner from '~/components/Common/Spinner';

import { gdprRemoval } from '~/services/OrdersService';
import { getCredits } from '~/services/PaymentsService';

import { reportError } from '~/utils/reportError';

const INITIAL_STATE = {
  hasError: false,
  errors: '',
  updating: false,
  updated: false,
};

class GDPRRemovalModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  hideGDPRRemovalModal = () => {
    this.setState(INITIAL_STATE);
    this.props.hideGDPRRemovalModal();
  };

  gdprRemoval = async () => {
    this.setState({ updating: true });
    const { memberId, email, currencyCode, brand } = this.props;
    const credits = await getCredits(memberId, currencyCode, brand, 1);
    try {
      if (Number(credits.result.available_credit) > 0) {
        throw new Error(
          `Unable to proceed. All active credit must first be removed. Currently ${credits.result.available_credit} ${currencyCode} credits`,
        );
      }
      await gdprRemoval(memberId, { oldEmail: email });
      this.setState({ updated: true });
    } catch (error) {
      this.setState({
        hasError: true,
        errors: error.errors || [error.message || 'Some Error Occurred'],
      });
      reportError(error);
    }
    this.setState({ updating: false });
  };

  render() {
    const { updating, updated, hasError, errors } = this.state;
    return (
      <div className="container">
        <Dialog open={this.props.isGDPRRemovalModalVisible} onClose={this.hideGDPRRemovalModal}>
          <DialogTitle>GDPR removal</DialogTitle>

          <DialogContent>
            <h6>
              This will remove all identifying information from the customer’s account and from our databases. This is a
              permanent action, and should only be done with written request from the customer. Are you sure you want to
              continue?
            </h6>

            {hasError && <ErrorListDisplay messages={errors} />}

            {updated && (
              <Alert severity="success">
                <p>
                  All identifying information for this user is being removed. This may take several seconds. Please
                  reload the page shortly.
                </p>
              </Alert>
            )}
          </DialogContent>

          <DialogActions>
            <Button variant="text" onClick={this.hideGDPRRemovalModal} disabled={updating}>
              Close
            </Button>
            {!updated && (
              <Button variant="contained" onClick={this.gdprRemoval} disabled={updating}>
                Remove {updating && <Spinner size={15} inline={true} />}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(GDPRRemovalModalContainer);
