import qs from 'qs';

import { TripPlannerTripItemTypes } from '~/consts/tripPlanner';

import { request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/trips/admin/trips';
}

function customerPortalApiBasePath() {
  return window.configs.API_HOST + '/api/trips';
}

function getRequest(path: string) {
  return request(path, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

function deleteRequest(path: string) {
  return request(path, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
}

function postRequest(path: string, data) {
  return request(path, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
}

function patchRequest(path: string, data) {
  return request(path, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
}

export async function getTrips({
  page,
  showHiddenOnly,
  tripId,
  orderId,
  showConciergeOnly,
}: {
  page: number;
  showHiddenOnly?: boolean;
  tripId?: string;
  orderId?: string;
  showConciergeOnly?: boolean;
}) {
  // Use qs.stringify to create the query string
  const queryString = qs.stringify({
    page,
    showHiddenOnly,
    tripId,
    orderId,
    showConciergeOnly,
  });

  return getRequest(basePath() + `?${queryString}`);
}

export async function getExperienceRecommendations(tripId: string) {
  return getRequest(basePath() + `/${tripId}/recommendations/experiences`);
}

export async function addRecommendationToTrip(tripId: string, recommendationData) {
  return postRequest(basePath() + `/${tripId}/items`, recommendationData);
}

export async function getExploreRecommendations(query: string, locationBias: [number, number]) {
  const queryString = qs.stringify(
    {
      query,
      locationBias,
    },
    { arrayFormat: 'comma' },
  );

  return getRequest(customerPortalApiBasePath() + `/explore?${queryString}`);
}

export async function updateConciergeNote(tripId: string, tripItemId: string, note: string) {
  return patchRequest(basePath() + `/${tripId}/items/${tripItemId}`, {
    patchNoteItem: {
      notes: note,
      type: TripPlannerTripItemTypes.NOTE,
    },
  });
}

export async function getTripDetails(tripId: string) {
  return getRequest(basePath() + `/${tripId}`);
}

export async function unHideTrip(tripId: string) {
  return postRequest(basePath() + `/${tripId}/unhide`, {});
}

export async function deleteTripItem(tripId: string, tripItemId: string) {
  return deleteRequest(basePath() + `/${tripId}/items/${tripItemId}`);
}
