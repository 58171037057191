import qs from 'qs';

import { customerPortalHost, json_headers, request } from './common';

const domains = {
  scoopon: window.configs.SCOOPON_API_DOMAIN,
  cudo: window.configs.CUDO_API_DOMAIN,
  treatme: window.configs.TREATME_API_DOMAIN,
  deals: window.configs.DEALS_API_DOMAIN,
  kogantravel: window.configs.KOGANTRAVEL_API_DOMAIN,
  lebusinesstraveller: window.configs.LEBUSINESSTRAVELLER_API_DOMAIN,
  leagenthub: window.configs.LEAGENTHUB_API_DOMAIN,
};

export default class UsersService {
  static basePath() {
    return window.configs.AUTH_HOST + '/api/users';
  }

  static async getUser(id, opts) {
    const jsonBody = await request(`${this.basePath()}/${id}?${qs.stringify(opts)}`, {
      method: 'GET',
    });
    return jsonBody.result;
  }

  static async getUserByUrl(url) {
    const jsonBody = await request(`${window.configs.AUTH_HOST}${url}`, { method: 'GET' });
    return jsonBody.result;
  }

  static async getUserSummary(id, brand) {
    const params = { brand };

    return await request(`${this.basePath()}/summary/${id}?${qs.stringify(params)}`, {
      method: 'GET',
    });
  }

  static async getUsersSummaryByIds(ids, brand) {
    const params = {
      brand,
      filter: ids,
    };

    const body = await request(`${this.basePath()}/summary?${qs.stringify(params, { arrayFormat: 'repeat' })}`, {
      method: 'GET',
    });

    return new Map(body.map((user) => [user.id_member, user]));
  }

  static async getUserTenancy(email) {
    let sanitizedUserEmail = email;

    // get rid of the # if its at the start of an email address
    // why? because this is being appended to a URL, and leaving it in will cause
    // it to be parsed as an anchor
    if (email.startsWith('#')) {
      sanitizedUserEmail = email.slice(1, email.length);
    }

    const jsonBody = await request(`${window.configs.AUTH_HOST}/api/get-user-tenants/${sanitizedUserEmail}`, {
      method: 'GET',
    });
    return jsonBody.result;
  }

  static async spoofUser(id, tenant, offerLink = '') {
    const jsonBody = await request(window.configs.AUTH_HOST + '/api/obtain-token', {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({
        customer_id: id,
        verbose: true,
        brand: tenant.value,
      }),
    });

    const apiDomain = domains[tenant.value] || window.configs.LUX_API_DOMAIN;

    return `${apiDomain}/redirect?token=${jsonBody.result.access_token}&url=${customerPortalHost(
      tenant,
    )}${offerLink}?utm_source=${tenant.brand}&utm_medium=admin&utm_campaign=spoof`;
  }

  static async spoofUserStore(id, tenant) {
    const jsonBody = await request(window.configs.AUTH_HOST + '/api/obtain-token', {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({
        customer_id: id,
        verbose: true,
        brand: tenant.value,
      }),
    });

    const apiDomain = domains[tenant.value] || window.configs.LUX_API_DOMAIN;

    return `${apiDomain}/redirect?token=${jsonBody.result.access_token}&url=${customerPortalHost(
      tenant,
    )}?utm_medium=popup_store&utm_source=${tenant.brand}&utm_campaign=spoof`;
  }

  static async spoofUserQuote(id, tenant, cartId) {
    const jsonBody = await request(window.configs.AUTH_HOST + '/api/obtain-token', {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({
        customer_id: id,
        verbose: true,
        brand: tenant.value,
      }),
    });

    const apiDomain = domains[tenant.value] || window.configs.LUX_API_DOMAIN;

    return `${apiDomain}/redirect?token=${jsonBody.result.access_token}&url=${customerPortalHost(
      tenant,
    )}/checkout-restore-cart?cartId=${cartId}&utm_medium=popup_store&utm_source=${
      tenant.brand
    }&utm_campaign=agent_purchased_quote`;
  }

  static async spoofUserTrip(id, tenant, tripLink = '') {
    const jsonBody = await request(window.configs.AUTH_HOST + '/api/obtain-token', {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({
        customer_id: id,
        verbose: true,
        brand: tenant.value,
      }),
    });

    const apiDomain = domains[tenant.value] || window.configs.LUX_API_DOMAIN;

    return `${apiDomain}/redirect?token=${jsonBody.result.access_token}&url=${customerPortalHost(
      tenant,
    )}${tripLink}?utm_medium=popup_store&utm_source=${tenant.brand}&utm_campaign=spoof`;
  }

  static async forceLogout(id, email, password) {
    await request(`${window.configs.AUTH_HOST}/api/users/${id}/invalidate-tokens`, {
      method: 'POST',
      headers: json_headers,
    });
    await request(`${window.configs.AUTH_HOST}/api/users/${id}`, {
      method: 'PUT',
      headers: json_headers,
      body: JSON.stringify({ email, password }),
    });
  }

  static async spoofUserWithOfferLink(id, offer, tenant) {
    const jsonBody = await request(window.configs.AUTH_HOST + '/api/obtain-token', {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({
        customer_id: id,
        verbose: true,
      }),
    });

    const apiDomain = domains[tenant.value] || window.configs.LUX_API_DOMAIN;
    const offer_url = customerPortalHost(tenant) + `/offer/${offer.slug}/${offer.id_salesforce_external}`;

    return `${apiDomain}/redirect?token=${jsonBody.result.access_token}&url=${offer_url}?`;
  }

  static getUserLogins(id) {
    return request(this.basePath() + '/logins/' + id, { method: 'GET' });
  }

  static getUsers({ filter, limit = 50, offset = 0, brand }, options = undefined) {
    const queryParams = {
      filter,
      limit,
      offset,
      brand,
    };

    return request(this.basePath() + `?${qs.stringify(queryParams)}`, options);
  }

  static getAdminUsers(filter, limit = 50, offset = 0) {
    return request(`${window.configs.AUTH_HOST}/api/admin-users?filter=${filter}&limit=${limit}&offset=${offset}`);
  }

  static getVendorUsers(vendorId, limit = 50, offset = 0) {
    return request(
      `${window.configs.AUTH_HOST}/api/vendor-users?vendor_id=${vendorId}&limit=${limit}&offset=${offset}`,
    );
  }

  static async patch(data) {
    const jsonBody = await request(`${this.basePath()}/${data.id_account}?${data.params}`, {
      method: 'PATCH',
    });
    return jsonBody.result;
  }

  static getUpdateSchema(data, brand) {
    return request(`${this.basePath()}/${data.id_member}?brand=${brand}`, {
      method: 'OPTIONS',
    });
  }

  static async updateUser(data, brand) {
    const jsonBody = await request(`${this.basePath()}/${data.id_member}?brand=${brand}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    return jsonBody.result;
  }

  static async toggle(data) {
    const jsonBody = await request(`${window.configs.AUTH_HOST}/api/toggles`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    return jsonBody.result;
  }

  static async updatePartnershipsDetails(idMember, data) {
    const jsonBody = await request(`${window.configs.AUTH_HOST}/api/partnerships/${idMember}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    return jsonBody.result;
  }

  static getRegisterSchema(brand) {
    return request(`${window.configs.AUTH_HOST}/register?brand=${brand}`, {
      method: 'OPTIONS',
    });
  }

  static async registerUser(data) {
    const jsonBody = await request(`${window.configs.AUTH_HOST}/register`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    return jsonBody.result;
  }

  static getRoles() {
    return request(`${window.configs.AUTH_HOST}/api/roles`);
  }

  static getUsersByRole(role, page = 1) {
    return request(`${window.configs.AUTH_HOST}/api/roles/users?role=${role}&page=${page}`);
  }

  static healthCheck() {
    return request(`${window.configs.AUTH_HOST}/api/auth/health`);
  }

  static async validateEmail(email) {
    return request(`${window.configs.AUTH_HOST}/api/validate-email?email=${email}`);
  }

  static async getEmailChangeHistory(userId) {
    return request(`${window.configs.AUTH_HOST}/api/auth/email-change/email-change-history/${userId}`);
  }
}
