import React from 'react';

import { Add, ArrowForwardIos, DensityLarge } from '@mui/icons-material';

import DebugModal from '~/components/DebugModal/DebugModal';

function Internals({ internals }) {
  return (
    <>
      <DebugModal title="Order" type="generic" data={internals.svcOrderOrder} />
      <DebugModal
        title={`(${internals.calculator.asyncFields.user.givenName})`}
        type="generic"
        data={internals.calculator.asyncFields.user}
      />
      <ArrowForwardIos />
      <DebugModal title="Promo Order" type="generic" data={internals.promoOrder} />
      <Add />
      <DebugModal title="Promo" type="generic" data={internals.calculator.asyncFields.promo} />
      <DebugModal title="Discounts" type="generic" data={internals.calculator.asyncFields.promo.promo_discounts} />
      <DensityLarge />
    </>
  );
}

export default Internals;
