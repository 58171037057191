import { ROLE_ADMIN_USER } from '~/consts/roles';

export default function canShowTab(user, disallowedRoles: Array<string>) {
  if (user?.roles.includes(ROLE_ADMIN_USER)) {
    return true;
  }
  const hasDisallowedRole = disallowedRoles.some((disallowedRole) => {
    user?.roles.includes(disallowedRole);
  });
  // user can see the tab if they have no banned roles
  return !hasDisallowedRole;
}
