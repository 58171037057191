import React from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button, Checkbox, FormControlLabel, Grid, MenuItem } from '@mui/material';

import useCurrentUser from '~/hooks/useCurrentUser';

import LereService from '~/services/LereService';

import { hasFullAccess } from '~/utils/lere';

import { Select } from '../Common/Forms/fields';

const emailVariantList = ['tpfm_daily-1d', 'tpfm_daily-3d', 'tpfm_daily-7d', 'tpfm_weekly'];
const testRecipientsSet = new Set([
  'shay.hamama@luxuryescapes.com',
  'samuel.drown@luxuryescapes.com',
  'ryan.tian@luxuryescapes.com',
  'jerry.huang@luxuryescapes.com',
  'nirnay.polaboina@luxuryescapes.com',
  'di.shang@luxuryescapes.com',
  'vladislav.milov@luxuryescapes.com',
  'soumya.bhattacharya@luxuryescapes.com',
  'ahijith.chandra@luxuryescapes.com',
  'maria.nouneva@luxuryescapes.com',
  'christopher.nassif@luxuryescapes.com',
]);

interface FormInput {
  emailVariant: string;
  recipient: string;
  useRecipientData: boolean;
}

interface Props {
  onLereError: (error: unknown) => void;
}

export default function LereTPFMEmailTrigger({ onLereError }: Props) {
  const { user } = useCurrentUser();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormInput>({
    defaultValues: {
      emailVariant: emailVariantList[0],
      recipient: user.email,
      useRecipientData: false,
    },
  });
  const possibleRecipients = Array.from(testRecipientsSet.add(user.email));

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const { emailVariant, recipient, useRecipientData } = data;
    try {
      await LereService.sendTPFMTestEmail(emailVariant, recipient, useRecipientData);
    } catch (e) {
      onLereError(e);
    }
  };

  const userHasFullAccess = hasFullAccess(user);
  if (!userHasFullAccess) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} style={{ marginBottom: '30px' }}>
        <Grid item xs={12}>
          <h2>TPFM Test Trigger</h2>
          <p>
            Send TPFM Test Emails. If 'Use Recipient Data' is left unchecked random user data will be used to generate
            the contents of the email.
          </p>
        </Grid>
        <Grid item md={6} flexGrow={1}>
          <Select control={control} name="emailVariant" muiLabel="Email Variant" disabled={isSubmitting}>
            {emailVariantList.map((variant) => (
              <MenuItem key={variant} value={variant}>
                {variant}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={6} flexGrow={1}>
          <Select control={control} disabled={isSubmitting} name="recipient" muiLabel="Email Recipient (Tester)">
            {possibleRecipients.map((recipient) => (
              <MenuItem key={recipient} value={recipient}>
                {recipient}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="useRecipientData"
            control={control}
            render={({ field }) => (
              <FormControlLabel control={<Checkbox {...field} disabled={isSubmitting} />} label="Use Recipient Data" />
            )}
          />
        </Grid>
        <Grid item xs={2.5}>
          <Button
            disabled={isSubmitting}
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: '5px' }}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
