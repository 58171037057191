import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Button, ButtonGroup, Stack, Typography } from '@mui/material';

import { formatDateShort } from '~/services/TimeService';

import { PROVIDER_COVER_GENIUS, PROVIDER_NIB } from '../../../consts/insurance';

function formatDate(cell) {
  const date = new Date(cell);
  return formatDateShort(date);
}

interface Props {
  name: string;
  policyId: string;
  pds: string;
  coolingOffMessage: string;
  provider: string;
  policyStartDate: Date;
  policyEndDate: Date;
  coverAmount: number;
  currencyCode: string;
  claimStatus: string;
}

export default function InsuranceSummary({
  name,
  policyId,
  policyStartDate,
  pds,
  coolingOffMessage,
  provider,
  policyEndDate,
  coverAmount,
  currencyCode,
  claimStatus,
}: Props) {
  return (
    <Stack direction="column" spacing={0.5}>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        {name}
      </Typography>

      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight="bold">Policy ID</Typography>
        <Typography>{policyId}</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight="bold">Policy Start Date</Typography>
        <Typography>{formatDate(policyStartDate)}</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight="bold">Policy End Date</Typography>
        <Typography>{formatDate(policyEndDate)}</Typography>
      </Stack>

      {!!coverAmount && (
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="bold">Coverage Amount</Typography>
          <Typography>
            {currencyFormatter.format(coverAmount, {
              symbol: currencyCode,
            })}
          </Typography>
        </Stack>
      )}

      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight="bold">Claim status</Typography>
        <Typography>{claimStatus}</Typography>
      </Stack>

      {provider === PROVIDER_COVER_GENIUS && <Typography>Cancellation portion of policy is non-refundable.</Typography>}

      {provider === PROVIDER_NIB && (
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="bold">Cooling Off Period</Typography>
          <Typography className={coolingOffMessage === 'Ended' ? 'ended' : ''}>{coolingOffMessage}</Typography>
        </Stack>
      )}

      <ButtonGroup variant="outlined" size="small">
        <Button onClick={() => window.open(pds, '_blank').focus()}>View PDS</Button>
        <Button onClick={() => window.open(`https://partners.xcover.com/bookings/${policyId}`, '_blank').focus()}>
          Open In Cover Genius
        </Button>
      </ButtonGroup>
    </Stack>
  );
}
