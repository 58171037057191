import qs from 'qs';

import { json_headers, request } from '~/services/common';

export type AdGroup = {
  campaignName: string;
  campaignId: string;
  adGroupName: string;
  adGroupId: string;
  adGroupResourceName: string;
  tcpa: number;
  cpa: number;
  expectedConversionMargin: number;
  bidPercentage: number;
};

export type AdGroupResponse = {
  result: AdGroup[];
  count: number;
  nextPageToken?: string;
};

export type AdCampaign = {
  id: string;
  name: string;
};

export type AdCustomer = {
  id: string;
  descriptiveName: string;
  campaigns: AdCampaign[];
};

function basePath() {
  return window.configs.API_HOST + '/api/public-offer-feed';
}

export async function getAdGroups(
  customerId: string,
  campaignId: string,
  pageSize?: number,
  pageToken?: string,
): Promise<AdGroupResponse> {
  const queryParams = {
    pageSize,
    pageToken,
  };
  const url = `${basePath()}/google-ads/${customerId}/campaign/${campaignId}/ad-group?${qs.stringify(queryParams)}`;
  return request(url, {
    method: 'GET',
  });
}

export async function mutateAdgroup(
  customerId: string,
  adGroupId: string,
  resourceName: string,
  targetCPA: number,
): Promise<void> {
  const url = `${basePath()}/google-ads/${customerId}/ad-group/${adGroupId}`;
  return request(url, {
    method: 'PATCH',
    body: JSON.stringify({ resourceName, targetCPA }),
    headers: json_headers,
  });
}

export async function getCustomers(): Promise<{ result: AdCustomer[] }> {
  const url = `${basePath()}/google-ads/customers`;
  return request(url, {
    method: 'GET',
    headers: json_headers,
  });
}
