import { useCallback, useState } from 'react';

import { operations } from '@luxuryescapes/contract-svc-promo';

import { processReferralLogs } from '~/services/PromoService';

type ReferralLog = operations['processReferralLogs']['responses']['200']['content']['application/json']['result']['0'];

type ProcessLogProps =
  | {
      limit: number;
      offset: number;
    }
  | {
      referralLogIds: string[];
    };

interface UseReferralLogsReturn {
  logs: ReferralLog[] | null;
  errors: any[] | null;
  isLoading: boolean;
  processLogs: (params: ProcessLogProps) => Promise<void>;
}

const useReferralProcessor = (): UseReferralLogsReturn => {
  const [logs, setLogs] = useState<ReferralLog[] | null>(null);
  const [errors, setErrors] = useState<any[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const processLogs = useCallback(async (props: ProcessLogProps) => {
    setIsLoading(true);
    setErrors(null);

    try {
      const apiCallProps =
        'referralLogIds' in props
          ? {
              referralLogIds: props.referralLogIds,
            }
          : {
              limit: props.limit,
              offset: props.offset,
            };
      const response = await processReferralLogs(apiCallProps);

      setLogs(response.result.logs || []);
    } catch (error) {
      setErrors([error.response?.data || error.message]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    logs,
    errors,
    isLoading,
    processLogs,
  };
};

export default useReferralProcessor;
