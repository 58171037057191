import { SearchFilters } from '~/components/Cruises/pages/Home/components/Sailings/constants';

import { addQuery } from '~/utils/url';

export function onSearchNewLocation(params: SearchFilters, location) {
  const filterKeys = [
    'name',
    'status',
    'offerId',
    'vendorId',
    'sailingExternalId',
    'sailingPlan',
    'sailingDurationMin',
    'sailingDurationMax',
    'hasCoreChanges',
    'hasItineraryChanges',
    'departureDateMin',
    'departureDateMax',
    'departurePortExternalIds',
    'shipName',
    'shipExternalId',
  ];

  const filtersToRemove = filterKeys.filter(
    (key) =>
      !params[key] ||
      (key === 'status' && params[key] === 'ALL') ||
      (key === 'hasCoreChanges' && params[key] === 'ALL') ||
      (key === 'hasItineraryChanges' && params[key] === 'ALL'),
  );

  const newLocation = addQuery(location, {
    name: params.name,
    status: params.status,
    offerId: params.offerId,
    vendorId: params.vendorId,
    sailingExternalId: params.sailingExternalId,
    sailingPlan: params.sailingPlan,
    sailingDurationMin: params.sailingDurationMin,
    sailingDurationMax: params.sailingDurationMax,
    departureDateStart: params.departureDateStart,
    departureDateEnd: params.departureDateEnd,
    departurePortExternalIds: params.departurePortExternalIds.join(','),
    hasCoreChanges: params.hasCoreChanges,
    hasItineraryChanges: params.hasItineraryChanges,
    shipName: params.shipName,
    shipExternalId: params.shipExternalId,
  });

  return { newLocation, filtersToRemove };
}
