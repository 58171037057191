import React from 'react';

import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

import { PromoProductTypes, getMinSpendProductTypeOptions, getProductTypeOptions } from '~/services/PromoService';

type MultiProductOptionSelectProps = {
  id: string;
  value: Array<string>;
  filterFields?: Array<string>;
  promoProductTypes: PromoProductTypes;
  multiSelectType: 'products' | 'min_spend_products';
};

function MultiProductOptionSelect({
  id,
  value,
  filterFields,
  promoProductTypes,
  multiSelectType,
}: MultiProductOptionSelectProps) {
  const apiRef = useGridApiContext();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field: multiSelectType, value: newValue });
  };

  const productOptions =
    multiSelectType == 'products'
      ? getProductTypeOptions(promoProductTypes, filterFields)
      : getMinSpendProductTypeOptions(promoProductTypes, filterFields);

  const productTypeOptions = productOptions.map((opt) => ({
    isChecked: value.includes(opt.value),
    label: opt.label,
    value: opt.value,
  }));

  const renderValue = (selected: Array<string>) => {
    if (selected.length == 1) {
      const match = productTypeOptions.find((opt) => opt.value === selected[0]);
      return match.label;
    } else {
      return `${selected.length} Products Selected`;
    }
  };

  return (
    <Select
      labelId={multiSelectType}
      id={multiSelectType}
      multiple
      value={value}
      placeholder="Select Products"
      onChange={handleValueChange}
      renderValue={(selected) => renderValue(selected)}
      fullWidth
    >
      {productTypeOptions.map((opt, i) => (
        <MenuItem key={`${i}_${opt.value}`} value={opt.value}>
          <Checkbox checked={opt.isChecked} />
          <ListItemText primary={opt.label} />
        </MenuItem>
      ))}
    </Select>
  );
}

export default MultiProductOptionSelect;
