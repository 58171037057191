import { isBeforeAutoRejectedLogicCutoff } from '~/utils/refundRequests';

import { BookingRequest, UserMapping } from '../types/types';

import { getRefundRequestStatus } from './getRefundRequestStatus';

export const mapRefundRequestsToBookingRequest = (
  refundRequests: Array<RefundRequest>,
  order: App.Order,
  reviewerMap: UserMapping,
): Array<BookingRequest> => {
  return refundRequests.map((refundRequest) => {
    const matchedOrderItem = order.items.find((item) => item.id === refundRequest.fk_item_id);
    const checkInDate = matchedOrderItem?.reservation?.check_in;

    let status = refundRequest.status;
    if (checkInDate && isBeforeAutoRejectedLogicCutoff(refundRequest, checkInDate)) {
      status = 'auto_rejected';
    }

    const reviewerUser = reviewerMap[refundRequest.fk_reviewer_id];

    const { text: statusText, severity: statusSeverity } = getRefundRequestStatus(
      status,
      refundRequest.refund_option,
      reviewerUser,
    );

    return {
      type: 'Refunds outside of policy request',
      id: refundRequest.id_refund_request,
      created_at: refundRequest.created_at,
      updated_at: refundRequest.updated_at,
      statusText,
      statusSeverity,
    };
  });
};
