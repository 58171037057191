import React, { useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';
import { theme } from '~/theme';

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box, Button, Chip, IconButton, Stack, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';

import { MessageSenders } from '~/consts/supportAssistant';

import { formatDateWithClock } from '~/services/TimeService';

import { Chat } from '../types/Chat';
import { ChatMessage, FeedbackSentiment } from '../types/ChatMessage';

type Props = {
  chat: Chat;
  message: ChatMessage;
  messageId: string;
};

const getOfferLink = (offerId: string, offerType: string) => {
  return offerType === 'bedbank_hotel' ? `/bedbank/properties/${offerId}` : `/offers/${offerId}`;
};

export default function Message({ chat, message, messageId }: Props) {
  const selectedMessageRef = useRef(null);

  // This is required to display historical chatss that have offers listed.
  // Offers were removed with the removal of the sales functionality.
  const hasOffers = message.text && message?.meta?.payload?.offersSuggestions?.offers?.length;

  useEffect(() => {
    if (messageId === message.id) {
      selectedMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messageId, message.id]);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="end"
      justifyContent={message.sender === MessageSenders.USER ? 'flex-end' : 'flex-start'}
    >
      <Box
        key={message.id}
        ref={messageId === message.id ? selectedMessageRef : null}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '80%',
          gap: 1,
          p: 1,
          borderRadius: '8px',
          bgcolor: messageId === message.id ? blue[100] : theme.palette.grey[100],
          wordBreak: 'break-word',
          whiteSpace: 'pre-wrap',
          textAlign: 'initial',
        }}
      >
        {message.text && !message.meta?.action && <Typography variant="body1">{message.text}</Typography>}
        {message?.meta?.content?.length > 0 && (
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1 }}>
            {message.meta.content.map((content, index) => (
              <Chip key={`chat-action-${index}`} label={content.text} color="primary" variant="outlined" />
            ))}
          </Stack>
        )}
        {message.meta?.action && message.text && <Chip label={message.text} color="success" variant="outlined" />}
        {!message.text && message?.meta?.payload?.id_orders && (
          <>
            <Typography variant="body1">
              Order ID:
              <Button
                target="_blank"
                to={`/purchases/${message?.meta?.payload?.id_orders}`}
                component={Link}
                variant="text"
                size="small"
              >
                {message?.meta?.payload?.id_orders}
              </Button>
            </Typography>
            <Typography variant="caption" color="red">
              Please keep in mind, customer might select different order further down the conversation
            </Typography>
          </>
        )}
        {hasOffers && (
          <>
            <Typography variant="body1">
              <Typography variant="body2">
                System searched offers for: <b>{message?.meta?.payload?.offersSuggestions.searchDestination?.name}</b>
              </Typography>
              <Typography variant="body2">
                <i>Offers that were suggested to the user:</i>
              </Typography>
              {message?.meta?.payload?.offersSuggestions.offers.map((offer) => (
                <Button
                  key={offer.id}
                  target="_blank"
                  to={getOfferLink(offer.id, offer.type)}
                  component={Link}
                  variant="text"
                  size="small"
                  sx={{ display: 'block' }}
                >
                  {offer.id}
                </Button>
              ))}
            </Typography>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {message.userId && (
            <Typography variant="caption">
              User:{' '}
              <Button target="_blank" href={`/users/${message.userId}`} variant="text" size="small">
                profile
              </Button>
            </Typography>
          )}
          {!!message.articles?.length && (
            <Typography variant="caption">
              Source:{' '}
              <Button
                to={`/support-assistant/articles?chatId=${chat.id}&messageId=${message.id}&articleIds=${message.articles
                  ?.map((article) => article.articleId)
                  .join(',')}`}
                component={Link}
                variant="text"
                size="small"
              >
                article
              </Button>
            </Typography>
          )}
          <Typography variant="caption">{formatDateWithClock(message.createdAt)}</Typography>
        </Box>
      </Box>
      <Box>
        {message.feedback && (
          <IconButton
            aria-label="delete"
            size="large"
            component={Link}
            sx={{
              border: '1px solid', // Border style
              borderColor: 'grey.300', // Light grey border color
              '&:hover': {
                borderColor: 'grey.500', // Darker grey border color on hover
              },
            }}
            to={`/support-assistant/feedback?search=${message.id}`}
          >
            {message.feedback === FeedbackSentiment.POSITIVE && <ThumbUpIcon color="primary" />}
            {message.feedback === FeedbackSentiment.NEGATIVE && <ThumbDownIcon color="warning" />}
          </IconButton>
        )}
      </Box>
    </Stack>
  );
}
