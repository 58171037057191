import React from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Stack, Typography } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

interface Props {
  bookingInfo: CruisesContract.BookingOrderResponse;
  hasConversion: boolean;
}

export default function CruiseOrderFxRate(props: Props) {
  const { bookingInfo, hasConversion } = props;
  return (
    <>
      {hasConversion && bookingInfo.convertedCurrency && bookingInfo.baseCurrency && bookingInfo.requiredFxRate && (
        <Typography variant="body2">
          <Stack direction="column" alignItems="start" gap={1}>
            The fx rate used for this booking was:
            <Typography variant="subtitle2">
              <Stack direction="row" alignItems="center">
                Vendor ({bookingInfo.baseCurrency})
                <ArrowRightAltIcon />
                AUD fx rate: ({bookingInfo.fxRate})
              </Stack>
              <Stack direction="row" alignItems="center">
                Customer ({bookingInfo.convertedCurrency})
                <ArrowRightAltIcon />
                AUD fx rate: ({bookingInfo.requiredFxRate})
              </Stack>
            </Typography>
          </Stack>
        </Typography>
      )}
    </>
  );
}
