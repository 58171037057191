import React, { useState } from 'react';

import { RJSFSchema } from '@rjsf/utils';
import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Dialog, DialogContent, IconButton, Stack } from '@mui/material';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';

import VendorsService from '~/services/VendorsService';

import { ManualPaymentsRecord } from './ManualPaymentsList';
import { ManualPaymentsUpdateForm } from './ManualPaymentsUpdateForm';

type ManualPaymentsRecordWithCallBacks = ManualPaymentsRecord & {
  schema: RJSFSchema;
  onManualPaymentUpdated: (errorPayment: ManualPaymentsRecord) => void;
  onManualPaymentDeleted: (errorPaymentId: string) => void;
};

interface Props {
  row: ManualPaymentsRecordWithCallBacks;
}

export default function ManualPaymentsActions({ row }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const updateRow = (updatedRow: ManualPaymentsRecord) => {
    row.onManualPaymentUpdated(updatedRow);
    setShowUpdateModal(false);
  };

  const deleteRow = async () => {
    try {
      await VendorsService.deleteManualPayment({ id_manual_payment: row.id_manual_payment });
      enqueueSnackbar(`Payment deleted`, { variant: 'success' });
      row.onManualPaymentDeleted(row.id_manual_payment);
    } catch (err) {
      enqueueSnackbar(`Can't delete manual payment: ${err.message}`, { variant: 'error' });
    }
  };

  return (
    <Stack direction="row">
      <IconButton onClick={() => setShowUpdateModal(true)}>
        <ModeEditIcon fontSize="medium" />
      </IconButton>
      <ConfirmButton asIcon onConfirm={deleteRow}>
        <DeleteIcon fontSize="medium" />
      </ConfirmButton>

      <Dialog open={showUpdateModal} onClose={() => setShowUpdateModal(false)}>
        <DialogContent>
          <ManualPaymentsUpdateForm
            schema={row.schema}
            id_manual_payment={row.id_manual_payment}
            date_of_manual_payment={row.date_of_manual_payment}
            fk_offer={row.fk_offer}
            fk_vendor={row.fk_vendor}
            currency={row.currency}
            amount={row.amount}
            le_entity_currency={row.le_entity_currency}
            le_entity_amount={row.le_entity_amount}
            bank_transaction={row.bank_transaction}
            description={row.description}
            onManualPaymentUpdated={updateRow}
          />
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
