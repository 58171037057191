import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Button, Grid, Stack, Typography } from '@mui/material';

import RestrictedComponent from '~/components/Common/RestrictedComponent';

import { ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import { formatDateShort } from '~/services/TimeService';

import FlightRefundStatus from './FlightRefundStatus';

const getBaggageTotalCost = (addons: Array<App.BookingJourneyAddon>) => {
  return addons.reduce((sum, addon) => {
    return sum + addon.amount * addon.quantity;
  }, 0);
};

interface FlightTravellersProps {
  traveller: any;
  index: number;
  status: string;
  cost: number;
  currency: string;
  onIssueRefund: (travellerId: string) => void;
  updateTravellerRefundStatus: (orderId: string, itemId: string, payload: any) => void;
  orderId: string;
  itemId: string;
  addons: Array<App.BookingJourneyAddon>;
}

export function FlightTravellers({
  traveller,
  index,
  status,
  cost,
  currency,
  onIssueRefund,
  updateTravellerRefundStatus,
  orderId,
  itemId,
  addons = [],
}: FlightTravellersProps) {
  return (
    <Grid container key={index} mt={2} mb={2}>
      <Grid xs={1} className="type-heading">
        {index + 1}
      </Grid>
      <Grid xs={3}>
        <Stack gap={1}>
          <Typography>
            {traveller.given_name} {traveller.surname}
          </Typography>
          <Typography>{traveller.type.charAt(0).toUpperCase() + traveller.type.slice(1)}</Typography>
          {addons.length > 0 && addons.map((addon, index) => <Typography key={index}>{addon.description}</Typography>)}
          <Typography fontWeight="bold"> Total</Typography>
        </Stack>
      </Grid>
      <Grid xs={1}>
        <Stack gap={1}>
          <Typography>
            {currencyFormatter.format(cost, {
              code: currency,
            })}
          </Typography>
          <Typography>—</Typography>
          {addons.length > 0 &&
            addons.map((addon, index) => (
              <Typography key={index}>
                {currencyFormatter.format(addon.amount * addon.quantity, { code: currency })}
              </Typography>
            ))}
          <Typography fontWeight="bold">
            {currencyFormatter.format(cost + getBaggageTotalCost(addons), {
              code: currency,
            })}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={2} display="flex" justifyContent="center" alignItems="center">
        {traveller.refunded_at ? (
          <Stack spacing={2}>
            <Typography>Refunded</Typography>
            <Typography>{formatDateShort(traveller.refunded_at)}</Typography>
            <Typography>
              {currencyFormatter.format(traveller.refund_amount, {
                code: currency,
              })}
            </Typography>
          </Stack>
        ) : (
          <Typography fontWeight="bold">{status}</Typography>
        )}
      </Grid>
      <RestrictedComponent excludedRoles={[ROLE_EXPERIENCES_COORDINATOR]}>
        <Grid xs={2} display="flex" justifyContent="center" alignItems="center">
          {!traveller.refunded_at && (
            <Button variant="contained" color="error" onClick={() => onIssueRefund(traveller.id)}>
              Issue Refund
            </Button>
          )}
        </Grid>
      </RestrictedComponent>

      <Grid xs={3} display="flex" justifyContent="center" alignItems="center">
        <FlightRefundStatus
          orderId={orderId}
          itemId={itemId}
          travellerId={traveller.id}
          travellerRefundStatus={traveller.refund_status}
          onUpdateTravellerRefundStatus={updateTravellerRefundStatus}
        />
      </Grid>
    </Grid>
  );
}
