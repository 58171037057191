import React, { useCallback, useEffect, useState } from 'react';

import { RJSFSchema, UiSchema } from '@rjsf/utils';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Container } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import Spinner from '~/components/Common/Spinner';

import useToggleState from '~/hooks/useToggleState';

import VendorsService from '~/services/VendorsService';

import { ManualPaymentsAddForm } from './ManualPaymentsAddForm';
import { ManualPaymentsList, ManualPaymentsRecord } from './ManualPaymentsList';

export const uiSchema: UiSchema = {
  fk_offer: { 'ui:widget': 'hidden' },
  fk_vendor: { 'ui:widget': 'hidden' },
  fk_added_by: { 'ui:widget': 'hidden' },
  date_of_manual_payment: {
    'ui:placeholder': '31/12/2017',
    'ui:widget': 'date',
    'ui:title': 'Date of Payment',
  },
  currency: {
    'ui:widget': 'select',
    'ui:title': 'Payment currency',
  },
  amount: {
    'ui:placeholder': '0',
    'ui:widget': 'text',
    'ui:title': 'Payment amount',
  },
  le_entity_currency: {
    'ui:widget': 'select',
    'ui:title': 'LE Entity Currency',
  },
  le_entity_amount: {
    'ui:placeholder': '0',
    'ui:widget': 'text',
    'ui:title': 'LE Entity Amount Paid',
  },
  bank_transaction: {
    'ui:widget': 'textarea',
    'ui:title': 'Bank Transaction',
  },
  description: {
    'ui:widget': 'textarea',
    'ui:title': 'Description',
  },
};

interface Props {
  offerId: string;
  vendorId: string;
  manualPayments: ManualPaymentsRecord[];
  totalManualPayments: {
    amount: number;
    currency: string;
  };
  onManualPaymentAdded: (manualPayment: ManualPaymentsRecord) => void;
  onManualPaymentUpdated: (manualPayment: ManualPaymentsRecord) => void;
  onManualPaymentDeleted: (manualPaymentId: string) => void;
}

export default function ManualPaymentsPane(props: Props) {
  const { onManualPaymentAdded, onManualPaymentUpdated, onManualPaymentDeleted } = props;

  const [schema, setSchema] = useState<RJSFSchema>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { isToggled: showForm, toggle: setShowForm, toggleOff: setShowFormOff } = useToggleState();

  const handleManualPaymentAdded = useCallback(
    (manualPayment) => {
      setShowFormOff();
      onManualPaymentAdded(manualPayment);
    },
    [onManualPaymentAdded],
  );

  useEffect(() => {
    setLoading(true);
    VendorsService.getManualPaymentsSchema()
      .then((schemas) => setSchema(schemas.post.body))
      .finally(() => setLoading(false));
  }, []);

  if (loading || !props.vendorId) {
    return <Spinner />;
  }

  return (
    <Box>
      <PageSubheader title="Manual Payments">
        {showForm ? (
          <Button startIcon={<RemoveIcon />} variant="text" onClick={setShowForm} aria-label="Hide manual payment form">
            Hide manual payment form
          </Button>
        ) : (
          <Button startIcon={<AddIcon />} variant="text" onClick={setShowForm} aria-label="Add new manual payment">
            Add manual payment
          </Button>
        )}
      </PageSubheader>

      {showForm && (
        <Container maxWidth="sm">
          <ManualPaymentsAddForm
            schema={schema}
            offerId={props.offerId}
            vendorId={props.vendorId}
            onManualPaymentAdded={handleManualPaymentAdded}
          />
        </Container>
      )}

      <Box id="manual-payments-pane">
        <ManualPaymentsList
          schema={schema}
          manualPayments={props.manualPayments}
          totalManualPayments={props.totalManualPayments}
          onManualPaymentUpdated={onManualPaymentUpdated}
          onManualPaymentDeleted={onManualPaymentDeleted}
        />
      </Box>
    </Box>
  );
}
