import React, { useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import { Box, Button, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import { GoogleAdGroupSearch } from '~/components/GoogleAds/GoogleAdGroupSearch';
import { calculateItcpa } from '~/components/GoogleAds/utils';

import * as publicOfferFeedService from '~/services/PublicOfferFeedService';
import { AdGroup } from '~/services/PublicOfferFeedService';

const SIZE_PER_PAGE = 20;

export const GoogleAdsContainer = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [customerId, setCustomerId] = useState<string>(undefined);
  const [campaignId, setCampaignId] = useState<string>(undefined);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [dataState, setData] = useState<Array<AdGroup>>([]);
  const [calculatedItcpa, setCalculatedItcpa] = useState(new Map<string, number>());
  const [bidMap, setBidMap] = useState(new Map<string, number>());
  const [currentPage, setCurrentPage] = useState(1);
  const [rowCountState, setRowCountState] = useState(SIZE_PER_PAGE);
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);

  const setAdGroupData = useCallback((adgroupData) => {
    setData(adgroupData);
    const itcpaMap = new Map();
    const bidMap = new Map();
    adgroupData.map((row) => {
      if (row.expectedConversionMargin && row.bidPercentage) {
        itcpaMap.set(row.adGroupId, calculateItcpa(row.expectedConversionMargin, row.bidPercentage));
        bidMap.set(row.adGroupId, row.bidPercentage);
      }
    });
    setCalculatedItcpa(itcpaMap);
    setBidMap(bidMap);
  }, []);

  const searchAdGroups = useCallback(
    async (token?: string) => {
      try {
        setFetchingState('loading');
        const { result, count, nextPageToken } = await publicOfferFeedService.getAdGroups(
          customerId,
          campaignId,
          SIZE_PER_PAGE,
          token,
        );
        setAdGroupData(result);
        setRowCountState(count);
        setPageToken(nextPageToken);
        setFetchingState('success');
      } catch (e) {
        setFetchingState('failed');
        enqueueSnackbar(`Error while fetching adGroups: ${e.message}`, { variant: 'error' });
      }
    },
    [setAdGroupData, campaignId, customerId, enqueueSnackbar],
  );

  const dataRows = useMemo(
    () =>
      dataState.map((row, index) => ({
        id: index,
        ...row,
      })),
    [dataState],
  );

  const onChangeBid = useCallback(
    (params, event) => {
      const newBid = event.target.value;
      const newBidMap = new Map(bidMap);
      newBidMap.set(params.row.adGroupId, newBid);
      setBidMap(newBidMap);
      const newCalculatedItcpa = new Map(calculatedItcpa);
      newCalculatedItcpa.set(params.row.adGroupId, calculateItcpa(newBid, params.row.expectedConversionMargin));
      setCalculatedItcpa(newCalculatedItcpa);
    },
    [bidMap, calculatedItcpa],
  );

  const performAdGroupMutate = useCallback(
    async (params) => {
      try {
        setFetchingState('loading');
        await publicOfferFeedService.mutateAdgroup(
          customerId,
          params.row.adGroupId,
          params.row.adGroupResourceName,
          calculatedItcpa.get(params.row.adGroupId),
        );
        enqueueSnackbar('Successfully updated the adGroup', { variant: 'success' });
        await searchAdGroups();
      } catch (e) {
        setFetchingState('failed');
        enqueueSnackbar(`Error while updating the adgroup: ${e.message}`, { variant: 'error' });
      }
    },
    [enqueueSnackbar, calculatedItcpa, customerId, searchAdGroups],
  );

  const onUpdateAdGroup = useCallback(
    (event, params) => {
      event.preventDefault();
      event.stopPropagation();
      performAdGroupMutate(params);
    },
    [performAdGroupMutate],
  );

  const onPageChange = useCallback(
    async (page: number) => {
      setCurrentPage(page);
      await searchAdGroups(pageToken);
    },
    [pageToken, searchAdGroups],
  );

  const columns: GridColDef[] = [
    {
      field: 'campaignName',
      headerName: 'Campaign',
      width: 250,
      editable: false,
      display: 'flex',
    },
    {
      field: 'adGroupName',
      headerName: 'Ad group',
      width: 250,
      editable: false,
      display: 'flex',
    },
    {
      field: 'expectedConversionMargin',
      headerName: 'Expected Conversion Margin',
      width: 250,
      renderCell: (params) =>
        params.row.expectedConversionMargin && params.row.expectedConversionMargin !== 0
          ? params.row.expectedConversionMargin
          : '-',
      editable: false,
      display: 'flex',
    },
    {
      field: 'bidPercentage',
      headerName: 'Bid Percentage',
      width: 200,
      editable: false,
      renderCell: (params) => {
        return (
          <TextField
            type="number"
            name={'tcpa'}
            onChange={(event) => onChangeBid(params, event)}
            value={bidMap.get(params.row.adGroupId)}
            required
          />
        );
      },
      display: 'flex',
    },
    {
      field: 'itcpa',
      headerName: 'iTCPA',
      width: 150,
      editable: false,
      renderCell: (params) =>
        params.row.expectedConversionMargin && calculatedItcpa.get(params.row.adGroupId)
          ? calculatedItcpa.get(params.row.adGroupId)
          : '-',
      display: 'flex',
    },
    {
      field: 'tcpa',
      headerName: 'tCPA',
      width: 150,
      renderCell: (params) => (params.row.tcpa && params.row.tcpa > 0 ? params.row.tcpa : '-'),
      editable: false,
      display: 'flex',
    },
    {
      field: 'cpa',
      headerName: 'CPA',
      width: 150,
      editable: false,
      renderCell: (params) => (params.row.cpa && params.row.cpa > 0 ? params.row.cpa : '-'),
      display: 'flex',
    },
    {
      field: 'mutate_adGroup',
      headerName: 'Update Ad Group',
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="text"
            size="small"
            onClick={(e) => onUpdateAdGroup(e, params)}
            disabled={!calculatedItcpa.get(params.row.adGroupId)}
          >
            Update
          </Button>
        );
      },
      display: 'flex',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Search Google Ad Groups</title>
      </Helmet>
      <GoogleAdGroupSearch
        customerId={customerId}
        campaignId={campaignId}
        fetchingState={fetchingState}
        setCustomerId={setCustomerId}
        setCampaignId={setCampaignId}
        searchAdGroups={searchAdGroups}
      />
      <Grid container spacing={2} />
      <Box mt={2}>
        <Box mt={2}>
          <DataGrid
            loading={fetchingState === 'loading'}
            rows={dataRows}
            columns={columns}
            disableRowSelectionOnClick={true}
            autoHeight
            pagination
            paginationMode="server"
            paginationModel={{
              page: currentPage - 1,
              pageSize: SIZE_PER_PAGE,
            }}
            pageSizeOptions={[SIZE_PER_PAGE]}
            classes={{
              root: 'T-packages-container',
              virtualScrollerContent: 'T-packages-table',
            }}
            slots={{ pagination: GridPagination }}
            onPaginationModelChange={({ page }) => onPageChange(page + 1)}
            getRowHeight={() => 'auto'}
            rowCount={rowCountState}
          />
        </Box>
      </Box>
    </>
  );
};
