import React, { useCallback, useEffect, useState } from 'react';

import cn from 'clsx';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { Link } from '@mui/material';
import { Button, Container, FormControlLabel, Stack, Switch, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { getTrips } from '~/services/TripPlannerService';

import PageHeader from '../Common/Elements/PageHeader';

// Fix for no trips found overlay height being 0
const StyledDataGrid = styled(DataGrid)`
  &.no-trips {
    .MuiDataGrid-overlayWrapper {
      height: 50px;
    }
    .MuiDataGrid-overlayWrapperInner {
      height: 50px;
    }
  }
`;

function TripPlannerPage() {
  // table data
  const [trips, setTrips] = useState<Array<App.AdminTripDetails>>([]);
  const [paginationModel, setPaginationModel] = useState<{
    pageSize: number;
    page: number;
  }>({
    pageSize: 20,
    page: 0,
  });
  const [totalTrips, setTotalTrips] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  // Search param input fields
  const [tripId, setTripId] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');
  const [showHidden, setShowHidden] = useState<boolean>(false);
  const [showConciergeOnly, setShowConciergeOnly] = useState<boolean>(false);

  // Currently being used search params. These are changed to current values in the above state upon pressing search.
  const [searchParams, setSearchParams] = useState<{
    tripId: string | undefined;
    orderId: string | undefined;
    showHidden: boolean;
    showConciergeOnly: boolean;
  }>({ tripId: undefined, orderId: undefined, showHidden: false, showConciergeOnly: false });

  const { enqueueSnackbar } = useSnackbar();

  const fetchTrips = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getTrips({
        page: paginationModel.page + 1,
        tripId: searchParams.tripId ? searchParams.tripId.trim() : undefined,
        orderId: searchParams.orderId ? searchParams.orderId.trim() : undefined,
        showHiddenOnly: searchParams.showHidden,
        showConciergeOnly: searchParams.showConciergeOnly,
      });
      if (response.status === 200) {
        setTrips(response.result.trips);
        setTotalTrips(response.result.numberOfTrips);
      }
    } catch {
      setTrips([]);
      setTotalTrips(0);
      enqueueSnackbar('Invalid Trip ID or Order ID. Please ensure the IDs are entered correctly and try again.', {
        variant: 'error',
      });
    }
    setLoading(false);
  }, [enqueueSnackbar, paginationModel, searchParams]);

  useEffect(() => {
    fetchTrips(); // Fetch trips whenever pagination or search parameters change

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, searchParams]);

  const handleSearch = () => {
    setPaginationModel({
      pageSize: 20,
      page: 0,
    });
    setSearchParams({
      tripId,
      orderId,
      showHidden,
      showConciergeOnly,
    });
  };

  // changing of showHidden and showConciergeOnly also causes a new search.
  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHidden, showConciergeOnly]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 150 },
    {
      field: 'createdAt',
      headerName: 'Created At (UTC)',
      width: 200,
      valueFormatter: (value: string) => {
        return value ? new Date(value).toLocaleString('en-GB', { timeZone: 'UTC' }) : '';
      },
    },
    { field: 'isHidden', headerName: 'Hidden', width: 80, valueFormatter: (value: boolean) => (value ? 'Yes' : 'No') },
    { field: 'id', headerName: 'Trip Id', width: 200 },
    {
      field: 'createdBy',
      headerName: 'User',
      width: 200,
      renderCell: (params) => {
        return (
          <Link color="primary" rel="noreferrer" href={`/users/${params.row.createdBy}`} underline="hover">
            View User
          </Link>
        );
      },
      display: 'flex',
    },
    { field: 'sourceOrderId', headerName: 'Order Id', width: 200 },
    {
      field: 'openTrip',
      headerName: '',
      width: 150,
      renderCell: (params) => (
        <Link color="primary" rel="noreferrer" href={`/trip-planner/trip/${params.row.id}`} underline="hover">
          Open Trip
        </Link>
      ),
      display: 'flex',
    },
  ];

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>TripPlanner - Trips</title>
      </Helmet>
      <PageHeader title="TripPlanner - Trips" />

      <Stack spacing={2} direction="row" mb={2}>
        <TextField
          label="Trip Id"
          variant="outlined"
          value={tripId}
          fullWidth
          onChange={(e) => setTripId(e.target.value)}
        />
        <TextField
          label="Order Id"
          variant="outlined"
          value={orderId}
          fullWidth
          onChange={(e) => setOrderId(e.target.value)}
        />
      </Stack>

      <Stack spacing={2} direction="row" mb={2} justifyContent={'space-between'}>
        <FormControlLabel
          control={<Switch checked={showHidden} onChange={(e) => setShowHidden(e.target.checked)} color="primary" />}
          label="Show Hidden Only"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showConciergeOnly}
              onChange={(e) => setShowConciergeOnly(e.target.checked)}
              color="primary"
            />
          }
          label="Show Concierge Trips Only"
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
      </Stack>
      <StyledDataGrid
        rows={trips}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={totalTrips}
        pageSizeOptions={[]}
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        localeText={{
          noRowsLabel: 'No trips found',
        }}
        getRowHeight={() => 'auto'}
        className={cn({ 'no-trips': trips.length === 0 })}
      />
    </Container>
  );
}

export default TripPlannerPage;
