import React from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import BorderedGridItem from '~/components/Common/BorderedGridItem';

import { getPromoProductDisplayName } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';

import TravellersList from '../PromoPage/TravellersList';

type PromoResponseItemProps = {
  item: definitions['Discount Response']['promo']['items'][0];
  currencyCode: string;
  handleGridClick: (item: definitions['Discount Response']['promo']['items'][0]) => void;
  meta: definitions['PromoMeta'];
  clientOrderVersion?: number;
  extendedItem: boolean;
};

function PromoResponseItem({
  item,
  currencyCode,
  handleGridClick,
  clientOrderVersion,
  meta,
  extendedItem,
}: PromoResponseItemProps) {
  const hasDiscount = item.discountAmount > 0;
  const isLuxPlusPrice = item.luxPlusPrice < item.discountableTotal;
  return (
    <Grid
      container
      key={`${item.offerId || item.itemId}`}
      sx={{
        marginBottom: 1,
        border: '1px solid',
        borderColor: 'grey.400',
        width: '100%',
      }}
      onClick={() => handleGridClick(item)}
      title={`${JSON.stringify(item, null, 4)}`}
      spacing={1}
    >
      <BorderedGridItem>
        <Stack direction="row" gap={1}>
          {hasDiscount && (
            <Typography component="span" color={isLuxPlusPrice ? 'blue' : 'text.primary'}>
              {currencyFormatter(currencyCode, item.discountableTotal - item.discountAmount)}
            </Typography>
          )}
          <Typography
            sx={{
              textDecoration: hasDiscount ? 'line-through' : '',
            }}
            component="span"
            color={isLuxPlusPrice ? 'blue' : 'text.primary'}
          >
            {currencyFormatter(currencyCode, item.discountableTotal)}
          </Typography>
          {hasDiscount && (
            <Typography color="green" component="span">
              ({currencyFormatter(currencyCode, item.discountAmount)})
            </Typography>
          )}
        </Stack>
      </BorderedGridItem>

      <BorderedGridItem>
        <Stack direction="column" onClick={() => handleGridClick(item)} title={JSON.stringify(item, null, 4)}>
          <Typography>
            <strong>Product Type: </strong>
            {meta && getPromoProductDisplayName(meta.promo_product_types, item.categoryBK, item.subCategoryBK)}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Travellers: </strong>
            <TravellersList travellers={item.travellers} />
          </Typography>
          <Typography>
            <strong>ReservationType: </strong>
            {item.reservationType ? `${item.reservationType}` : ''}
          </Typography>
        </Stack>
      </BorderedGridItem>
      <BorderedGridItem>
        {extendedItem && (
          <Stack direction="column" title={JSON.stringify(item, null, 4)}>
            <Typography>
              {' '}
              <strong>Item Country Code: </strong>
              {item.itemCountryCode && item.itemCountryCode}
            </Typography>
            <Typography>
              <strong>Number of Nights: </strong>
              {item.numberOfNights && item.numberOfNights}
            </Typography>
            <Typography>
              <strong>Adults: </strong>
              {item.numberOfAdults ? `${item.numberOfAdults}` : ''}
            </Typography>
            {item.numberOfChildren > 0 && (
              <Typography>
                <strong>Children: </strong>
                {item.numberOfChildren}
              </Typography>
            )}
            {clientOrderVersion && (
              <Typography>
                <strong>v</strong>
                {clientOrderVersion}
              </Typography>
            )}
          </Stack>
        )}
      </BorderedGridItem>
    </Grid>
  );
}

export { PromoResponseItem };
