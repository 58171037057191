import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import * as publicOfferFeedService from '~/services/PublicOfferFeedService';
import { AdCampaign } from '~/services/PublicOfferFeedService';

interface Props {
  customerId: string;
  campaignId: string;
  fetchingState: Utils.FetchingState;
  setCustomerId: (value: string) => void;
  setCampaignId: (value: string) => void;
  searchAdGroups: () => void;
}

export const GoogleAdGroupSearch = ({
  customerId,
  campaignId,
  setCustomerId,
  setCampaignId,
  searchAdGroups,
  fetchingState,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [customerList, setCustomerList] = useState<{ accountName: string; accountId: string }[]>([]);
  const [campaignMap, setCampaignMap] = useState(new Map<string, AdCampaign[]>());

  const fetchCustomerList = useCallback(async () => {
    const customers = [];
    const campaigns = new Map();
    try {
      const { result } = await publicOfferFeedService.getCustomers();
      result.forEach((customer) => {
        if (customer.campaigns.length > 0) {
          customers.push({ accountName: customer.descriptiveName, accountId: customer.id });
          campaigns.set(customer.id, customer.campaigns);
        }
      });

      setCustomerList(customers);
      setCampaignMap(campaigns);
    } catch (e) {
      enqueueSnackbar(`Failed to fetch campaign list : ${e.message}`, { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchCustomerList();
  }, [fetchCustomerList]);

  return (
    <Grid container spacing={2} mb={2} mt={2}>
      <Grid md={3}>
        <FormControl fullWidth>
          <InputLabel id="select-customer-label">Select Customer Account</InputLabel>
          <Select
            labelId="select-customer-label"
            value={customerId}
            label="Select Customer Account"
            onChange={(e) => setCustomerId(e.target.value)}
          >
            {customerList.map(({ accountName, accountId }) => (
              <MenuItem key={accountId} value={accountId}>
                {accountName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid md={3}>
        <FormControl fullWidth>
          <InputLabel id="select-customer-label">Select Campaign</InputLabel>
          <Select
            disabled={!customerId}
            labelId="select-campaign-label"
            value={customerId}
            label="Select Campaign"
            onChange={(e) => setCampaignId(e.target.value)}
          >
            {campaignMap?.get(customerId)?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid md={3}>
        <Button
          variant="contained"
          className="T-promo-search-button"
          type="submit"
          disabled={!customerId || !campaignId}
          onClick={() => searchAdGroups()}
        >
          {fetchingState === 'loading' ? 'Searching…' : 'Search'}
        </Button>
      </Grid>
    </Grid>
  );
};
