import React, { useCallback } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { Box, MenuItem, TextField } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { addQuery } from '~/utils/url';

import PageHeader from '../../Common/Elements/PageHeader';

type Props = {
  user: App.User | null;
  currency: string;
  regions: libRegions.Region[];
};

export default function CreditsCurrencySelector(props: Props) {
  const { user, currency, regions } = props;

  const location = useLocation();
  const history = useHistory();

  const handleCurrencyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newLocation = addQuery(location, { currency: event.target.value });
      history.push(newLocation);
    },
    [location, history],
  );

  return (
    <PageHeader title={user ? `View credits / ${user.fullName}` : 'View user credits'}>
      <Box width={250}>
        <TextField
          value={currency}
          onChange={handleCurrencyChange}
          label="Region and currency"
          variant="outlined"
          fullWidth
          select
        >
          {regions.map(
            (region) =>
              region.currencyCode && (
                <MenuItem key={region.name} value={region.currencyCode}>
                  {region.name + ' (' + region.currencyCode + ')'}
                </MenuItem>
              ),
          )}
        </TextField>
      </Box>
    </PageHeader>
  );
}
