import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { FileUpload as FileUploadIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';

import VendorsService from '~/services/VendorsService';

export default function UploadBedBankPaymentsForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [file, setFile] = useState<File>();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event) => {
    try {
      const formData = new FormData();
      formData.append('bedbank-payments', file);
      setIsSubmitting(true);
      await VendorsService.uploadBedbankPayments(formData);
      enqueueSnackbar('Bedbank payments submitted successfully', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onFileInputChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <Box width="50%">
      <Stack sx={{ my: 2 }} spacing={2}>
        <Typography>{file ? file.name : 'No file chosen'}</Typography>
        <Button
          variant="contained"
          component="label"
          size="medium"
          startIcon={<FileUploadIcon />}
          disabled={isSubmitting}
          sx={{ width: '50%' }}
        >
          Select file
          <input type="file" accept=".xlsx" hidden onChange={onFileInputChange} />
        </Button>
      </Stack>
      {isSubmitting ? (
        <CircularProgress />
      ) : (
        <Button type="submit" variant="contained" onClick={handleSubmit} disabled={isSubmitting || !file}>
          Upload bedbank payments report
        </Button>
      )}
    </Box>
  );
}
