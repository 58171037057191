import React, { useState } from 'react';

import currencyFormatter from 'currency-formatter';

import { Alert, Box, Button, Link, Stack, Typography } from '@mui/material';

import TENANTS from '~/consts/tenant';

import { formatDateWithClock } from '~/services/TimeService';
import { customerPortalHost } from '~/services/common';

import * as CE from '../../../../consts/experiences';
import * as ES from '../../../../services/ExperiencesService';
import * as LE from '../../../Experiences/components';
import Log from '../OrderItem/Log';

interface ExperienceItemProps {
  count?: number;
  hasAllowedRefund: boolean;
  experienceItem: App.ExperienceItem;
  experienceOffer?: ES.ExperienceOffer;
  showRefundModal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, experienceItem: App.ExperienceItem) => void;
  orderBrand: string;
  experienceBookingDetail?: ES.BookingDetails;
}

const isProduction = process.env.NODE_ENV === 'production';

function ExperienceItem({
  count = 1,
  experienceItem,
  experienceOffer,
  showRefundModal,
  hasAllowedRefund,
  orderBrand,
  experienceBookingDetail,
}: ExperienceItemProps) {
  const [fetchingBookingDetails, setFetchingBookingDetails] = useState(false);
  const [error, setError] = useState(null);

  const providerLabels = { led: 'LED', rez: 'Rezdy' };
  const disabled = experienceItem.status.toLocaleUpperCase() === 'CANCELLED';

  const status = CE.getStatusByPurchase(experienceItem.status);

  const experiencePage = () => `${customerPortalHost(tenant)}/experience/${experienceOffer.slug}/${experienceOffer.id}`;

  const salesforceLink = (salesforceOfferId) =>
    `${window.configs.SALESFORCE_HOST}/lightning/r/Opportunity/${salesforceOfferId}/view`;

  const renderTitle = () => {
    if (experienceOffer?.title) {
      return null;
    }

    return 'Not found title';
  };

  const tenant = Object.values(TENANTS).find((tenant) => tenant.brand === orderBrand);

  const viewVoucher = async () => {
    if (experienceOffer?.provider) {
      return window.open(
        `${customerPortalHost(tenant)}/account/my-escapes/experiences/${experienceItem.id}/voucher`,
        '_blank',
      );
    }

    // Musement vouchers are only visible in production
    if (!isProduction) {
      return;
    }

    try {
      if (error) {
        setError(null);
      }
      setFetchingBookingDetails(true);
      const { result } = await ES.getBookingDetails(experienceItem.id);
      return window.open(result[0]?.voucherUrl, '_blank');
    } catch (error) {
      setError(error.message);
    } finally {
      setFetchingBookingDetails(false);
    }
  };

  const offerSalesforceId =
    experienceOffer?.idSalesforceExternal || experienceBookingDetail?.redemptionLocation?.dealIdSalesforceExternal;

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        <Box>
          <Typography variant="h4" color="grey.800" fontWeight={500}>
            {count}
          </Typography>
        </Box>

        <Box flexGrow={1}>
          <Stack>
            {experienceOffer && (
              <Link
                variant="h5"
                href={experiencePage()}
                fontWeight={500}
                target="_blank"
                rel="noreferrer"
                underline="hover"
              >
                {experienceOffer.title}
              </Link>
            )}
            {!experienceOffer && <Typography>Loading...</Typography>}

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Fare Type:</Typography>
              <Typography>{experienceItem?.ticket.fareType}</Typography>
            </Stack>

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Date:</Typography>
              <Typography>
                {experienceItem?.ticket.date ? formatDateWithClock(experienceItem.ticket.date) : '-'}
              </Typography>
            </Stack>

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Free Cancellation:</Typography>
              <Typography>{experienceOffer?.cancellationInfo.isFree ? 'YES' : 'NO'}</Typography>
            </Stack>

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Booking Number:</Typography>
              <Typography>{experienceItem.booking_number}</Typography>
            </Stack>

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Vendor Id:</Typography>
              <Typography>{providerLabels[experienceItem.provider] ?? experienceItem.provider}</Typography>
            </Stack>

            {experienceBookingDetail?.providerBookingNumber && (
              <Stack direction="row" gap={1}>
                <Typography fontWeight="bold">Provider Booking Id:</Typography>
                <Typography> {experienceBookingDetail?.providerBookingNumber}</Typography>
              </Stack>
            )}

            {experienceItem.provider_order_id && (
              <Stack direction="row" gap={1}>
                <Typography fontWeight="bold">Provider Order Id:</Typography>
                <Typography> {experienceItem.provider_order_id}</Typography>
              </Stack>
            )}

            {offerSalesforceId && (
              <Stack direction="row" gap={1}>
                <Typography fontWeight="bold">Salesforce Offer Id:</Typography>
                <Link href={salesforceLink(offerSalesforceId)}>{offerSalesforceId}</Link>
              </Stack>
            )}
          </Stack>
        </Box>

        <Box>
          <LE.FlexWrapper direction="column">
            <LE.Badge mb="5px" py="8px" px="16px" lineHeight="10px" title={status.title} background={status.color} />
            {hasAllowedRefund && (
              <Button
                disabled={disabled}
                className="T-issue-refund"
                onClick={(e) => showRefundModal(e, experienceItem)}
              >
                Issue refund
              </Button>
            )}
            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Total Sell Price:</Typography>
              <Typography>
                {`${experienceItem.currency} ${currencyFormatter.format(experienceItem.total, {
                  code: experienceItem.currency,
                })}`}
              </Typography>
            </Stack>
            {experienceBookingDetail?.costPriceAmount && (
              <Stack direction="row" gap={1}>
                <Typography fontWeight="bold">Total Cost Price:</Typography>
                <Typography>
                  {`${experienceBookingDetail?.costPriceCurrency} ${currencyFormatter.format(
                    experienceBookingDetail?.costPriceAmount,
                    { code: experienceBookingDetail?.costPriceCurrency },
                  )}`}
                </Typography>
              </Stack>
            )}
          </LE.FlexWrapper>
        </Box>

        <Stack direction="column" spacing={1} alignItems="end">
          {offerSalesforceId && (
            <Box>
              <Button variant="text" size="small" target="_blank" href={`/finance/offers/${offerSalesforceId}`}>
                Vendor payments
              </Button>
            </Box>
          )}

          <Box>
            <Button variant="text" size="small" onClick={viewVoucher} disabled={fetchingBookingDetails}>
              View Voucher
              {fetchingBookingDetails && <LE.Spinner size={26} />}
            </Button>
          </Box>

          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </Stack>

      <Box mt={2}>
        <Log itemId={experienceItem.id} orderId={experienceItem.fk_order_id} />
      </Box>
    </Box>
  );
}

export default ExperienceItem;
