import React from 'react';

import Form from '@rjsf/core';
import { UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Container } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import VendorsService from '~/services/VendorsService';

import VendorOfferPaymentsListContainer from '../OfferDetail/VendorOfferPayments/VendorOfferPaymentsListContainer';

import VendorOfferFilesContainer from './VendorOfferFilesContainer';

const uiSchema: UiSchema = {
  fk_offer: { 'ui:widget': 'hidden' },
  fk_vendor: { 'ui:widget': 'hidden' },
  fk_added_by: { 'ui:widget': 'hidden' },
  date_of_payment: {
    'ui:placeholder': '31/12/2017',
    'ui:widget': 'date',
    'ui:title': 'Date of Payment',
  },
  cost_currency: {
    'ui:widget': 'select',
    'ui:title': 'Currency in which Vendor was paid',
  },
  cost_amount: {
    'ui:placeholder': '0',
    'ui:widget': 'text',
    'ui:title': 'Amount Paid to Vendor',
  },
  entity_currency: { 'ui:widget': 'select', 'ui:title': 'LE Entity Currency' },
  entity_amount: {
    'ui:placeholder': '0',
    'ui:widget': 'text',
    'ui:title': 'LE Entity Amount',
  },
  billing_currency: { 'ui:widget': 'select', 'ui:title': 'Billing Currency' },
  billing_amount: {
    'ui:placeholder': '0',
    'ui:widget': 'text',
    'ui:title': 'Billing Amount',
  },
  id_bank_transaction: {
    'ui:placeholder': 'Bank-Transaction-ID',
    'ui:title': 'Bank Transaction ID',
  },
  description: {
    'ui:widget': 'textarea',
    'ui:placeholder': '(any comments)',
    'ui:title': 'Description',
  },
  legacy_id_offer_payment: { 'ui:widget': 'hidden' },
};

interface Props {
  match: {
    params: {
      id_offer: string;
      id_vendor: string;
      id_offer_payment: string;
    };
  };
}

interface State {
  isLoading: boolean;
  paymentData: {
    fk_offer: string;
    fk_vendor: string;
    date_of_payment: string;
    cost_currency: string;
    cost_amount: string;
    entity_currency: string;
    entity_amount: string;
    billing_currency: string;
    billing_amount: string;
    id_bank_transaction: string;
    description: string;
  };
  alertError: boolean;
  editMode: boolean;
  error: string | null;
  offerPayments: any;
  offerPayment: any;
  formSchema: any;
  attachments?: any[];
  isSubmitting: boolean;
  isDownloading: object;
  isDialogOpen: boolean;
  currentFile: any;
}

class VendorOfferPaymentsContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { match } = props;
    const idOfferPayment = match.params.id_offer_payment;

    this.state = {
      isLoading: true,
      paymentData: {
        fk_offer: match.params.id_offer,
        fk_vendor: match.params.id_vendor,
        date_of_payment: '',
        cost_currency: '',
        cost_amount: '0',
        entity_currency: 'AUD',
        entity_amount: '0',
        billing_currency: 'AUD',
        billing_amount: '0',
        id_bank_transaction: '',
        description: '',
      },
      alertError: false,
      editMode: !!idOfferPayment,
      error: null,
      offerPayments: [],
      offerPayment: null,
      formSchema: null,
      attachments: [],
      isSubmitting: false,
      isDownloading: {},
      isDialogOpen: false,
      currentFile: null,
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    const { match } = this.props;
    const { id_offer_payment } = match.params;

    if (prevProps.match.params.id_offer_payment !== id_offer_payment) {
      await this.fetchData();
    }
  }

  fetchData = async () => {
    const { id_offer, id_vendor, id_offer_payment } = this.props.match.params;

    this.setState({ isLoading: true });

    try {
      const requests = [
        VendorsService.getPaymentsByOfferVendor(id_offer, id_vendor),
        VendorsService.getPaymentsByOfferVendorSchema(),
      ];

      if (id_offer_payment) {
        requests.push(VendorsService.getOfferPayment(id_offer_payment));
      }

      const responses = await Promise.all(requests);

      if (id_offer_payment) {
        const offerPayment = responses[2].result;
        this.setState((prevState) => ({
          offerPayments: responses[0],
          formSchema: responses[1].put.body,
          offerPayment: offerPayment,
          paymentData: {
            ...prevState.paymentData,
            date_of_payment: offerPayment.date_of_payment.slice(0, 10),
            cost_currency: offerPayment.cost_currency,
            cost_amount: offerPayment.cost_amount,
            entity_currency: offerPayment.entity_currency,
            entity_amount: offerPayment.entity_amount,
            billing_currency: offerPayment.billing_currency,
            billing_amount: offerPayment.billing_amount,
            id_bank_transaction: offerPayment.id_bank_transaction,
            description: offerPayment.description,
          },
          attachments: offerPayment.files,
        }));
      } else {
        this.setState({
          offerPayments: responses[0],
          formSchema: responses[1].post.body,
        });
      }
    } catch (err) {
      this.setState({ error: err.message });
      console.warn(err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  validateForm = (formData, errors) => {
    const validateNumber = (value) => {
      try {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
          return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    };

    if (!validateNumber(formData.cost_amount)) {
      errors.cost_amount.addError('Should be a number');
    }

    if (!validateNumber(formData.entity_amount)) {
      errors.entity_amount.addError('Should be a number');
    }

    if (!validateNumber(formData.billing_amount)) {
      errors.billing_amount.addError('Should be a number');
    }

    return errors;
  };

  async handleSubmit(event) {
    const { editMode } = this.state;

    this.setState({ isSubmitting: true });

    try {
      const { formData } = event;
      if (editMode) {
        await VendorsService.updateOfferVendorPayment({
          idOfferPayment: this.props.match.params.id_offer_payment,
          paymentDetails: formData,
        });
      } else {
        await VendorsService.logOfferVendorPayment({
          paymentDetails: formData,
        });
      }

      await this.fetchData();

      this.setState({ isSubmitting: false });
    } catch (err) {
      this.setState({ error: err.message, isSubmitting: false });
    }
  }

  render() {
    const { match } = this.props;
    const { id_offer_payment } = match.params;
    const { isLoading, attachments, formSchema, paymentData, offerPayments, error, isSubmitting } = this.state;

    if (isLoading) {
      return (
        <Container maxWidth="xl">
          <Spinner />
        </Container>
      );
    }

    if (error) {
      return (
        <Container maxWidth="xl">
          <Button
            variant="outlined"
            component={Link}
            startIcon={<ChevronLeftIcon />}
            to={`/finance/offers/${match.params.id_offer}`}
          >
            Return to offer
          </Button>

          <br />

          <ErrorDisplay message={error} />
        </Container>
      );
    }

    return (
      <Container maxWidth="xl">
        <Button
          variant="outlined"
          component={Link}
          startIcon={<ChevronLeftIcon />}
          to={`/finance/offers/${match.params.id_offer}`}
        >
          Return to offer
        </Button>
        <br />
        <br />

        <Form
          schema={formSchema}
          uiSchema={uiSchema}
          validator={validator}
          formData={paymentData}
          customValidate={this.validateForm}
          onSubmit={(event) => this.handleSubmit(event)}
        >
          <Button sx={{ my: 2 }} type="submit" variant="contained" disabled={!!isSubmitting}>
            {isSubmitting && <Spinner size={15} />}
            Submit
          </Button>
        </Form>

        <VendorOfferFilesContainer
          id_offer_payment={id_offer_payment}
          attachments={attachments}
          refetch={this.fetchData}
        />

        <VendorOfferPaymentsListContainer data={offerPayments} />
      </Container>
    );
  }
}

export default VendorOfferPaymentsContainer;
