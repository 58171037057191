import React from 'react';

import { InfoRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

type HeaderInfoProps = {
  header: string;
  title: string;
};

function HeaderInfo({ header, title }: HeaderInfoProps) {
  return (
    <Box
      className="MuiDataGrid-columnHeaderTitleContainer"
      sx={{
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        cursor: 'default', // Mimics the non-interactive behavior
      }}
      title={title}
    >
      <Typography
        variant="body2"
        className="MuiDataGrid-columnHeaderTitle"
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontWeight: 'inherit', // Inherit the weight to align with DataGrid
        }}
      >
        {header}
      </Typography>
      <InfoRounded fontSize="small" sx={{ ml: 0.5 }} />
    </Box>
  );
}

export default HeaderInfo;
