import { addDays, datesHaveSameDayMonthYear, isAfter, isBefore } from '~/services/TimeService';

const AUTO_REJECTION_LOGIC_CUTOFF_DATE = '2024-09-23T05:23:00.000Z'; // September 23rd, 2024, 3:23 PM GMT+10 is 5:23 AM UTC (https://github.com/lux-group/svc-order/pull/3723#event-14357114089)
const OLD_LOGIC_CHANGE_DATE = '2024-09-04T08:49:00.000Z'; // September 4th, 2024, 6:49 PM GMT+10 is 8:49 AM UTC (https://github.com/lux-group/svc-order/pull/3648)

const getCancellationWindow = (createdAt: string | Date) => {
  return isBefore(createdAt, OLD_LOGIC_CHANGE_DATE) ? 2 : 3;
};

const withinCancellationPolicyDate = (createdAt: string, checkInDate: string) => {
  const daysToAdd = getCancellationWindow(createdAt);
  const createdAtPlusDays = addDays(daysToAdd, createdAt);

  return isAfter(createdAtPlusDays, checkInDate) || datesHaveSameDayMonthYear(createdAtPlusDays, checkInDate);
};

/**
 * Determines if the refund request was auto-rejected based on the old logic before the cutoff date,
 * After the cutoff, 'auto_rejected' is a stored status.
 *
 * @param {RefundRequest} refundRequest - The refund request data.
 * @param {string} check_in_date - The booking's check-in date.
 * @returns {boolean} - True if auto-rejected under old logic.
 */
export function isBeforeAutoRejectedLogicCutoff(refundRequest: RefundRequest, check_in_date: string) {
  return (
    isBefore(refundRequest.created_at, AUTO_REJECTION_LOGIC_CUTOFF_DATE) &&
    check_in_date &&
    withinCancellationPolicyDate(refundRequest.created_at, check_in_date) &&
    refundRequest.status === 'rejected'
  );
}
