/**
 * A function to provide switch/case like functionality without the actual syntax
 * @param cases An object of supported cases and their values
 * @param defaultCase Acts like the 'default' in a case statement, will default to this if no cases are found in the provided case object
 * @returns cases A function to retrieve the value for the given case
 *
 * @example
 * const animal = switchFunc({AU: 'Kangaroo', NZ: 'Kiwi'}, 'Dog')
 * animal('NZ') // returns 'Kiwi'
 * animal('AU') // returns 'Kangaroo'
 * animal('US') // returns 'Dog'
 */
export default function switchFunc<R, K extends string | number | symbol = string>(
  cases: Partial<Record<K, R>>,
  defaultCase?: R,
): (key: string) => R {
  return (key: string) => (Object.prototype.hasOwnProperty.call(cases, key) ? cases[key] : defaultCase);
}

/**
 * A alternative typing of `switchFunc` that will only accept values that have defined cases.
 * This lets us leverage the type checker to ensure that all possible values are covered.
 *
 * @param cases An object of supported cases and their values
 * @returns cases A function to retrieve the value for the given case
 *
 * @example
 * const animal = switchFunc({AU: 'Kangaroo', NZ: 'Kiwi'})
 * animal('NZ') // returns 'Kiwi'
 * animal('AU') // returns 'Kangaroo'
 * animal('US') // Produces a type error
 */
export function switchFuncStrict<R, K extends string>(cases: Record<K, R>): (key: K) => R {
  return (key: K) => cases[key];
}
