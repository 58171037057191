import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation, useParams } from 'react-router';

import { Button, FormControl, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { listAgencies } from '~/services/AgentHub/AgentService';

import { addQuery, parseSearchString } from '~/utils/url';

import AgencyDetail from './AgencyDetail';
import AgencyEditor from './AgencyEditor';

const DEFAULT_SIZE_PER_PAGE = 10;

export default function AgencyList() {
  const location = useLocation();
  const params = useParams<{ id?: string }>();

  const { push: setQueryString } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);

  const [details, setDetails] = useState({ id: null, opened: false });
  const [editor, setEditor] = useState({ id: null, opened: false });

  const [filter, setFilter] = useState({
    page: 0,
    status: null,
    order: null,
    search: null,
  });

  const onPageChange = useCallback(
    (page: number) => {
      setQueryString(addQuery(location, { page }));
    },
    [setQueryString, location],
  );

  const onSearchChange = useCallback(
    (search: string) => setQueryString(addQuery(location, { search: search || undefined })),
    [setQueryString, location],
  );
  const onSearchChangeDebounced = debounce(onSearchChange, 500);

  useEffect(() => {
    const { status, order, page, search } = parseSearchString(location.search);
    setFilter((prev) => ({
      ...prev,
      order,
      status,
      search,
      page: parseInt((page as string) ?? '0'),
    }));
  }, [location]);

  const fetchAgencyList = useCallback(async (): Promise<void> => {
    try {
      setLoadingState('loading');
      const { search } = filter;
      const { rows, total } = await listAgencies({
        page: filter.page.toString(),
        pageSize: DEFAULT_SIZE_PER_PAGE.toString(),
        ...(search ? { search } : {}),
      });

      setTotal(total);
      setResult(rows);
      setLoadingState('success');
    } catch (e) {
      setLoadingState('failed');
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    }
  }, [filter, enqueueSnackbar]);

  const closeDetailsModal = useCallback(() => {
    setDetails({ id: null, opened: false });
    fetchAgencyList();
  }, [setDetails, fetchAgencyList]);

  const closeEditorModal = useCallback(() => {
    setEditor({ id: null, opened: false });
    fetchAgencyList();
  }, [setEditor, fetchAgencyList]);

  useEffect(() => {
    fetchAgencyList();
  }, [fetchAgencyList]);

  useEffect(() => {
    if (params.id) {
      setDetails({ id: params.id, opened: true });
    }
  }, [params]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
      { field: 'city', headerName: 'City', flex: 1, sortable: false, display: 'flex' },
      {
        field: 'identity',
        headerName: 'Identity',
        flex: 1,
        renderCell: (params) => {
          return (
            <>
              {params.row.businessCodeType} - {params.row.businessCodeValue}
            </>
          );
        },
        display: 'flex',
      },
      {
        field: 'action',
        headerName: '',
        flex: 1,
        renderCell: (params) => {
          return (
            <>
              <Button variant="text" onClick={() => setEditor({ id: params.row.id, opened: true })}>
                Edit
              </Button>
              <Button variant="text" onClick={() => setDetails({ id: params.row.id, opened: true })}>
                Show
              </Button>
            </>
          );
        },
        display: 'flex',
      },
    ];
  }, [setDetails]);

  return (
    <>
      <Stack display="flex" justifyContent="end">
        <FormControl fullWidth>
          <TextField
            id="search-input-label"
            data-testid="search"
            type="text"
            name="search"
            label="Search"
            placeholder="Search"
            onChange={(e) => onSearchChangeDebounced(e.target.value as string)}
            fullWidth
          />
        </FormControl>
      </Stack>
      <Stack mt={1}>
        <DataGrid
          rows={result}
          rowCount={total}
          columns={columns}
          loading={loadingState == 'loading'}
          pagination
          paginationMode="server"
          paginationModel={{ page: filter.page, pageSize: DEFAULT_SIZE_PER_PAGE }}
          pageSizeOptions={[DEFAULT_SIZE_PER_PAGE]}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          slots={{ pagination: GridPagination }}
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          autoHeight
        />
        <AgencyDetail onClose={closeDetailsModal} id={details.id} opened={details.opened}></AgencyDetail>
        <AgencyEditor onClose={closeEditorModal} id={editor.id} opened={editor.opened}></AgencyEditor>
      </Stack>
    </>
  );
}
