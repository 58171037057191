import { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { resetPromoLog } from '~/services/PromoService';

type UsePromoUsersProps = {
  referralLogId: string;
};

export default function useReferralLogReset({ referralLogId }: UsePromoUsersProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState<boolean>(true);

  const resetReferralLog = useCallback(async () => {
    setLoading(true);
    try {
      const response = await resetPromoLog(referralLogId);
      if (response.status == 200) {
        enqueueSnackbar('Referral log reset', { variant: 'success' });
      } else {
        enqueueSnackbar(response.message || 'Failed to reset referral log', { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(`Error: ${JSON.stringify(err)}`, { variant: 'error', autoHideDuration: 5000 });
    } finally {
      setLoading(false);
    }
  }, [referralLogId, enqueueSnackbar]);

  return {
    referralLogId,
    isLoading,
    resetReferralLog,
  };
}
