import { PACKAGE_STATUS_APPROVED } from '~/consts/package';

import { datesHaveSameDayMonthYear, formatDateNumeralMonths, getTimeDifference } from '~/services/TimeService';

import { mapSFCancellationPolicyToAdmin } from '~/utils/mapSFCancelltionPolicyToAdmin';

import { ICondition, LEVELS } from './types';
import { pluralisedCount } from './utils';

export const offer: { [key: string]: ICondition } = {
  recordType: {
    check: (offer) =>
      ['escapes approved', 'dynamic rate products - approved', 'tours approved', 'cruises approved'].includes(
        offer.salesforce_record_type?.toLowerCase(),
      ),
    result: (offer) => offer.salesforce_record_type,
    summary: (resultOutput) => resultOutput ?? 'No record type',
    label: 'Record Type',
    helpText: 'Must be approved to be visible on the website',
  },
  opportunityStage: {
    check: (offer) => ['closed won'].includes(offer.stagename?.toLowerCase()),
    result: (offer) => offer.stagename,
    summary: (resultOutput) => resultOutput ?? 'No opportunity stage',
    label: 'Opportunity Stage',
    nonCritical: true,
    helpText: 'Must be closed won to avoid search issues',
  },
  productionStage: {
    check: (offer) => ['production complete'].includes(offer.sf_production_stage?.toLowerCase()),
    result: (offer) => offer.sf_production_stage,
    summary: (resultOutput) => resultOutput ?? 'No production stage',
    label: 'Production Stage',
    nonCritical: true,
    helpText: 'Should be Production Complete once deal is live and all pre-checks complete for reporting purposes.',
  },
  cancellationPolicy: {
    check: (offer, property) => {
      if (!offer.sf_cancellation_policy) {
        return true;
      }
      if (!property) {
        return false;
      }
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      const ratePlans = (property?.room_types?.flatMap((rt) => rt.room_rates) ?? [])
        .filter((rr) => roomRateIds.includes(rr.id))
        .map((rr) => rr.rate_plan);
      return (
        ratePlans
          .filter((rp) => rp.cancellation_policy !== mapSFCancellationPolicyToAdmin(offer.sf_cancellation_policy))
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i).length === 0
      );
    },
    result: (offer, property, _, linkBuilder) => {
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      const ratePlans = (property?.room_types?.flatMap((rt) => rt.room_rates) ?? [])
        .filter((rr) => roomRateIds.includes(rr.id))
        .map((rr) => rr.rate_plan);
      return {
        expected: mapSFCancellationPolicyToAdmin(offer.sf_cancellation_policy),
        ratePlans: ratePlans
          .filter((rp) => rp.cancellation_policy !== mapSFCancellationPolicyToAdmin(offer.sf_cancellation_policy))
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
          .map((rp) => ({
            link: linkBuilder(property.id_salesforce_external, rp.id),
            id: rp.id,
            policy: rp.cancellation_policy,
          })),
      };
    },
    summary: (resultOutput) => {
      if (!resultOutput.expected) {
        return 'No cancellation policy set on offer';
      }
      return resultOutput.ratePlans.length === 0
        ? 'Cancellation policies match all rate plans'
        : resultOutput.ratePlans.length + " cancellation policies don't match";
    },
    label: 'Cancellation Policy',
    hidden: (offer) => offer.type?.toLowerCase() !== 'hotel',
    linkBuilder: (vendorId, rateplanId) => {
      return `/vendors/${vendorId}/rate-plans/${rateplanId}`;
    },
    helpText: 'Red indicates Admin Portal does not match Salesforce. Please verify.',
  },
  packageUpgradesAllowed: {
    check: (offer) =>
      offer.package_upgrades_allowed === true &&
      (offer.number_of_date_changes !== null ||
        (offer.days_before_check_in_changes_disallowed && offer.days_before_check_in_changes_disallowed > 0)),
    result: (offer) => ({
      packageUpgradeAllowed: offer.package_upgrades_allowed,
      dateChanges: offer.number_of_date_changes,
      daysBeforeCheckInChangesDisallowed: offer.days_before_check_in_changes_disallowed,
    }),
    summary: (resultOutput) => {
      const { packageUpgradeAllowed, dateChanges, daysBeforeCheckInChangesDisallowed } = resultOutput;
      switch (true) {
        case packageUpgradeAllowed && dateChanges !== null && daysBeforeCheckInChangesDisallowed > 0:
          return 'Package upgrades allowed';
        case packageUpgradeAllowed &&
          dateChanges !== null &&
          (!daysBeforeCheckInChangesDisallowed || daysBeforeCheckInChangesDisallowed === 0):
          return 'Package upgrades allowed, number of date changes disabled';
        case packageUpgradeAllowed &&
          dateChanges === null &&
          (!daysBeforeCheckInChangesDisallowed || daysBeforeCheckInChangesDisallowed === 0):
          return 'Package upgrades allowed, customer portal date change enabled';
        default:
          return 'Package upgrades not allowed';
      }
    },
    label: 'Package Upgrades Allowed',
    hidden: (offer) => offer.type?.toLowerCase() !== 'hotel' && offer.package_upgrades_allowed !== true,
    helpText:
      'Customers are allowed to self-service upgrades once purchased. If Date Change Policy and Cancellation not allowed this should show as False. Orange indicates that if Package Upgrades Allowed but Date Change Allowed is 0 or Number of Date Changes is none/null, please verify.',
    nonCritical: true,
  },
  packageStatus: {
    thresholds: {
      type: 'lt',
      levels: [65, 90, 95], // percentage of packages that are content approved
    },
    check: (offer) => {
      const packages = offer.packages ?? [];
      if (packages.length === 0) {
        return 0;
      }
      const draft = packages.filter((p) => p.status !== PACKAGE_STATUS_APPROVED).length;
      return Math.round((draft / packages.length) * 100);
    },
    result: (offer) => {
      const packages = offer.packages ?? [];
      const drafts = packages.filter((p) => p.status !== PACKAGE_STATUS_APPROVED);
      return {
        draftPercentage: Math.round((drafts.length / packages.length) * 100),
        total: packages.length,
        drafts: drafts.map((p) => ({
          name: p.name,
          id: p.le_package_id,
          status: p.status,
          link: `/edit-offers/${offer.id_salesforce_external}/packages#${p.le_package_id}`,
        })),
      };
    },
    summary: (resultOutput) => {
      if (resultOutput.total === 0) {
        return 'No packages';
      }
      return resultOutput.draftPercentage === 0
        ? 'All packages approved'
        : `${pluralisedCount('package', resultOutput.drafts.length)} not approved`;
    },
    label: 'Package Status',
    helpText:
      'Orange indicates there are some packages set to either draft or hidden, please check if this is intentional.',
  },
  offerStatus: {
    check: (offer) => ['active', 'content-approved'].includes(offer.status?.toLowerCase()),
    result: (offer) => offer.status,
    summary: (resultOutput) => resultOutput ?? 'No status',
    label: 'Status',
    helpText: 'Red indicates Offer Status is not Content Approved, please update.',
  },
  bookByDate: {
    check: (offer) => {
      if (!offer.book_by_date) {
        return true;
      }
      const schedules = (offer.schedules ?? [])
        .filter((s) => s.type === 'availability' && !datesHaveSameDayMonthYear(offer.book_by_date, s.end.split('T')[0]))
        // @ts-expect-error dates can be compared
        .sort((a, b) => new Date(b.end) - new Date(a.end));
      return new Date(offer.book_by_date) > new Date() && schedules.length === 0;
    },
    result: (offer) => ({
      bookBy: offer.book_by_date,
      schedule:
        (offer.schedules ?? [])
          .filter(
            (s) => s.type === 'availability' && !datesHaveSameDayMonthYear(offer.book_by_date, s.end.split('T')[0]),
          )
          // @ts-expect-error dates can be compared
          .sort((a, b) => new Date(b.end) - new Date(a.end))[0] ?? undefined,
    }),
    summary: ({ bookBy, schedule }) => {
      if (!bookBy) {
        return 'No book by date';
      }
      const schedulesLabel = schedule
        ? `${formatDateNumeralMonths(new Date(schedule.end.split('T')[0]))} from schedule does not match.`
        : '';
      const currentDate = new Date();
      const bookByDate = new Date(bookBy);
      if (bookByDate < currentDate) {
        return `Book by date is ${formatDateNumeralMonths(bookByDate)} (in the past). ${schedulesLabel}`;
      }
      const futureTime = getTimeDifference(bookByDate, currentDate);
      return `Book by date ${formatDateNumeralMonths(bookByDate)} (${futureTime} into the future). ${schedulesLabel}`;
    },
    label: 'Book By Date',
    hidden: (offer) => !offer.book_by_date,
    helpText:
      'Bookings must be made by this date and orange indicates Admin Portal does not match Salesforce, please verify.',
    nonCritical: true,
  },
  travelToDate: {
    check: (offer) => {
      if (!offer.travel_to_date) {
        return true;
      }
      return new Date(offer.travel_to_date) > new Date();
    },
    result: (offer) => offer.travel_to_date,
    summary: (resultOutput) => {
      if (!resultOutput) {
        return 'No Travel to date';
      }
      const currentDate = new Date();
      const travelToDate = new Date(resultOutput);
      if (travelToDate < currentDate) {
        return `Travel to date is ${formatDateNumeralMonths(travelToDate)} in the past`;
      }
      const futureTime = getTimeDifference(travelToDate, currentDate);
      return `Travel to date ${formatDateNumeralMonths(travelToDate)} (${futureTime} into the future)`;
    },
    label: 'Travel To Date',
    hidden: (offer) => !offer.travel_to_date,
    nonCritical: true,
    helpText: 'Travel is only allowed until this date. Orange indicates the date is in the past.',
  },
  offerSchedules: {
    thresholds: {
      type: 'gt',
      levels: [50, 20, 1],
    },
    check: (offer) => {
      return Math.ceil(
        (offer.schedules?.filter((s) => new Date(s.end) > new Date()).length / offer.schedules.length) * 100,
      );
    },
    result: (offer) => {
      const expiredSchedules =
        offer.schedules
          ?.filter((s) => new Date(s.end) < new Date())
          .map((s) => ({
            id: s.offer_id_salesforce_external ?? s.id,
            end: formatDateNumeralMonths(new Date(s.end)),
            type: s.type,
          })) ?? [];
      return {
        totalSchedules: offer.schedules.length,
        expiredSchedules,
      };
    },
    summary: (resultOutput) => {
      const { totalSchedules, expiredSchedules } = resultOutput;
      const percentage = Math.ceil((expiredSchedules.length / totalSchedules) * 100);
      switch (true) {
        case totalSchedules > 0 && expiredSchedules.length === 0:
          return 'All schedules are active';
        case expiredSchedules.length > 0 && totalSchedules === expiredSchedules.length:
          return 'All schedules are expired';
        case expiredSchedules.length > 0:
          return `${percentage < 50 ? 'Most schedules are active. ' : ''}${pluralisedCount(
            'schedule',
            expiredSchedules.length,
          )} have expired`;
        default:
          return 'No schedules';
      }
    },
    label: 'Offer Schedules',
    helpText: 'Schedules control the visibility of the offer on the website. Orange indicates expired schedules.',
  },
  brandSchedules: {
    thresholds: {
      type: 'gt',
      levels: [50, 20, 1],
    },
    check: (offer) => {
      return Math.ceil(
        (offer.brand_schedules?.filter((s) => new Date(s.end) > new Date()).length / offer.brand_schedules.length) *
          100,
      );
    },
    result: (offer) => {
      const expiredSchedules =
        offer.brand_schedules
          ?.filter((s) => new Date(s.end) < new Date())
          .map((s) => ({
            id: s.offer_id_salesforce_external ?? s.id,
            end: formatDateNumeralMonths(new Date(s.end)),
            type: s.type,
            brand: s.brand,
          })) ?? [];
      return {
        totalSchedules: offer.brand_schedules.length,
        expiredSchedules,
      };
    },
    summary: (resultOutput) => {
      const { totalSchedules, expiredSchedules } = resultOutput;
      const percentage = Math.ceil((expiredSchedules.length / totalSchedules) * 100);
      switch (true) {
        case totalSchedules > 0 && expiredSchedules.length === 0:
          return 'All brand schedules are active';
        case expiredSchedules.length > 0 && totalSchedules === expiredSchedules.length:
          return 'All brand schedules are expired';
        case expiredSchedules.length > 0:
          return `${percentage < 50 ? 'Most brand schedules are active. ' : ''}${pluralisedCount(
            'brand schedule',
            expiredSchedules.length,
          )} have expired`;
        default:
          return 'No brand schedules';
      }
    },
    label: 'Brand Schedules',
    helpText:
      'Brand schedules control the visibility of the offer on the white-labelled website(s). Orange indicates expired schedules.',
  },
  packageRegions: {
    check: (offer) => {
      return offer.packages?.every((p) => p.regions.length === 1 && p.regions[0] === 'world');
    },
    result: (offer) =>
      offer.packages
        ?.filter((p) => p.regions.length !== 1 || p.regions[0] !== 'world')
        .map((p) => ({
          id: p.le_package_id,
          link: `/edit-offers/${offer.id_salesforce_external}/packages#${p.le_package_id}`,
          name: p.name,
          regions: p.regions,
        })),
    summary: (resultOutput) => {
      return resultOutput.length > 0
        ? `${pluralisedCount('package', resultOutput.length)} with multiple regions`
        : 'All packages are world';
    },
    label: 'Package Regions',
    nonCritical: true,
    hlpText: 'Orange indicates there are package regions set other than world.',
    level: -1,
  },
  packageRatePlans: {
    thresholds: (resultOutput) => (resultOutput.noRatePlans?.length > 0 ? LEVELS.WARNING : LEVELS.PASS),
    check: (offer, property) => {
      if (offer.packages.length === 0) {
        return false;
      }
      if (!property) {
        return false;
      }
      const roomRateIds = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      const matchedRRs = property?.room_types
        ?.flatMap((rt) => rt.room_rates)
        .map((rr) => [rr.id, rr.rate_plan])
        .filter(([id, _]: [string, App.RatePlan]) => roomRateIds.includes(id));
      if (matchedRRs?.length === 0) {
        return 0;
      }
      return Math.ceil((matchedRRs?.filter(([_, rp]) => !!rp).length / matchedRRs?.length) * 100);
    },
    result: (offer, property) => {
      const roomRateIds: Array<string> = [
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .map((p: App.AccommodationPackage) => p.fk_room_rate_id),
        ...offer.packages
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .flatMap((p: App.AccommodationPackage) => p.package_options?.map((po) => po.fk_room_rate_id)),
      ];
      const matchedRRs = property?.room_types
        ?.flatMap((rt) => rt.room_rates)
        .map((rr) => [rr.id, rr.rate_plan])
        .filter(([id, _]: [string, App.RatePlan]) => roomRateIds.includes(id));
      return {
        link: `/edit-offers/${offer.id_salesforce_external}/packages`,
        packages: offer.packages.length,
        total: matchedRRs?.length,
        noRatePlans: matchedRRs?.filter(([id, rp]) => !rp).map(([id, rp]) => !!rp),
      };
    },
    summary: (resultOutput) => {
      if (resultOutput.packages === 0) {
        return 'No packages';
      }
      return resultOutput.noRatePlans?.length > 0
        ? `${pluralisedCount('package', resultOutput.noRatePlans.length)} with no rate plans`
        : resultOutput.total
        ? 'All packages have rate plans'
        : 'No packages have rate plans';
    },
    label: 'Package Rate Plans',
    helpText: 'Orange indicates there are some rate plans set to draft, please check if this is intentional.',
  },
  // inciting incident https://luxgroup-hq.slack.com/archives/CV68S267R/p1711682166364729
  searchHidden: {
    check: (offer) => offer.is_search_hidden === false,
    result: (offer) => ({
      searchHidden: offer.is_search_hidden,
      searchByNameHidden: offer.is_search_by_name_hidden,
    }),
    summary: ({ searchHidden, searchByNameHidden }) =>
      `${searchHidden ? 'Search hidden' : 'Search visible'} & ${
        searchByNameHidden ? 'Search by name hidden' : 'Search by name visible'
      }`,
    label: 'Search Hidden',
    helpText: 'Orange indicates the offer is hidden from search results.',
    nonCritical: true,
  },
  packagePrices: {
    check: (offer) => {
      return offer.packages?.filter((p) => p.status === PACKAGE_STATUS_APPROVED).every((p) => p.prices_count > 0);
    },
    result: (offer) => {
      return {
        totalPackages: offer.packages?.length ?? 0,
        packagesNoPrices: (offer.packages ?? [])
          .filter((p) => p.status === PACKAGE_STATUS_APPROVED)
          .filter((p) => p.prices_count === 0)
          .map((p) => ({
            id: p.le_package_id,
            link: `/edit-offers/${offer.id_salesforce_external}/packages#${p.le_package_id}`,
            name: p.name,
          })),
      };
    },
    summary: ({ packagesNoPrices }) => {
      return packagesNoPrices.length > 0
        ? `${pluralisedCount('package', packagesNoPrices.length)} with no prices`
        : 'All packages have prices';
    },
    label: 'Package Prices',
    helpText:
      'Orange indicates there are some packages with no currencies. Please check currencies are synchronised from salesforce.',
    hidden: (offer) => offer.type?.toLowerCase() !== 'hotel',
  },
};

export default offer;
