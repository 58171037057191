export const EXP_PROVIDER_REZDY = 'Rezdy';
export const EXP_PROVIDER_LED = 'LED';
export const EXP_PROVIDER_DERBYSOFT = 'Derbysoft';

export const EXP_PROVIDER_REZDY_PREFIX = 'rez';
export const EXP_PROVIDER_LED_PREFIX = 'led';
export const EXP_PROVIDER_DERBYSOFT_PREFIX = 'dby';

export const PROVIDER_OPTIONS = [
  {
    name: 'LED',
    value: 'led',
  },
  {
    name: 'Rezdy',
    value: 'rez',
  },
  {
    name: 'Derbysoft',
    value: 'dby',
  },
];
