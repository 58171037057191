import React, { useState } from 'react';

import { Button, Typography } from '@mui/material';

const RefundsOutsidePolicyReason = ({ value }) => {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  const isLongText = value.length > 40;

  return (
    <>
      <Typography noWrap={!showMore}>{value}</Typography>
      {isLongText && (
        <Button size="small" onClick={handleToggle}>
          {showMore ? 'Less' : 'More'}
        </Button>
      )}
    </>
  );
};

export default RefundsOutsidePolicyReason;
