import React from 'react';

import { Typography } from '@mui/material';

import { RoomStatusEnum } from './common';

export const RoomStatusStatusVisibility = ({ roomStatus }: { roomStatus: string }) => {
  const status = roomStatus as RoomStatusEnum;
  return (
    <>
      {status === RoomStatusEnum.contentApproved && (
        <Typography>
          Room is visible on the customer portal will always be included in first display of the page along with rate
          shopping and optimal rate selection. Room mapping will occur for this room.
        </Typography>
      )}
      {status === RoomStatusEnum.excluded && (
        <Typography>
          Room is <strong>never</strong> visible on the customer portal, and will be excluded from all rate shopping
          actions.
        </Typography>
      )}
      {status === RoomStatusEnum.decoupled && (
        <Typography>
          Room is <em>not visible</em> on the customer portal on first page load, however once dates are selected if
          this room is in the cheapest it may be return. Room mapping should only be done against it's original supplier
          room and any duplicates from that same supplier.
        </Typography>
      )}
      {status === RoomStatusEnum.remapped && (
        <Typography>
          Room is <em>not visible</em> on the customer portal. This room has been remapped to another room, and should
          be childless with it's supplier room mapped against that room only.
        </Typography>
      )}
    </>
  );
};
