import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Alert, Box, Container, Tab, Tabs, Typography } from '@mui/material';

import useCurrentUser from '~/hooks/useCurrentUser';

import { hasFullAccess, hasReadAccess } from '~/utils/lere';

import PageHeader from '../Common/Elements/PageHeader';

import LereTPFMEmailTrigger from './LereTPFMEmailTrigger';
import RevenueManagement from './RevenueManagement';

const TabsMap = {
  'email-trigger': {
    label: 'Email Trigger',
    slug: 'email-trigger',
    component: LereTPFMEmailTrigger,
  },
  'revenue-management': {
    label: 'Revenue Management',
    slug: 'revenue-management',
    component: RevenueManagement,
  },
};
type TabKey = keyof typeof TabsMap;
const allTabs = Object.keys(TabsMap);

export default function LereAdminPageContainer() {
  const { section = 'email-trigger' } = useParams<{ section?: TabKey }>();
  const { user } = useCurrentUser();
  const currentTab = TabsMap[section];
  const title = `LERE / ${currentTab.label}`;
  const [lereError, setLereError] = useState<Error | undefined>(undefined);

  if (!hasFullAccess(user) && !hasReadAccess(user)) {
    return <Typography>You do not have the correct permissions to access this page</Typography>;
  }

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: 'white' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title} />

      {lereError && (
        <Alert severity="error" onClose={() => setLereError(undefined)}>
          {lereError.message ?? lereError.toString()}
        </Alert>
      )}

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={section} variant="scrollable" scrollButtons="auto">
          {allTabs.map((tab) => (
            <Tab
              key={tab}
              value={tab}
              label={TabsMap[tab].label}
              to={`/lere/${tab}`}
              component={Link}
              sx={{
                minWidth: 30,
                padding: '12px 10px',
              }}
            />
          ))}
        </Tabs>
      </Box>

      <Box mt={4}>
        <Box>
          <currentTab.component onLereError={setLereError} />
        </Box>
      </Box>
    </Container>
  );
}
