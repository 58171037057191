import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { FlashlightOff, FlashlightOn } from '@mui/icons-material';
import { Alert, Box, Button, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import CopyableField from '~/components/Common/CopyableField';
import { Autocomplete, Input } from '~/components/Common/Forms/fields';
import UserSearchWidget from '~/components/Common/UserSearchWidget';
import DebugModal from '~/components/DebugModal/DebugModal';
import { PromoRequestDeviceType } from '~/components/PromoV3/PromoRequestDeviceType';
import { PromoRequestSourceFormatter } from '~/components/PromoV3/PromoRequestSource';

import { DEFAULT_PAGE_SIZES } from '~/consts/filters';

import useQuery from '~/hooks/useQuery';

import { PromoMeta, getPromoRequestWarnings } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';
import dateFormatterDetailed from '~/utils/dateFormatterDetails';
import { getCurrencyCodeByRegionCode } from '~/utils/getCurrencyCodeByRegionCode';
import statusCodeFormatter from '~/utils/statusCodeFormatter';
import { addQuery } from '~/utils/url';

import GridPagination from '../../Common/Elements/GridPagination';
import Spinner from '../../Common/Spinner';
import { PromoResponse } from '../PromoResponse/PromoResponse';
import PromoCodeUser from '../formatters/PromoCodeUser';
import usePromoRequests from '../hooks/usePromoRequests';

import { PromoOrderTableCell } from './PromoOrderTableCell';

const sharedAttrs: Pick<GridColDef, 'sortable' | 'display' | 'flex'> = {
  sortable: false,
  display: 'flex',
  flex: 0,
};

const getColumns = (meta: PromoMeta, isDev: boolean): GridColDef[] => [
  {
    field: 'requestAt',
    headerName: 'Request At',
    renderCell: (params) => dateFormatterDetailed({ date: params.row.createdAt }),
    ...sharedAttrs,
    flex: 0,
  },
  {
    field: 'codeName',
    headerName: 'CodeName',
    renderCell: (params) => (
      <Stack>
        <CopyableField
          value={params.row.codeName}
          label={
            <Link href={`/promos/code/${params.row.codeName}`} target="_blank" rel="noreferrer" color="inherit">
              {params.row.codeName}
            </Link>
          }
        />
      </Stack>
    ),
    ...sharedAttrs,
    flex: 1,
  },
  {
    field: 'Requested By',
    renderCell: (params) => <PromoCodeUser userId={params.row.userId} userIdFor={params.row.userIdFor} />,
    ...sharedAttrs,
  },
  {
    field: 'amount',
    headerName: 'Discount',
    sortable: false,
    renderCell: (params) => {
      if (params.row.amount == null) {
        return null;
      }

      if (params.row.bodyJSON?.order?.region?.length > 0) {
        return currencyFormatter(getCurrencyCodeByRegionCode(params.row.bodyJSON.order.region), params.row.amount);
      }
      return `${params.row.amount} (no region)`;
    },
    ...sharedAttrs,
  },
  {
    field: 'statusCode',
    headerName: 'Error Code',
    renderCell: (params) => statusCodeFormatter(params.row.statusCode, params.row.errorCode, params.row.errorMsg),
    display: 'flex',
    ...sharedAttrs,
    flex: 2,
  },
  {
    field: 'requestSource',
    headerName: 'Source',
    renderCell: (params) => PromoRequestSourceFormatter({ requestSource: params.row.bodyJSON.requestSource }),
    ...sharedAttrs,
  },
  {
    field: 'deviceType',
    headerName: 'Source',
    sortable: false,
    renderCell: (params) => PromoRequestDeviceType({ discountRequest: params.row?.bodyJSON }),
    ...sharedAttrs,
  },
  {
    field: 'region',
    headerName: 'Region',
    renderCell: (params) => <Typography>{params.row.bodyJSON?.order?.region}</Typography>,
    ...sharedAttrs,
  },
  {
    field: 'bodyJSON',
    headerName: 'Order',
    renderCell: (params) => <PromoOrderTableCell order={params.row.bodyJSON?.order} meta={meta} />,
    ...sharedAttrs,
  },
  {
    field: 'responseAndRequest',
    headerName: 'Response/Request',
    description: '(if successful, this is the response, if unsuccessful this is the request)',
    renderCell: (params) => {
      if (params.row.statusCode >= 200 && params.row.statusCode < 300) {
        return (
          <PromoResponse
            response={params.row.responseJSON}
            meta={meta}
            clientOrderVersion={params.row?.order?.clientOrderVersion}
            isDev={isDev}
          />
        );
      } else {
        return <PromoOrderTableCell order={params.row.bodyJSON?.order} meta={meta} />;
      }
    },
    ...sharedAttrs,
    flex: 5,
  },
  {
    field: 'responseJSON',
    headerName: 'Response',
    description: '(if successful, this is the response, if unsuccessful this is the request)',
    renderCell: (params) => {
      return (
        <Stack direction="column">
          <PromoResponse response={params.row.responseJSON} meta={meta} isDev={isDev} />
        </Stack>
      );
    },
    ...sharedAttrs,
    flex: 2,
  },
  {
    field: 'warnings',
    headerName: 'Warnings',
    renderCell: (params) => {
      const warns = getPromoRequestWarnings(params.row);
      if (warns.length > 0) {
        return (
          <Stack direction="column">
            {warns.map((w, i) => (
              <Alert key={i} severity={w.severity}>
                {w.message}
              </Alert>
            ))}
          </Stack>
        );
      }
      return null;
    },
    ...sharedAttrs,
  },
  {
    field: 'debug',
    headerName: 'Raw',
    renderCell: (params) => (
      <CopyableField
        value={JSON.stringify(params.row, null, 4)}
        label={<DebugModal type="generic" data={params.row ?? { data: 'None' }} />}
      />
    ),
    ...sharedAttrs,
    flex: 0,
  },
  {
    field: 'preCheckoutOrder',
    headerName: '[old order]',
    description:
      '[to be removed] The previous order format. This is only used for old requests that have not been updated to the new format.',
    renderCell: (params) => {
      if (
        !params.row.bodyJSON ||
        !params.row.bodyJSON?.preCheckoutOrder ||
        params.row.bodyJSON?.preCheckoutOrder?.items?.length == 0
      ) {
        return <>(none)</>;
      }
      return <PromoOrderTableCell order={params.row.bodyJSON?.preCheckoutOrder} meta={meta} />;
    },
    ...sharedAttrs,
  },
];

interface Props {
  initCodeName?: string;
  initUserId?: string;
  initErrorCode?: string;
  initPage?: number;
  initLimit?: number;
  brand: App.Brands | string;
  handleUserIdFilterSet?: (userId: string) => void;
  promoMeta: PromoMeta;
}

const PromoRequests = ({
  initCodeName,
  initUserId,
  initErrorCode,
  initPage,
  initLimit,
  brand,
  handleUserIdFilterSet,
  promoMeta,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { promoRequests, totalRows, isLoading, promoRequestsFilter, setPromoRequestsFilter } = usePromoRequests({
    initPage,
    initLimit,
    initErrorCode,
    initCodeName,
    brand: brand.toString(),
    initUserId,
  });

  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      codeName: initCodeName || '',
      userId: initUserId || '',
      errorCode: initErrorCode || '',
    },
  });

  const query = useQuery();
  const [isDevMode, setIsDevMode] = useState(query.get('dev') === 'true');

  useEffect(() => {
    setValue('codeName', initCodeName || '');
    setValue('userId', initUserId || '');
    setValue('errorCode', initErrorCode || '');
    if (handleUserIdFilterSet && initUserId) {
      handleUserIdFilterSet(initUserId || '');
    }
  }, [initCodeName, initUserId, initErrorCode, setValue]);

  const onSubmit = async (formData) => {
    try {
      setPromoRequestsFilter({
        ...promoRequestsFilter,
        ...formData,
      });
      if (handleUserIdFilterSet) {
        handleUserIdFilterSet(formData.userId);
      }
      const newLocation = addQuery(location, formData);
      history.push(newLocation);
    } catch (err) {
      enqueueSnackbar(err.message || 'Error! Unable to update filters.', { variant: 'error' });
    }
  };

  const onPageChange = async (page, pageSize) => {
    const formData = getValues();
    setPromoRequestsFilter({
      page,
      limit: pageSize,
      brand: brand as definitions['Promo Brands'],
      ...formData,
    });
    if (handleUserIdFilterSet) {
      handleUserIdFilterSet(formData.userId);
    }
  };

  const fetchUser = useCallback(
    async (userId: string) => {
      setValue('userId', userId);
    },
    [brand, enqueueSnackbar],
  );

  const handleSetDev = () => {
    setIsDevMode(!isDevMode);
    const newLocation = addQuery(location, { dev: !isDevMode });
    history.push(newLocation);
  };

  const handleUserSearchSelect = useCallback(
    (id, searchQuery: string) => {
      fetchUser(searchQuery);
    },
    [fetchUser],
  );

  const errorOptions: Array<string> = Array.from(
    new Set(promoMeta?.discount_error_codes.map((c) => c.toString()).concat('')),
  );

  return (
    <>
      <Box m={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={2} alignItems="center">
          <UserSearchWidget
            id="fk_customer_id"
            label="Name / Email / Customer Support Code / User ID"
            formErrors={[]}
            loading={false}
            onChange={handleUserSearchSelect}
          />
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <Input fullWidth control={control} muiLabel="Code Name" name="codeName" />
            {promoMeta && (
              <Autocomplete control={control} options={errorOptions} name="errorCode" muiLabel="Error Code" />
            )}
            <Button variant="contained" type="submit" fullWidth>
              Search
            </Button>
            <Button
              variant="contained"
              onClick={handleSetDev}
              sx={{ padding: '0 16px' }}
              title={isDevMode ? 'Hide extended info' : 'Show extended info'}
            >
              {isDevMode ? <FlashlightOn /> : <FlashlightOff />}
            </Button>
          </Stack>
        </Stack>
      </Box>
      {!isLoading ? (
        <DataGrid
          columns={getColumns(promoMeta, isDevMode)}
          autoHeight
          getRowId={(row) => row.uniqueKey}
          rowCount={totalRows}
          rows={promoRequests || []}
          pagination
          paginationMode="server"
          paginationModel={{ page: promoRequestsFilter.page, pageSize: promoRequestsFilter.limit }}
          slots={{ pagination: GridPagination }}
          pageSizeOptions={DEFAULT_PAGE_SIZES}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          getRowHeight={() => 'auto'}
          columnVisibilityModel={{
            uniqueId: false,
            warnings: false,
            preCheckoutOrder: isDevMode,
            bodyJSON: isDevMode,
            requestSource: isDevMode,
            responseJSON: isDevMode,
            responseAndRequest: !isDevMode,
          }}
        />
      ) : (
        <Spinner size={36} />
      )}
    </>
  );
};

export default PromoRequests;
