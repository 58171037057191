import React from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import debounce from 'lodash/debounce';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { RouteChildrenProps, withRouter } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Box, Button, IconButton, Stack, Typography } from '@mui/material';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';
import ErrorDisplay from '~/components/Common/ErrorDisplay';
import cleanProperty from '~/components/Vendors/Properties/helpers/cleanProperty';

import { PROPERTY_CHANNEL_MANAGERS } from '~/consts/reservation';

import BedbankService from '~/services/BedbankService';
import ReservationService from '~/services/ReservationService';
import { formatDateISO } from '~/services/TimeService';

import deleteNulls from '~/utils/deleteNulls';
import featureToggle from '~/utils/featureToggle';
import { reportError } from '~/utils/reportError';

import AsyncAutocomplete from '../Legacy/AsyncAutocomplete';
import PropertyMap from '../PropertyMap';

import ImageUploadField from './ImageUploadField';
import { buttonMessages, buttonStates } from './states/submitButton';

const uiSchema: UiSchema = {
  id_salesforce_external: { 'ui:widget': 'hidden' },
  logo_id_cloudinary_external: {
    'ui:title': 'Property logo',
    'ui:widget': (props) => (
      <ImageUploadField
        label="logo"
        field_key={props.id}
        value={props.value}
        onUpload={(field_key, newValue) => {
          props.onChange(newValue);
        }}
      />
    ),
  },
  'ui:order': ['*', 'parent_property_id', 'bedbank_property_id', 'logo_id_cloudinary_external', 'geo_data'],
  max_child_age: {
    'ui:help': 'The age up to which a child is classified as a child',
  },
  max_infant_age: {
    'ui:help': 'The age up to which a child is classified as an infant',
  },
  geo_data: {
    'ui:classNames': 'geo-field',
  },
  regions_marketed_los: {
    'ui:options': {
      addable: false,
      orderable: false,
      removable: false,
    },
  },
  location_heading: {
    'ui:title': 'Location City',
  },
  location_subheading: {
    'ui:title': 'Location Country/State',
  },
};

const guidReg = new RegExp('\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b');

const propertyKeysToDeleteIfNull = ['hotel_meta_provider', 'pms'];

const contentEnabled = featureToggle.availableToShow('ADDITIONAL_PROPERTY_CONTENT_ENABLED');

function formExtraValidation(formData, errors) {
  if (formData.max_child_age <= formData.max_infant_age) {
    errors.max_child_age.addError('Max age of Children must be higher than Max age of Infants');
  }

  if (!Number.isInteger(formData.max_child_age)) {
    errors.max_child_age.addError('Max age of Children must be integer');
  }

  return errors;
}

type Props = RouteChildrenProps &
  WithSnackbarProps & {
    property: unknown;
    schema: RJSFSchema;
    vendorId: string;
  };

type State = {
  property: any;
  saveState: string;
  errorMsg: string;
  bedbank_property_invalid: boolean;
};

class PropertyForm extends React.Component<Props, State> {
  vendorId: string;
  schema: RJSFSchema;

  constructor(props: Props) {
    super(props);

    this.state = {
      property: cleanProperty(deleteNulls(props.property, propertyKeysToDeleteIfNull)),
      saveState: buttonStates.default,
      errorMsg: '',
      bedbank_property_invalid: false,
    };

    this.vendorId = props.vendorId;
    this.schema = props.schema;

    delete this.schema.properties.taxes_and_fees_content;
    delete this.schema.properties.commissionable_taxes_and_fees;
    delete this.schema.properties.taxes_and_fees;
  }

  onSubmit = (form: IChangeEvent) => {
    const { history } = this.props;

    form.errors = [];
    form.errorSchema = {};

    this.setState({ saveState: buttonStates.saving, property: form.formData });

    let submitType = 'createProperty';
    let id = null;
    if (this.state.property.id) {
      submitType = 'updateProperty';
      id = this.state.property.id;
    }

    if (form.formData.channel_manager !== PROPERTY_CHANNEL_MANAGERS.derbysoft) {
      delete form.formData.channel_ari_type;
      delete form.formData.channel_rate_type;
      delete form.formData.send_nightly_reservation_rates;
    }

    if (form.formData.channel_manager === PROPERTY_CHANNEL_MANAGERS.selfManaged) {
      form.formData.regions_marketed_los = [];
    }

    // Don't send large room_types data as it is not on this form
    const formDataWithoutRoomTypes = { ...form.formData };
    if ('room_types' in formDataWithoutRoomTypes) {
      delete formDataWithoutRoomTypes['room_types'];
    }

    ReservationService[submitType](formDataWithoutRoomTypes, id)
      .then((response) => {
        history.push(`/vendors/${this.vendorId}/properties/${response.id}`);
      })
      .catch((e) => {
        if (e.name === 'ValidationError' && e.errors[0]?.message === 'bedbank_property_id must be unique') {
          this.setState({
            bedbank_property_invalid: true,
            saveState: buttonStates.failed,
          });
        } else {
          this.setState({ saveState: buttonStates.failed });
          reportError(e);
        }
      });
  };

  onChange = (edit: IChangeEvent) => {
    const newState: Pick<State, 'property' | 'saveState'> = {
      property: edit.formData,
      saveState: buttonStates.default,
    };

    const { saveState, property } = this.state;

    if (saveState !== buttonStates.default) {
      newState.saveState = buttonStates.default;
    }

    if (!newState.property.hotel_meta_provider && !!property.hotel_meta_code) {
      newState.property.hotel_meta_code = '';
    }

    if (
      (!newState.property.channel_manager ||
        newState.property.channel_manager === PROPERTY_CHANNEL_MANAGERS.selfManaged) &&
      !!property.hotel_code
    ) {
      newState.property.hotel_code = '';
    }

    if (newState.property.channel_manager !== PROPERTY_CHANNEL_MANAGERS.derbysoft && !!property.channel_supplier_id) {
      newState.property.channel_supplier_id = '';
    }

    if (newState.property.channel_manager !== PROPERTY_CHANNEL_MANAGERS.travelclick) {
      delete newState.property.pms;
    }
    if (newState.property.channel_manager === PROPERTY_CHANNEL_MANAGERS.travelclick && !newState.property.pms) {
      delete newState.property.pms;
    }

    this.setState(newState);
  };

  onDelete = () => {
    const { history } = this.props;

    ReservationService.deleteProperty(this.state.property.id)
      .then(() => {
        history.push(`/vendors/${this.vendorId}`);
      })
      .catch((e) => {
        reportError(e);
      });
  };

  onRestore = () => {
    const { history } = this.props;

    ReservationService.restoreProperty(this.state.property.id)
      .then(() => {
        history.push(`/vendors/${this.vendorId}`);
      })
      .catch((e) => {
        reportError(e);
      });
  };

  onMapClick = (latitude: string, longitude: string) => {
    // TODO: save the place ID (future enhancement)

    const edit = {
      formData: Object.assign({}, this.state.property, {
        latitude,
        longitude,
      }),
    };
    this.onChange(edit as IChangeEvent);
  };

  onReverseGeocode = (result) => {
    const edit = {
      formData: Object.assign({}, this.state.property, {
        geo_data: result,
      }),
    };
    this.onChange(edit as IChangeEvent);
  };

  setErrorMsg = (msg: string) => {
    this.setState({ errorMsg: msg });
  };

  addLogo(fieldKey, imageId) {
    const edit = {
      formData: Object.assign({}, this.state.property),
    };
    edit.formData[fieldKey] = imageId;
    this.onChange(edit as IChangeEvent);
  }

  getParentPropertyPredictions = (input, callback) => {
    if (!input) {
      return callback([]);
    }
    if (guidReg.test(input)) {
      ReservationService.getProperty(input).then((prediction) => {
        if (!prediction || prediction.result.parent_property_id) {
          return callback([]);
        }
        return callback([prediction.result]);
      });
    } else {
      const limit = 100;
      const page = 1;
      const includeDeleted = false;
      ReservationService.getPropertiesByName(input, limit, page, includeDeleted).then((predictions) => {
        if (!predictions) {
          return callback([]);
        }
        const filtered = predictions.result.filter((property) => !property.parent_property_id);
        return callback(filtered);
      });
    }
  };

  debouncedGetParentPredictions = debounce(this.getParentPropertyPredictions, 500);

  onChangeWithParentProperty = (selected) => {
    if (selected) {
      this.setState({
        property: {
          ...this.state.property,
          parent_property_id: selected.id,
          parentProperty: selected,
        },
      });
    }
  };

  clearParentProperty = () => {
    const newState = { ...this.state };
    delete newState.property.parent_property_id;
    delete newState.property.parentProperty;
    this.setState(newState);
  };

  getBedbankPropertyPredictions = (input: string, callback) => {
    if (!input) {
      return callback([]);
    }

    const params = {
      query: input,
      type: BedbankService.isBedbankId(input) ? 'id' : 'name',
    };

    BedbankService.getProperties(1, 20, params)
      .then((predictions) => {
        if (!predictions) {
          return callback([]);
        }
        return callback(predictions.result);
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message || 'Error! Unable to fetch properties.', {
          variant: 'error',
        });
      });
  };

  debouncedGetBedbankPredictions = debounce(this.getBedbankPropertyPredictions, 500);

  onChangeWithBedbankProperty = (selected) => {
    if (selected) {
      this.setState({
        bedbank_property_invalid: false,
        property: {
          ...this.state.property,
          bedbank_property_id: selected.id,
          bedbankProperty: selected,
        },
      });
    }
  };

  clearBedbankProperty = () => {
    const newState = { ...this.state };
    delete newState.property.bedbank_property_id;
    delete newState.property.bedbankProperty;
    this.setState(newState);
  };

  noOptionsMessage = (i) => {
    return !i.inputValue ? 'Search for a property by name or hotel code' : 'Not Found';
  };

  mapParentPropertyLabel = (property) => {
    if (!property) return '';

    return `
      ${property.name} | Vendor: ${property.id_salesforce_external}
      ${property.channel_manager ? ` | CM: ${property.channel_manager}` : ''}
      ${property.hotel_code ? ` | Hotel Code: ${property.hotel_code}` : ''}
      | Created: ${formatDateISO(property.created_at)}`;
  };

  render() {
    let deleteButton = null;
    let restoreButton = null;

    if (this.state.property.id) {
      if (this.state.property.deleted_at) {
        restoreButton = (
          <ConfirmButton confirmTitle="Restore property" confirmAnswer="Yes, continue" onConfirm={this.onRestore}>
            Restore Property
          </ConfirmButton>
        );
      } else {
        deleteButton = (
          <ConfirmButton
            confirmTitle="Delete property"
            confirmQuestion="Are you sure you want to delete this property?"
            confirmAnswer="Yes, delete"
            onConfirm={this.onDelete}
          >
            Delete
          </ConfirmButton>
        );
      }
    }

    const property = Object.assign({}, this.state.property);

    if (typeof property.latitude !== 'number' && typeof property.latitude !== 'string') {
      delete property.latitude;
    }

    if (typeof property.longitude !== 'number' && typeof property.longitude !== 'string') {
      delete property.longitude;
    }

    if (!property.logo_id_cloudinary_external) {
      delete property.logo_id_cloudinary_external;
    }

    uiSchema.parent_property_id = {
      'ui:title': 'Parent Property',
      'ui:widget': (props) => (
        <>
          {props.formContext.parentProperty && (
            <Stack mb={1} direction="row" spacing={2} alignItems="center">
              <Typography>{props.formContext.parentProperty.name}</Typography>
              <IconButton aria-label="Remove" onClick={this.clearParentProperty}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          )}

          <AsyncAutocomplete
            loadOptions={this.debouncedGetParentPredictions}
            onChange={this.onChangeWithParentProperty}
            getOptionLabel={this.mapParentPropertyLabel}
            placeholder="Search for a property by name or hotel code"
            label="Parent property"
          />
        </>
      ),
    };

    uiSchema.bedbank_property_id = {
      'ui:title': 'Bedbank Property',
      'ui:widget': (props) => (
        <>
          {props.formContext.bedbankProperty && (
            <Stack mb={1} direction="row" spacing={2} alignItems="center">
              <Typography>{props.formContext.bedbankProperty.name}</Typography>
              <IconButton aria-label="Remove" onClick={this.clearBedbankProperty}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          )}
          {this.state.bedbank_property_invalid && (
            <Alert severity="warning">This property is already linked to another property</Alert>
          )}

          <AsyncAutocomplete
            loadOptions={this.debouncedGetBedbankPredictions}
            onChange={this.onChangeWithBedbankProperty}
            getOptionLabel={(property: App.Property) => property.name}
            placeholder="Search for a property by name or hotel code"
            label="Bedbank property"
          />
        </>
      ),
    };

    uiSchema.hotel_meta_code = {
      'ui:widget': this.state.property.hotel_meta_provider ? 'text' : 'hidden',
    };

    uiSchema.hotel_code = {
      'ui:widget':
        this.state.property.channel_manager !== PROPERTY_CHANNEL_MANAGERS.selfManaged &&
        !!this.state.property.channel_manager
          ? 'text'
          : 'hidden',
    };

    uiSchema.channel_supplier_id = {
      'ui:widget': this.state.property.channel_manager === PROPERTY_CHANNEL_MANAGERS.derbysoft ? 'text' : 'hidden',
    };

    if (this.state.property.channel_manager !== PROPERTY_CHANNEL_MANAGERS.derbysoft) {
      uiSchema.channel_ari_type = {
        'ui:widget': 'hidden',
      };
      uiSchema.channel_rate_type = {
        'ui:widget': 'hidden',
      };
      uiSchema.send_nightly_reservation_rates = {
        'ui:widget': 'hidden',
      };
    } else {
      uiSchema.channel_ari_type = this.schema.properties.channel_ari_type;
      uiSchema.channel_rate_type = this.schema.properties.channel_rate_type;
      uiSchema.send_nightly_reservation_rates = this.schema.properties.send_nightly_reservation_rates;
    }

    if (this.state.property.channel_manager !== PROPERTY_CHANNEL_MANAGERS.travelclick) {
      uiSchema.pms = {
        'ui:widget': 'hidden',
      };
    } else {
      uiSchema.pms = this.schema.properties.pms;
    }

    if (!contentEnabled) {
      uiSchema.videos = {
        'ui:widget': 'hidden',
      };
    }

    return (
      <Form
        schema={this.schema}
        onChange={this.onChange}
        formData={property}
        onSubmit={this.onSubmit}
        uiSchema={uiSchema}
        formContext={property}
        customValidate={formExtraValidation}
        validator={validator}
      >
        <Box mt={2}>
          <label>Select location</label>
          <PropertyMap
            name={property.name}
            address={property.address}
            lat={property.latitude}
            lng={property.longitude}
            onMapClick={this.onMapClick}
            reverseGeocode={true}
            onReverseGeocode={this.onReverseGeocode}
            setErrorMsg={this.setErrorMsg}
          />
        </Box>

        {this.state.errorMsg && (
          <Box mt={2}>
            <ErrorDisplay message={this.state.errorMsg} />
          </Box>
        )}

        <div className="button-container">
          <Stack direction="row" spacing={1}>
            <Button type="submit" className={this.state.saveState} variant="contained">
              {buttonMessages[this.state.saveState]}
            </Button>
            {restoreButton ? restoreButton : deleteButton}
          </Stack>
        </div>
      </Form>
    );
  }
}

export default withRouter(withSnackbar(PropertyForm));
