import React, { useCallback, useState } from 'react';

import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import { SearchFilters } from '~/components/Cruises/pages/Home/components/Sailings/constants';
import RateCodeCreationAutocomplete from '~/components/Cruises/pages/RateCodeCreation/components/RateCodeCreationAutocomplete';
import { INITIAL_FEEDBACK_STATE } from '~/components/Cruises/pages/RateCodeManagement/constants';
import { Feedback } from '~/components/Cruises/pages/RateCodeManagement/types';

import useToggleState from '~/hooks/useToggleState';

import rateCodeService from '~/services/cruises/RateCodeService';

interface Props {
  selectedSailings: GridRowSelectionModel;
  isSelectAll: boolean;
  searchFilters: SearchFilters;
}

const RateCodeCreationFormPage = (props: Props) => {
  const { selectedSailings, isSelectAll, searchFilters } = props;
  const [selectedValues, setSelectedValues] = useState<Array<CruisesContract.RateCodeListResponse>>([]);
  const [feedback, setFeedback] = useState<Feedback>(INITIAL_FEEDBACK_STATE);
  const { isToggled: isFlash, toggle: toggleFlash } = useToggleState(false);

  const handleSubmit = useCallback(async () => {
    const {
      name,
      offerId,
      vendorId,
      sailingExternalId,
      sailingPlan,
      sailingDurationMin,
      sailingDurationMax,
      departureDateStart,
      departureDateEnd,
      departurePortExternalIds,
      status,
      hasCoreChanges,
      hasItineraryChanges,
      shipName,
      shipExternalId,
    } = searchFilters;

    const departureIds = [...new Set(selectedSailings as Array<string>)];
    if (!selectedValues.length || !vendorId || !departureIds?.length) {
      setFeedback({
        loading: false,
        status: 'error',
        message: 'Please fill all the fields',
      });
    } else {
      setFeedback({ loading: true, status: null, message: null });
      const rateCodes = selectedValues.map((item) => item.code);
      const res = await rateCodeService.createBulkRateCodeByDepartureId({
        rateCodes,
        departureIds,
        isFlash,
        isSelectAll,
        name,
        offerId,
        vendorId,
        sailingExternalId,
        sailingPlan,
        sailingDurationMin,
        sailingDurationMax,
        departureDateStart,
        departureDateEnd,
        departurePortExternalIds,
        status,
        hasCoreChanges,
        hasItineraryChanges,
        shipName,
        shipExternalId,
      });

      if (res?.status === 201) {
        setFeedback({
          loading: false,
          status: 'success',
          message: `Rate code created successfully`,
        });
      } else {
        setFeedback({
          loading: false,
          status: 'error',
          message: 'Something went wrong. Please contact cruises team',
        });
      }
    }
  }, [isFlash, isSelectAll, selectedValues, searchFilters, selectedSailings]);

  const getButtonLabel = useCallback(() => {
    const length = selectedSailings.length;
    return isSelectAll ? ' ALL ' : length > 0 ? ` ${length} ` : ' ';
  }, [isSelectAll, selectedSailings.length]);

  return (
    <>
      <Typography variant="h5">Create Rate Codes</Typography>
      <Stack mt={1} mb={1} display="flex" direction="row" gap={2}>
        <FormControl fullWidth>
          <RateCodeCreationAutocomplete
            disabled={!searchFilters.vendorId}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!searchFilters.vendorId}
                checked={isFlash}
                onChange={toggleFlash}
                size="medium"
                data-testid="cruise-checkbox-is-flash"
              />
            }
            label="Is Flash?"
          />
        </FormControl>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={!selectedSailings.length || !selectedValues.length || !searchFilters.vendorId}
          data-testid="cruise-submit-rate-code"
        >
          Apply Rate Code to
          {getButtonLabel()}
          Sailing(s)
        </Button>
        <Stack width="100%" flexDirection="row" justifyContent="center">
          {!!feedback.loading && !feedback.status && <CircularProgress size={40} />}

          {!!feedback.message && !!feedback.status && (
            <Alert sx={{ width: '100%' }} severity={feedback.status}>
              {feedback.message}
            </Alert>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default RateCodeCreationFormPage;
