import React from 'react';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const layoverTime = (layover) => {
  if (!layover) return '-';
  const { hours, minutes } = layover;
  if (hours || minutes) return `${hours}h ${minutes}m`;
  return '-';
};

const getCarryOn = (inclusions: App.BookingJourneyInclusion[] = []) => {
  return inclusions.find(
    (inclusion) => ['carry_on_baggage', 'carry_on_bag'].includes(inclusion.type) && inclusion.status === 'INCLUDED',
  );
};

const getCheckedBaggage = (inclusions: App.BookingJourneyInclusion[] = []) => {
  return inclusions.find(
    (inclusion) => ['checked_baggage', 'checked_bag'].includes(inclusion.type) && inclusion.status === 'INCLUDED',
  );
};

interface Props {
  leg: App.BookingJourneyLeg;
}

export default function OrderFlightItemFlights(props: Props) {
  const { leg } = props;

  return (
    <Box mt={5} mb={7}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Flight No</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Layover</TableCell>
              <TableCell>Departure</TableCell>
              <TableCell>Arrival</TableCell>
              <TableCell>Baggage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leg.flights.map((flight) => (
              <TableRow key={`${flight.departingDate}-${flight.departingTime}`}>
                <TableCell component="th" scope="row">
                  {flight.flightNumber}
                </TableCell>
                <TableCell component="th" scope="row">
                  <strong>{flight.departureAirport}</strong>
                  <br />
                  {flight.departureAirportName}, {flight.departureCountry}
                </TableCell>
                <TableCell component="th" scope="row">
                  <strong>{flight.arrivalAirport}</strong>
                  <br />
                  {flight.arrivalAirportName}, {flight.arrivalCountry}
                </TableCell>
                <TableCell>{/* {layoverTime(flight.stopoverTime)} */}-</TableCell>
                <TableCell>
                  <strong>{flight.departingTime}</strong>
                  <br />
                  {flight.departingDate}
                </TableCell>
                <TableCell>
                  <strong>{flight.arrivalTime}</strong>
                  <br />
                  {flight.arrivalDate}
                </TableCell>
                <TableCell>
                  <strong>Carry on:</strong> {getCarryOn(leg.inclusions)?.description}
                  <br />
                  <strong>Check in:</strong> {getCheckedBaggage(leg.inclusions) && 'Checked Bag included'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
