import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Box, Stack, Typography } from '@mui/material';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';

export default function OrderMerchantFees({ order }) {
  const getMerchantFees = (merchant_fees) => {
    const sale_records = merchant_fees.filter((item) => item.intent === 'sale');
    // using this as only sale records in the array have card brand, metadata etc.
    return {
      type: sale_records[0]?.metadata?.data?.brand || sale_records[0]?.metadata?.type,
      itemId: merchant_fees.reduce(
        (itemIds, merchant_fee_item) => (itemIds && itemIds + ', ') + merchant_fee_item.fk_item,
        '',
      ),
      total: sale_records.reduce((total, merchant_fee_item) => total + parseFloat(merchant_fee_item.amount), 0),
      net: merchant_fees.reduce((total, merchant_fee_item) => total + parseFloat(merchant_fee_item.amount), 0),
      percentage: sale_records[0].metadata.data.feePercentage,
    };
  };

  const merchantFees = getMerchantFees(order?.merchant_fees);

  return (
    <Box mt={2}>
      <FoldableSection title="Card fee" initiallyExpanded>
        <Stack direction="row" spacing={4}>
          <Box flexGrow={1}>
            <Typography variant="h6">Card Fee</Typography>

            <Box display="grid" columnGap={2} gridTemplateColumns="auto 1fr">
              <Typography>Fee Rate</Typography>
              <Typography fontWeight="bold">{merchantFees.percentage}%</Typography>

              <Typography>Card Type</Typography>
              <Typography fontWeight="bold">{merchantFees.type}</Typography>

              <Typography>Total Fee</Typography>
              <Typography fontWeight="bold">
                {currencyFormatter.format(merchantFees.total, {
                  code: order.currency_code,
                  precision: 2,
                })}
              </Typography>

              <Typography>Net Fee</Typography>
              <Typography fontWeight="bold">
                {currencyFormatter.format(merchantFees.net, {
                  code: order.currency_code,
                  precision: 2,
                })}
              </Typography>

              <Typography>Items</Typography>
              <Typography fontWeight="bold">{merchantFees.itemId}</Typography>
            </Box>
          </Box>

          <Box flexGrow={1}>
            <Typography variant="h6">Card fee explanation</Typography>
            <Typography>A card fee is payable on this booking as it was made with a card.</Typography>
          </Box>
        </Stack>
      </FoldableSection>
    </Box>
  );
}
