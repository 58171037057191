import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import PlusIcon from '@mui/icons-material/DataSaverOn';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CarIcon from '@mui/icons-material/DirectionsCar';
import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import TourIcon from '@mui/icons-material/Tour';
import SecurityIcon from '@mui/icons-material/VerifiedUser';
import { Box, Skeleton, Stack, Typography } from '@mui/material';

import BedbankService from '~/services/BedbankService';
import OffersService from '~/services/OffersService';
import ReservationService from '~/services/ReservationService';
import { getTourById } from '~/services/ToursService';
import CruiseService from '~/services/cruises/BookingInfoService';

const ITEM_STATUS = {
  PENDING: 'Pending',
  BOOKED: 'Booked',
};

const ITEM_TYPE_ICONS = {
  hotel: <HotelIcon />,
  bedbankHotel: <HotelIcon />,
  bundleAndSave: <HotelIcon />,
  tourV1: <TourIcon />,
  tourV2: <TourIcon />,
  flight: <FlightIcon />,
  experience: <LocalActivityIcon />,
  cruise: <DirectionsBoatIcon />,
  'car-hire': <CarIcon />,
  insurance: <SecurityIcon />,
  'booking-protection': <SecurityIcon />,
  'subscription-join': <PlusIcon />,
  'luxury-plus-subscription': <PlusIcon />,
};

const getItemTypeURL = (cartItem) => {
  switch (cartItem.itemType) {
    case 'tourV1':
    case 'tourV2':
      return `/tours/details/${cartItem.itemProductId}`;
    case 'experience':
      return `/experience/${cartItem.itemProductId}`;
    case 'cruise':
      return `/cruises/offer/${cartItem.itemProductId}`;
    case 'bedbankHotel':
      return `/bedbank/properties/${cartItem.itemProductId}`;
    default:
      return `/offers/${cartItem.itemProductId}`;
  }
};

const getOccupancyLabel = (occupancy) => {
  const label = `Adults: ${occupancy.adults}`;
  if (occupancy.children) label + `, Children: ${occupancy.children}`;
  if (occupancy.infants) label + `, Infants: ${occupancy.infants}`;
  return label;
};

type QuoteCartItem = {
  itemId: string;
  itemType: string;
  status: string;
  name?: string;
  itemProductId?: string;
  orderId?: string;
  packageId?: string;
  bookingId?: string;
  dateLabel?: string;
  occupancy?: {
    adults: number;
    infants: number;
    children: number;
  };
};

type Props = {
  items: QuoteCartItem[];
};

export default function QuoteCartItems({ items }: Props) {
  const [cartItemNames, setCartItemNames] = useState({});
  const [packageNames, setPackageNames] = useState({});
  const [loading, setLoading] = useState<Utils.FetchingState>('idle');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchHotelOffer = async (offerId: string, packageId: string) => {
      try {
        const result = await OffersService.getOffer(offerId);
        if (!result) return;
        const pkg = result.result.packages.find((pkg) => pkg.id_salesforce_external === packageId);
        if (!pkg) return;
        const property = await ReservationService.getProperty(pkg.fk_property_id);
        setCartItemNames((prevState) => ({ ...prevState, [offerId]: property?.result.name || result.result.name }));
        setPackageNames((prevState) => ({ ...prevState, [offerId]: pkg.name }));
      } catch (error) {
        enqueueSnackbar(`Error fetching hotel details: ${error}`, { variant: 'error' });
      }
    };
    const fetchBedbankOffer = async (bedbankId: string) => {
      try {
        const result = await BedbankService.getPropertyById(bedbankId);
        setCartItemNames((prevState) => ({ ...prevState, [bedbankId]: result?.result.name }));
      } catch (error) {
        enqueueSnackbar(`Error fetching bedbank details: ${error}`, { variant: 'error' });
      }
    };
    const fetchTourOffer = async (tourId: string) => {
      try {
        const result = await getTourById(tourId);
        setCartItemNames((prevState) => ({ ...prevState, [tourId]: result?.result.name }));
      } catch (error) {
        enqueueSnackbar(`Error fetching tour details: ${error}`, { variant: 'error' });
      }
    };
    const fetchCruiseDetails = async (cruiseId: string, bookingId: string) => {
      try {
        const result = await CruiseService.getById(bookingId);
        setPackageNames((prevState) => ({ ...prevState, [cruiseId]: result?.result.rateCodeDescription }));
      } catch (error) {
        enqueueSnackbar(`Error fetching cruise details: ${error}`, { variant: 'error' });
      }
    };

    setLoading('loading');
    Promise.all(
      items.map((cartItem) => {
        if (cartItem.itemType === 'hotel') return fetchHotelOffer(cartItem.itemProductId, cartItem.packageId);
        if (cartItem.itemType === 'bedbankHotel') return fetchBedbankOffer(cartItem.itemProductId);
        if (cartItem.itemType === 'tourV2') return fetchTourOffer(cartItem.itemProductId);
        if (cartItem.itemType === 'cruise') return fetchCruiseDetails(cartItem.itemProductId, cartItem.bookingId);
      }),
    ).finally(() => {
      setLoading('success');
    });
  }, [items]);

  if (loading === 'loading') {
    return <Skeleton variant="text" width="80%" />;
  }

  return (
    <Stack direction="column" spacing={1}>
      {items.map((cartItem) => (
        <Stack key={cartItem.itemId} direction="row" spacing={2} alignItems="center">
          <Box sx={{ minWidth: '70px' }}>
            {cartItem.orderId && (
              <Link target="_blank" rel="noreferrer" to={`/purchases/${cartItem.orderId}`}>
                <Typography variant="subtitle1">Booked</Typography>
              </Link>
            )}
            {!cartItem.orderId && <Typography variant="subtitle1">{ITEM_STATUS[cartItem.status]}</Typography>}
          </Box>
          {ITEM_TYPE_ICONS[cartItem.itemType]}
          <Stack direction="column">
            {cartItem.itemProductId && (
              <Link target="_blank" rel="noreferrer" to={getItemTypeURL(cartItem)}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {cartItemNames[cartItem.itemProductId] || cartItem.name}
                </Typography>
              </Link>
            )}
            {!cartItem.itemProductId && (
              <Typography variant="subtitle1" fontWeight="bold">
                {cartItemNames[cartItem.itemProductId] || cartItem.name}
              </Typography>
            )}
            {cartItem.dateLabel && (
              <Typography variant="subtitle2">
                {cartItem.dateLabel}
                {cartItem.occupancy && <> / {getOccupancyLabel(cartItem.occupancy)}</>}
              </Typography>
            )}
            {packageNames[cartItem.itemProductId] && (
              <Typography variant="subtitle2">{packageNames[cartItem.itemProductId]}</Typography>
            )}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
