import React, { useCallback, useEffect, useState } from 'react';

import qs from 'qs';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Box, Button, Grid, Link, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import ReferralLogsProcessor from '~/components/Promo/ReferralLogs/ReferralLogProcessor';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useQuery from '~/hooks/useQuery';

import { searchGiftCardLogs } from '~/services/PromoService';

import dateFormatter from '~/utils/dateFormatter';

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 1,
};

const getColumns = (): GridColDef[] => [
  {
    field: 'gift_card_code',
    headerName: 'Gift Card Code',
    ...sharedOpts,
  },
  {
    field: 'gift_card_status',
    headerName: 'Gift Card Status',
    ...sharedOpts,
  },
  {
    field: 'gift_card_value',
    headerName: 'Gift Card Value',
    ...sharedOpts,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    ...sharedOpts,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    renderCell: (params) => dateFormatter(params.row.created_at),
    ...sharedOpts,
  },
  {
    field: 'expires_at',
    headerName: 'Expires At',
    renderCell: (params) => dateFormatter(params.row.expires_at),
    ...sharedOpts,
  },
  {
    field: 'added_by',
    headerName: 'Added By',
    renderCell: (params) => (
      <Link href={`/users/${params.row.added_by}`} target="_blank">
        {params.row.added_by}
      </Link>
    ),
    ...sharedOpts,
  },
  {
    field: 'redeemed_by',
    headerName: 'Redeemed By',
    renderCell: (params) => (
      <Link href={`/users/${params.row.redeemed_by}`} target="_blank">
        {params.row.redeemed_by}
      </Link>
    ),
    ...sharedOpts,
  },
];

const PAGE_SIZE = 10;

function GiftCardLogsContainer() {
  const query = useQuery();
  const [dataState, setData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNum, setPageNum] = useState<number>(Number(query.get('page_num')) || 0);
  const [totalCount, setTotalCount] = useState(0);
  const isDev = !!query.get('isDev');
  const [giftCardCode, setGiftCardCode] = useState<string>(query.get('code') || '');
  const [addedBy, setAddedBy] = useState<string>(query.get('added_by') || '');
  const [redeemedBy, setRedeemedBy] = useState<string>(query.get('redeemed_by') || '');
  const tenant = useCurrentTenant();

  const history = useHistory();

  const setQueryParams = useCallback(() => {
    const searchParams = qs.stringify({
      code: giftCardCode,
      added_by: addedBy,
      redeemed_by: redeemedBy,
      page_num: pageNum.toString(),
    });
    history.push({
      search: `?${searchParams}`,
    });
  }, [addedBy, giftCardCode, history, pageNum, redeemedBy]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const {
      result: { total, logs },
    } = await searchGiftCardLogs({
      code: giftCardCode,
      addedBy: addedBy,
      redeemedBy: redeemedBy,
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
      brand: tenant.tenant.brand,
    });
    setData(logs ?? []);
    setIsLoading(false);
    setTotalCount(Number(total));
    setQueryParams();
  }, [giftCardCode, addedBy, redeemedBy, pageNum, tenant.tenant.brand, setQueryParams]);

  const dataRows = dataState.map((row, index) => ({
    id: index,
    ...row,
  }));

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);

  const onSubmit = () => {
    fetchData();
  };

  const onPageChange = useCallback(
    (page: number) => {
      setPageNum(page);
      query.set('page_num', page.toString());
      window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`);
    },
    [query],
  );

  return (
    <>
      <Helmet>
        <title>Gift Card Logs</title>
      </Helmet>

      <PageSubheader title={`Search Gift Card Logs [${dataState?.length} of ${totalCount}]`} />

      <Grid container spacing={2}>
        <Grid item sm={3}>
          <TextField
            fullWidth
            autoFocus
            type="text"
            value={giftCardCode}
            onChange={(e) => setGiftCardCode(e.target.value)}
            label="Gift Card Code"
            placeholder="Gift Card Code"
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            fullWidth
            type="text"
            value={addedBy}
            onChange={(e) => setAddedBy(e.target.value)}
            label="Added By"
            placeholder="Added By (User ID)"
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            fullWidth
            type="text"
            value={redeemedBy}
            onChange={(e) => setRedeemedBy(e.target.value)}
            label="Redeemed By"
            placeholder="RedeemedBy By (User ID)"
          />
        </Grid>
        <Grid item sm={1}>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Search
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          loading={isLoading}
          rows={dataRows}
          columns={getColumns()}
          autoHeight
          rowCount={totalCount}
          paginationMode="server"
          pageSizeOptions={[10]}
          paginationModel={{ page: pageNum, pageSize: PAGE_SIZE }}
        />
        <Box mt={2}>
          <ReferralLogsProcessor isDev={isDev} />
        </Box>
      </Box>
    </>
  );
}

export default GiftCardLogsContainer;
