import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import ErrorPaymentsActions from '~/components/Finance/OfferDetail/ErrorPayments/ErrorPaymentsActions';

import { formatDateShortDD, formatDateWithClock } from '~/services/TimeService';

interface Props {
  schema: any;
  errorPayments: Record<string, unknown>[];
  totalErrorPayments: {
    amount: number;
    currency: string;
  };
  onErrorPaymentUpdated: (errorPayment: Record<string, unknown>) => void;
  onErrorPaymentDeleted: (errorPaymentId: string) => void;
}

const columns: GridColDef[] = [
  {
    field: 'date_of_adjustment',
    headerName: 'Date Of Payment',
    width: 200,
    valueFormatter: (value) => formatDateShortDD(value),
    display: 'flex',
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 200,
    renderCell: (params) =>
      currencyFormatter.format(params.value, {
        code: params.row.currency,
      }),
    display: 'flex',
  },
  { field: 'description', headerName: 'Description', flex: 1, display: 'flex' },
  { field: 'currency', headerName: 'Currency', width: 100, display: 'flex' },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 200,
    valueFormatter: (value) => formatDateWithClock(value),
    display: 'flex',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    align: 'center',
    renderCell: ErrorPaymentsActions,
    display: 'flex',
  },
];

export function ErrorPaymentsList({
  schema,
  errorPayments,
  totalErrorPayments,
  onErrorPaymentUpdated,
  onErrorPaymentDeleted,
}: Props) {
  const errorPaymentsWithCallBacks = errorPayments.map((element) => {
    return {
      ...element,
      schema,
      onErrorPaymentUpdated,
      onErrorPaymentDeleted,
    };
  });

  return (
    <>
      <Typography component="p" variant="body1" fontSize={18} mb={1}>
        Total Paid to Vendor {totalErrorPayments.currency}
        &nbsp;
        {currencyFormatter.format(totalErrorPayments.amount, {
          code: totalErrorPayments.currency,
        })}
      </Typography>

      <DataGrid
        getRowId={(row) => row.id_adjustment}
        rows={errorPaymentsWithCallBacks}
        columns={columns}
        paginationMode="client"
        slots={{
          pagination: GridPagination,
        }}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
      />
    </>
  );
}
