import { json_headers, request } from './common';

export default class BedbankService {
  static basePath() {
    return window.configs.API_HOST + '/api/bedbank';
  }

  static isBedbankId(str) {
    return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(str);
  }

  static getReservationInfo(reservationId) {
    return request(this.basePath() + `/reservations/${reservationId}`, {
      method: 'GET',
    });
  }

  static getBookingInfo(reservationId) {
    return request(this.basePath() + `/reservations/${reservationId}/supplier`, {
      method: 'GET',
    });
  }

  static getRebookableInfo(reservationIds) {
    return request(
      this.basePath() + `/reservations/rebookable?${reservationIds.map((id) => `reservationIds[]=${id}`).join('&')}`,
      {
        method: 'GET',
      },
    );
  }

  static getPropertyById(id) {
    let baseUrl = `/properties/${id}/edit`;
    return request(this.basePath() + baseUrl, {
      method: 'GET',
    });
  }

  static getRatesScan(id) {
    let baseUrl = `/properties/${id}/rates/scan`;
    return request(this.basePath() + baseUrl, {
      method: 'GET',
    });
  }

  static postRatesScan(id) {
    let baseUrl = `/properties/${id}/rates/scan`;
    return request(this.basePath() + baseUrl, {
      method: 'POST',
    });
  }

  static getRatesSync(id) {
    let baseUrl = `/properties/${id}/rates/sync/job`;
    return request(this.basePath() + baseUrl, {
      method: 'GET',
    });
  }

  static postRatesSync(id) {
    let baseUrl = `/properties/${id}/rates/sync/job`;
    return request(this.basePath() + baseUrl, {
      method: 'POST',
    });
  }

  static getAllCalendar(id) {
    let baseUrl = `/properties/${id}/calendar?region=AU&brand=luxuryescapes&all=true`;
    return request(this.basePath() + baseUrl, {
      method: 'GET',
    });
  }

  static getChains() {
    let baseUrl = '/chains';
    return request(this.basePath() + baseUrl, {
      method: 'GET',
    });
  }

  static getMappedRooms(id) {
    let baseUrl = `/properties/${id}/mapped-rooms`;
    return request(this.basePath() + baseUrl, {
      method: 'GET',
    });
  }

  static getSourceRooms(id) {
    let baseUrl = `/properties/${id}/source-rooms`;
    return request(this.basePath() + baseUrl, {
      method: 'GET',
    });
  }

  static createMappedRooms(id, payload) {
    let baseUrl = `/properties/${id}/map-rooms`;
    return request(this.basePath() + baseUrl, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static deleteMappedRooms(id, payload) {
    let baseUrl = `/properties/${id}/map-rooms`;
    return request(this.basePath() + baseUrl, {
      method: 'DELETE',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static getPropertiesUrlQuery(params) {
    let uri = [];
    const {
      query,
      type,
      propertyType,
      country,
      status,
      ratingOp,
      ratingVal,
      city,
      stateProvince,
      domain,
      comment,
      profile,
      chainId,
      promotions,
      chunk,
      startOffset,
    } = params;

    if (query && type) {
      uri.push(`q=${encodeURIComponent(query)}`);
      uri.push(`type=${type}`);
    }
    if (propertyType) {
      uri.push(`propertyType=${propertyType}`);
    }
    if (status) {
      uri.push(`status=${status}`);
    }
    if (country) {
      uri.push(`country=${encodeURIComponent(country)}`);
    }
    if (chainId) {
      uri.push(`chainId=${encodeURIComponent(chainId)}`);
    }
    if (promotions) {
      uri.push(`promotions=${encodeURIComponent(promotions)}`);
    }
    if (ratingOp && ratingVal) {
      uri.push(`ratingOp=${ratingOp}`);
      uri.push(`ratingVal=${ratingVal}`);
    }
    if (city) {
      uri.push(`city=${encodeURIComponent(city)}`);
    }
    if (stateProvince) {
      uri.push(`stateProvince=${encodeURIComponent(stateProvince)}`);
    }
    if (domain) {
      uri.push(`domain=${domain}`);
    }
    if (comment) {
      uri.push(`comment=${comment}`);
    }
    if (profile) {
      uri.push(`profile=${profile}`);
    }
    if (chunk) {
      uri.push(`chunk=${chunk}`);
    }
    if (startOffset >= 0) {
      uri.push(`startOffset=${startOffset}`);
    }

    return uri.join('&');
  }

  static getProperties(page, limit, query) {
    let uri = `/properties/list?page=${page}&limit=${limit}`;
    const additional = BedbankService.getPropertiesUrlQuery(query);

    if (additional) {
      uri = `${uri}&${additional}`;
    }

    return request(this.basePath() + uri, {
      method: 'GET',
    });
  }

  static getPropertiesByName(page, limit, q) {
    return this.getProperties(page, limit, { query: q, type: 'name' });
  }

  static createPropertyImage(id, payload) {
    return request(this.basePath() + `/properties/add-image/${id}`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static createRoomImage(id, payload) {
    return request(this.basePath() + `/properties/room/add-image/${id}`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static getPropertyEdit(id) {
    return request(this.basePath() + `/properties/${id}/edit`, {
      method: 'GET',
    });
  }

  static patchPropertyEdit(id, payload) {
    return request(this.basePath() + `/properties/${id}/edit`, {
      method: 'PATCH',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static runRoomMapping(id) {
    return request(this.basePath() + `/properties/${id}/run-map-rooms`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({}),
    });
  }

  static runRoomGeneration(id) {
    return request(this.basePath() + `/properties/${id}/map-rooms/generate`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({}),
    });
  }

  static getProgressStatus(id, type) {
    return request(this.basePath() + `/progress/status?id=${id}&type=${type}`, {
      method: 'GET',
    });
  }

  static bulkPatchPropertiesEdit(payload) {
    return request(this.basePath() + `/properties/edit`, {
      method: 'PATCH',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static patchShowHideImages(id, payload) {
    return request(this.basePath() + `/properties/${id}/batch-property-image-show-hide`, {
      method: 'PATCH',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static remapDecoupledRoom(propertyId, roomId, payload) {
    return request(this.basePath() + `/properties/${propertyId}/rooms/${roomId}/remap`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static patchRoom(propertyId, roomId, payload) {
    return request(this.basePath() + `/properties/${propertyId}/rooms/${roomId}`, {
      method: 'PATCH',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static setAutomappingFlag(id, payload) {
    return request(this.basePath() + `/properties/${id}/auto-map-rooms`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }
}
