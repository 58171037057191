import qs from 'qs';

import { GridFilterItem } from '@mui/x-data-grid';

import { SurchargeSuggestionDB } from '~/components/LERE/RevenueManagement';

import { json_headers, request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/recommendation';
}

function sendTPFMTestEmail(emailVariant: string, emailRecipient: string, useRecipientData: boolean) {
  return request(`${basePath()}/tpfm_operations/tpfm_test_trigger`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({
      email_variant: emailVariant,
      email_recipient: emailRecipient,
      use_recipient_data: useRecipientData,
    }),
  });
}

function getSurchargeSuggestions(page: number, limit = 5) {
  const queryParams = { page, limit };
  return request(`${basePath()}/dynamic_pricing/?${qs.stringify(queryParams)}`, {
    method: 'GET',
    headers: json_headers,
  });
}

function getLatestSurchargeSuggestions(page: number, limit = 10, aggregate = false, filters: Array<GridFilterItem>) {
  const queryParams = {
    page,
    limit,
    aggregate,
    ...filters.reduce(
      (acc, filter) => ({
        ...acc,
        [filter.field]: filter.value,
      }),
      {},
    ),
  };
  return request(`${basePath()}/dynamic_pricing/latest?${qs.stringify(queryParams)}`, {
    method: 'GET',
    headers: json_headers,
  });
}

function createSurchargeSuggestion(body: SurchargeSuggestionDB) {
  return request(`${basePath()}/dynamic_pricing`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(body),
  });
}

export default {
  sendTPFMTestEmail,
  getSurchargeSuggestions,
  getLatestSurchargeSuggestions,
  createSurchargeSuggestion,
};
