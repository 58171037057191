import { HB_ROOM_SIZE_FACILITY_CODE } from '../common';

import type { SourceRoom } from './types';

const sqmToSqft = (sqm: number) => {
  return Math.round(sqm * 10.7639);
};

const generateRoomSizeText = (room: SourceRoom): string => {
  const roomSizeSQM = room?.roomSize
    ? room?.roomSize
    : room.sourceContent?.roomFacilities?.find((f) => f.facilityCode === HB_ROOM_SIZE_FACILITY_CODE)?.number ??
      room.sourceContent?.area?.square_meters ??
      undefined;
  const roomSizeFT = room?.roomSize
    ? sqmToSqft(room?.roomSize)
    : room.sourceContent?.area?.square_feet ?? sqmToSqft(roomSizeSQM) ?? undefined;

  const roomSizeText = roomSizeSQM ? `${roomSizeSQM} m² / ${roomSizeFT} ft²` : 'N/A';
  return roomSizeText;
};

const generateOccupancyText = (room: SourceRoom) => {
  const occupancyFromSourceContentMaxAdults = room?.sourceContent?.maxAdults;
  const occupancyFromSourceContentMaxChildren = room?.sourceContent?.maxChildren;
  const occupancyFromSourceContentMaxTotal = room?.sourceContent?.maxPax;

  const occupancyFromSourceContent = occupancyFromSourceContentMaxAdults
    ? `Adults: ${occupancyFromSourceContentMaxAdults}, Children: ${occupancyFromSourceContentMaxChildren}, Total: ${occupancyFromSourceContentMaxTotal}`
    : undefined;

  const occupancyAge = room?.occupancy?.age_categories
    ? `Min adult: ${room?.occupancy?.age_categories?.Adult?.minimum_age}, Min child: ${room?.occupancy?.age_categories?.Child?.minimum_age}, `
    : room?.sourceContent?.occupancy
    ? `Min adult: ${room?.sourceContent.occupancy.age_categories?.Adult?.minimum_age}, Min child: ${
        room?.sourceContent.occupancy.age_categories?.Child?.minimum_age ??
        room?.sourceContent.occupancy.age_categories?.ChildAgeA?.minimum_age
      }, `
    : 'N/A';

  const occupancyAllowed = room?.occupancy?.max_allowed
    ? `Adults: ${room.occupancy.max_allowed.adults}, Children: ${room.occupancy.max_allowed.children}, Total: ${room.occupancy.max_allowed.total}`
    : occupancyFromSourceContent ??
      (room?.sourceContent
        ? `Adults: ${room.sourceContent?.occupancy?.max_allowed?.adults}, Children: ${room.sourceContent?.occupancy?.max_allowed?.children}, Total: ${room.sourceContent?.occupancy?.max_allowed?.total}`
        : occupancyFromSourceContent ?? 'N/A');
  return { occupancyAge, occupancyAllowed };
};

const generateViewsText = (room: SourceRoom) => {
  const description = room?.description ?? 'N/A';
  const doesDescriptionContainView = description.toLowerCase().includes('view');
  const viewsArray = room?.views
    ? Array.isArray(room?.views)
      ? room?.views
      : Object.values(room?.views).map((v: any) => v.name)
    : undefined;
  const views = viewsArray?.join(' ') || (doesDescriptionContainView ? 'Check description for view info' : 'N/A');
  return views;
};

export { generateRoomSizeText, generateOccupancyText, generateViewsText };
