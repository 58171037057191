import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation, useParams } from 'react-router';

import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button, Container, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { getCommissions } from '~/services/AgentHub/AgentService';
import { formatDateSlashes } from '~/services/TimeService';

import { Commission } from '~/types/services/agentHub';

import { addQuery, parseSearchString } from '~/utils/url';

import DeleteCommissionDialog from './AgentHubCommissions/DeleteCommissionDialog';
import EditCommissionDialog from './AgentHubCommissions/EditCommissionDialog';
import AgentHubCreateCommissionForm from './AgentHubCreateCommissionForm';

const DEFAULT_SIZE_PER_PAGE = 10;

const AgentCommissions = () => {
  const location = useLocation();
  const params = useParams<{ id?: string }>();
  const [filter, setFilter] = useState({
    page: 1,
    status: null,
    order: null,
    search: null,
  });

  const [commissionList, setCommissionList] = useState<Array<Commission>>([]);
  const [total, setTotal] = useState<number>(0);
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [isEditModeOpen, setIsEditModeOpen] = useState<boolean>(false);
  const [commissionData, setCommissionData] = useState<Commission | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const { push: setQueryString } = useHistory();

  const openEditMode = () => setIsEditModeOpen(true);
  const closeEditMode = () => setIsEditModeOpen(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      setQueryString(addQuery(location, { page }));
    },
    [location, setQueryString],
  );

  useEffect(() => {
    const { status, order, page, search } = parseSearchString(location.search);
    setFilter((prev) => ({
      ...prev,
      order,
      status,
      search,
      page: parseInt((page as string) ?? '1'),
    }));
  }, [location.search]);

  const listCommissions = useCallback(() => {
    setIsListLoading(true);
    getCommissions({
      page: filter.page.toString(),
      pageSize: DEFAULT_SIZE_PER_PAGE.toString(),
    })
      .then((res) => {
        if (res) {
          setCommissionList(res.rows);
          setTotal(res.total);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setIsListLoading(false);
      });
  }, [enqueueSnackbar, filter.page]);

  useEffect(() => {
    listCommissions();
  }, [listCommissions]);

  const sharedOpts: Partial<GridColDef> = {
    editable: false,
    sortable: true,
    filterable: false,
    hideable: false,
    disableColumnMenu: true,
    flex: 2,
  };

  const columns: Array<GridColDef> = [
    { ...sharedOpts, field: 'description', headerName: 'Description' },
    { ...sharedOpts, field: 'commissionType', headerName: 'Type' },
    {
      ...sharedOpts,
      field: 'commissionPercentage',
      headerName: 'Commission Percentage',
      headerAlign: 'center',
      align: 'center',
    },
    {
      ...sharedOpts,
      field: 'startDate',
      headerName: 'Start Date',
      renderCell: (params) => (params.value ? formatDateSlashes(params.value) : ''),
    },
    {
      ...sharedOpts,
      field: 'endDate',
      headerName: 'End Date',
      renderCell: (params) => (params.value ? formatDateSlashes(params.value) : ''),
    },
    {
      ...sharedOpts,
      field: 'edit',
      headerName: 'Edit',
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() => {
            openEditMode();
            setCommissionData(params.row);
          }}
        >
          <ModeEditIcon />
        </Button>
      ),
    },
    {
      ...sharedOpts,
      field: 'delete',
      flex: 1,
      sortable: false,
      headerName: 'Delete',
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              openDeleteModal();
              setCommissionData(params.row);
            }}
            variant="text"
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Agent Commissions</title>
      </Helmet>

      <Grid item xs={12}>
        <PageSubheader title="Commission rules" />

        <DataGrid
          loading={isListLoading}
          columns={columns}
          getRowId={(row) => row.id}
          rows={commissionList}
          autoHeight
          rowCount={total}
          paginationMode="server"
          paginationModel={{ page: filter.page - 1, pageSize: DEFAULT_SIZE_PER_PAGE }}
          pageSizeOptions={[DEFAULT_SIZE_PER_PAGE]}
          onPaginationModelChange={({ page }) => onPageChange(page + 1)}
          onProcessRowUpdateError={(error) => {
            enqueueSnackbar(error.message, { autoHideDuration: 5000 });
          }}
          getRowHeight={() => 'auto'}
        />

        <AgentHubCreateCommissionForm listCommissions={listCommissions} />
      </Grid>

      <EditCommissionDialog
        isOpen={isEditModeOpen}
        onClose={closeEditMode}
        data={commissionData}
        listCommissions={listCommissions}
      />
      <DeleteCommissionDialog
        listCommissions={listCommissions}
        data={commissionData}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
      />
    </Container>
  );
};

export default AgentCommissions;
