import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { resendEmail } from '~/services/SailthruService';

function EmailFormModal({ open, handleClose, params, initialEmail }) {
  const [email, setEmail] = useState(initialEmail);
  const [isValid, setIsValid] = useState(true);
  const [isDomainValid, setIsDomainValid] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const domainRegex = /^[^\s@]+@luxuryescapes\.com$/;
    setIsValid(emailRegex.test(emailValue));
    setIsDomainValid(domainRegex.test(emailValue));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid && isDomainValid) {
      try {
        await resendEmail({ id: params.row.id, email: email });
        enqueueSnackbar('Email sent, please check inbox', { variant: 'success' });
        handleClose();
      } catch (error) {
        enqueueSnackbar('Failed to resend email', { variant: 'error' });
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Forward Email: {params.row.notification_type}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
          <TextField
            margin="dense"
            label="Forward To:"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            error={!isValid || !isDomainValid}
            helperText={
              !isValid
                ? 'Please enter a valid email address.'
                : !isDomainValid
                ? 'Please enter an email address within the luxuryescapes.com domain.'
                : ''
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button type="submit" onClick={handleSubmit} color="primary" disabled={!isValid || !isDomainValid}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmailFormModal;
