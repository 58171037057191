import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TableCell, TableRow, Tooltip } from '@mui/material';

import { FlexTableCell } from '~/components/Content/SearchRanking/FlexTableCell';

import SearchService, { UnifiedSearchList } from '~/services/SearchService';
import { OfferDetails, fetchUnifiedOfferDetails } from '~/services/UnifiedOfferService';

interface IExpectedValueData {
  expectedValue: string;
  expectedValueInfo?: string;
  adjustedExpectedValue: string;
  adjustedExpectedValueInfo?: string;
  salesAmount: string;
  marginAmount: string;
  mpv: string;
  salesCount: string;
  views: string;
}

const soldOutValueData: IExpectedValueData = {
  expectedValue: 'N/A',
  adjustedExpectedValue: 'N/A',
  salesAmount: 'N/A',
  marginAmount: 'N/A',
  mpv: 'N/A',
  salesCount: 'N/A',
  views: 'N/A',
};

export default function SearchCruiseRow({
  offer,
  region,
  variant,
  isManuallySorted,
}: {
  offer: UnifiedSearchList[0];
  region: string;
  variant: string;
  isManuallySorted?: boolean;
}) {
  const isMountedRef = useRef(true);
  const tenant = useSelector((state: App.State) => state.tenant);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [offerDetails, setOfferDetails] = useState<OfferDetails | undefined>();
  const [offerScore, setOfferScore] = useState<App.OfferScore | undefined>();

  useEffect(() => {
    (async () => {
      try {
        setFetchingState('loading');
        const [score, offerDetails] = await Promise.all([
          SearchService.getOfferScoreForVariant({
            vertical: offer.vertical,
            offerId: offer.bk,
            region,
            variant,
          }),
          fetchUnifiedOfferDetails(offer, region, tenant.brand),
        ]);

        if (!isMountedRef.current) return;

        setOfferScore(score.result.score);
        setOfferDetails(offerDetails);
        setFetchingState('success');
      } catch (error) {
        setFetchingState('failed');
      }
    })();

    return () => {
      isMountedRef.current = false;
    };
  }, [variant, offer, region, tenant.brand]);

  const expectedValueData: IExpectedValueData = useMemo(() => {
    if (offerDetails?.isSoldOut) {
      return soldOutValueData;
    }

    if (offerScore) {
      return {
        views: offerScore.numberOfViews.toLocaleString(),
        salesCount: offerScore.salesCount.toLocaleString(),
        salesAmount: offerScore.totalSales.toLocaleString(),
        marginAmount: offerScore.totalMargin.toLocaleString(),
        mpv:
          offerScore.totalMargin && offerScore.numberOfViews
            ? (offerScore.totalMargin / offerScore.numberOfViews).toFixed(3)
            : '0',
        expectedValue: offerScore.expectedValue.toFixed(3),
        expectedValueInfo: offerScore.expectedValueInfo,
        adjustedExpectedValue: offerScore.adjustedExpectedValue.toFixed(3),
        adjustedExpectedValueInfo: offerScore.adjustedExpectedValueInfo,
      };
    }
  }, [offerScore, offerDetails?.isSoldOut]);

  const titleTooltip = useMemo(() => {
    if (isManuallySorted) {
      return 'Commercial ranked';
    }

    if (offerDetails?.cruiseSpecialOffer) {
      return 'Special Offer';
    }

    if (offerDetails?.cruiseLeExclusive) {
      return 'LUX Exclusive';
    }

    return null;
  }, [isManuallySorted, offerDetails?.cruiseLeExclusive, offerDetails?.cruiseSpecialOffer]);

  return (
    <>
      {fetchingState == 'loading' && (
        <TableRow>
          <TableCell component="th" colSpan={10}>
            Loading...
          </TableCell>
        </TableRow>
      )}
      {fetchingState == 'success' && offerDetails && (
        <TableRow>
          <FlexTableCell component="th" scope="row" sx={{ maxWidth: 300 }}>
            <Link to={offerDetails.url}>{offerDetails.name}</Link>
            {offerDetails.isSoldOut && ' (sold out)'}
            {titleTooltip && (
              <Tooltip title={titleTooltip} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
          <TableCell align="right">{expectedValueData.views}</TableCell>
          <TableCell align="right">{expectedValueData.salesCount}</TableCell>
          <TableCell align="right">{expectedValueData.salesAmount}</TableCell>
          <TableCell align="right">{expectedValueData.marginAmount}</TableCell>
          <TableCell align="right">{expectedValueData.mpv}</TableCell>
          <FlexTableCell align="right">
            {expectedValueData.expectedValue}
            {expectedValueData.expectedValueInfo && (
              <Tooltip title={expectedValueData.expectedValueInfo} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
          <FlexTableCell align="right">
            {expectedValueData.adjustedExpectedValue}
            {expectedValueData.adjustedExpectedValueInfo && (
              <Tooltip title={expectedValueData.adjustedExpectedValueInfo} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
        </TableRow>
      )}
      {fetchingState == 'failed' && (
        <TableRow>
          <TableCell component="th" colSpan={10}>
            Failed to load offer: {offer?.bk}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
