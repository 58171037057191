import React from 'react';

import { Box } from '@mui/material';

import dateFormatter from '~/utils/dateFormatter';

type Props = {
  date: Date;
};

const getDateColor = (date: Date) => (date > new Date() ? 'grey.800' : 'red');

const DateHighlightIfPast = ({ date }: Props) => {
  return <Box color={getDateColor(date)}>{dateFormatter(date)}</Box>;
};

export default DateHighlightIfPast;
