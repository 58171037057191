import React from 'react';

import { useSnackbar } from 'notistack';
import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

import { getOrderItemInsuranceLetterPDF } from '~/services/OrdersService';
import { formatDateISO } from '~/services/TimeService';

interface InsuranceLetterProps {
  item: App.AnyItem;
  order: App.Order;
  offer?: App.Offer;
  refundRequest: RefundRequest;
}

const getOrderInsuranceLetterFilename = (order: App.Order) => {
  return `Insurance-Letter-${formatDateISO(order.created_at)}-${order.number}.pdf`;
};

function InsuranceLetter({ item, order, offer, refundRequest }: InsuranceLetterProps) {
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = async () => {
    try {
      const blob = await getOrderItemInsuranceLetterPDF(order, item, refundRequest, offer);
      const fileName = getOrderInsuranceLetterFilename(order);
      fileDownload(blob, fileName);
      enqueueSnackbar('Insurance letter has been downloaded successfully.', { variant: 'success' });
    } catch {
      enqueueSnackbar('Your insurance letter could not be downloaded. Please contact us for support.', {
        variant: 'error',
      });
    }
  };

  return (
    <Button
      variant="text"
      size="small"
      onClick={handleDownload}
      title="CS often need to send this info to vendors. This makes it easy to copy/paste."
    >
      Download Insurance Letter
    </Button>
  );
}

export default InsuranceLetter;
