import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Box, Button, Grid, Link, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import ReferralLogsProcessor from '~/components/Promo/ReferralLogs/ReferralLogProcessor';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useQuery from '~/hooks/useQuery';

import { searchBlackhawkGiftCardLogs } from '~/services/PromoService';

import dateFormatter from '~/utils/dateFormatter';
import isUUID from '~/utils/isUUID';

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 1,
};

const getColumns = (): Array<GridColDef> => [
  {
    field: 'gift_card_code',
    headerName: 'code',
    ...sharedOpts,
  },
  {
    field: 'gift_card_status',
    headerName: 'Gift Card Status',
    ...sharedOpts,
  },
  {
    field: 'gc_total_amount',
    headerName: 'Gift Card Total Amount',
    ...sharedOpts,
  },
  {
    field: 'redeemed_amount',
    headerName: 'Redeemed Amount',
    ...sharedOpts,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    ...sharedOpts,
  },
  {
    field: 'user_id',
    headerName: 'User ID',
    renderCell: (params) => {
      return (
        <Link href={`/users/${params.row.user_id}`} target="_blank">
          {params.row.user_id}
        </Link>
      );
    },
    ...sharedOpts,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    renderCell: (params) => dateFormatter(params.row.created_at),
    ...sharedOpts,
  },
  {
    field: 'expires_at',
    headerName: 'Expires At',
    renderCell: (params) => dateFormatter(params.row.expires_at),
    ...sharedOpts,
  },
  {
    field: 'blackhawk_msg',
    headerName: 'Blackhawk Msg',
    ...sharedOpts,
  },
];

const PAGE_SIZE = 10;

function BlackhawkGiftCardLogsContainer() {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNum, setPageNum] = useState<number>(Number(query.get('page_num')) || 0);
  const [totalCount, setTotalCount] = useState(0);
  const isDev = !!query.get('isDev');
  const [giftCardCode, setGiftCardCode] = useState<string>(query.get('code') || '');
  const [userId, setUserId] = useState<string>(query.get('userId') || '');
  const tenant = useCurrentTenant();

  const history = useHistory();

  const setQueryParams = useCallback(() => {
    const searchParams = qs.stringify({
      code: giftCardCode,
      userId,
      page_num: pageNum.toString(),
    });
    history.push({
      search: `?${searchParams}`,
    });
  }, [giftCardCode, history, pageNum, userId]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    if (userId && !isUUID(userId)) {
      enqueueSnackbar('Invalid User ID - must be a uuid', { variant: 'error' });
      setIsLoading(false);
      return;
    }

    const {
      result: { total, logs },
    } = await searchBlackhawkGiftCardLogs({
      code: giftCardCode,
      userId: userId,
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
      brand: tenant.tenant.brand,
    });
    setData(logs ?? []);
    setIsLoading(false);
    setTotalCount(Number(total));
    setQueryParams();
  }, [userId, giftCardCode, pageNum, tenant.tenant.brand, setQueryParams, enqueueSnackbar]);

  const dataRows = data.map((row, index) => ({
    id: index,
    ...row,
  }));

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);

  const onSubmit = () => {
    fetchData();
  };

  const onPageChange = useCallback(
    (page: number) => {
      setPageNum(page);
      query.set('page_num', page.toString());
      window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`);
    },
    [query],
  );

  return (
    <>
      <Helmet>
        <title>Blackhawk Giftcard Logs</title>
      </Helmet>

      <PageSubheader title={`Search Blackhawk Gift Card Logs [${data?.length} of ${totalCount}]`} />

      <Grid container spacing={2}>
        <Grid item sm={3}>
          <TextField
            fullWidth
            autoFocus
            type="text"
            value={giftCardCode}
            onChange={(e) => setGiftCardCode(e.target.value)}
            label="Gift Card Code"
            placeholder="Gift Card Code"
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            fullWidth
            type="text"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            label="User ID"
            placeholder="User ID"
          />
        </Grid>
        <Grid item sm={1}>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Search
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          loading={isLoading}
          rows={dataRows}
          columns={getColumns()}
          autoHeight
          rowCount={totalCount}
          paginationMode="server"
          pageSizeOptions={[10]}
          paginationModel={{ page: pageNum, pageSize: PAGE_SIZE }}
        />
        <Box mt={2}>
          <ReferralLogsProcessor isDev={isDev} />
        </Box>
      </Box>
    </>
  );
}

export default BlackhawkGiftCardLogsContainer;
