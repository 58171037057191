import React, { useMemo } from 'react';

import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import { ConnectionCancellationPolicies } from '@luxuryescapes/lib-refunds';

import { OFFER_TYPE_HOTEL } from '~/consts/offerTypes';
import { ITEM_STATUS_CANCELLED } from '~/consts/order';

import { diffDays, isAfter, isBefore, subDays } from '~/services/TimeService';

import { isOfferTypeHotel } from '~/utils/offer';
import { formatOrderDate } from '~/utils/order';

import ReservationInfoCancellationPolicies from './ReservationInfo/ReservationInfoCancellationPolicies';
import { RefundRequests } from './components/RefundRequests';
import { SpecialRequests } from './components/SpecialRequests';

const isTour = (traveller) => {
  return !!traveller;
};

const renderDateChangePolicy = (
  offer: App.AccommodationOffer,
  item: App.OrderItem,
  reservation: App.OrderItemReservation,
) => {
  // days_before_check_in_changes_disallowed and number_of_date_changes are salesforce fields that only apply to LTLE offers
  // for other offer types, we should use the channel manager's provided cancellation policies instead.
  if (offer.type !== OFFER_TYPE_HOTEL) {
    return <Typography>See cancellation policies</Typography>;
  }

  const daysBeforeDateChangeDisallowed = offer.days_before_check_in_changes_disallowed;

  if (!daysBeforeDateChangeDisallowed || !reservation) {
    return <Typography>No changes allowed</Typography>;
  }

  const lastDay = subDays(daysBeforeDateChangeDisallowed, reservation.check_in);
  const lastDayFormatted = formatOrderDate(lastDay);

  const willExpireToday = diffDays(lastDay, new Date(), 'day') === 0;
  const isLastDayPassed = isBefore(lastDay, new Date()) && !willExpireToday;

  let maxNumOfDateChangesMade = false;
  if (offer.number_of_date_changes && offer.number_of_date_changes !== 'Unlimited') {
    maxNumOfDateChangesMade = item.number_of_date_changes >= Number(offer.number_of_date_changes);
  }
  const isItemCancelled = item.status === ITEM_STATUS_CANCELLED;
  const isDateChangeDisabled = isLastDayPassed || maxNumOfDateChangesMade || isItemCancelled;

  let baseColor = isItemCancelled ? 'grey' : 'black';
  let detailColor;
  if (isItemCancelled) {
    detailColor = 'grey';
  } else if (isDateChangeDisabled) {
    baseColor = 'red';
    detailColor = 'red';
  } else {
    detailColor = 'green';
  }

  return (
    <Stack direction="row" spacing={1}>
      <CalendarTodayIcon />
      <Typography color={baseColor}>
        <Typography fontWeight="bold" component="span" color={detailColor}>
          {offer.number_of_date_changes}
        </Typography>{' '}
        changes can be made{' '}
        <Typography fontWeight="bold" component="span" color={detailColor}>
          (currently {item.number_of_date_changes}){' '}
        </Typography>
        prior to{' '}
        <Typography fontWeight="bold" component="span" color={detailColor}>
          {daysBeforeDateChangeDisallowed}
        </Typography>{' '}
        days before check-in (until{' '}
        <Typography fontWeight="bold" component="span" color={detailColor}>
          {lastDayFormatted}
        </Typography>
        )
      </Typography>
    </Stack>
  );
};

//if today is the 20th, return false if checkin is on the 27th
//or earlier
const reservationIsMoreThan7DaysFromCheckIn = (reservation) => {
  const oneWeekFromCheckIn = subDays(7, reservation.check_in);
  return isAfter(oneWeekFromCheckIn);
};

interface Props {
  item: App.OrderItem;
  order: App.Order;
  traveller: any;
  connectionCancellationPolicies: ConnectionCancellationPolicies;
  reservation: any;
}

const ReservationInfo = ({ item, order, traveller, reservation }: Props) => {
  const { offer } = item;

  const guestSpecialRequest = useMemo(() => {
    if (reservation && reservation.guest_special_requests) {
      const specialRequest =
        traveller && traveller.traveller_submitted ? traveller.requests : reservation.guest_special_requests;
      return specialRequest;
    }
  }, [reservation, traveller]);

  const showEditSpecialRequests = useMemo(() => {
    return !isTour(traveller) && reservationIsMoreThan7DaysFromCheckIn(reservation) && offer.type === OFFER_TYPE_HOTEL;
  }, [offer.type, reservation, traveller]);

  return (
    <Box>
      {isOfferTypeHotel(offer) && (
        <>
          <Typography variant="subtitle1" fontWeight="bold">
            Cancellation Policy
          </Typography>

          <ReservationInfoCancellationPolicies item={item} />

          <Stack direction="column" mt={1}>
            <Typography variant="subtitle1" fontWeight="bold">
              Date change policies
            </Typography>

            <Typography>{renderDateChangePolicy(offer as App.AccommodationOffer, item, reservation)}</Typography>
          </Stack>
        </>
      )}

      <SpecialRequests
        item={item}
        order={order}
        isVisible={showEditSpecialRequests}
        specialRequestText={guestSpecialRequest}
      />

      <RefundRequests item={item} order={order} offer={offer} />
    </Box>
  );
};

export default ReservationInfo;
