import React, { useEffect, useState } from 'react';

import cn from 'clsx';
import { useSnackbar } from 'notistack';

import { Button, Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid';

import { TripPlannerTripItemTypes } from '~/consts/tripPlanner';

import { addRecommendationToTrip, getExploreRecommendations } from '~/services/TripPlannerService';

import { TripPlannerDataGrid } from '../TripPlannerDataGrid';

interface Props {
  tripId: string;
  tripItems: Array<App.TripItemDetailsBasic>;
  accommodation: App.TripItemDetailsBasic;
  onAddSuccess: () => void;
}

function ExploreRecommendations({ tripId, tripItems, accommodation, onAddSuccess }: Props) {
  const [exploreItems, setExploreItems] = useState<App.ExploreRecommendation[]>([]);
  const [initialExploreItems, setInitialExploreItems] = useState<App.ExploreRecommendation[] | undefined>(undefined);
  const [isSaving, setIsSaving] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getExploreItems = async () => {
      try {
        if (!initialExploreItems) {
          const locationBias = 'point' in accommodation.place && accommodation.place.point;
          const exploreItemsResponse = await getExploreRecommendations(
            `Top attractions in ${accommodation.place.region}`,
            locationBias ? [locationBias.lat, locationBias.lng] : undefined,
          );
          setInitialExploreItems(exploreItemsResponse.result.items);
        }

        const tripItemsExploreCodes = new Set(
          tripItems
            .filter((item) => item.type === TripPlannerTripItemTypes.ATTRACTION)
            .map((item) => 'id' in item.place && item.place.id),
        );

        const filteredExploreItems = initialExploreItems?.filter((item) => !tripItemsExploreCodes.has(item.id)) ?? [];
        setExploreItems(filteredExploreItems);
      } catch (e) {
        enqueueSnackbar('Failed to get explore recommendations', { variant: 'error' });
      }
    };

    getExploreItems();
  }, [accommodation, enqueueSnackbar, initialExploreItems, tripItems]);

  const handleAddExploreItem = async (exploreItem: App.ExploreRecommendation) => {
    setIsSaving(true);
    try {
      const exploreBookmarkPayload = {
        type: TripPlannerTripItemTypes.ATTRACTION,
        place: {
          type: 'GEO',
          name: exploreItem.name,
          address: exploreItem.place.address,
          photoUrl: exploreItem.photo?.photoUri,
          id: exploreItem.place.id,
          region: exploreItem.place.region,
          point: {
            lat: exploreItem.place.point.lat,
            lng: exploreItem.place.point.lng,
          },
        },
        phone: exploreItem.phone,
        sourceType: 'Explore',
        status: 'PLANNED',
        url: exploreItem.website,
        isBooked: false,
        code: exploreItem.place.id,
      };

      await addRecommendationToTrip(tripId, exploreBookmarkPayload);
      enqueueSnackbar('Explore item added to trip', { variant: 'success' });
      onAddSuccess();
    } catch (e) {
      enqueueSnackbar('Failed to add explore item to trip', { variant: 'error' });
    } finally {
      setIsSaving(false);
    }
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 500 },
    {
      field: 'id',
      headerName: '',
      renderCell: (params: GridRenderCellParams) => (
        <Link
          color="primary"
          rel="noreferrer"
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${params.row.id}`}
          underline="hover"
        >
          View on google maps
        </Link>
      ),
      width: 250,
    },
    {
      headerName: '',
      field: 'Add',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddExploreItem(params.row)}
          disabled={isSaving}
        >
          Add to trip
        </Button>
      ),
      display: 'flex',
      align: 'right',
    },
    {
      field: 'place.address',
      headerName: 'Address',
      renderCell: (params: GridRenderCellParams) => params.row.place.address,
      width: 500,
    },
  ];

  return (
    <TripPlannerDataGrid
      rows={exploreItems}
      columns={columns}
      rowCount={exploreItems.length}
      loading={!exploreItems}
      localeText={{
        noRowsLabel: 'No recommendations found',
      }}
      hideFooterPagination
      getRowHeight={() => 'auto'}
      className={cn({ 'no-trip-items': exploreItems.length === 0 })}
    />
  );
}

export default ExploreRecommendations;
