import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';

import { getOrderDatesRequest, getRefundRequestsByOrderId } from '../../../../services/OrdersService';
import Spinner from '../../../Common/Spinner';

import BookingRequestsTable from './components/BookingRequestsTable';
import { BookingRequest } from './types/types';
import { fetchReviewerMap } from './utils/fetchReviewerMap';
import { mapOrderDatesToBookingRequest } from './utils/mapOrderDates';
import { mapRefundRequestsToBookingRequest } from './utils/mapRefundRequests';

interface OrderDetailProps {
  order: App.Order;
}

function BookingRequests({ order }: OrderDetailProps) {
  const [bookingRequests, setBookingRequests] = useState<Array<BookingRequest>>([]);
  const [error, setError] = useState<string>();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  useEffect(() => {
    const fetchBookingRequests = async () => {
      const orderId = order.id_orders;
      try {
        const orderDates = await getOrderDatesRequest(orderId);
        const refundRequests = await getRefundRequestsByOrderId(orderId);
        const reviewerMap = await fetchReviewerMap(refundRequests);

        // Map data to the booking requests structure
        const mappedOrderDates = mapOrderDatesToBookingRequest(orderDates);
        const mappedRefundRequests = mapRefundRequestsToBookingRequest(refundRequests, order, reviewerMap);

        setBookingRequests([...mappedOrderDates, ...mappedRefundRequests]);
      } catch {
        setError('Could not load booking requests');
      }
    };

    fetchBookingRequests();
  }, [brand, order]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  if (!bookingRequests) {
    return <Spinner />;
  }
  if (bookingRequests.length === 0) {
    return null;
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Typography>Booking Requests</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <BookingRequestsTable bookingRequests={bookingRequests} />
      </AccordionDetails>
    </Accordion>
  );
}

export default BookingRequests;
