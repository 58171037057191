import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import Spinner from '~/components/Common/Spinner';

import { getEOI, listUserEmailLogs, resendActivationEmail, updateEOIStatus } from '~/services/AgentHub/AgentService';
import { formatDateShortDD } from '~/services/TimeService';

import { Eoi, EoiStatus } from '~/types/services/agentHub';

import { titleCase } from '~/utils/stringUtils';

const SIZE_PER_PAGE = 4;

interface Props {
  id: string;
  onClose?: () => void;
  opened?: boolean;
}

const columns: GridColDef[] = [
  { field: 'status', headerName: 'Status', sortable: false, display: 'flex' },
  { field: 'sentAt', headerName: 'Sent At', flex: 1, sortable: false, display: 'flex' },
  { field: 'failureMessage', headerName: 'Message', flex: 1, sortable: false, display: 'flex' },
];

export default function AgencySubmissionDetail({ id, onClose, opened = false }: Props) {
  const [open, setOpen] = useState(opened);
  const [eoiDetails, setEoiDetails] = useState<Eoi | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const closeModal = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, [setOpen, onClose]);

  const openModal = useCallback(async () => {
    if (!id) {
      closeModal();
    }

    setOpen(true);

    try {
      setLoading(true);

      const result = await getEOI(id);
      setEoiDetails(result);
    } finally {
      setLoading(false);
    }
  }, [setOpen, closeModal, id]);

  async function updateSubmission(status: 'approved' | 'rejected') {
    setLoading(true);
    const updatedSubmission = await updateEOIStatus(eoiDetails.id, status);
    setEoiDetails(updatedSubmission);
    setLoading(false);
  }

  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
  });

  const fetchEmailLogList = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const { rows, total } = await listUserEmailLogs(id, {
        type: 'activation',
        page: filter.page,
        pageSize: SIZE_PER_PAGE,
      });

      setTotal(total);
      setResult(rows);
    } catch (e) {
      setTotal(0);
      setResult([]);
    } finally {
      setLoading(false);
    }
  }, [filter, id, setTotal, setResult]);

  const fetchResendActivationEmail = useCallback(async () => {
    try {
      setLoading(true);

      await resendActivationEmail(id);

      fetchEmailLogList();
    } catch (err) {
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [id, setLoading, enqueueSnackbar, fetchEmailLogList]);

  const copyOnboardLink = useCallback(() => {
    navigator.clipboard.writeText(
      `${window.configs.LEAGENTHUB_CUSTOMER_PORTAL}/agent-hub/complete-account-setup?agent_id=${encodeURIComponent(
        eoiDetails?.agentId,
      )}&agent_email=${encodeURIComponent(eoiDetails.email)}`,
    );
    enqueueSnackbar(`Copied to clipboard`, { variant: 'success' });
  }, [enqueueSnackbar, eoiDetails?.agentId, eoiDetails?.email]);

  useEffect(() => {
    if (open) {
      fetchEmailLogList();
    }
  }, [filter, fetchEmailLogList, open]);

  useEffect(() => {
    if (opened) {
      openModal();
    }
  }, [openModal, opened]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Agency Submission Details</DialogTitle>

        <DialogContent>
          {loading ? (
            <Spinner />
          ) : (
            eoiDetails && (
              <>
                <Grid direction="column" columns={12} sx={{ minWidth: '500px' }}>
                  <Typography variant="h5">Agency</Typography>

                  <Grid item mt={1}>
                    <Stack>
                      <Typography>Agency Name:</Typography>
                      <Typography>
                        <b>{eoiDetails.agencyName}</b>
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item mt={1}>
                    <Stack>
                      <Typography>Affiliation:</Typography>
                      <Typography>
                        <b>{eoiDetails.affiliation}</b>
                      </Typography>
                    </Stack>
                  </Grid>

                  <Typography mt={2} variant="h5">
                    User
                  </Typography>

                  <Grid item mt={1}>
                    <Stack>
                      <Typography>Name:</Typography>
                      <Typography>
                        <b>{eoiDetails.name}</b>
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item mt={1} display="flex" direction="row">
                    <Stack>
                      <Typography>Email:</Typography>
                      {eoiDetails.customerId ? (
                        <Link href={`/users/${eoiDetails.customerId}`} target="_blank" rel="noreferrer">
                          <b>{eoiDetails.email}</b>
                        </Link>
                      ) : (
                        <Typography>
                          <b>{eoiDetails.email}</b>
                        </Typography>
                      )}
                    </Stack>
                    <Stack ml={2}>
                      <Typography>Phone:</Typography>
                      <Typography>
                        <b>{eoiDetails.phone}</b>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item mt={1} display="flex" direction="row">
                    <Stack>
                      <Typography>Submitted At:</Typography>
                      <Typography>
                        <b>{formatDateShortDD(eoiDetails.submittedAt)}</b>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item mt={1} display="flex" direction="row">
                    <Stack>
                      <Typography>Status:</Typography>
                      <Typography>
                        <b>{titleCase(eoiDetails.status)}</b>
                      </Typography>
                    </Stack>
                    {eoiDetails.onboardedAt && (
                      <Stack ml={2}>
                        <Typography>Onboarded At:</Typography>
                        <Typography>
                          <b>{formatDateShortDD(eoiDetails.onboardedAt)}</b>
                        </Typography>
                      </Stack>
                    )}
                    {(eoiDetails.status === EoiStatus.Approved || eoiDetails.status === EoiStatus.Onboarded) && (
                      <Stack ml={2}>
                        <Typography>Approved At: </Typography>
                        <Typography>
                          <b>{eoiDetails.approvedAt ? formatDateShortDD(eoiDetails.approvedAt) : '-'}</b>
                        </Typography>
                      </Stack>
                    )}
                    {eoiDetails.status === EoiStatus.Rejected && (
                      <Stack ml={2}>
                        <Typography>Rejected At: </Typography>
                        <Typography>
                          <b>{eoiDetails.rejectedAt ? formatDateShortDD(eoiDetails.rejectedAt) : '-'}</b>
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                  {[EoiStatus.Pending, EoiStatus.Rejected].includes(eoiDetails.status) && (
                    <Grid item mt={2} display="flex" justifyContent={'space-between'} direction="row">
                      <Button variant="contained" onClick={() => updateSubmission('approved')}>
                        Approve
                      </Button>

                      {eoiDetails.status !== EoiStatus.Rejected && (
                        <Button variant="contained" color="error" onClick={() => updateSubmission('rejected')}>
                          Reject
                        </Button>
                      )}
                    </Grid>
                  )}
                  {[EoiStatus.Approved, EoiStatus.Onboarded].includes(eoiDetails.status) && (
                    <Grid item mt={2}>
                      <DataGrid
                        rows={result}
                        rowCount={total}
                        columns={columns}
                        loading={loading}
                        pagination
                        paginationMode="server"
                        paginationModel={{ page: filter.page - 1, pageSize: SIZE_PER_PAGE }}
                        pageSizeOptions={[SIZE_PER_PAGE]}
                        onPaginationModelChange={({ page }) => setFilter({ page: page + 1 })}
                        slots={{ pagination: GridPagination }}
                        getRowId={(row) => row.sentAt}
                        disableColumnFilter
                        disableColumnMenu
                        disableRowSelectionOnClick
                        autoHeight
                      />

                      <Grid item mt={2} display="flex" justifyContent={'space-between'} direction="row">
                        <Button variant="contained" onClick={() => fetchResendActivationEmail()}>
                          Re-send Activation Email
                        </Button>

                        {eoiDetails?.agentId &&
                          ![EoiStatus.Onboarded, EoiStatus.Rejected].includes(eoiDetails.status) && (
                            <Button variant="contained" color="primary" onClick={copyOnboardLink}>
                              Onboard Link
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={closeModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
