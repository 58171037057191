import React, { useCallback, useState } from 'react';

import { Control, useController } from 'react-hook-form';
import { MarkdownPreview } from 'react-marked-markdown';

import { Box, Button, Card, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material';

import { RichTextEditor } from '~/components/Common/Forms/fields';

interface Props {
  helperText?: string;
  offer: Control<any>;
  propertyContext: any;
  label: string;
  field: string;
  canUseBrandTokens?: boolean;
}

const OverridableRichText = ({ offer, helperText, label, field, propertyContext, canUseBrandTokens }: Props) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [showPropertyData, setShowPropertyData] = useState(false);
  const {
    field: { value, onChange },
  } = useController({
    control: offer,
    name: field,
  });
  const propertyContent =
    propertyContext && propertyContext[field] && propertyContext[field] !== '' ? propertyContext[field] : null;
  const hasOfferContent = value && value !== '';
  const hasPropertyFieldButNotOfferField = propertyContent && !hasOfferContent;

  const goToEditMode = useCallback(() => {
    onChange(propertyContent);
    setIsInEditMode(true);
  }, [onChange, propertyContent]);

  const shouldShowEditor = isInEditMode || hasOfferContent || !propertyContent;
  return (
    <>
      {shouldShowEditor && (
        <Stack direction="column">
          <RichTextEditor
            control={offer}
            helperText={helperText}
            label={label}
            name={field}
            canUseBrandTokens={canUseBrandTokens}
          />
          {propertyContent && (
            <Card variant="outlined">
              <CardHeader
                title={`Field ${label} from property`}
                titleTypographyProps={{ variant: 'h6' }}
                action={
                  <Button onClick={() => setShowPropertyData(!showPropertyData)}>
                    {showPropertyData ? 'Hide' : 'Show'}
                  </Button>
                }
              />
              {showPropertyData && (
                <CardContent sx={{ backgroundColor: 'grey.200' }}>
                  <MarkdownPreview value={propertyContent} disabled />
                </CardContent>
              )}
            </Card>
          )}
        </Stack>
      )}
      {!shouldShowEditor && (
        <Box sx={{ width: '100%' }}>
          <Stack direction="column">
            <Typography>{label}</Typography>
            <Card variant="outlined">
              <CardContent>
                <MarkdownPreview value={propertyContent} />
              </CardContent>
              {hasPropertyFieldButNotOfferField && (
                <CardActions>
                  <Button variant="outlined" color="secondary" onClick={goToEditMode}>
                    Override {label}
                  </Button>
                </CardActions>
              )}
            </Card>
          </Stack>
        </Box>
      )}
    </>
  );
};
export default OverridableRichText;
