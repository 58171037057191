import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { OFFER_TYPE_TACTICAL_AO_HOTEL } from '~/consts/offerTypes';

export enum ManifestStatuses {
  BY_MAPPING,
  BY_TYPE_ORDER,
}

interface ManifestPackagesModalProps {
  onClose: () => void;
  onSave: (manifestType) => void;
  showModal: boolean;
  offerType: string;
}

export function ManifestPackagesModal({ onClose, onSave, showModal, offerType }: ManifestPackagesModalProps) {
  const [manifestType, setManifestType] = useState<ManifestStatuses>(ManifestStatuses.BY_MAPPING);

  const onOK = () => {
    onSave(manifestType);
  };

  return (
    <Dialog open={showModal} onClose={onClose}>
      <DialogTitle>Confirm to autofill Offer Package</DialogTitle>
      <DialogContent>
        <div className="form-group">
          <p>
            <strong>Please choose autocomplete method</strong>
          </p>
          <label className="radio-inline">
            <input
              name="manifest-prompt"
              type="radio"
              checked={manifestType === ManifestStatuses.BY_MAPPING}
              onChange={() => setManifestType(ManifestStatuses.BY_MAPPING)}
            />
            Add by deal option name mapping
          </label>
          <br />
          <label className="radio-inline">
            <input
              name="manifest-prompt"
              type="radio"
              checked={manifestType === ManifestStatuses.BY_TYPE_ORDER}
              onChange={() => setManifestType(ManifestStatuses.BY_TYPE_ORDER)}
              disabled={offerType !== OFFER_TYPE_TACTICAL_AO_HOTEL}
            />
            Add by room order from property
            {offerType !== OFFER_TYPE_TACTICAL_AO_HOTEL && ' (applicable only for LPC)'}
          </label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onOK}>
          OK
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
