import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Container, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import VimeoWidget from '~/components/Common/Forms/widgets/VimeoWidget';
import Spinner from '~/components/Common/Spinner';

import { OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE_HOTEL, OFFER_TYPE_TACTICAL_AO_HOTEL } from '~/consts/offerTypes';

import OffersService from '~/services/OffersService';

import featureToggle from '~/utils/featureToggle';
import { getHotelUniquePropertyDetails } from '~/utils/hotelUtils';
import { reportError } from '~/utils/reportError';

interface RouteParams {
  id_offer: string;
}

export default function VideosEditContainer() {
  const { id_offer: offerId } = useParams<RouteParams>();

  const [offerDetails, setOfferDetails] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState<App.Property>(null);
  const [leVimeoId, setLeVimeoId] = useState<string>(null);
  const [whitelabelVimeoId, setWhitelabelVimeoId] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isOverridableContent, setIsOverridableContent] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    try {
      const { result } = await OffersService.getOffer(offerId);
      setOfferDetails(result);
      setPropertyDetails(await getHotelUniquePropertyDetails(result));
      setLeVimeoId(result.videos.luxuryescapes.vimeo_id);
      setWhitelabelVimeoId(result.videos.whitelabel.vimeo_id);
      const overridableContent =
        featureToggle.availableToShow('ADDITIONAL_PROPERTY_CONTENT_ENABLED') &&
        [OFFER_TYPE_TACTICAL_AO_HOTEL, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE_HOTEL].includes(result.type);
      setIsOverridableContent(overridableContent);
      setIsLoading(false);
    } catch (err) {
      reportError(err);
    }
  };

  const saveData = async () => {
    setIsSubmitting(true);

    try {
      const { result } = await OffersService.updateOffer(
        {
          type: offerDetails.type,
          video_vimeo_id: leVimeoId || null,
          whitelabel_video_vimeo_id: whitelabelVimeoId || null,
        },
        offerId,
      );

      setOfferDetails(result);
      enqueueSnackbar(`Successfully saved videos`, { variant: 'success' });
    } catch (err) {
      reportError(err);
      enqueueSnackbar(`"Failed to save videos`, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [offerId]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Offers | {offerDetails?.name || offerId} | Edit Offer Videos</title>
      </Helmet>
      <Button variant="outlined" component={Link} to={'/offers/' + offerId}>
        <ChevronLeftIcon /> Return to offer
      </Button>
      <PageHeader title="Edit Offer Videos" />
      {!isLoading && (
        <>
          <Typography variant="h5">Vimeo video (Luxury Escapes)</Typography>
          <VimeoWidget value={leVimeoId} onChange={setLeVimeoId} />
          <Typography variant="h5">Vimeo video (Whitelabelled)</Typography>
          <VimeoWidget value={whitelabelVimeoId} onChange={setWhitelabelVimeoId} />
          <Button sx={{ width: 100 }} onClick={saveData} variant="contained" disabled={isSubmitting}>
            {isSubmitting ? <Spinner size={20} /> : 'Save'}
          </Button>
          {isOverridableContent && (
            <>
              <PageSubheader title="Property Videos" />
              {propertyDetails && (
                <>
                  <Typography variant="h5">Vimeo video (Luxury Escapes)</Typography>
                  {propertyDetails?.videos?.luxuryescapes?.vimeo_id && (
                    <>
                      <VimeoWidget
                        value={propertyDetails.videos.luxuryescapes.vimeo_id}
                        onChange={setLeVimeoId}
                        readOnly={true}
                      />
                      <Button onClick={() => setLeVimeoId(propertyDetails.videos.luxuryescapes.vimeo_id)}>Copy</Button>
                    </>
                  )}
                  {!propertyDetails?.videos?.luxuryescapes?.vimeo_id && (
                    <Typography> No property video found for Luxury Escapes</Typography>
                  )}

                  <Typography variant="h5">Vimeo video (Whitelabelled)</Typography>
                  {propertyDetails?.videos?.whitelabel?.vimeo_id && (
                    <>
                      <VimeoWidget
                        value={propertyDetails.videos.whitelabel.vimeo_id}
                        onChange={setLeVimeoId}
                        readOnly={true}
                      />
                      <Button onClick={() => setWhitelabelVimeoId(propertyDetails.videos.whitelabel.vimeo_id)}>
                        Copy
                      </Button>
                    </>
                  )}
                  {!propertyDetails?.videos?.whitelabel?.vimeo_id && (
                    <Typography> No property video found for Whitelabelled</Typography>
                  )}
                </>
              )}
              {!propertyDetails && <Typography> No property has been linked to the offer yet!</Typography>}
            </>
          )}
        </>
      )}
      {isLoading && <Spinner />}
    </Container>
  );
}
