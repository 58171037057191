import React from 'react';

import isFinite from 'lodash/isFinite';
import toNumber from 'lodash/toNumber';
import Form from 'react-jsonschema-form';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '@mui/material';

import VendorsService from '../../../services/VendorsService';
import Spinner from '../../Common/Spinner';
import VendorBookingPaymentsListContainer from '../OfferDetail/VendorBookingPayments/VendorBookingPaymentsListContainer';

const uiSchema = {
  fk_offer: { 'ui:widget': 'hidden' },
  fk_vendor: { 'ui:widget': 'hidden' },
  fk_added_by: { 'ui:widget': 'hidden' },
  cost_currency: {
    'ui:widget': 'select',
    'ui:title': 'Currency in which Vendor was paid',
  },
  date_of_payment: {
    'ui:placeholder': '31/12/2017',
    'ui:widget': 'date',
    'ui:title': 'Date of Payments',
  },
  booking_number: {
    'ui:title': 'Booking Number',
  },
  adjustment: {
    'ui:title': 'Adjustment',
  },
  adjustment_reason: {
    'ui:title': 'Adjustment Reason',
  },
};

export default class VendorBookingPaymentsContainer extends React.Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.paymentData = {
      fk_offer: match.params.id_offer,
      fk_vendor: match.params.id_vendor,
    };

    this.state = {
      formSchema: null,
      alertError: false,
      lastUpdate: null,
    };
  }

  validateForm(formData, errors) {
    let adjustment = toNumber(formData.adjustment);

    if (!isFinite(adjustment)) {
      errors.adjustment.addError('should be a number');
    }

    return errors;
  }

  removeCustomFormats(schema) {
    for (let prop in schema.properties) {
      if (schema.properties[prop].format !== undefined) {
        if (schema.properties[prop].format == 'numbersOnly') {
          delete schema.properties[prop].format;
        }
      }
    }

    return schema;
  }

  componentDidMount() {
    this.updateFormSchema();
  }

  updateFormSchema = () => {
    const { match } = this.props;

    VendorsService.getPaymentsByOfferBookingSchema(match.params.id_offer, match.params.id_vendor)
      .then((formSchema) => {
        this.setState({
          formSchema: this.removeCustomFormats(formSchema.post.body),
        });
      })
      .catch((error) => {
        console.warn('Failed to get form schema payments', error);
      });
  };

  handleSubmit = async (event) => {
    const { match } = this.props;

    try {
      const { formData } = event;
      const payment = await VendorsService.logBookingVendorPayment({
        offerId: match.params.id_offer,
        vendorId: match.params.id_vendor,
        paymentDetails: formData,
      });
      this.setState({
        lastUpdate: payment.result.id_booking_payment,
      });
    } catch (err) {
      this.setState({ alertError: true });
    }
  };

  render() {
    let content = <Spinner />;
    const { match } = this.props;

    if (this.state.formSchema) {
      content = (
        <div>
          <Form
            schema={this.state.formSchema}
            uiSchema={uiSchema}
            onSubmit={this.handleSubmit}
            validate={this.validateForm}
            formData={this.paymentData}
          />
          <VendorBookingPaymentsListContainer
            id_offer={match.params.id_offer}
            id_vendor={match.params.id_vendor}
            last_update={this.state.lastUpdate}
          />
        </div>
      );
    }

    return (
      <div className="container">
        <Button component={Link} startIcon={<ChevronLeftIcon />} to={`/finance/offers/${match.params.id_offer}`}>
          Return to offer
        </Button>

        <br />
        <br />
        {content}
      </div>
    );
  }
}
