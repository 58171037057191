import React from 'react';

import { Alert, AlertTitle } from '@mui/material';

import CopyableField from '~/components/Common/CopyableField';

const statusCodeFormatter = (statusCode: number, errorCode?: string, errorMsg?: string) => {
  if (statusCode == 422 && !errorMsg) {
    return (
      <Alert severity="info">
        <AlertTitle>Corporate Promo - [Verify your email popup]</AlertTitle>
      </Alert>
    );
  }
  if (statusCode > 300) {
    return (
      <Alert severity="warning">
        <AlertTitle>
          {errorMsg}
          <CopyableField value={errorCode} label={`[${errorCode}]`} />
        </AlertTitle>
      </Alert>
    );
  }
  return (
    <Alert severity="success">
      <AlertTitle>{statusCode}</AlertTitle>
    </Alert>
  );
};

export default statusCodeFormatter;
