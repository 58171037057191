import React from 'react';

import { List, ListItem, Typography } from '@mui/material';

import { PromoMeta } from '~/services/PromoService';

type PromoReplacementExplainerProp = {
  promoTextReplacements: PromoMeta['promo_text_replacements'];
};

function PromoReplacementExplainer({ promoTextReplacements }: PromoReplacementExplainerProp) {
  return (
    <Typography variant="caption">
      <details>
        <summary>🚧 (Promo Replacement Field) 🚧</summary>Replacements tags included in this fields will be replaced
        with the dynamic values from the promo code.
        <br />
        Replacements tags:
        <List>
          {promoTextReplacements?.length > 0
            ? promoTextReplacements.map((ptr) => <ListItem key={ptr.name}>{`<${ptr.name}>`}</ListItem>)
            : '(loading)'}
        </List>
      </details>
    </Typography>
  );
}

export { PromoReplacementExplainer };
