export const STEP_SELECT_TYPE_OF_REFUND = 'select_type_of_refund';
export const STEP_PREREFUND_NIB_INSURANCE = 'prerefund_nib_insurance';
export const STEP_LATE_INSURANCE_REFUND = 'late_insurance_refund';
export const STEP_ADDITIONAL_INFO = 'additional_info';
export const STEP_SUMMARY = 'summary';
export const STEP_BEDBANK_AUTOMATIC_REFUND = 'bedbank_automatic_refund';
export const STEP_TOUR_AUTOMATIC_REFUND = 'tour_automatic_refund';
export const STEP_CONNECTION_AUTOMATIC_REFUND = 'connection_automatic_refund';
export const STEP_VENDOR_EMAIL_REMINDER = 'ttc_vendor_email_reminder';
export const ALL_STEPS = [
  STEP_SELECT_TYPE_OF_REFUND,
  STEP_PREREFUND_NIB_INSURANCE,
  STEP_LATE_INSURANCE_REFUND,
  STEP_ADDITIONAL_INFO,
  STEP_SUMMARY,
  STEP_BEDBANK_AUTOMATIC_REFUND,
  STEP_TOUR_AUTOMATIC_REFUND,
  STEP_CONNECTION_AUTOMATIC_REFUND,
  STEP_VENDOR_EMAIL_REMINDER,
];
export const CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD = 'Change of Mind Within Refund Guarantee Period';
export const PACKAGE_AMENDMENT = 'Package Amendment';
export const MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION =
  'Misleading Content, Incorrect Pricing & Customer Retention';
export const LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS =
  'Late Change of Mind, Bad Experience or Unfortunate Events';
export const LATE_CANCEL_GOODWILL = 'Late Cancel - Goodwill';
export const OPERATOR_CANCELLATION = 'Operator Cancellation';
export const COVID_REASON = 'Covid 19';
export const FRAUD_AND_COMPLIANCE_AND_DISPUTE = 'Fraud, Compliance and Dispute';
export const FAILED_REFUND_FROM_PAYMENT_GATEWAYS = 'Failed refund from Payment Gateways';
export const COVID_19 = 'Covid 19';
export const BEDBANK_AUTOMATIC_REFUND = 'Bedbank automatic refund';
export const TOUR_AUTOMATIC_REFUND = 'Tour automatic refund';
export const AO_AUTOMATIC_REFUND = 'AO automatic refund';
export const RENTAL_AUTOMATIC_REFUND = 'Rental automatic refund';
export const REFUND_TEST_PURCHASE = 'Refund test purchase';
export const EXPIRED_BNBL_PACKAGE = 'Expired BNBL package';
export const INSURANCE_BREAKAGE = 'Insurance Breakage';

export const VARIANT_OF_REFUND_ALL = 'all';
export const VARIANT_OF_REFUND_PARTIAL = 'partial';
export const VARIANT_OF_REFUND_PARTIAL_CALCULATED = 'partial_calculated';
export const VARIANT_OF_REFUND_SURCHARGE = 'surcharge';
export const VARIANT_OF_REFUND_NONE = 'none';

export const VENDOR_REFUND_AGREES_NUMBER = 'number';
export const VENDOR_REFUND_AGREES_PERCENTAGE = 'percentage';

export const REFUND_METHOD_DEFAULT = 'Back To Original';
export const REFUND_METHOD_CREDITS = 'LE Credits';
export const REFUND_METHOD_MANUAL = 'Manual';

export const SERVICE_FEE_ALLOWED_TYPES = [
  'PACKAGE_AMENDMENT',
  'MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION',
];

export const EXPIRED_TOUR_REFUND_ALLOWED_TYPES = [
  'LATE_CANCEL_GOODWILL',
  'OPERATOR_CANCELLATION',
  'LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS',
];

export const EXPIRED_ONLY_TOUR_REFUND_ALLOWED_TYPES = ['LATE_CANCEL_GOODWILL', 'OPERATOR_CANCELLATION'];
export const TNFH_REASON_SOURCE = 'Luxury Escapes (TNFH)';
const DEFAULT_REASON_SOURCE = 'Default';

const collectionReasons = {
  CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD: {
    key: CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD,
    hidden: false,
    description: 'Customer wants to cancel within 7 days for a hotel or 48 hours for tour.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  PACKAGE_AMENDMENT: {
    key: PACKAGE_AMENDMENT,
    hidden: false,
    description: 'Customer wants to upgrade, downgrade or change to dates with different price.',
    can_select_variant: true,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION: {
    key: MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION,
    hidden: false,
    description: 'Customer happiness at LE expense.',
    can_select_variant: true,
    defaults: {
      source: TNFH_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS: {
    key: LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
    hidden: false,
    description:
      'Customer wants to cancel after 7 days. LE and vendor share responsibility. Management approval required.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  LATE_CANCEL_GOODWILL: {
    key: LATE_CANCEL_GOODWILL,
    hidden: false,
    description:
      'The customer wants to cancel their booking after the final payment has been made. Customer may be entitled to a full refund. LE to bear the cost.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  OPERATOR_CANCELLATION: {
    key: OPERATOR_CANCELLATION,
    hidden: false,
    description:
      'All or part of the tour has been cancelled by the operator. Customer is entitled to a full or partial refund. DMC to bear the cost.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  FRAUD_AND_COMPLIANCE_AND_DISPUTE: {
    key: FRAUD_AND_COMPLIANCE_AND_DISPUTE,
    hidden: false,
    description: 'Credit Card Fraud, fair trading and consumer affair complaint, paypal dispute.',
    can_select_variant: false,
    defaults: {
      source: null,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  FAILED_REFUND_FROM_PAYMENT_GATEWAYS: {
    key: FAILED_REFUND_FROM_PAYMENT_GATEWAYS,
    hidden: false,
    description: 'Use this refund type only when the refund needs to be manually processed from the payment gateway.',
    can_select_variant: true,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_MANUAL],
    },
  },
  COVID_19: {
    key: COVID_19,
    hidden: false,
    description: 'Refund related to Covid 19',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      comment: 'Refund related to Covid 19',
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  BEDBANK_AUTOMATIC_REFUND: {
    key: BEDBANK_AUTOMATIC_REFUND,
    hidden: true,
    description: 'Automatic refund',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      comment: 'Automatic refund',
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS],
    },
  },
  TOUR_AUTOMATIC_REFUND: {
    key: TOUR_AUTOMATIC_REFUND,
    hidden: true,
    description: 'Automatic refund',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      comment: 'Automatic refund',
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS],
    },
  },
  AO_AUTOMATIC_REFUND: {
    key: AO_AUTOMATIC_REFUND,
    hidden: true,
    description: 'Automatic refund',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      comment: 'Automatic refund',
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS],
    },
  },
  RENTAL_AUTOMATIC_REFUND: {
    key: RENTAL_AUTOMATIC_REFUND,
    hidden: true,
    description: 'Automatic refund',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      comment: 'Automatic refund',
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS],
    },
  },
  REFUND_TEST_PURCHASE: {
    key: REFUND_TEST_PURCHASE,
    hidden: false,
    description: 'Should only be used to refund test purchases made for the purpose of testing the setup of an offer',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT],
    },
  },
  EXPIRED_BNBL_PACKAGE: {
    key: EXPIRED_BNBL_PACKAGE,
    hidden: false,
    description:
      "Only to be used in circumstances where a customer with a 'buy now, book later’ package has not selected dates within the book by date",
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: null,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_CREDITS],
    },
  },
  INSURANCE_BREAKAGE: {
    key: INSURANCE_BREAKAGE,
    label: 'Insurance letter',
    hidden: false,
    description:
      'Customer wishes to file an insurance claim or has been paid out by insurance. Order will be cancelled and customer will not receive a refund from LE',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: false,
      mark_cancelled: true,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      comment: 'Insurance breakage',
      methods: [REFUND_METHOD_MANUAL],
    },
  },
};

export const COLLECTION_REASONS = collectionReasons;

export const CH_WITHIN_CANCELLATION_PERIOD = 'Within cancellation period';
export const CH_OUTSIDE_CANCELLATION_PERIOD = 'Outside of cancellation period';
export const CH_GOODWILL = 'Goodwill';

export const CAR_HIRE_REASONS = {
  CH_WITHIN_CANCELLATION_PERIOD: {
    key: CH_WITHIN_CANCELLATION_PERIOD,
    hidden: false,
    description:
      'The customer wants to cancel thei booking within the cancellation period. Refund should be provided to customer. No additional charge.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: null,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT],
    },
  },
  CH_OUTSIDE_CANCELLATION_PERIOD: {
    key: CH_OUTSIDE_CANCELLATION_PERIOD,
    hidden: false,
    description:
      'Customer wants to cancel their booking outside of cancellation period. The booking can be cancelled but no refund provided.',
    can_select_variant: false,
    defaults: {
      source: TNFH_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_NONE,
      is_refunded_surcharge: null,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT],
    },
  },
  CH_GOODWILL: {
    key: CH_GOODWILL,
    hidden: false,
    description:
      'Customer wants to cancel their booking outside of cancellation period and goodwill has been agreed to be provided. LE will bear the cost.',
    can_select_variant: true,
    defaults: {
      source: TNFH_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: null,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
};

export const SUBSCRIPTION_REASONS = [
  COLLECTION_REASONS.MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION,
  COLLECTION_REASONS.FRAUD_AND_COMPLIANCE_AND_DISPUTE,
  COLLECTION_REASONS.FAILED_REFUND_FROM_PAYMENT_GATEWAYS,
];
