import { BookingRequest } from '../types/types';

import { typeFormatter } from './formatters';

export const mapOrderDatesToBookingRequest = (orderDates: Array<any>): Array<BookingRequest> => {
  const statusMap = {
    pending: 'warning',
    approved: 'success',
    rejected: 'error',
    customer_booked: 'success',
    customer_cancelled: 'error',
  };
  const textMap = {
    pending: 'Request sent',
    approved: 'Request approved',
    rejected: 'Request rejected',
    customer_booked: 'Customer booked',
    customer_cancelled: 'Customer cancelled',
  };

  return orderDates.map((orderDate) => ({
    type: typeFormatter(orderDate.type),
    id: orderDate.id_dates_request,
    created_at: orderDate.created_at,
    updated_at: orderDate.updated_at,
    statusText: textMap[orderDate.status],
    statusSeverity: statusMap[orderDate.status],
  }));
};
