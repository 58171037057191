import contentDisposition from 'content-disposition';
import qs from 'qs';
import fileDownload from 'react-file-download';
import { v4 as uuid } from 'uuid';

import ApiError from '~/types/ApiError';

import { json_headers, request } from './common';

export default class VendorsService {
  static basePath() {
    return window.configs.API_HOST + '/api/vendor';
  }

  static getVendorById(id) {
    return request(this.basePath() + '/' + id, { method: 'GET' });
  }

  static getVendorByName(filter, limit = 10, page = 1) {
    const queryParams = {
      vendor_name: filter,
      per_page: limit,
      page,
    };

    return request(`${this.basePath()}?${qs.stringify(queryParams)}`);
  }

  static getVendorsByIds(ids, limit = 10) {
    const queryParams = {
      vendor_id: ids,
      per_page: limit,
    };
    return request(`${this.basePath()}?${qs.stringify(queryParams)}`);
  }

  static getVendorsByChainId(parentid) {
    const queryParams = {
      parent_id: parentid,
    };
    return request(`${this.basePath()}/chain?${qs.stringify(queryParams)}`);
  }

  static getUpdateSchema(data) {
    return request(`${this.basePath()}/${data.sfid}`, { method: 'OPTIONS' });
  }

  static getOfferPayment(idOfferPayment) {
    return request(`${this.basePath()}/offer-payments/${idOfferPayment}`);
  }

  static getPaymentsByOfferVendor(idOffer, idVendor) {
    return request(`${this.basePath()}/offer-payments/${idOffer}/${idVendor}`, { method: 'GET' });
  }

  static getPaymentsByOfferVendorSchema() {
    return request(`${this.basePath()}/offer-payments`, {
      method: 'OPTIONS',
    });
  }

  static getPaymentsByBookingVendor(idOffer, idVendor, searchString, limit = 10) {
    const queryParams = {
      limit,
    };

    if (searchString) {
      queryParams['q'] = searchString;
    }

    let url = `${window.configs.API_HOST}/api/vendor/booking-payments/${idOffer}/${idVendor}?${qs.stringify(
      queryParams,
    )}`;

    return request(url, { method: 'GET' });
  }

  static async downloadPaymentsByBookingVendor({ idOffer, idVendor, format }) {
    const queryParams = {
      format: format,
    };

    const response = await fetch(
      `${this.basePath()}/booking-payments/${idOffer}/${idVendor}?${qs.stringify(queryParams)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

    if (!response.ok) {
      if (response.status === 403) {
        throw new ApiError({
          message: "You don't have the permissions for this operation",
          name: 'PermissionError',
        });
      } else if (response.status === 500) {
        throw new ApiError({
          message: '',
          name: 'Empty data set',
        });
      } else if (response.status === 404) {
        const errorResponse = await response.json();
        throw new ApiError({
          message: errorResponse.message,
          name: 'Empty data set',
        });
      } else {
        throw new ApiError({
          message: `Network error received: ${response.status}`,
          name: 'NetworkError',
        });
      }
    }
    const body = await response.blob();
    let fileName = `VendorBookingPayments_${idOffer}_${idVendor}`;
    if (format === 'xlsx') {
      fileName = `${fileName}.xlsx`;
    } else {
      fileName = `${fileName}.json`;
    }

    await fileDownload(body, fileName);
  }

  static async downloadPaymentsByOfferVendor({ idOffer, idVendor, format }) {
    const queryParams = {
      format: format,
    };

    const response = await fetch(
      `${this.basePath()}/offer-payments/${idOffer}/${idVendor}?${qs.stringify(queryParams)}`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

    if (!response.ok) {
      if (response.status === 403) {
        throw new ApiError({
          message: "You don't have the permissions for this operation",
          name: 'PermissionError',
        });
      } else if (response.status === 500) {
        throw new ApiError({
          message: '',
          name: 'Empty data set',
        });
      } else if (response.status === 404) {
        const errorResponse = await response.json();
        throw new ApiError({
          message: errorResponse.message,
          name: 'Empty data set',
        });
      } else {
        throw new ApiError({
          message: `Network error received: ${response.status}`,
          name: 'NetworkError',
        });
      }
    }
    const body = await response.blob();
    let fileName = `VendorOfferPayments_${idOffer}_${idVendor}`;
    if (format === 'xlsx') {
      fileName = `${fileName}.xlsx`;
    } else {
      fileName = `${fileName}.json`;
    }

    await fileDownload(body, fileName);
  }

  static getPotentialRemittance(dateOfPayment, page = 1, limit = 200) {
    const queryParams = {
      page,
      limit,
    };

    if (dateOfPayment) {
      queryParams['date_of_payment'] = dateOfPayment;
    }

    let url = `${window.configs.API_HOST}/api/vendor/booking-payments/remittance?${qs.stringify(queryParams)}`;

    return request(url, { method: 'GET' });
  }

  static getRemittance(dateOfPayment, page = 1, limit = 200) {
    const queryParams = {
      page,
      limit,
    };

    if (dateOfPayment) {
      queryParams['date_of_payment'] = dateOfPayment;
    }

    let url = `${window.configs.API_HOST}/api/vendor/remittance?${qs.stringify(queryParams)}`;

    return request(url, { method: 'GET' });
  }

  static async getAllRemittance(dateOfPayment, emailSent, page = 1, limit = 200) {
    const remittanceResponse = await this.getRemittance(dateOfPayment, page, limit);

    let potentialRemittance = [];

    // If looking for remittance without sent emails we need drafts
    if (emailSent !== true) {
      const potentialRemittanceResponse = await this.getPotentialRemittance(dateOfPayment, page, limit);
      potentialRemittance = potentialRemittanceResponse.result;
    }
    return {
      paginatedRemittance: remittanceResponse.result,
      paginatedPotentialRemittance: potentialRemittance,
    };
  }

  static async deleteRemittance(id) {
    return request(`${this.basePath()}/remittance/${id}`, {
      method: 'DELETE',
    });
  }

  // we use holdback-adjustments endpoint for error payments
  static async getErrorPaymentsSchema() {
    return request(`${this.basePath()}/holdback-adjustments`, {
      method: 'OPTIONS',
    });
  }

  // we use holdback-adjustments endpoint for error payments
  static async createErrorPayment(errorPayment) {
    return request(`${this.basePath()}/holdback-adjustments`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(errorPayment),
    });
  }

  // we use holdback-adjustments endpoint for error payments
  static async updateErrorPayment(errorPayment) {
    return request(`${this.basePath()}/holdback-adjustments`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(errorPayment),
    });
  }

  // we use holdback-adjustments endpoint for error payments
  static async deleteErrorPayment(errorPayment) {
    return request(`${this.basePath()}/holdback-adjustments`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(errorPayment),
    });
  }

  // we use holdback-adjustments endpoint for error payments
  static async getAllErrorPayments({ vendorId, offerId }) {
    const queryParams = { vendor_id: vendorId, offer_id: offerId };
    return request(`${this.basePath()}/holdback-adjustments?${qs.stringify(queryParams)}`);
  }

  static async getManualPaymentsSchema() {
    return request(`${this.basePath()}/manual-payments`, {
      method: 'OPTIONS',
    });
  }

  static async createManualPayment(manualPayment) {
    return request(`${this.basePath()}/manual-payments`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(manualPayment),
    });
  }

  static async updateManualPayment(manualPayment) {
    return request(`${this.basePath()}/manual-payments`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(manualPayment),
    });
  }

  static async deleteManualPayment(manualPayment) {
    return request(`${this.basePath()}/manual-payments`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(manualPayment),
    });
  }

  static async getAllManualPayments({ vendorId, offerId }) {
    const queryParams = { vendor_id: vendorId, offer_id: offerId };
    return request(`${this.basePath()}/manual-payments?${qs.stringify(queryParams)}`);
  }

  static getPaymentsByOfferBookingSchema(offerId, vendorId) {
    return request(`${this.basePath()}/booking-payments/${offerId}/${vendorId}`, {
      method: 'OPTIONS',
    });
  }

  static async updateOfferVendorPayment({ idOfferPayment, paymentDetails }) {
    return request(`${this.basePath()}/offer-payments/${idOfferPayment}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(paymentDetails),
    });
  }

  static async logOfferVendorPayment({ paymentDetails }) {
    return request(`${this.basePath()}/offer-payments`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(paymentDetails),
    });
  }

  static async logBookingVendorPayment({ offerId, vendorId, paymentDetails }) {
    return request(`${this.basePath()}/booking-payments/${offerId}/${vendorId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(paymentDetails),
    });
  }

  static async checkUploadJobStatus(jobId) {
    return request(`${this.basePath()}/jobs/file-upload/${jobId}`);
  }

  static async uploadBookingPayments(data) {
    return request(`${this.basePath()}/upload-booking-payments`, {
      method: 'POST',
      body: data,
    });
  }

  static async uploadOfferPayments(data) {
    return request(`${this.basePath()}/upload-offer-payments`, {
      method: 'POST',
      body: data,
    });
  }

  static async uploadExpediaPayments(data) {
    return request(`${this.basePath()}/upload-expedia-payments`, {
      method: 'POST',
      body: data,
    });
  }

  static async uploadBedbankPayments(data) {
    return request(`${this.basePath()}/upload-bedbank-payments`, {
      method: 'POST',
      body: data,
    });
  }

  static async uploadExpediaMarketingFees(data) {
    return request(`${this.basePath()}/upload-expedia-marketing-fees`, {
      method: 'POST',
      body: data,
    });
  }

  static async uploadTTCPayments(data) {
    return request(`${this.basePath()}/upload-ttc-payments`, {
      method: 'POST',
      body: data,
    });
  }

  static async uploadRevelexPayments(data) {
    return request(`${this.basePath()}/upload-revelex-payments`, {
      method: 'POST',
      body: data,
    });
  }

  static async uploadCitibankStatements(data) {
    return request(`${this.basePath()}/upload-citibank-payments`, {
      method: 'POST',
      body: data,
    });
  }

  static async createRemittances(remittances) {
    const data = remittances.map((remittance) => ({
      ...remittance,
      transaction_key: uuid(),
    }));

    return request(`${this.basePath()}/remittance`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ remittances: data }),
    });
  }

  static async downloadRemittance(id, type = 'pdf') {
    const queryParams = {
      type,
    };

    const response = await fetch(
      `${window.configs.API_HOST}/api/vendor/remittance/${id}/download?${qs.stringify(queryParams)}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      },
    );

    const body = await response.blob();

    const fileNameHeader = contentDisposition.parse(response.headers.get('content-disposition'));

    await fileDownload(body, fileNameHeader.parameters.filename);
  }

  static async emailRemittance(remittanceIds) {
    return request(`${this.basePath()}/remittance/email`, {
      body: JSON.stringify({
        remittanceIds,
      }),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      ignoreResponse: true,
      method: 'POST',
    });
  }

  static async downloadABAFile(dateOfPayment) {
    const queryParams = {
      date_of_payment: dateOfPayment,
    };

    const response = await fetch(`${window.configs.API_HOST}/api/vendor/aba-file?${qs.stringify(queryParams)}`, {
      credentials: 'include',
    });

    const body = await response.text();

    const fileNameHeader = contentDisposition.parse(response.headers.get('content-disposition'));

    await fileDownload(body, fileNameHeader.parameters.filename);
  }

  static async downloadIMTFile(dateOfPayment, fromCurrency, toCurrency) {
    const queryParams = {
      date_of_payment: dateOfPayment,
      from_currency: fromCurrency,
      to_currency: toCurrency,
    };

    const response = await fetch(
      `${window.configs.API_HOST}/api/vendor/international-payments?${qs.stringify(queryParams)}`,
      {
        credentials: 'include',
      },
    );

    const body = await response.text();

    const fileNameHeader = contentDisposition.parse(response.headers.get('content-disposition'));

    await fileDownload(body, fileNameHeader.parameters.filename);
  }

  static async downloadVCCFile(dateOfPayment, paymentMethod, contractsGeo, fileFormat) {
    const queryParams = {
      date_of_payment: dateOfPayment,
      payment_method: paymentMethod,
      contracts_geo: contractsGeo,
      format: fileFormat,
    };
    const response = await fetch(`${window.configs.API_HOST}/api/vendor/vcc-file?${qs.stringify(queryParams)}`, {
      credentials: 'include',
    });

    if (!response.ok) {
      if (response.status === 403) {
        throw new ApiError({
          message: "You don't have the permissions for this operation",
          name: 'PermissionError',
        });
      } else if (response.status === 500) {
        throw new ApiError({
          message: '',
          name: 'Vendor service error',
        });
      } else if (response.status === 404) {
        const errorResponse = await response.json();
        throw new ApiError({
          message: errorResponse.message,
          name: 'Not found',
        });
      } else {
        throw new ApiError({
          message: `Network error received: ${response.status}`,
          name: 'NetworkError',
        });
      }
    }

    const body = await response.blob();

    const fileNameHeader = contentDisposition.parse(response.headers.get('content-disposition'));

    await fileDownload(body, fileNameHeader.parameters.filename);
  }

  static async uploadFxRates(data) {
    return request(`${this.basePath()}/upload-fx-rates`, {
      method: 'POST',
      body: data,
    });
  }

  static async downloadFxRatesFile(effectiveDate) {
    const queryParams = {
      effective_date: effectiveDate,
    };

    const response = await fetch(`${window.configs.API_HOST}/api/vendor/fx-rates?${qs.stringify(queryParams)}`, {
      credentials: 'include',
    });

    const body = await response.text();

    const fileNameHeader = contentDisposition.parse(response.headers.get('content-disposition'));

    await fileDownload(body, fileNameHeader.parameters.filename);
  }

  static async downloadExpediaPaymentsReport({ startAt, endAt, dateField }) {
    const queryParams = {
      start_at: startAt,
      end_at: endAt,
      date_field: dateField,
      format: 'xlsx',
    };

    const response = await fetch(`${this.basePath()}/expedia-payments?${qs.stringify(queryParams)}`, {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      if (response.status === 403) {
        throw new ApiError({
          message: "You don't have the permissions for this operation",
          name: 'PermissionError',
        });
      } else if (response.status === 500) {
        throw new ApiError({
          message: '',
          name: 'Empty data set',
        });
      } else if (response.status === 404) {
        const errorResponse = await response.json();
        throw new ApiError({
          message: errorResponse.message,
          name: 'Empty data set',
        });
      } else {
        throw new ApiError({
          message: `Network error received: ${response.status}`,
          name: 'NetworkError',
        });
      }
    }
    const body = await response.blob();

    const fileName = `ExpediaPayments_${dateField}_${startAt}_${endAt}.xlsx`;

    await fileDownload(body, fileName);
  }

  static async downloadExpediaMarketingFeesReport({ startAt, endAt, dateField }) {
    const queryParams = {
      start_at: startAt,
      end_at: endAt,
      date_field: dateField,
      format: 'xlsx',
    };

    const response = await fetch(`${this.basePath()}/expedia-marketing-fees?${qs.stringify(queryParams)}`, {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      if (response.status === 403) {
        throw new ApiError({
          message: "You don't have the permissions for this operation",
          name: 'PermissionError',
        });
      } else if (response.status === 500) {
        throw new ApiError({
          message: '',
          name: 'Empty data set',
        });
      } else if (response.status === 404) {
        const errorResponse = await response.json();
        throw new ApiError({
          message: errorResponse.message,
          name: 'Empty data set',
        });
      } else {
        throw new ApiError({
          message: `Network error received: ${response.status}`,
          name: 'NetworkError',
        });
      }
    }
    const body = await response.blob();

    const fileName = `ExpediaMarketingFees_${dateField}_${startAt}_${endAt}.xlsx`;

    await fileDownload(body, fileName);
  }

  static async downloadTTCPaymentsReport({ startAt, endAt }) {
    const queryParams = {
      start_at: startAt,
      end_at: endAt,
      format: 'xlsx',
    };

    const response = await fetch(`${this.basePath()}/ttc-payments?${qs.stringify(queryParams)}`, {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      if (response.status === 403) {
        throw new ApiError({
          message: "You don't have the permissions for this operation",
          name: 'PermissionError',
        });
      } else if (response.status === 500) {
        throw new ApiError({
          message: '',
          name: 'Empty data set',
        });
      } else if (response.status === 404) {
        const errorResponse = await response.json();
        throw new ApiError({
          message: errorResponse.message,
          name: 'Empty data set',
        });
      } else {
        throw new ApiError({
          message: `Network error received: ${response.status}`,
          name: 'NetworkError',
        });
      }
    }
    const body = await response.blob();

    const fileName = `TTC_payments_${startAt}_${endAt}.xlsx`;

    await fileDownload(body, fileName);
  }

  static async downloadRevelexPaymentsReport({ startAt, endAt }) {
    const queryParams = {
      start_at: startAt,
      end_at: endAt,
      format: 'csv',
    };

    const response = await fetch(`${this.basePath()}/revelex-payments?${qs.stringify(queryParams)}`, {
      method: 'GET',
      credentials: 'include',
    });

    if (!response.ok) {
      if (response.status === 403) {
        throw new ApiError({
          message: "You don't have the permissions for this operation",
          name: 'PermissionError',
        });
      } else if (response.status === 500) {
        throw new ApiError({
          message: '',
          name: 'Empty data set',
        });
      } else if (response.status === 404) {
        const errorResponse = await response.json();
        throw new ApiError({
          message: errorResponse.message,
          name: 'Empty data set',
        });
      } else {
        throw new ApiError({
          message: `Network error received: ${response.status}`,
          name: 'NetworkError',
        });
      }
    }
    const body = await response.blob();

    const fileName = `revelex_payments_${startAt}_${endAt}.csv`;

    await fileDownload(body, fileName);
  }

  static async uploadMusementPayments(data) {
    return request(`${this.basePath()}/upload-musement-payments`, {
      method: 'POST',
      body: data,
    });
  }

  static async sendPartnerCentralInvite(data) {
    return request(`${this.basePath()}/partner-central/invitation`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(data),
      credentials: 'include',
    });
  }

  static async downloadOfferPaymentFile(fileId) {
    const response = await fetch(`${window.configs.API_HOST}/api/vendor/offer-payments/files/${fileId}`, {
      method: 'GET',
      credentials: 'include',
    });

    const body = await response.blob();
    const fileNameHeader = contentDisposition.parse(response.headers.get('content-disposition'));

    await fileDownload(body, fileNameHeader.parameters.filename);
  }

  static async deleteOfferPaymentFile(fileId) {
    return request(`${this.basePath()}/offer-payments/files/${fileId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
  }

  static async uploadOfferPaymentFiles(id_offer_payment, files) {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });

    return request(`${this.basePath()}/offer-payments/files/${id_offer_payment}`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
  }

  static async getRelationsBySearchId(searchId) {
    const url = `${window.configs.API_HOST}/api/properties/relations?search_id=${searchId}`;
    return request(url, {
      method: 'GET',
      credentials: 'include',
    });
  }
}
