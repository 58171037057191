import React, { useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, FormControl, MenuItem, Select, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { SalesforceSurchargesModal } from '../SalesforceSurcharges/SalesforceSurchargesModal';

import { AllSurchargesViewModal } from './AllSurchargesViewModal';
import RoomTypeAddRatePlansModal from './RoomTypeAddRatePlansModal';

interface Props {
  roomTypes: Array<App.RoomType>;
  ratePlans: Array<App.RatePlan>;
  vendorId: string;
  propertyId: string;
  offers: Array<App.Offer>;
}

const mapLinkedOffers = (offer, roomType, filterOfferId) => {
  const havePackage = offer.packages.some((pkg) => pkg.fk_room_type_id === roomType.id);
  let hideOffer = false;

  if (offer.id_salesforce_external === filterOfferId || !filterOfferId) {
    hideOffer = true;
  }

  if (havePackage) {
    return { ...offer, hideOffer };
  }
};

const mapRoomTypes = (roomType, filterOfferId, linkedOffers) => {
  let hideRoom = false;

  if (filterOfferId && filterOfferId !== 'no_links') {
    hideRoom = !linkedOffers.some((offer) => offer.id_salesforce_external === filterOfferId);
  }

  if (filterOfferId === 'no_links') {
    hideRoom = linkedOffers.length > 0;
  }

  if (filterOfferId === 'is_packaged') {
    hideRoom = !roomType.room_rates.some((roomRate) => roomRate.rate_plan.is_packaged_rate);
  }

  return {
    ...roomType,
    linkedOffers,
    hideRoom,
  };
};

export default function RoomTypePane({ roomTypes, vendorId, propertyId, ratePlans, offers }: Props) {
  const [editing, setEditing] = useState(false);
  const [showAddSurchargesModal, setShowAddSurchargesModal] = useState(false);
  const [showAllSurchargesViewModal, setShowAllSurchargesViewModal] = useState(false);
  const [filterOfferId, setFilterOfferId] = useState(null);
  const [roomTypesWithOffers, setRoomTypesWithOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);

  useEffect(() => {
    const offersWithPackages = [];
    const mappedRoomTypes = roomTypes.map((roomType) => {
      const linkedOffers = offers.filter((offer) => {
        const linkedOffer = mapLinkedOffers(offer, roomType, filterOfferId);
        const isIncluded =
          linkedOffer &&
          offersWithPackages.some((offer) => offer.id_salesforce_external === linkedOffer.id_salesforce_external);
        if (linkedOffer && !isIncluded) {
          offersWithPackages.push(linkedOffer);
        }
        return linkedOffer;
      });

      return mapRoomTypes(roomType, filterOfferId, linkedOffers);
    });
    setRoomTypesWithOffers(mappedRoomTypes.filter((roomType) => !roomType.hideRoom));
    setFilteredOffers(offersWithPackages);
  }, [filterOfferId, roomTypes, offers]);
  const uniqueOffers = filteredOffers.filter(
    (value, index, array) => array.indexOf(value) === index || value.hideOffer,
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        flex: 2,
        headerName: 'Room name',
        sortable: false,
        display: 'flex',
      },
      {
        field: 'room_type_code',
        flex: 2,
        sortable: false,
        headerName: 'Room code',
        display: 'flex',
      },
      {
        field: 'offerName',
        flex: 2,
        headerName: 'Linked offers',
        sortable: false,
        display: 'flex',
        renderCell: (params) => {
          return (
            <ul>
              {params.row.linkedOffers.map((offer) => (
                <li key={offer.id_salesforce_external}>{offer.opportunity_name}</li>
              ))}
            </ul>
          );
        },
      },
      {
        field: 'offerId',
        flex: 2,
        headerName: 'Linked offer IDs',
        sortable: false,
        display: 'flex',
        renderCell: (params) => {
          return (
            <ul>
              {params.row.linkedOffers.map((offer) => (
                <li key={offer.id_salesforce_external}>{offer.id_salesforce_external}</li>
              ))}
            </ul>
          );
        },
      },
      {
        field: 'room_types_group.name',
        flex: 2,
        headerName: 'Room Group',
        sortable: false,
        display: 'flex',
        renderCell: (params) => (params.row.room_types_group ? params.row.room_types_group.name : ''),
      },
      {
        field: 'link',
        width: 250,
        headerName: '',
        sortable: false,
        align: 'right',
        display: 'flex',
        renderCell: (params) => {
          if (params.row.room_rates.length === 0) {
            return 'No room rates available.';
          }

          let defaultRoomRate = params.row.room_rates.find((room_rate) => room_rate.rate_plan.default_plan);

          if (!defaultRoomRate) {
            // Value doesn't matter. Just put something as a placeholder.
            defaultRoomRate = params.row.room_rates[0];
          }
          const { id } = defaultRoomRate;

          return (
            <Button
              variant="text"
              className="room-type-availability-preview-link"
              component={Link}
              to={`/vendors/${vendorId}/properties/${propertyId}/room-types/${params.row.id}/room-rates/${id}`}
            >
              Edit Availability and Rates
            </Button>
          );
        },
      },
    ],
    [vendorId, propertyId],
  );

  const toggleEditMode = () => {
    setEditing(!editing);
  };

  const toggleAddSurchargesModal = () => {
    setShowAddSurchargesModal(!showAddSurchargesModal);
  };

  const toggleAllSurchargesViewModal = () => {
    setShowAllSurchargesViewModal(!showAllSurchargesViewModal);
  };

  return (
    <>
      <PageSubheader title="Room types">
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
          <FormControl variant="standard">
            <Select
              sx={{ width: '150px' }}
              value={filterOfferId || ''}
              onChange={(e) => setFilterOfferId(e.target.value)}
              placeholder="Offer"
              displayEmpty
            >
              <MenuItem value="">No filters</MenuItem>
              <MenuItem value="no_links">Unlinked</MenuItem>
              <MenuItem value="is_packaged">Is Packaged</MenuItem>
              {uniqueOffers.map((offer) => (
                <MenuItem key={offer.id_salesforce_external} value={offer.id_salesforce_external}>
                  {offer.opportunity_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {roomTypes.length > 0 && ratePlans.length > 0 && (
            <>
              <Button variant="text" onClick={toggleAllSurchargesViewModal}>
                View Surcharges
              </Button>
              <Button variant="text" onClick={toggleAddSurchargesModal}>
                Add Surcharges
              </Button>
              <Button variant="text" onClick={toggleEditMode}>
                Add Rate Plans
              </Button>
            </>
          )}
          <Button
            variant="text"
            component={Link}
            to={`/vendors/${vendorId}/properties/${propertyId}/room-types-groups`}
          >
            Room Groups
          </Button>
          <Button variant="text" component={Link} to={`/vendors/${vendorId}/properties/${propertyId}/edit-room-types`}>
            Edit
          </Button>
        </Stack>
      </PageSubheader>

      <DataGrid
        columns={columns}
        rows={roomTypesWithOffers}
        getRowId={(row) => row.id}
        pageSizeOptions={[]}
        getRowHeight={() => 'auto'}
        autoHeight
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter
      />

      {editing && (
        <RoomTypeAddRatePlansModal
          editing={editing}
          toggleEditMode={toggleEditMode}
          propertyId={propertyId}
          roomTypes={roomTypes}
          ratePlans={ratePlans}
        />
      )}

      {showAddSurchargesModal && (
        <SalesforceSurchargesModal
          closeModal={toggleAddSurchargesModal}
          isOpen
          offers={offers}
          propertyId={propertyId}
          roomTypes={roomTypes}
          vendorId={vendorId}
        />
      )}

      {showAllSurchargesViewModal && (
        <AllSurchargesViewModal
          closeModal={toggleAllSurchargesViewModal}
          isOpen
          propertyId={propertyId}
          roomTypes={roomTypes}
          vendorId={vendorId}
        />
      )}
    </>
  );
}
