import React, { useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';

import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';

import { RuleCondition } from '~/consts/agentHub';

import { listAgencies } from '~/services/AgentHub/AgentService';

import { Agency } from '~/types/services/agentHub';

const DEFAULT_SIZE_PER_PAGE = '20';

interface Props {
  setCreatedConditions: (condition: RuleCondition) => void;
}

function AgentHubCommissionsAgencyInput(props: Props) {
  const { setCreatedConditions } = props;
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [result, setResult] = useState<Array<Agency>>([]);
  const [search, setSearch] = useState('');
  const [selectedAgencies, setSelectedAgencies] = useState<Array<Agency>>([]);
  const [noOptionsText, setNoOptionsText] = useState<string>('Start typing to search for agencies');

  const debouncedGetAgencyList = useMemo(
    () =>
      debounce((search: string) => {
        if (!search) {
          setResult([]);
          return;
        }
        setLoadingState('loading');
        listAgencies({ page: '0', pageSize: DEFAULT_SIZE_PER_PAGE, ...(search ? { search } : {}) })
          .then(({ rows, total }) => {
            if (total === 0) {
              setNoOptionsText('No agencies found');
            }
            setResult(rows);
          })
          .finally(() => setLoadingState('success'));
      }, 500),
    [],
  );

  const handleSaveCondition = () => {
    setCreatedConditions({
      type: 'agency',
      value: selectedAgencies.map((agency) => ({ value: agency.id, label: agency.name })),
    });
  };

  const handleInputChange = (_: React.ChangeEvent, newInputValue: string) => {
    if (!newInputValue) {
      setNoOptionsText('Start typing to search for agencies');
      return;
    }
    setSearch(newInputValue);
  };

  const handleChange = (_: React.ChangeEvent, value: Array<Agency>) => {
    setSelectedAgencies(value);
  };

  useEffect(() => {
    debouncedGetAgencyList(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const defaultProps = {
    options: result,
    getOptionLabel: (option: Agency) => option.name,
  };

  return (
    <Stack spacing={2}>
      <div>
        <Autocomplete
          {...defaultProps}
          multiple
          loading={loadingState === 'loading'}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} label="Agencies" placeholder="Agencies" />}
          onInputChange={handleInputChange}
          value={selectedAgencies}
          onChange={handleChange}
          noOptionsText={noOptionsText}
        />
        <Typography variant="caption" color="textSecondary">
          Start typing to search for agencies
        </Typography>
      </div>

      <Button onClick={handleSaveCondition} variant="contained">
        Add Condition
      </Button>
    </Stack>
  );
}

export default AgentHubCommissionsAgencyInput;
