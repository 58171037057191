import React, { useState } from 'react';

import { Box, Button, MenuItem, Modal, Select, Stack, Typography } from '@mui/material';

type PackageStatus = 'draft' | 'hidden' | 'content-approved';
function BulkUpdatePackageStatusModal({ onConfirm }: { onConfirm: (status: PackageStatus) => void }) {
  const [status, setStatus] = useState<PackageStatus | undefined>('draft');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const confirm = () => {
    if (status) {
      onConfirm(status);
      closeModal();
    }
  };
  return (
    <>
      <Button onClick={openModal}>Bulk Update Package Status</Button>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          height={200}
          width={400}
          bgcolor="background.paper"
          boxShadow={24}
          p={4}
          gap={2}
          display="flex"
          flexDirection="column"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Bulk Update Package Status
          </Typography>
          <Stack direction="column" spacing={2}>
            <Select value={status} onChange={(e) => setStatus(e.target.value as PackageStatus)}>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="hidden">Hidden</MenuItem>
              <MenuItem value="content-approved">Content Approved</MenuItem>
            </Select>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button onClick={closeModal}>Cancel</Button>
              <Button variant="contained" onClick={confirm}>
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default BulkUpdatePackageStatusModal;
