import React, { useState } from 'react';

import currencyFormatter from 'currency-formatter';

import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';

import RestrictedComponent from '~/components/Common/RestrictedComponent';

import { ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import { formatDateShort } from '~/services/TimeService';

import FlightRefundModal from '../../Refund/FlightModal';

import OfflineFlightRefundStatus from './OrderFlightItems/FlightRefundStatus';

const getPnrPrice = (flightsFile) => {
  const currency = flightsFile.currency;
  const price = flightsFile.travellers.reduce((priceSum, traveller) => priceSum + parseFloat(traveller.price), 0);
  return currencyFormatter.format(price, {
    code: currency,
  });
};

const mapTraveller = ({ orderId, itemId, traveller, index, currency, onIssueRefund, updateTravellerRefundStatus }) => {
  return (
    <Card key={index} variant="outlined">
      <CardContent>
        <Box display="grid" gap={2} gridTemplateColumns="repeat(12, 1fr)">
          <Typography fontWeight="bold">{index + 1}</Typography>

          <Box gridColumn="span 4">
            <Typography>{traveller.travellerName}</Typography>
            <Typography>{traveller.type}</Typography>
          </Box>

          <Typography fontWeight="bold">
            {currencyFormatter.format(traveller.price, {
              code: currency,
            })}
          </Typography>

          <Box gridColumn="span 2">
            {traveller.refundedAt && (
              <>
                <div>Refunded</div>
                <div>{formatDateShort(traveller.refundedAt)}</div>
                <div>
                  {currencyFormatter.format(traveller.refundAmount, {
                    code: currency,
                  })}
                </div>
              </>
            )}
          </Box>

          <RestrictedComponent excludedRoles={[ROLE_EXPERIENCES_COORDINATOR]}>
            <Box gridColumn="span 2">
              {traveller.id && !traveller.refundedAt && (
                <Button variant="outlined" onClick={() => onIssueRefund(traveller.id)}>
                  Issue Refund
                </Button>
              )}
            </Box>
          </RestrictedComponent>

          <Box gridColumn="span 2">
            <OfflineFlightRefundStatus
              orderId={orderId}
              itemId={itemId}
              travellerId={traveller.id}
              travellerRefundStatus={traveller.refundStatus}
              onUpdateTravellerRefundStatus={updateTravellerRefundStatus}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default function OrderOfflineFlightItem({
  refreshData,
  itemId,
  orderId,
  offlineFlightDetails,
  updateTravellerRefundStatus,
}) {
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundTravellerId, setRefundTravellerId] = useState();
  const [refundAmount, setRefundAmount] = useState(0);
  const closeRefundModal = () => {
    refreshData();
    setShowRefundModal(false);
    setRefundTravellerId(undefined);
    setRefundAmount(0);
  };

  return (
    <Stack direction="column" spacing={4}>
      {offlineFlightDetails.map((flightsFile, index) => (
        <div className="T-Order-Flight-Item-List" key={`${flightsFile.PNR}-${index}`}>
          <div>
            <Stack direction="column" spacing={2}>
              <Box width="30%" display="grid" gridTemplateColumns="repeat(2, 1fr)">
                <Typography fontWeight="bold">Booking Ref</Typography>
                <Typography>{flightsFile.PNR}</Typography>

                <Typography fontWeight="bold">Price</Typography>
                <Typography>{getPnrPrice(flightsFile)}</Typography>
              </Box>

              {flightsFile.travellers.map((traveller, index) => {
                return mapTraveller({
                  traveller,
                  index,
                  currency: flightsFile.currency,
                  onIssueRefund: (travellerId) => {
                    setShowRefundModal(true);
                    setRefundTravellerId(travellerId);
                  },
                  updateTravellerRefundStatus,
                  orderId,
                  itemId,
                });
              })}
            </Stack>

            <FlightRefundModal
              show={showRefundModal}
              travellerId={refundTravellerId}
              onClose={closeRefundModal}
              refundableAmount={refundAmount}
              orderId={orderId}
              itemId={itemId}
              currency={flightsFile.currency}
            />
          </div>
        </div>
      ))}
    </Stack>
  );
}
