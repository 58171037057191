import React, { useCallback, useState } from 'react';

import { Alert, Button, Dialog, DialogContent, Stack, TextField } from '@mui/material';

import UsersService from '~/services/UsersService';

type Props = {
  cartId: string;
  user: App.User;
  tenant: App.Tenant;
};

export default function QuoteSpoofUser({ cartId, user, tenant }: Props) {
  const [spoofUrl, setSpoofUrl] = useState<string | null>(null);
  const [isProcessingSpoofing, setProcessingSpoofing] = useState(false);

  const flushSpoofingState = useCallback(() => {
    setSpoofUrl(null);
  }, []);

  const handleSpoofUserStore = useCallback(async () => {
    try {
      setProcessingSpoofing(true);

      const spoofUrl = await UsersService.spoofUserQuote(user.id_member, tenant, cartId);
      navigator.clipboard.writeText(spoofUrl);

      setSpoofUrl(spoofUrl);
    } finally {
      setProcessingSpoofing(false);
    }
  }, [cartId, user]);

  return (
    <>
      {spoofUrl && (
        <Dialog open={true} onClose={flushSpoofingState}>
          <DialogContent>
            <Stack direction="column" spacing={2} sx={{ my: 2 }}>
              <Alert severity="warning" onClose={flushSpoofingState}>
                Extreme caution, paste into incognito only
              </Alert>
              <Alert severity="info" onClose={flushSpoofingState}>
                Your link has been copied to the clipboard!
              </Alert>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={`Store mode spoofing link for ${user.email}`}
                defaultValue={spoofUrl}
                multiline
                disabled
              />
            </Stack>
          </DialogContent>
        </Dialog>
      )}
      <Button variant="text" size="small" onClick={handleSpoofUserStore} disabled={isProcessingSpoofing}>
        View quote
      </Button>
    </>
  );
}
