import React, { useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Grid } from '@mui/material';

import { HOTEL_OFFER_TYPES } from '~/consts/offerTypes';

import { getLuxMomentByOfferId } from '~/services/LuxMomentsService';
import ReservationService from '~/services/ReservationService';

interface Props {
  offer: App.AccommodationOffer;
}

interface Field {
  label: string;
  value: string;
}

function getUniquePropertyIds(packages, idField) {
  return packages
    .map((pkg) => pkg[idField])
    .filter((pkgId, index, ids) => pkgId && typeof pkgId === 'string' && ids.indexOf(pkgId) === index);
}

const getDateChangeMessage = (numberOfChanges: string): string => {
  if (numberOfChanges === null) {
    return 'NO';
  }
  if (numberOfChanges.toLowerCase() === 'unlimited') {
    return 'UNLIMITED';
  }
  if (numberOfChanges === '0') {
    return 'NO';
  }
  return `${numberOfChanges} LEFT`;
};

export default function OfferChecks(props: Props): JSX.Element {
  const { offer } = props;
  const [luxMomentsPage, setLuxMomentsPage] = useState(null);
  const [channelManager, setChannelManager] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const hasVideo = useMemo(
    () => Object.values(offer.videos).some((videoGroup) => Object.values(videoGroup).some((video) => !!video)),
    [offer.videos],
  );
  const hasBadge = useMemo(() => Object.values(offer.badges).some((badge) => !!badge), [offer.badges]);

  useEffect(() => {
    const fetchLuxMoments = async () => {
      try {
        const luxMomentsRes = await getLuxMomentByOfferId(offer.id_salesforce_external);
        return luxMomentsRes?.result?.[0].fields || null;
      } catch (e) {
        console.error('Error fetching lux moments:', e);
      }
    };

    fetchLuxMoments().then((luxMoments) => {
      setLuxMomentsPage(luxMoments);
    });
  }, [enqueueSnackbar, offer.id_salesforce_external]);

  useEffect(() => {
    const fetchChannelManager = async () => {
      try {
        const isHotelOffer = HOTEL_OFFER_TYPES.includes(offer.type);
        const uniqueIds = getUniquePropertyIds(offer.packages, isHotelOffer ? 'fk_property_id' : 'fk_tour_id');
        let channelManager = null;

        for (const propertyId of uniqueIds) {
          const propertyResult = await ReservationService.getProperty(propertyId);
          channelManager = propertyResult.result?.channel_manager || null;
          if (channelManager) break;
        }

        return channelManager;
      } catch (e) {
        console.error('Error fetching channel manager:', e);
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    };

    fetchChannelManager().then((channelManager) => {
      setChannelManager(channelManager);
    });
  }, [enqueueSnackbar, offer.packages, offer.type]);

  const fields = useMemo(() => {
    const fields: Field[] = [];

    const updateField = (condition: boolean, label: string, positiveMessage: string, negativeMessage: string) => {
      const field = { label, value: condition ? positiveMessage : negativeMessage };
      fields.push(field);
    };

    updateField(hasVideo, 'HAS VIDEO?', 'YES', 'NO');
    updateField(hasBadge, 'HAS BADGE?', 'YES', 'NO');
    updateField(!!luxMomentsPage, 'HAS LUX MOMENTS?', 'YES', 'NO');
    updateField(
      getDateChangeMessage(offer.number_of_date_changes) !== 'NO',
      'DATE CHANGE ALLOWED?',
      getDateChangeMessage(offer.number_of_date_changes),
      'NO',
    );

    return fields;
  }, [hasVideo, hasBadge, luxMomentsPage, offer.number_of_date_changes]);

  return (
    <Grid container rowGap={3}>
      {fields.map((field, index) => (
        <Grid key={index} item xs={3}>
          <Box>
            {field.label}
            <br />
            {field.value}
            <br />
          </Box>
        </Grid>
      ))}
      <Grid item xs={3}>
        <div>
          CHANNEL MANAGER
          <br />
          {channelManager ?? 'N/A'}
        </div>
      </Grid>
    </Grid>
  );
}
