import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Container } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import { getCreditTypes, getCredits, getReconciledCredits } from '~/services/PaymentsService';
import UsersService from '~/services/UsersService';

import { parseSearchString } from '~/utils/url';

import ErrorDisplay from '../../Common/ErrorDisplay';
import Spinner from '../../Common/Spinner';

import CreditsCurrencySelector from './CreditsCurrencySelector';
import CreditsPage from './CreditsPage';
import { Credit, CreditCommentMap, CreditTypes, Credits, ReconciledCredits } from './types';

type Props = RouteComponentProps<{ id_user: string }>;

const regions = libRegions.getRegions();

// we will be searching this array a lot to grab comments
// so convert to hash map to minimise loops
function transformCreditsArrayToHashMap(credits: Credit[]): CreditCommentMap {
  return credits.reduce((acc, credit) => {
    acc[credit.id_credit] = credit.comments;
    return acc;
  }, {} as CreditCommentMap);
}

export default function CreditsContainer({ match, location }: Props) {
  const { id_user: userId } = match.params;
  const qsString = location.search;

  // const tenant = useSelector((state: App.State) => state.tenant);
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [creditTypes, setCreditTypes] = useState<CreditTypes>([]);
  const [credits, setCredits] = useState<Credits | null>(null);
  const [user, setUser] = useState<App.User | null>(null);
  const [initiators, setInitiators] = useState([]);
  const [error, setError] = useState<string | null>(null);
  const [isInitialLoad, setInitialLoad] = useState(true);
  const [creditCommentMap, setCreditCommentMap] = useState<CreditCommentMap>({});
  const [reconciledCredits, setReconciledCredits] = useState<ReconciledCredits>({
    expiredCredits: [],
    reconciledDebits: [],
    unspentCredits: [],
  });

  const currency = useMemo<string>(() => {
    const { currency } = parseSearchString(qsString);
    return (currency as string) || 'AUD';
  }, [qsString]);

  const handlePageChange = useCallback((page) => setCurrentPage(page), []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const creditTypes = getCreditTypes(userId);

      const [credits, allCredits, user, reconciledCredits] = await Promise.all([
        getCredits(userId, currency, brand, currentPage),
        getCredits(userId, currency, brand, null),
        UsersService.getUser(userId),
        getReconciledCredits(userId, brand, currency),
      ]);

      const initiators = await Promise.all(
        [...new Set(credits.result.collection.map((item) => item.fk_added_by))].map((id) => UsersService.getUser(id)),
      );

      setCreditTypes(creditTypes);
      setCredits(credits);
      setInitiators(initiators);
      setReconciledCredits(reconciledCredits);
      setCreditCommentMap(transformCreditsArrayToHashMap(allCredits.result.collection));
      setUser(user);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
      setInitialLoad(false);
    }
  }, [brand, currency, currentPage, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth="xl">
      <CreditsCurrencySelector user={user} regions={regions} currency={currency as string} />

      {isInitialLoad && !user && <Spinner />}

      {error && <ErrorDisplay message={error} />}

      {!isInitialLoad && user && (
        <CreditsPage
          creditTypes={creditTypes}
          credits={credits}
          initiators={initiators}
          user={user}
          idUser={userId}
          currency={currency}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          isLoading={isLoading}
          reconciledCredits={reconciledCredits}
          rerender={fetchData}
          creditCommentMap={creditCommentMap}
        />
      )}
    </Container>
  );
}
