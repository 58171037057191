import React, { useCallback, useEffect, useState } from 'react';

import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import styled from 'styled-components';

import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import { Field, FlexWrapper, Spinner, Text } from '~/components/Experiences/components';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import { EXP_PROVIDER_LED, ORIGINAL_EXPERIENCE_CONTENT_TAB, themes } from '~/consts/experiences';

import {
  BookingOptions,
  ChildSeatType,
  ExperienceOffer,
  TransferType,
  getAvailableDates,
  getAvailableTimes,
} from '~/services/ExperiencesService';
import { formatDateWithClock } from '~/services/TimeService';

interface Props {
  experience: ExperienceOffer;
  tenant: App.Tenant;
}

interface TicketView extends Experiences.DealTicket {
  price?: number;
  providerPrice?: number;
}

const TicketImage = styled.img`
  height: 50px;
  width: 60px;
`;

const calculatePrice = (price: number, margin?: number) => {
  margin = !margin || isNaN(margin) ? 0 : margin;
  return parseFloat((price + (price / 100) * margin).toFixed(2));
};

const transferTypesOptions = [
  { value: TransferType.AIRPORT_TO_HOTEL, label: 'Airport to Hotel' },
  { value: TransferType.HOTEL_TO_AIRPORT, label: 'Hotel to Airport' },
];

const childSeatTypesOptions = [
  { value: ChildSeatType.INFANT_SEAT, label: 'Infant Seat' },
  { value: ChildSeatType.BOOSTER_SEAT, label: 'Booster Seat' },
];

const ExperienceCurationTickets: React.FC<Props> = (props) => {
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { experience, tenant } = props;

  const { updateValues, contentTab, values } = useUpdateExperienceValues();

  useEffect(() => {
    return () => {
      setTickets([]);
      setLoading(false);
      setError('');
    };
  }, []);

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;

  const fetchAvailableTickets = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const availableDates = await getAvailableDates(experience.id, tenant.brand);

      const availableSlot = availableDates.result.find((slot) => !slot.soldOut);

      if (!availableSlot) {
        setError('This offer has no dates available at the moment.');
      }

      const res = await getAvailableTimes(experience.id, tenant.brand, {
        day: availableSlot.day,
      });

      const dealOptions: TicketView[] = uniqBy(
        res.result.flatMap((group) =>
          group.slots.flatMap((slot) =>
            slot.tickets.map((ticket) => {
              const fareType = !areOriginalValues ? ticket.fareType : ticket.providerFareType;
              const currencyCode = ticket.salesPrices[0].currencyCode;
              const margin = !areOriginalValues ? ticket.margin : 0;
              let bookingOptions: Partial<BookingOptions> = {
                childSeatTypes: [],
                transferTypes: [],
              };
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const ticketUnitLabel = ticket.ticketUnitLabel;
              if (ticket.bookingOptions) {
                bookingOptions = ticket.bookingOptions;
              }

              return {
                id: ticket.productId,
                fareType,
                price: calculatePrice(ticket.providerPrice.amount, margin),
                providerPrice: ticket.providerPrice.amount,
                margin: margin,
                bookingOptions,
                imageUrl: ticket.imageUrl,
                currencyCode,
                ticketUnitLabel,
                rateStartDate: ticket.rateStartDate,
                rateEndDate: ticket.rateEndDate,
                isExtra: ticket.isExtra,
                isHidden: ticket.isHidden,
              };
            }),
          ),
        ),
        'id',
      );

      const orderedDealOptions = orderBy(dealOptions, ['id']);

      setTickets(orderedDealOptions);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [areOriginalValues, experience.id, tenant.brand]);

  useEffect(() => {
    if (experience) {
      fetchAvailableTickets();
    }
  }, [experience, fetchAvailableTickets]);

  const handleTicket = (index: number, value: { [key: string]: number | string | object | boolean }) => {
    setTickets((tickets: TicketView[]) => {
      const key = Object.keys(value)[0];

      tickets[index] = { ...tickets[index], ...value };

      tickets[index].price = calculatePrice(tickets[index].providerPrice ?? 0, tickets[index].margin);

      const ticketsToStore = values.tickets ?? [];
      const indexToStore = ticketsToStore.findIndex((ticket) => tickets[index].id === ticket.id);
      if (indexToStore >= 0) {
        ticketsToStore[indexToStore] = {
          ...ticketsToStore[indexToStore],
          ...value,
        };
      } else {
        ticketsToStore.push({ id: tickets[index].id, ...value });
      }

      if (key === 'fareType' && !value[key]) {
        delete ticketsToStore[index][key];
      }

      updateValues({
        tickets: ticketsToStore,
      });

      return orderBy(tickets, ['id']);
    });
  };

  const handleTicketBookingOptions = (index: number, value: { [key: string]: string | number }) => {
    setTickets((tickets) => {
      tickets[index].bookingOptions = {
        ...tickets[index].bookingOptions,
        ...value,
      };

      const ticketsToStore = values.tickets ?? [];
      const indexToStore = ticketsToStore.findIndex((ticket) => tickets[index].id === ticket.id);
      if (indexToStore >= 0) {
        ticketsToStore[indexToStore].bookingOptions = {
          ...ticketsToStore[indexToStore].bookingOptions,
          ...value,
        };
      } else {
        ticketsToStore.push({
          id: tickets[index].id,
          bookingOptions: value,
        });
      }

      updateValues({
        tickets: ticketsToStore,
      });

      return orderBy(tickets, ['id']);
    });
  };

  const handleSelectedTransferTypes = (index, transferTypes) => {
    const transferTypesValues = transferTypes.map((type) => {
      if (typeof type === 'string') {
        return type;
      }

      return type.value;
    });

    handleTicketBookingOptions(index, { transferTypes: transferTypesValues });
  };

  const handleSelectedChildSeatTypes = (index, childSeatTypes) => {
    const childSeatTypesValues = childSeatTypes.map((type) => {
      if (typeof type === 'string') {
        return type;
      }

      return type.value;
    });

    handleTicketBookingOptions(index, { childSeatTypes: childSeatTypesValues });
  };

  const isMarginDisabled = areOriginalValues || experience?.provider === EXP_PROVIDER_LED;

  const hasTransferFeature = !!experience?.features?.transfer || !!values?.features?.transfer;

  const hasPriceOverridden = experience?.tickets?.some((ticket) => ticket.margin);

  return (
    <>
      {loading && (
        <FlexWrapper align="center" justify="center">
          <Spinner size={25} />
        </FlexWrapper>
      )}

      {error && <Alert severity="error">{error}</Alert>}

      {!loading && !error && (
        <Stack direction="column" spacing={4}>
          {tickets.map((ticket, index) => (
            <Box key={`${ticket.id}-container`}>
              <Box display="grid" gap={2} gridTemplateColumns="repeat(8, 1fr)">
                <Box gridColumn="span 4">
                  <Field
                    fullWidth
                    name="fareType"
                    onChange={({ value }) => handleTicket(index, { fareType: value })}
                    label="Title"
                    value={ticket.fareType}
                    disabled={areOriginalValues}
                  />
                </Box>
                <Stack spacing={2} gridColumn="span 2">
                  <Box>
                    <Field
                      fullWidth
                      type="number"
                      name="margin"
                      onChange={({ value }) => handleTicket(index, { margin: parseInt(value) })}
                      label="Margin %"
                      value={ticket.margin}
                      disabled={isMarginDisabled}
                    />
                  </Box>
                  <FormGroup title="Complement or add-on to the main tickets. If this option is checked, this ticket cannot be sold stand alone">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!ticket.isExtra}
                          onChange={(event: any) => handleTicket(index, { isExtra: event.target.checked })}
                        />
                      }
                      label="Extra / Complement"
                    />
                  </FormGroup>
                  <FormGroup title="Hide this ticket from listing at Customer Portal">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!ticket.isHidden}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleTicket(index, { isHidden: event.target.checked })
                          }
                        />
                      }
                      label="Hide Ticket"
                    />
                  </FormGroup>
                </Stack>

                <Stack spacing={2}>
                  <Box>
                    <Typography title={experience.title}>{`Provider price (${ticket.currencyCode})`}</Typography>
                    <Text weight={600} title={experience.title}>
                      {ticket.providerPrice}
                    </Text>
                  </Box>
                  <Box>
                    <Typography title={'Minimum date that can be selected by the customer at the time of booking'}>
                      {'Min date'}
                    </Typography>
                    <Text weight={600} title={experience.title}>
                      {ticket.rateStartDate ? formatDateWithClock(ticket.rateStartDate) : 'Any date'}
                    </Text>
                  </Box>
                </Stack>

                <Stack spacing={2}>
                  <Box>
                    <Typography title={experience.title}>{`Sale price (${ticket.currencyCode})`}</Typography>
                    <Text weight={600} title={experience.title}>
                      {ticket.price}
                    </Text>
                  </Box>
                  <Box>
                    <Typography title={'Maximum date that can be selected by the customer at the time of booking for'}>
                      {'Max date'}
                    </Typography>
                    <Text weight={600} title={experience.title}>
                      {ticket.rateStartDate ? formatDateWithClock(ticket.rateEndDate) : 'Any date'}
                    </Text>
                  </Box>
                </Stack>

                {hasTransferFeature && (
                  <>
                    <Box gridColumn="span 2">
                      <Field
                        fullWidth
                        type="number"
                        name="maxPassengers"
                        onChange={({ value }) =>
                          handleTicketBookingOptions(index, {
                            maxPassengers: parseInt(value),
                          })
                        }
                        label="Max Passengers"
                        value={ticket.bookingOptions.maxPassengers}
                        disabled={areOriginalValues}
                      />
                    </Box>

                    <Box gridColumn="span 2">
                      <Field
                        fullWidth
                        type="number"
                        name="maxCheckedBags"
                        onChange={({ value }) =>
                          handleTicketBookingOptions(index, {
                            maxCheckedBags: parseInt(value),
                          })
                        }
                        label="Max Checked Bags"
                        value={ticket.bookingOptions.maxCheckedBags}
                        disabled={areOriginalValues}
                      />
                    </Box>

                    <Box gridColumn="span 2">
                      <Field
                        fullWidth
                        type="number"
                        name="maxCarryonBags"
                        onChange={({ value }) =>
                          handleTicketBookingOptions(index, {
                            maxCarryOnBags: parseInt(value),
                          })
                        }
                        label="Max CarryOn Bags"
                        value={ticket.bookingOptions.maxCarryOnBags}
                        disabled={areOriginalValues}
                      />
                    </Box>

                    <Box gridColumn="span 2">
                      <Field
                        fullWidth
                        type="number"
                        name="distanceCovered"
                        onChange={({ value }) =>
                          handleTicketBookingOptions(index, {
                            distanceCovered: parseInt(value),
                          })
                        }
                        label="Distance covered (KM)"
                        value={ticket.bookingOptions.distanceCovered}
                        disabled={areOriginalValues}
                      />
                    </Box>

                    <Box gridColumn="span 2">
                      <label>Child Seat Type</label>
                      <Box flexGrow={1}>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          options={childSeatTypesOptions.map((type) => type.value)}
                          onChange={(_, values) => handleSelectedChildSeatTypes(index, values)}
                          value={ticket.bookingOptions.childSeatTypes ?? []}
                          renderInput={(params) => <TextField {...params} label="" />}
                          getOptionLabel={(option) =>
                            childSeatTypesOptions.find((type) => type.value === option)?.label
                          }
                        />
                      </Box>
                    </Box>

                    <Box gridColumn="span 2">
                      <label>Transfer Type</label>
                      <Box flexGrow={1}>
                        <Autocomplete
                          multiple
                          disableCloseOnSelect
                          options={transferTypesOptions.map((type) => type.value)}
                          onChange={(_, values) => handleSelectedTransferTypes(index, values)}
                          value={ticket.bookingOptions.transferTypes ?? []}
                          renderInput={(params) => <TextField {...params} label="" />}
                          getOptionLabel={(option) => transferTypesOptions.find((type) => type.value === option)?.label}
                        />
                      </Box>
                    </Box>

                    <Box gridColumn="1 / span 4">
                      <Text weight={700} lineHeight="32px" color={themes.primaryBlue}>
                        Upload a image
                      </Text>

                      <Stack direction="row" spacing={4}>
                        <TextField
                          inputProps={{ accept: 'image/*' }}
                          type="file"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleTicket(index, { image: e.target.files[0] })
                          }
                          disabled={areOriginalValues}
                          fullWidth
                        />
                        <TicketImage src={ticket.imageUrl} />
                      </Stack>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ))}
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked={hasPriceOverridden} disabled />}
              label="Price Overridden"
            />
          </FormGroup>
        </Stack>
      )}
    </>
  );
};

export default ExperienceCurationTickets;
