import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { getAffiliationList, getAgency, updateAgency } from '~/services/AgentHub/AgentService';

import { Agency, AvailableAffiliations } from '~/types/services/agentHub';

interface Props {
  id: string;
  onClose?: () => void;
  opened?: boolean;
}

export default function AgencyEditor(props: Props) {
  const { id, onClose, opened = false } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(opened);
  const [agencyDetails, setAgencyDetails] = useState<Agency | undefined>(undefined);
  const [availableAffiliations, setAvailableAffiliations] = useState<AvailableAffiliations>([]);

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');

  const closeModal = useCallback(() => {
    setOpen(false);
    onClose && onClose();
  }, [onClose]);

  const save = useCallback(async () => {
    try {
      setLoadingState('loading');

      if (Object.entries(agencyDetails).some(([_, value]) => !value)) {
        setLoadingState('failed');
        enqueueSnackbar('Please fill all the fields', { variant: 'error' });
        return;
      }

      const { id, name, businessCodeValue, address, city, state, postcode, country, affiliation } = agencyDetails;
      await updateAgency(id, {
        name,
        businessCodeValue,
        address,
        city,
        state,
        postcode,
        country,
        affiliation,
      });
      setLoadingState('success');
      enqueueSnackbar('Register updated sucessfully');
      closeModal();
    } catch (err) {
      setLoadingState('failed');
      enqueueSnackbar('There was an error updating the agency', { variant: 'error' });
    }
  }, [agencyDetails, enqueueSnackbar, closeModal]);

  const openModal = useCallback(async () => {
    if (!id) {
      closeModal();
    }

    setOpen(true);

    try {
      setLoadingState('loading');

      const [agencyData, affiliations] = await Promise.all([getAgency(id), getAffiliationList()]);

      setAgencyDetails(agencyData);
      setAvailableAffiliations(affiliations);
      setLoadingState('success');
    } catch (err) {
      setLoadingState('failed');
    }
  }, [closeModal, id]);

  useEffect(() => {
    if (opened) {
      openModal();
    }
  }, [openModal, opened]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Edit Agency</DialogTitle>

        <DialogContent>
          {loadingState == 'loading' && <Spinner />}
          {loadingState != 'loading' && (
            <form onSubmit={(e) => e.preventDefault()} style={{ minWidth: '500px' }}>
              <Stack mt={2} direction="column" spacing={2}>
                <FormGroup>
                  <TextField
                    label="Name"
                    value={agencyDetails?.name}
                    required
                    fullWidth
                    onChange={(e) => setAgencyDetails({ ...agencyDetails, name: e.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    label={agencyDetails?.businessCodeType}
                    value={agencyDetails?.businessCodeValue}
                    required
                    fullWidth
                    onChange={(e) => setAgencyDetails({ ...agencyDetails, businessCodeValue: e.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <Autocomplete
                    value={availableAffiliations?.find(
                      (affiliation) => affiliation.name === agencyDetails?.affiliation,
                    )}
                    options={availableAffiliations}
                    getOptionLabel={(option) => `${option.region} - ${option.name}`}
                    onChange={(_, value) =>
                      setAgencyDetails({ ...agencyDetails, affiliation: value ? value.name : '' })
                    }
                    renderInput={(params) => <TextField {...params} label="Affiliation" />}
                  />
                </FormGroup>
                <Typography mt={2} variant="h6" sx={{ gridColumn: 'span 2' }}>
                  Address
                </Typography>

                <FormGroup>
                  <TextField
                    label="Street"
                    value={agencyDetails?.address}
                    required
                    fullWidth
                    onChange={(e) => setAgencyDetails({ ...agencyDetails, address: e.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    label="City"
                    value={agencyDetails?.city}
                    required
                    fullWidth
                    onChange={(e) => setAgencyDetails({ ...agencyDetails, city: e.target.value })}
                  />
                </FormGroup>

                <FormGroup>
                  <TextField
                    label="Postal Code"
                    value={agencyDetails?.postcode}
                    required
                    fullWidth
                    onChange={(e) => setAgencyDetails({ ...agencyDetails, postcode: e.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    label="State"
                    value={agencyDetails?.state}
                    required
                    fullWidth
                    onChange={(e) => setAgencyDetails({ ...agencyDetails, state: e.target.value })}
                  />
                </FormGroup>
                <FormGroup>
                  <TextField
                    label="Country"
                    value={agencyDetails?.country}
                    required
                    fullWidth
                    onChange={(e) => setAgencyDetails({ ...agencyDetails, country: e.target.value })}
                  />
                </FormGroup>
                {loadingState == 'failed' && (
                  <ErrorDisplay message="There was an error updating the agency" severity="error" />
                )}
              </Stack>
            </form>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button variant="text" onClick={closeModal}>
            Close
          </Button>
          <Button variant="contained" onClick={save}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
