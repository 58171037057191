import React, { useCallback } from 'react';

import {
  Book as BookIcon,
  CalendarToday as CalendarTodayIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  DirectionsCar as DirectionsCarIcon,
  Info as InfoIcon,
  Place as PinIcon,
  Shield as ShieldIcon,
  Description as SupplierIcon,
  VerifiedUser as VerifiedUserIcon,
} from '@mui/icons-material';
import { Box, Button, Chip, Divider, Grid, Stack, Typography } from '@mui/material';

import RestrictedComponent from '~/components/Common/RestrictedComponent';
import { currencyFormatter } from '~/components/Experiences/helpers';

import { ITEM_TYPE_CAR_HIRE } from '~/consts/order';
import { ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import { CarHireStatus } from '~/services/CarHireService';
import { formatDateLongMonthWithMeridiem, isBefore, subtractHours } from '~/services/TimeService';

const getChipColor = (status: string): 'success' | 'error' | 'warning' => {
  switch (status) {
    case CarHireStatus.COMPLETED:
      return 'success';
    case CarHireStatus.CANCELLED:
      return 'error';
    default:
      return 'warning';
  }
};

const isFreeCancellation = (carHireItem: App.CarHireItem, cancellationPolicy: any) => {
  return carHireItem.reservation
    ? isBefore(
        new Date().toJSON(),
        subtractHours(parseInt(cancellationPolicy.beforeHours), carHireItem.reservation.pickUp.date),
      )
    : false;
};

type Props = {
  count: number;
  currencyCode: string;
  carHireItem: App.CarHireItem;
  bookingInfo: any;
  hasAllowedRefund: boolean;
  showRefundModal: ({ itemId, itemType }) => void;
  refreshData: () => void;
};

function CarHireOrderItemDetails({ carHireItem, bookingInfo, currencyCode, showRefundModal, hasAllowedRefund }: Props) {
  const calculateDueAtPickUpAmount = useCallback(() => {
    if (!bookingInfo) {
      return 0;
    }

    const payOnArrivalTaxesAndFees = bookingInfo.totalCharge.payOnArrival.amount;
    const addonsAmount = bookingInfo.addons.reduce((acc, addon) => acc + parseFloat(addon.price.total), 0.0);
    return addonsAmount + payOnArrivalTaxesAndFees;
  }, [bookingInfo]);

  const hasAddons = bookingInfo?.addons?.length > 0;
  const paidOnPickUpFeesAndTaxes = bookingInfo?.totalCharge?.payOnArrival.amount;
  const shouldShowPayOnPickUpSection = hasAddons || paidOnPickUpFeesAndTaxes > 0;

  return (
    <Box p={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} flexGrow={1} alignItems="start">
          {bookingInfo && <img src={bookingInfo.vehicle?.image} style={{ maxWidth: '200px', width: '100%' }} />}

          <Typography variant="h5">{bookingInfo?.vehicle?.model}</Typography>

          <Box pt={0.5}>
            <Chip
              size="small"
              variant="filled"
              color={getChipColor(carHireItem.status)}
              label={carHireItem.status.toUpperCase()}
            />
          </Box>
        </Stack>

        {hasAllowedRefund && (
          <RestrictedComponent excludedRoles={[ROLE_EXPERIENCES_COORDINATOR]}>
            <Box>
              <Button
                variant="text"
                disabled={carHireItem.status === CarHireStatus.CANCELLED}
                onClick={() => showRefundModal({ itemId: carHireItem.id, itemType: ITEM_TYPE_CAR_HIRE })}
              >
                ISSUE REFUND
              </Button>
            </Box>
          </RestrictedComponent>
        )}
      </Stack>

      <Grid mt={5} container columns={12}>
        <Grid item px={2} md={bookingInfo?.cancellationPolicy || bookingInfo?.addons?.length > 0 ? 4 : 6} xs={12}>
          {bookingInfo && (
            <>
              <Stack mt={1} alignItems="center" flexDirection="row">
                <ConfirmationNumberIcon />
                <Typography ml={2} variant="body1">
                  Confirmation ID: <br />
                  <b>{bookingInfo.providerReservationCode}</b>
                </Typography>
              </Stack>
              <Stack mt={1} alignItems="center" flexDirection="row">
                <BookIcon />
                <Typography ml={2} variant="body1">
                  Booking Status: <br />
                  <b>{bookingInfo.status.toUpperCase()}</b>
                </Typography>
              </Stack>
              <Stack mt={1} alignItems="center" flexDirection="row">
                <SupplierIcon />
                <Typography ml={2} variant="body1">
                  Supplier: <br />
                  <b>{bookingInfo.vendor.shortName}</b>
                </Typography>
              </Stack>
              <Stack mt={1} alignItems="center" flexDirection="row">
                <DirectionsCarIcon />
                <Typography ml={2} variant="body1">
                  Transmission Type: <br />
                  <b>{bookingInfo.vehicle.transmissionType}</b>
                </Typography>
              </Stack>

              <Grid container columns={12}>
                <Grid item mt={2} xs={12}>
                  <Typography variant="h6">Pick-up</Typography>
                  <Stack alignItems="center" flexDirection="row">
                    <CalendarTodayIcon />
                    <Typography ml={2} variant="body1">
                      Date: <br />
                      <b>{formatDateLongMonthWithMeridiem(bookingInfo.pickUp.date)}</b>
                    </Typography>
                  </Stack>
                  <Stack mt={1} alignItems="center" flexDirection="row">
                    <PinIcon />
                    <Typography ml={2} variant="body1">
                      Location: <br />
                      <b>{bookingInfo.pickUp.location.locationName}</b>
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item mt={2} xs={12}>
                  <Typography variant="h6">Return</Typography>
                  <Stack alignItems="center" flexDirection="row">
                    <CalendarTodayIcon />
                    <Typography ml={2} variant="body1">
                      Date: <br />
                      <b>{formatDateLongMonthWithMeridiem(bookingInfo.return.date)}</b>
                    </Typography>
                  </Stack>
                  <Stack mt={1} alignItems="center" flexDirection="row">
                    <PinIcon />
                    <Typography ml={2} variant="body1">
                      Location: <br />
                      <b>{bookingInfo.return.location.locationName}</b>
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
        <Grid item px={2} md={4} xs={12}>
          {bookingInfo?.cancellationPolicy && (
            <>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography mr={2} variant="h6">
                  Cancellation Policy
                </Typography>
                {isFreeCancellation(carHireItem, bookingInfo?.cancellationPolicy) ? (
                  <Chip size="small" variant="filled" color="info" label="Free Cancellation" />
                ) : (
                  <Chip size="small" variant="filled" color="error" label="Non-Refundable" />
                )}
              </Box>
              <Box mt={2} display="flex" flexDirection="row" alignItems="center">
                <InfoIcon />
                <Typography ml={1} variant="body1">
                  Cancellation free until
                  <b> {bookingInfo?.cancellationPolicy.beforeHours}hrs</b> before the pick-up.
                </Typography>
              </Box>
              {carHireItem.reservation && (
                <Box mt={1} display="flex" flexDirection="row" alignItems="center">
                  <CalendarTodayIcon />
                  <Typography ml={1} variant="body1">
                    Date limit for <b>free</b> cancellation:
                    <br />
                    <b>
                      {' '}
                      {formatDateLongMonthWithMeridiem(
                        subtractHours(
                          parseInt(bookingInfo?.cancellationPolicy.beforeHours),
                          carHireItem.reservation.pickUp.date,
                        ),
                      )}
                    </b>
                  </Typography>
                </Box>
              )}
            </>
          )}

          {shouldShowPayOnPickUpSection && (
            <>
              {hasAddons && (
                <Box>
                  <Typography mt={3} variant="h6">
                    Add-Ons:
                  </Typography>

                  <Stack spacing={1} direction="column">
                    {bookingInfo.addons.map((addon) => (
                      <Box
                        key={addon.description}
                        display="flex"
                        width="100%"
                        flexDirection="row"
                        justifyContent="between"
                      >
                        <Box flexGrow={1}>
                          <Typography variant="body1">
                            {addon.quantity} - {addon.description}
                          </Typography>
                        </Box>
                        <Typography variant="body1">
                          {addon.price.total ? currencyFormatter(addon.price.currency, addon.price.total, 2) : '-'}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>
                </Box>
              )}

              {paidOnPickUpFeesAndTaxes > 0 && (
                <Box>
                  <Typography mt={3} variant="h6">
                    Additional Pay on Arrival fees:
                  </Typography>

                  <Stack spacing={1} direction="column">
                    {(bookingInfo?.beforePickupInclusions || []).map((item) => (
                      <Box
                        key={item.description}
                        display="flex"
                        width="100%"
                        flexDirection="row"
                        justifyContent="between"
                      >
                        <Box flexGrow={1}>
                          <Typography variant="body1">{item.description}</Typography>
                        </Box>
                      </Box>
                    ))}
                    <Box display="flex" width="100%" flexDirection="row" justifyContent="between">
                      <Box flexGrow={1}>
                        <Typography variant="body1">Total</Typography>
                      </Box>
                      <Typography variant="body1">
                        {currencyFormatter(
                          bookingInfo?.totalCharge?.payOnArrival.currency,
                          paidOnPickUpFeesAndTaxes,
                          2,
                        )}
                      </Typography>
                    </Box>

                    <Typography variant="body1"></Typography>
                  </Stack>
                </Box>
              )}

              <Typography mt={3} variant="h6" display="flex" flexDirection="row">
                <Box flexGrow={1}>Due at Pick-up:</Box>
                <b>{currencyFormatter(currencyCode, calculateDueAtPickUpAmount(), 2)}</b>
              </Typography>
            </>
          )}

          <>
            <Box mt={3} display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6">Insurance</Typography>
            </Box>
            <Box mt={2}>
              {bookingInfo?.insurance ? (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <VerifiedUserIcon color="success" />
                  <Box display="flex" flexDirection="column" flexGrow={1}>
                    <Typography ml={1} variant="body1">
                      <b>Enhanced Protection</b>
                    </Typography>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <a href={bookingInfo.insurance.reference.url} target="_blank" rel="noreferrer">
                        <Typography ml={1} variant="body1">
                          What's covered
                        </Typography>
                      </a>
                      <Typography variant="body1" display="flex" flexDirection="row" justifyContent="end">
                        <b>{currencyFormatter(bookingInfo.insurance.currency, bookingInfo.insurance.price, 2)}</b>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box display="flex" flexDirection="row">
                  <ShieldIcon />
                  <Typography ml={1} variant="body1">
                    Limited Protection
                  </Typography>
                </Box>
              )}
            </Box>
          </>
        </Grid>
        <Divider orientation="vertical" flexItem sx={{ mr: '-1px' }} />
        <Grid item px={2} md={bookingInfo?.cancellationPolicy || bookingInfo?.addons?.length > 0 ? 4 : 6} xs={12}>
          {bookingInfo ? (
            <>
              <Typography variant="h6">Driver</Typography>
              <Typography mt={1} variant="body1">
                Name:{' '}
                <b>
                  {bookingInfo.customer.namePrefix && bookingInfo.customer.namePrefix + ' '}
                  {bookingInfo.customer.givenName} {bookingInfo.customer.surname}
                </b>
              </Typography>
              <Typography mt={1} variant="body1">
                Age: <b>{bookingInfo.customer.age}</b>
              </Typography>
              {bookingInfo.customer.phoneNumber && (
                <Typography mt={1} variant="body1">
                  Phone: <b>{bookingInfo.customer.phoneNumber}</b>
                </Typography>
              )}
              {bookingInfo.customer.email && (
                <Typography mt={1} variant="body1">
                  Email: <b>{bookingInfo.customer.email}</b>
                </Typography>
              )}
              {bookingInfo.customer.countryCode && (
                <Typography mt={1} variant="body1">
                  Country: <b>{bookingInfo.customer.countryCode}</b>
                </Typography>
              )}
            </>
          ) : (
            <>
              {carHireItem.reservation ? (
                <>
                  <Typography mt={1} variant="body1">
                    ID: <b>{carHireItem.reservation.reference.id}</b>
                  </Typography>
                  <Typography mt={1} variant="body1">
                    URL: <b>{carHireItem.reservation.reference.url}</b>
                  </Typography>
                  <Typography mt={1} variant="body1">
                    DateTime: <b>{carHireItem.reservation.reference.dateTime}</b>
                  </Typography>
                </>
              ) : (
                <Typography mt={1} variant="body1">
                  Booking ID: <b>{carHireItem.id_reservation}</b>
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Typography mt={3} variant="h6" display="flex" flexDirection="row" justifyContent="end">
        <Box mr={2}>Total Price:</Box>
        <b>{currencyFormatter(currencyCode, carHireItem.total, 2)}</b>
      </Typography>

      {bookingInfo?.failureReason && (
        <Box mt={4}>
          <hr />
          <Box mt={2}>
            <Typography variant="body1">
              Failure Reason: <b>{bookingInfo.failureReason}</b>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default CarHireOrderItemDetails;
