import React from 'react';

import { RJSFSchema } from '@rjsf/utils';
import currencyFormatter from 'currency-formatter';

import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import ManualPaymentsActions from '~/components/Finance/OfferDetail/ManualPayments/ManualPaymentsActions';

import { formatDateShortDD, formatDateWithClock } from '~/services/TimeService';

export type ManualPaymentsRecord = {
  id_manual_payment?: string;
  date_of_manual_payment: string;
  fk_offer: string;
  fk_vendor: string;
  currency: string;
  amount: string;
  le_entity_currency: string;
  le_entity_amount: string;
  bank_transaction: string;
  description: string;
};

interface Props {
  schema: RJSFSchema;
  manualPayments: ManualPaymentsRecord[];
  totalManualPayments: {
    amount: number;
    currency: string;
  };
  onManualPaymentUpdated: (manualPayment: ManualPaymentsRecord) => void;
  onManualPaymentDeleted: (manualPaymentId: string) => void;
}

const columns: GridColDef[] = [
  {
    field: 'date_of_manual_payment',
    headerName: 'Date Of Payment',
    width: 200,
    valueFormatter: (value) => formatDateShortDD(value),
    display: 'flex',
  },
  {
    field: 'amount',
    headerName: 'Amount',
    width: 100,
    renderCell: (params) =>
      currencyFormatter.format(params.value, {
        code: params.row.currency,
      }),
    display: 'flex',
  },
  { field: 'currency', headerName: 'Currency', width: 100, display: 'flex' },
  {
    field: 'le_entity_amount',
    headerName: 'LE Entity Amount Paid',
    width: 200,
    renderCell: (params) =>
      currencyFormatter.format(params.value, {
        code: params.row.le_entity_currency,
      }),
    display: 'flex',
  },
  { field: 'le_entity_currency', headerName: 'LE Entity Currency', width: 200, display: 'flex' },
  { field: 'bank_transaction', headerName: 'Bank Transaction', flex: 1, display: 'flex' },
  { field: 'description', headerName: 'Description', flex: 1, display: 'flex' },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 100,
    valueFormatter: (value) => formatDateWithClock(value),
    display: 'flex',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    align: 'center',
    renderCell: ManualPaymentsActions,
    display: 'flex',
  },
];

export function ManualPaymentsList({
  schema,
  manualPayments,
  totalManualPayments,
  onManualPaymentUpdated,
  onManualPaymentDeleted,
}: Props) {
  const manualPaymentsWithCallBacks = manualPayments.map((element) => {
    return {
      ...element,
      schema,
      onManualPaymentUpdated,
      onManualPaymentDeleted,
    };
  });

  return (
    <>
      <Typography component="p" variant="body1" fontSize={18} mb={1}>
        Total Paid to Vendor {totalManualPayments.currency}{' '}
        {currencyFormatter.format(totalManualPayments.amount, {
          code: totalManualPayments.currency,
        })}
      </Typography>

      <DataGrid
        getRowId={(row) => row.id_manual_payment}
        rows={manualPaymentsWithCallBacks}
        columns={columns}
        paginationMode="client"
        slots={{
          pagination: GridPagination,
        }}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
      />
    </>
  );
}
