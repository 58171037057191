import React, { useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import DataObjectIcon from '@mui/icons-material/DataObject';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

import { humanize } from '~/utils/humanize';

import FilteredDetail from './FilteredDetail';
import mappers from './mappers';
import { TraverseObject } from './utils';

type IDebugProps = {
  type: 'generic' | 'offer' | 'property' | 'discountItems';
  fullScreen?: boolean;
  data: unknown;
  title?: string;
  style?: unknown;
};

export default function DebugModal(props: IDebugProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [detailed, setDetailed] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const importantMap = useMemo(() => {
    return (mappers[props.type] ?? (() => null))(props.data);
  }, [props]);

  return (
    <>
      <Button onClick={() => setOpen(true)} title="Open debug modal">
        {props.title ?? 'Debug Modal'}&nbsp;
        <DataObjectIcon />
      </Button>

      <Dialog
        open={open}
        fullScreen={props?.fullScreen ?? false}
        sx={{ margin: props?.fullScreen ? '2rem' : '' }}
        maxWidth={props?.fullScreen ? false : 'lg'}
        onClose={() => setOpen(false)}
      >
        <DialogTitle textTransform="capitalize">{humanize(props.type)}</DialogTitle>

        <DialogContent sx={{ minWidth: '50vw' }}>
          {importantMap !== null && !detailed && <TraverseObject obj={importantMap} />}
          {detailed && <FilteredDetail details={props.data} />}
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(JSON.stringify(props.data, null, 2));
              enqueueSnackbar('JSON copied to clipboard', { variant: 'success' });
            }}
          >
            Copy JSON to Clipboard
          </Button>
          <Stack direction="row" gap={2}>
            <Button
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setDetailed(!detailed);
              }}
            >
              {detailed ? `Summary ${props.type} View` : `Full ${props.type} Details`}
            </Button>

            <Button
              variant="outlined"
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
              }}
            >
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
