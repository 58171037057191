import React from 'react';

import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { ITEM_TYPE_CAR_HIRE, ITEM_TYPE_SERVICE_FEE, ITEM_TYPE_SUBSCRIPTION } from '~/consts/order';
import {
  CAR_HIRE_REASONS,
  COLLECTION_REASONS,
  EXPIRED_ONLY_TOUR_REFUND_ALLOWED_TYPES,
  EXPIRED_TOUR_REFUND_ALLOWED_TYPES,
  SERVICE_FEE_ALLOWED_TYPES,
  STEP_ADDITIONAL_INFO,
  SUBSCRIPTION_REASONS,
  TNFH_REASON_SOURCE,
} from '~/consts/refund';

export default class SelectTypeOfRefund extends React.Component {
  componentDidMount() {
    this.props.setBackStep(null);
  }

  handler = () => {
    let refund = this.props.refund;
    let setDefaultValuesForReason = this.props.setDefaultValuesForReason;
    this.props.stepHandler(function (error, next) {
      if (refund.reason === '') {
        error('A refund reason must be selected');
        return;
      }

      setDefaultValuesForReason();

      next(STEP_ADDITIONAL_INFO);
    });
  };

  render() {
    const itemType = this.props.itemType;
    const order = this.props.order;

    let refundTypes;
    let reasons = COLLECTION_REASONS;

    if (this.props.refund.is_expired_tour_refund) {
      refundTypes = Object.keys(COLLECTION_REASONS).filter((key) => EXPIRED_TOUR_REFUND_ALLOWED_TYPES.includes(key));
    } else {
      switch (itemType) {
        case ITEM_TYPE_CAR_HIRE:
          refundTypes = Object.keys(CAR_HIRE_REASONS);
          reasons = CAR_HIRE_REASONS;
          break;
        case ITEM_TYPE_SERVICE_FEE:
          refundTypes = Object.keys(COLLECTION_REASONS).filter((key) => SERVICE_FEE_ALLOWED_TYPES.includes(key));
          break;
        case ITEM_TYPE_SUBSCRIPTION:
          refundTypes = Object.keys(SUBSCRIPTION_REASONS);
          reasons = SUBSCRIPTION_REASONS;
          break;
        default:
          refundTypes = Object.keys(COLLECTION_REASONS).filter(
            (key) => !EXPIRED_ONLY_TOUR_REFUND_ALLOWED_TYPES.includes(key),
          );
      }

      const hasAccommodation = !!order.accommodation_items.length || !!order.bedbank_items.length;
      const hasCruise = !!order.cruise_items.length;
      const hasTour = !!order.tour_items.length;

      if (hasAccommodation || hasCruise || hasTour) {
        if (!this.props.isRefundable) {
          // Change refund reason type when outside cancellation period
          if (reasons['INSURANCE_BREAKAGE']) {
            reasons['INSURANCE_BREAKAGE'] = {
              ...reasons['INSURANCE_BREAKAGE'],
              defaults: {
                ...reasons['INSURANCE_BREAKAGE'].defaults,
                source: TNFH_REASON_SOURCE,
              },
            };
          }
        }
      } else {
        refundTypes = refundTypes.filter((key) => key !== 'INSURANCE_BREAKAGE');
      }
    }

    return (
      <Box>
        <FormControl id="reason">
          <FormLabel>Select Type of Refund</FormLabel>
          <RadioGroup name="reason" onChange={this.props.handleValueChange} value={this.props.refund.reason}>
            {refundTypes
              .filter((key) => !reasons[key].hidden)
              .map((key) => (
                <FormControlLabel
                  key={key}
                  value={reasons[key].key}
                  control={<Radio />}
                  label={
                    <span>
                      <Typography fontWeight="bold" component="span" display="block">
                        {reasons[key].label || reasons[key].key}
                      </Typography>
                      <Typography component="span" display="block">
                        {reasons[key].description}
                      </Typography>
                    </span>
                  }
                  disableTypography
                  sx={{ py: 1 }}
                />
              ))}
          </RadioGroup>
        </FormControl>

        <Button variant="contained" className="T-submit" onClick={this.handler}>
          Continue
        </Button>
      </Box>
    );
  }
}
