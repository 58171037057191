import React, { useState } from 'react';

import BookmarksIcon from '@mui/icons-material/Bookmarks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

interface Props {
  templates: App.OfferTemplate[];
  templateTitle: string;
  show: boolean;
  hide: () => void;
  loadTemplate: (template: App.OfferTemplate) => void;
}

const emptyTemplate = {
  content: '',
  created_at: '',
  description: '',
  id: -1,
  offer_type: '',
  status: 'approved',
  template_type: 'fine_print',
  title: '',
  updated_at: '',
};

function PropertyContentTemplateModal(props: Props) {
  const [selectedTemplate, setSelectedTemplate] = useState<App.OfferTemplate>(emptyTemplate);

  function onChangeSelectedTemplate(e) {
    const title = e.target.value;
    //blank <option>
    if (!title) {
      setSelectedTemplate(emptyTemplate);
      return;
    }

    const selectedTemplate = props.templates.find((template) => template.title === title);
    setSelectedTemplate(selectedTemplate);
  }

  return (
    <Dialog open={props.show} onClose={props.hide}>
      <DialogTitle>Please select template</DialogTitle>
      <DialogContent>
        <Grid mt={1} sm={6}>
          <FormControl fullWidth>
            <TextField
              value={props.templateTitle}
              disabled
              name="Template Type"
              id="template-type"
              label="Template Type"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BookmarksIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              color="warning"
              fullWidth
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid mt={2} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Template Title</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedTemplate?.title}
              label="Template Title"
              onChange={onChangeSelectedTemplate}
            >
              {props.templates.length === 0 && <MenuItem value={''}>None found</MenuItem>}
              {props.templates.length !== 0 && <MenuItem disabled value={''}></MenuItem>}
              {props.templates.map((template) => (
                <MenuItem key={template.id} value={template.title}>
                  {template.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.hide} variant="contained" color="secondary">
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.loadTemplate(selectedTemplate)}
          disabled={props.templates.length === 0 || selectedTemplate.id === -1}
        >
          Import Template
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PropertyContentTemplateModal;
