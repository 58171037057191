import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import { Close, Done, HourglassBottom, HourglassDisabled, HourglassFull, PanTool } from '@mui/icons-material';
import { Box, Button, Grid, Link, MenuItem, Select, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import CopyableUserLink from '~/components/Common/CopyableUserLink';
import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import DebugModal from '~/components/DebugModal/DebugModal';
import { withTenant } from '~/components/hoc';

import useQuery from '~/hooks/useQuery';

import { ReferralConfig, getReferralLogs } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';
import dateFormatter from '~/utils/dateFormatter';
import isUUID from '~/utils/isUUID';

import DateHighlightIfPast from '../formatters/DateHighlightIfPast';
import usePromoMeta from '../hooks/usePromoMeta';

import ReferralLogListProcessor from './ReferralLogListProcessor';
import ReferralLogsProcessor from './ReferralLogProcessor';
import ResetReferralLogButton from './ResetReferralLogButton';

interface Props {
  tenant: App.Tenant;
}

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 1,
};

const getColumns = (referralConfig: ReferralConfig): GridColDef[] => [
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => {
      if (!params.row) {
        return;
      }
      if (params.row.log_status == 'redeemed') {
        return (
          <Box title={`Referral event complete ${params.row.updated_at}`}>
            <Done />
          </Box>
        );
      }
      if (!('referee_order' in params.row) || !(params.row.referee_order && 'status' in params.row.referee_order)) {
        return (
          <Box title={`Could not get current order from the orderId on referral log`}>
            <Close />
          </Box>
        );
      }

      if (params.row.referee_order.status !== 'completed') {
        return (
          <Box title={`Order Status is not complete [${params.row.referee_order.status}]`}>
            <PanTool />
          </Box>
        );
      }

      if (params.row.processing_attempts > referralConfig.maxProcessingAttemptsLimit) {
        return (
          <Box title={`Too many processing attempts: ${params.row.processing_attempts}`}>
            <HourglassDisabled />
          </Box>
        );
      }

      if (new Date(params.row.available_by) < new Date()) {
        return (
          <Box title={`Referral is waiting for job to run`}>
            <HourglassFull />
          </Box>
        );
      } else {
        return (
          <Box title={`Referral is still waiting for cooldown to expire`}>
            <HourglassBottom />
          </Box>
        );
      }
    },
    ...sharedOpts,
    flex: 0.3,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    editable: false,
    renderCell: (params) => dateFormatter(params.row.created_at),
    ...sharedOpts,
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    editable: false,
    renderCell: (params) => dateFormatter(params.row.updated_at),
    ...sharedOpts,
  },
  {
    field: 'log_status',
    headerName: 'Log Status',
    renderCell: (params) =>
      `${params.row.log_status}${
        params.row.earn_amount && params.row.currency_applied_in
          ? ` (${currencyFormatter(params.row.currency_applied_in, params.row.earn_amount)})`
          : ''
      }`,
    ...sharedOpts,
  },
  {
    field: 'referrer_email_sent',
    headerName: 'Email Sent',
    ...sharedOpts,
  },
  {
    field: 'processing_attempts',
    headerName: 'Processing Attempts',
    ...sharedOpts,
  },
  {
    field: 'available_by',
    headerName: 'Available By',
    renderCell: (params) => {
      if (!params.row.available_by) {
        return (
          <Box title="No Available by calculated - this is unexpected - see calculateCooldownDate in svc-promo for this order">
            <Stack direction="column">
              {params.row.error ? (
                <DebugModal type="generic" data={params.row.error} title={`${params.row.error.message}`} />
              ) : (
                'No Available By? - This is unexpected'
              )}
            </Stack>
          </Box>
        );
      }

      return DateHighlightIfPast({ date: new Date(params.row.available_by) });
    },
    ...sharedOpts,
  },
  {
    field: 'referee_order_region',
    headerName: 'Region',
    ...sharedOpts,
  },
  {
    field: 'order',
    headerName: 'Order',
    display: 'flex',
    renderCell: (params) => (
      <Stack direction="column">
        {params.row.referree_order_id ? (
          <Link href={`/purchases/${params.row.referree_order_id}`} target="_blank">
            Referrer Order
          </Link>
        ) : (
          'No Referree OrderId?'
        )}
        {params.row.id_promo_code ? (
          <Link href={`/promos/${params.row.id_promo_code}`} target="_blank">
            Referree Promo
          </Link>
        ) : (
          'No Promo Code?'
        )}
      </Stack>
    ),
    ...sharedOpts,
  },
  {
    field: 'source',
    headerName: 'Source (Referrer)',
    renderCell: (params) => <CopyableUserLink userId={params.row.referrer_user_id} label={'Referrer'} />,
    ...sharedOpts,
  },
  {
    field: 'target',
    headerName: 'Target (Referee)',
    renderCell: (params) => (
      <CopyableUserLink
        userId={params.row.referree_user_id}
        label={params.row.first_name ?? params.row.referree_user_id}
      />
    ),
    ...sharedOpts,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    renderCell: (params) => <ResetReferralLogButton referralLogId={params.row.referral_log_id} />,
    ...sharedOpts,
  },
  {
    field: 'debug',
    headerName: 'Raw',
    renderCell: (params) => <DebugModal type="generic" data={params.row ?? { data: 'None' }} fullScreen />,
    ...sharedOpts,
  },
];

const PAGE_SIZE = 50;

function ReferralLogs({ tenant }: Props) {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [dataState, setData] = useState([]);
  const [config, setConfig] = useState<ReferralConfig>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>(query.get('q') || query.get('userId') || '');
  const [logStatus, setLogStatus] = useState<string>(query.get('referral_log_status') || 'all');
  const [pageNum, setPageNum] = useState<number>(Number(query.get('page_num')) || 0);
  const [resultTotal, setResultTotal] = useState(0);
  const isDev = !!query.get('isDev');
  const [createdAfter, setCreatedAfter] = useState<string>(query.get('created_after') || '');
  const [orderId, setOrderId] = useState<string>(query.get('orderId') || '');

  const { promoMeta } = usePromoMeta();

  const setQueryParams = useCallback(() => {
    const qp = new URLSearchParams();
    qp.set('q', userId);
    qp.set('referral_log_status', logStatus);
    qp.set('created_after', createdAfter);
    qp.set('orderId', orderId);
    qp.set('page_num', pageNum.toString());
    window.history.pushState({}, '', `${window.location.pathname}?${qp.toString()}`);
  }, [userId, logStatus, createdAfter, orderId, pageNum]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    if (userId && !isUUID(userId)) {
      enqueueSnackbar('Invalid User ID - must be a uuid', { variant: 'error' });
      return;
    }

    if (orderId && !isUUID(orderId)) {
      enqueueSnackbar('Invalid Order ID - must be a uuid', { variant: 'error' });
      return;
    }

    const { result, config } = await getReferralLogs(
      tenant?.brand,
      userId,
      logStatus,
      createdAfter,
      orderId,
      pageNum,
      PAGE_SIZE,
    );
    const { logs, total } = result;
    setData(logs ?? []);
    setConfig(config);
    setResultTotal(Number(total));
    setIsLoading(false);
    setQueryParams();
  }, [pageNum, logStatus, userId, orderId, createdAfter, enqueueSnackbar, setQueryParams, tenant?.brand]);

  const dataRows = dataState.map((row, index) => ({
    id: index,
    ...row,
  }));

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = () => {
    fetchData();
  };

  const onPageChange = (page: number) => {
    setPageNum(page);
    query.set('page_num', page.toString());
    window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`);
    fetchData();
  };

  return (
    <div>
      <Helmet>
        <title>Referral Logs</title>
      </Helmet>

      <PageSubheader title={`Search Referral Logs [${dataState?.length} of ${resultTotal}]`} />
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <TextField
            fullWidth
            autoFocus
            type="text"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            label="User ID"
            placeholder="user ID"
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            fullWidth
            type="text"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
            label="Order ID"
            placeholder="order ID"
          />
        </Grid>
        <Grid item sm={2}>
          <Select
            placeholder="Log Status"
            name="status"
            value={logStatus}
            fullWidth
            onChange={(e) => setLogStatus(e.target.value)}
          >
            {promoMeta &&
              promoMeta.referral_log_statuses.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            <MenuItem key="none" value="all">
              ALL
            </MenuItem>
          </Select>
        </Grid>
        <Grid item sm={2}>
          <TextField
            fullWidth
            type="date"
            value={createdAfter}
            onChange={(e) => setCreatedAfter(e.target.value)}
            placeholder="Created After"
          />
        </Grid>
        <Grid item sm={1}>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Search
          </Button>
        </Grid>
        <Grid item sm={1}>
          <ReferralLogListProcessor isDev={isDev} referralLogIds={dataRows.map((row) => row.referral_log_id)} />
        </Grid>
      </Grid>
      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page)}
          loading={isLoading}
          rows={dataRows}
          columns={getColumns(config)}
          autoHeight
          rowCount={resultTotal}
          paginationMode="server"
          paginationModel={{ page: +pageNum ?? 10, pageSize: PAGE_SIZE }}
        />
        <Box mt={2}>
          <ReferralLogsProcessor isDev={isDev} />
        </Box>

        <DebugModal data={config} type="generic" />
      </Box>
    </div>
  );
}

export default withTenant(ReferralLogs);
