import React from 'react';

import { Error } from '@mui/icons-material';
import { Box, List, ListItem, Stack } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

type Travellers = definitions['Discount Response']['promo']['items'][0]['travellers'][0];

type TravellersListProps = {
  travellers?: Array<Travellers> | Travellers;
  expectTravellers?: boolean;
};
function TravellersList({ travellers, expectTravellers = false }: TravellersListProps) {
  if (Array.isArray(travellers)) {
    return (
      <Stack direction="column">
        {travellers.map((t, i) => (
          <Box key={i}>
            {t.firstName} {t.lastName}
          </Box>
        ))}
      </Stack>
    );
  }

  if (!travellers || !travellers?.firstName || !travellers?.lastName) {
    if (expectTravellers) {
      return <Error />;
    }
    return null;
  }

  return (
    <List>
      <ListItem>
        {travellers.firstName} {travellers.lastName}
      </ListItem>
    </List>
  );
}

export default TravellersList;
