import React, { ReactElement } from 'react';

import { Stack } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import featureToggle from '~/utils/featureToggle';

import CommissionableTaxesAndFeesPane from './CommissionableTaxesAndFeesPane';
import CuratedContentPane from './CuratedContentPane';
import ImagesPane from './ImagesPane';
import PropertyARIPane from './PropertyARIPane';
import PropertyPane from './PropertyPane';
import PropertyReviewsPane from './PropertyReviewsPane';
import PropertyUserReviewsPane from './PropertyUserReviewsPane';
import RoomTypePane from './RoomTypePane';
import TaxesAndFeesContentPane from './TaxesAndFeesContentPane';
import TaxesAndFeesPane from './TaxesAndFeesPane';
import VideosPane from './VideosPane';

interface Props {
  property: App.Property;
  ratePlans: Array<API.Reservation.RatePlan>;
  vendorId: string;
  downloadInProgress: boolean;
  downloadFailed: boolean;
  offers: App.Offer[];
  downloadTaxesAndFees: () => void;
}

const PropertyPage = (props: Props): ReactElement => {
  const { property, ratePlans, vendorId, downloadInProgress, downloadFailed, offers, downloadTaxesAndFees } = props;
  const roomTypes = property.room_types_count ? property.room_types : [];
  const reviewsData = property.reviews_count ? property.reviews : [];
  const contentEnabled = featureToggle.availableToShow('ADDITIONAL_PROPERTY_CONTENT_ENABLED');

  return (
    <Stack gap={4}>
      <div id="overview">
        <PropertyPane property={property} vendorId={vendorId} />
      </div>

      {contentEnabled && (
        <div id="curated-content">
          <CuratedContentPane property={property} vendorId={vendorId} />
        </div>
      )}

      <div id="room-types">
        <RoomTypePane
          roomTypes={roomTypes}
          vendorId={vendorId}
          propertyId={property.id}
          ratePlans={ratePlans}
          offers={offers}
        />
      </div>

      {contentEnabled && (
        <>
          <div id="images">
            <ImagesPane vendorId={vendorId} propertyId={property.id} images={property.images} />
          </div>

          <div id="videos">
            <VideosPane vendorId={vendorId} property={property} />
          </div>
        </>
      )}
      <div>
        <TaxesAndFeesContentPane
          taxesAndFeesContent={property.taxes_and_fees_content}
          vendorId={vendorId}
          propertyId={property.id}
          downloadInProgress={downloadInProgress}
          downloadFailed={downloadFailed}
          downloadTaxesAndFees={downloadTaxesAndFees}
        />
      </div>

      <div>
        <TaxesAndFeesPane items={property.taxes_and_fees} vendorId={vendorId} propertyId={property.id} />
      </div>

      <div>
        <CommissionableTaxesAndFeesPane
          items={property.commissionable_taxes_and_fees}
          vendorId={vendorId}
          propertyId={property.id}
        />
      </div>

      <div>
        <PropertyReviewsPane reviews={reviewsData} vendorId={vendorId} propertyId={property.id} />
      </div>

      <div>
        <PropertyUserReviewsPane propertyId={property.id} />
      </div>

      {property.outgoing_connections && property.outgoing_connections.length > 0 && (
        <div>
          <PropertyARIPane propertyId={property.id} outgoingConnections={property.outgoing_connections} />
        </div>
      )}
    </Stack>
  );
};

export default PropertyPage;
