import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { Bedbank } from '@luxuryescapes/contract-svc-bedbank';

import bedbankService from '~/services/BedbankService';

import ControlRoomElement from './RoomMapping/ControlRoomElement';
import SourceRoomElement from './RoomMapping/SourceRoomElement';
import type { MappedRoom, MappingChange, PropertyRoom, SourceRoom, Suppliers } from './RoomMapping/types';

interface Room extends Bedbank.RoomEdit {
  isSelected?: boolean;
}

interface SourceRoomsBySupplier {
  webbeds: Array<SourceRoom>;
  hotelbeds: Array<SourceRoom>;
  expedia: Array<SourceRoom>;
}

const formatSource = (rooms: Array<Bedbank.SupplierSourceRoom>, supplier: Suppliers): Array<SourceRoom> =>
  rooms.map((room) => ({
    externalId: room.externalId,
    externalPropertyId: room.externalPropertyId,
    supplier,
    name: room.name,
    // Remove HTML tags from description for Expedia rooms
    description: supplier === 'expedia' ? room.description.replace(/<[^>]*>?/gm, '') : room.description,
    amenities: room.amenities,
    bedGroups: room.bedGroups,
    views: room.views,
    characteristic: room.characteristic,
    occupancy: room.occupancy,
    isAutomappingEnabled: room.isAutoMappingEnabled,
    hasInfo: Boolean(
      room.amenities.length ||
        room.bedGroups.length ||
        room.characteristic ||
        room.views.length ||
        Object.values(room.occupancy ?? {}).length,
    ),
    sourceContent: room?.sourceContent,
    roomSize: room?.roomSize,
    availabilityScore: room.availabilityScore,
    availabilityScoreUpdatedAt: room.availabilityScoreUpdatedAt,
  }));

interface Props {
  property: App.Bedbank.PropertyEdit;
  handleChange: ({
    update,
    remove,
    automappingFlagUpdate,
  }: {
    update: App.Bedbank.SupplierRoomMappingPayload;
    remove: App.Bedbank.SupplierRoomMappingPayload;
    automappingFlagUpdate: App.Bedbank.SupplierRoomAutomappingFlagPayload;
  }) => void;
}

function BedbankRoomMapping({ property, handleChange }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [sourceRooms, setSourceRooms] = useState<SourceRoomsBySupplier>({ hotelbeds: [], webbeds: [], expedia: [] });
  const [propertyRooms, setPropertyRooms] = useState<Array<PropertyRoom>>([]);

  const [updatePayload, setUpdatePayload] = useState<App.Bedbank.SupplierRoomMappingPayload>([]);
  const [removePayload, setRemovePayload] = useState<App.Bedbank.SupplierRoomMappingPayload>([]);
  const [automappingFlagPayload, setAutomappingFlagPayload] = useState<App.Bedbank.SupplierRoomAutomappingFlagPayload>(
    [],
  );

  const [alreadyMappedRooms, setAlreadyMappedRooms] = useState<Record<string, boolean>>({});
  const [selectedRooms, setSelectedRooms] = useState<Array<string>>([]);

  const [filteringText, setFilteringText] = useState('');
  const resetFilteringText = useCallback(() => {
    setFilteringText('');
  }, []);
  const handleFilteringTextInput = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setFilteringText(event.target.value);
  }, []);
  const [supplierFilteringText, setSupplierFilteringText] = useState('');
  const resetSupplierFilteringText = useCallback(() => {
    setSupplierFilteringText('');
  }, []);
  const handleSupplierFilteringTextInput = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSupplierFilteringText(event.target.value);
  }, []);

  const [appliedButNotSavedRooms, setAppliedButNotSavedRooms] = useState<Array<SourceRoom>>([]);

  const [hideRoomsWithNoAvailabilityScore, setHideRoomsWithNoAvailabilityScore] = useState<boolean>(false);

  const toggleHideRoomsWithNoAvailabilityScore = () => {
    setHideRoomsWithNoAvailabilityScore((prev) => !prev);
  };

  const filteredPropertyRooms = useMemo(() => {
    const notExcludedRooms = propertyRooms.filter((room) => room.status !== 'excluded');
    return filteringText
      ? notExcludedRooms.filter((room) => {
          return (
            room.name.toLowerCase().includes(filteringText.toLowerCase()) ||
            room.mappedRooms
              // if there are any existing mapping that is deleted, then the parent property room should not be returned when searching by the mapping's external id
              .filter((mappedRoom) => alreadyMappedRooms[`${mappedRoom.supplier}-${mappedRoom.externalId}`])
              .some((mappedRoom) => mappedRoom.externalId.toLowerCase().includes(filteringText.toLowerCase())) ||
            room.externalId?.toLowerCase()?.includes(filteringText.toLowerCase())
          );
        })
      : notExcludedRooms;
  }, [propertyRooms, filteringText, alreadyMappedRooms]);

  const filteredSourceRooms = useMemo(() => {
    const initialRooms = [...(sourceRooms.hotelbeds ?? []), ...(sourceRooms.webbeds ?? [])];

    const supplierFilteredRooms = supplierFilteringText
      ? initialRooms.filter((room) => {
          return (
            room.name.toLowerCase().includes(supplierFilteringText.toLowerCase()) ||
            room.views?.some((view) => view.toLowerCase().includes(supplierFilteringText.toLowerCase())) ||
            room.amenities?.some((amenity) => amenity.toLowerCase().includes(supplierFilteringText.toLowerCase())) ||
            room.bedGroups?.some((bedGroup) =>
              bedGroup.description.toLowerCase().includes(supplierFilteringText.toLowerCase()),
            ) ||
            room.externalId.toLowerCase().includes(supplierFilteringText.toLowerCase()) ||
            room.description.toLowerCase().includes(supplierFilteringText.toLowerCase())
          );
        })
      : initialRooms;

    const availabilityScoreFilteredRooms = hideRoomsWithNoAvailabilityScore
      ? supplierFilteredRooms.filter((room) => room.availabilityScore !== null)
      : supplierFilteredRooms;

    // Rooms that have been applied but not saved should not be shown in the list
    const appliedButNotSavedFilteredRooms = availabilityScoreFilteredRooms.filter(
      (room) => !appliedButNotSavedRooms.some((r) => r.externalId === room.externalId),
    );

    return appliedButNotSavedFilteredRooms;
  }, [sourceRooms, supplierFilteringText, hideRoomsWithNoAvailabilityScore, appliedButNotSavedRooms]);

  const selectedFromSourceRooms = useMemo(() => {
    const selectedCount = selectedRooms.filter((key) => !key.includes('expedia')).length;

    const unmappedSourceRooms = [...(sourceRooms.hotelbeds ?? []), ...(sourceRooms.webbeds ?? [])].filter(
      (room) => !alreadyMappedRooms[`${room.supplier}-${room.externalId}`],
    );

    const totalCount = unmappedSourceRooms.length;

    const unmappedRoomsWithNoAvailabilityScoreCount = unmappedSourceRooms.filter(
      (room) => room.availabilityScore === null,
    ).length;

    const unmappedRoomsWithAvailabilityScoreCount =
      unmappedSourceRooms.length - unmappedRoomsWithNoAvailabilityScoreCount;

    return selectedCount > 0
      ? `${selectedCount} selected of ${totalCount} unmapped ${pluralize('rooms', totalCount)}`
      : `${unmappedRoomsWithAvailabilityScoreCount} 
         ${pluralize('rooms', unmappedRoomsWithAvailabilityScoreCount)} with availability score, 
        ${unmappedRoomsWithNoAvailabilityScoreCount} ${pluralize(
          'rooms',
          unmappedRoomsWithNoAvailabilityScoreCount,
        )} without availability score`;
  }, [alreadyMappedRooms, selectedRooms, sourceRooms]);

  const selectedFromPropertyRooms = useMemo(() => {
    let selectedRoomId = selectedRooms.filter((key) => key.includes('expedia'))[0] ?? '';
    selectedRoomId = selectedRoomId.split('-')[1];
    const selectedRoom = selectedRoomId
      ? filteredPropertyRooms.find((room) => room.externalId === selectedRoomId)
      : null;
    const totalCount = property.rooms.length;

    // Exclude expedia rooms since they would always be mapped
    const totalWithMappedRooms = property.rooms.filter(
      (room) => room.mappedRooms.filter((mappedRoom) => mappedRoom.supplier !== 'expedia').length > 0,
    ).length;

    const totalWithoutMappedRooms = totalCount - totalWithMappedRooms;

    return selectedRoom
      ? `${selectedRoom.name} selected`
      : `${totalWithMappedRooms} ${pluralize(
          'rooms',
          totalWithMappedRooms,
        )} with mapping, ${totalWithoutMappedRooms} ${pluralize('rooms', totalWithoutMappedRooms)} without mapping`;
  }, [selectedRooms, filteredPropertyRooms, property.rooms]);

  const updates = useMemo(() => {
    const updates: {
      [roomId: string]: Array<MappingChange>;
    } = {};

    for (const update of updatePayload) {
      if (!updates[update.roomId]) {
        updates[update.roomId] = [];
      }
      updates[update.roomId].push({
        supplier: update.supplier,
        externalId: update.supplierRoomId,
        isVerified: update.isVerified,
      });
    }
    return updates;
  }, [updatePayload]);

  const webbedsId = property['webbedsId'];
  const hotelbedsId = property['hotelbedsId'];
  const expediaId = property['externalId'];

  const supplierExternalId = (supplier: Suppliers) => {
    if (supplier === 'hotelbeds') {
      return hotelbedsId;
    } else if (supplier === 'webbeds') {
      return webbedsId;
    } else {
      return expediaId;
    }
  };

  const formatSourceRoom = (mappedRoom, supplier: Suppliers, roomId, includeVerified = false) => {
    return {
      supplier: supplier,
      roomId,
      supplierRoomId: mappedRoom.externalId,
      supplierPropertyId: supplierExternalId(supplier),
      mappingMethod: mappedRoom.mappingMethod?.length ? mappedRoom.mappingMethod : ['manual'],
      ...(includeVerified && { isVerified: mappedRoom.isVerified ?? true }),
    };
  };

  const getSourceRooms = async (): Promise<SourceRoomsBySupplier> => {
    try {
      const response = await bedbankService.getSourceRooms(property.id);
      const prettifiedWebBedsSourceRooms = formatSource(response.result['webbeds'], 'webbeds');
      const prettifiedHotelBedsSourceRooms = formatSource(response.result['hotelbeds'], 'hotelbeds');
      const prettifiedExpediaSourceRooms = formatSource(response.result['expedia'], 'expedia');

      const prettifiedData = {
        // sorting ensures rooms with more info are at the top
        hotelbeds: prettifiedHotelBedsSourceRooms.sort((a, b) => {
          if (a.hasInfo && !b.hasInfo) return -1;
          if (b.hasInfo && !a.hasInfo) return 1;
          return 0;
        }),
        webbeds: prettifiedWebBedsSourceRooms.sort((a, b) => {
          if (a.hasInfo && !b.hasInfo) return -1;
          if (b.hasInfo && !a.hasInfo) return 1;
          return 0;
        }),
        expedia: prettifiedExpediaSourceRooms.sort((a, b) => {
          if (a.hasInfo && !b.hasInfo) return -1;
          if (b.hasInfo && !a.hasInfo) return 1;
          return 0;
        }),
      };

      return prettifiedData;
    } catch (error) {
      console.error('Error fetching source rooms', error);
      return { hotelbeds: [], webbeds: [], expedia: [] };
    }
  };

  const handleRoomSelection = (room: PropertyRoom | SourceRoom, supplier: Suppliers) => {
    let update = [...selectedRooms];
    // SourceRoom should always have an externalId, so the typescript error can be ignored
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const key = `${supplier}-${room.externalId ?? room.id}`;
    if (update.includes(key)) {
      update.splice(update.indexOf(key), 1);
    } else {
      update.push(key);
    }
    // ensure only one expedia room is selected at a time
    if (supplier === 'expedia') {
      update = update.filter((val) => !val.includes('expedia') || val === key);
    }
    setSelectedRooms(update);
  };

  const handleSetAutomappingFlags = (
    supplierRoomId: string,
    externalPropertyId: string,
    supplier: Suppliers,
    checked: boolean,
  ) => {
    const newAutomappingFlagPayload = [...automappingFlagPayload];

    const index = newAutomappingFlagPayload.findIndex(
      (flag) => flag.supplierRoomId === supplierRoomId && flag.supplier === supplier,
    );
    if (index !== -1) {
      // if there already exist a flag update, it means we re-toggled the switch
      // thus we should remove the old update (since toggling twice means no update)
      newAutomappingFlagPayload.splice(index, 1);
    } else {
      newAutomappingFlagPayload.push({
        supplierRoomId: supplierRoomId,
        externalPropertyId: externalPropertyId,
        supplier,
        isAutoMappingEnabled: checked,
      });
    }

    setAutomappingFlagPayload(newAutomappingFlagPayload);
    handleChange({
      update: updatePayload,
      remove: removePayload,
      automappingFlagUpdate: newAutomappingFlagPayload,
    });
  };

  const handleUpdateMapping = () => {
    // get all selected source rooms
    const nonExpediaMappedRooms: Array<SourceRoom> = selectedRooms
      .filter((key) => !key.includes('expedia'))
      .map((key) => {
        const [supplier, ...rest] = key.split('-');
        // Keys can be in the format `hotelbeds-DBL.GX-GV`
        const externalId = rest.join('-');
        return sourceRooms[supplier].find((sr: SourceRoom) => sr.externalId === externalId);
      })
      .filter(Boolean); // remove any falsy values

    if (nonExpediaMappedRooms.length === 0) {
      enqueueSnackbar('Please select at least one source room', { variant: 'error' });
      return;
    }

    // get selected expedia room
    const room = selectedRooms
      .filter((key) => key.includes('expedia'))
      .map((key) => {
        const [_, externalId] = key.split('-');
        return propertyRooms.find((r) => r.externalId === externalId);
      })[0]; // singular to map to

    if (!room) {
      enqueueSnackbar('Please select a room from property rooms', { variant: 'error' });
      return;
    }

    setAppliedButNotSavedRooms([...nonExpediaMappedRooms, ...appliedButNotSavedRooms]);

    // update payload
    const newMappings = nonExpediaMappedRooms.map((sr) => formatSourceRoom(sr, sr.supplier, room.id, true));
    const newUpload = new Set([...updatePayload, ...newMappings]); // using set to ensure duplicates are trimmed
    setUpdatePayload(Array.from(newUpload));
    handleChange({
      update: Array.from(newUpload),
      remove: removePayload,
      automappingFlagUpdate: automappingFlagPayload,
    });
    // clear source rooms from selection
    setSelectedRooms([`expedia-${room.externalId}`]);
  };

  const handleRemove = (mappedRoom: MappedRoom) => {
    const remove: App.Bedbank.SupplierRoomMappingPayload = [
      ...removePayload,
      formatSourceRoom(mappedRoom, mappedRoom.supplier, mappedRoom.roomId),
    ];
    const newAlreadyMappedRooms = { ...alreadyMappedRooms };
    delete newAlreadyMappedRooms[`${mappedRoom.supplier}-${mappedRoom.externalId}`];
    setAlreadyMappedRooms(newAlreadyMappedRooms);
    setRemovePayload(remove);
    handleChange({
      update: updatePayload,
      remove,
      automappingFlagUpdate: automappingFlagPayload,
    });
  };

  const verifyMappedRoomModel = (mappedRoomModel: MappedRoom) => {
    const update = [...updatePayload];
    update.push({
      roomId: mappedRoomModel.roomId,
      supplierRoomId: mappedRoomModel.externalId,
      supplierPropertyId: mappedRoomModel.externalPropertyId,
      supplier: mappedRoomModel.supplier,
      mappingMethod: ['manual'],
      isVerified: true,
    });
    const newAppliedButNotSavedRooms = [...appliedButNotSavedRooms, mappedRoomModel.sourceContent];
    setAppliedButNotSavedRooms(newAppliedButNotSavedRooms);
    setUpdatePayload(update);
    handleChange({
      update,
      remove: removePayload,
      automappingFlagUpdate: automappingFlagPayload,
    });
  };

  const fetchData = async () => {
    const alreadyMapped: Record<string, boolean> = {};
    const fetchedSourceRooms = await getSourceRooms();

    setPropertyRooms(
      property.rooms.map((room: Room) => {
        const { id, name, descriptionOverride, mappedRooms: mappedRoomsModel, supplier, externalId, status } = room;
        const sourceRoom = mappedRoomsModel.find((sr) => sr.externalId === externalId && sr.supplier === supplier);
        return {
          id,
          name,
          description: descriptionOverride,
          externalId: sourceRoom?.externalId ?? externalId,
          mappedRooms: mappedRoomsModel.map<MappedRoom>((mappedRoom: App.Bedbank.MappedRoom) => {
            alreadyMapped[`${mappedRoom.supplier}-${mappedRoom.externalId}`] = true;
            return {
              ...mappedRoom,
              sourceContent: fetchedSourceRooms[mappedRoom.supplier]?.find(
                (sp) => sp.externalId === mappedRoom.externalId,
              ),
            };
          }),
          supplier,
          status,
        };
      }),
    );
    setSourceRooms(fetchedSourceRooms);
    setAlreadyMappedRooms(alreadyMapped);
    setIsLoading(false);
  };

  const clearNewMappings = (room: PropertyRoom) => {
    const newMappings = updatePayload.filter((update) => update.roomId !== room.id);
    setUpdatePayload(newMappings);
    // Any applied but not saved rooms that is not in the update payload should be cleared (only ones in the update payload should be kept)
    setAppliedButNotSavedRooms(
      appliedButNotSavedRooms.filter((r) =>
        newMappings.some((mappedRoom) => mappedRoom.supplierRoomId === r.externalId),
      ),
    );
    handleChange({
      update: newMappings,
      remove: removePayload,
      automappingFlagUpdate: automappingFlagPayload,
    });
  };

  useEffect(() => {
    async function firstLoad() {
      setIsLoading(true);
      await fetchData();
    }
    firstLoad();
  }, []);

  return (
    <Container maxWidth="xl" disableGutters>
      <Stack direction="row" spacing={2} mb={2} justifyContent="space-between">
        <Typography variant="h6" display="flex" justifyContent="space-evenly" width="100%">
          {updatePayload.length + automappingFlagPayload.length > 0 && (
            <Alert color="success">{updatePayload.length + automappingFlagPayload.length} changes to apply</Alert>
          )}
          {removePayload.length > 0 && <Alert color="error">{removePayload.length} changes to apply</Alert>}
          {updatePayload.length === 0 &&
            removePayload.length === 0 &&
            automappingFlagPayload.length === 0 &&
            'Map Source Rooms to Property Rooms'}
        </Typography>
      </Stack>
      {isLoading && (
        <Box m={2}>
          <LinearProgress />
        </Box>
      )}
      <Box display="grid" gridTemplateColumns="480px 1fr" gap={3}>
        {/* Container for Suppliers */}
        <Box position="relative">
          <Stack direction="column" gap={2} position="sticky" top={0} overflow="auto" height="calc(100dvh - 56px)">
            <Paper square sx={{ position: 'sticky', top: 0, zIndex: 1, pt: 1 }}>
              <Stack direction="column" gap={2}>
                <Typography variant="h6">Source Rooms: {selectedFromSourceRooms}</Typography>
                <Stack direction="column" gap={1}>
                  <TextField
                    fullWidth
                    label="Filter by room name:"
                    value={supplierFilteringText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={resetSupplierFilteringText}
                            disabled={supplierFilteringText.length < 1}
                            title="clear"
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onInput={handleSupplierFilteringTextInput}
                  />
                  <div>
                    <Tooltip
                      title="Availability score is a measure of how often a room has available rates"
                      placement="right-end"
                    >
                      <FormControlLabel
                        checked={hideRoomsWithNoAvailabilityScore}
                        onChange={toggleHideRoomsWithNoAvailabilityScore}
                        control={<Checkbox />}
                        label="Hide rooms with no availability score"
                      />
                    </Tooltip>
                  </div>
                </Stack>
              </Stack>
              <Divider />
            </Paper>
            <Stack direction="column" gap={2}>
              {filteredSourceRooms?.map(
                (room, ind) =>
                  !alreadyMappedRooms[`${room.supplier}-${room.externalId}`] && (
                    <SourceRoomElement
                      key={`${room.supplier}-${room.externalId}-${ind}`}
                      room={room}
                      onSelect={handleRoomSelection}
                      isSelected={selectedRooms.includes(`${room.supplier}-${room.externalId}`)}
                      isAutomappingFlagChanged={automappingFlagPayload.some(
                        (payload) => payload.supplierRoomId === room.externalId && payload.supplier === 'hotelbeds',
                      )}
                      handleSetAutomappingFlags={handleSetAutomappingFlags}
                      supplier={room.supplier}
                    />
                  ),
              )}
              {supplierFilteringText && filteredSourceRooms.length === 0 && (
                <Typography>No rooms found based on filter</Typography>
              )}
            </Stack>
          </Stack>
        </Box>
        {/* Container for LE/Expedia Rooms */}
        <Stack direction="column" gap={2}>
          <Paper square sx={{ position: 'sticky', top: 0, zIndex: 1, pt: 1 }}>
            <Stack direction="column" gap={2}>
              <Typography variant="h6">Property Rooms: {selectedFromPropertyRooms}</Typography>
              <TextField
                label="Filter by room name, room ID, or mapped room ID:"
                value={filteringText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={resetFilteringText}
                        disabled={filteringText.length < 1}
                        title="clear"
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onInput={handleFilteringTextInput}
              />
              <Button
                variant="contained"
                startIcon={<AccountTreeIcon />}
                title="Map selected source rooms to the selected Room"
                onClick={handleUpdateMapping}
                disabled={selectedRooms.length <= 1}
              >
                Apply selected Mapping
              </Button>
              <Divider />
            </Stack>
          </Paper>
          <Stack direction="column" gap={2}>
            {filteredPropertyRooms?.map((room) => (
              <ControlRoomElement
                key={room.id}
                room={room}
                isSelected={selectedRooms.includes(`expedia-${room.externalId}`)}
                onSelect={handleRoomSelection}
                onRemoveMappedRoom={handleRemove}
                verifyMappedRoomModel={verifyMappedRoomModel}
                newMappings={updates[room.id] ?? []}
                appliedButNotSavedRooms={appliedButNotSavedRooms}
                clearNewMappings={() => clearNewMappings(room)}
                removePayload={removePayload}
                automappingFlagPayload={automappingFlagPayload}
                handleSetAutomappingFlags={handleSetAutomappingFlags}
              />
            ))}
            {filteringText && filteredPropertyRooms.length === 0 && (
              <Typography>No rooms found based on filter</Typography>
            )}
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}

export default BedbankRoomMapping;
