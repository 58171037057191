import React, { useMemo, useState } from 'react';

import { DndContext, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { Control, Controller, useController, useFieldArray } from 'react-hook-form';
import { theme } from '~/theme';

import AddIcon from '@mui/icons-material/Add';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FinePrintIcon from '@mui/icons-material/ManageSearch';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import AIGeneratorButton, { AIContext } from '~/components/AIGenerator/AIGenerateButton';
import { Input, OfferInclusionsSelect, RichTextEditor } from '~/components/Common/Forms/fields';
import OverridableRichText from '~/components/Offers/Detail/OverridableRichText';

import featureToggle from '~/utils/featureToggle';

import AdditionalUSPComponent from '../../../AIGenerator/AdditionalUSPComponent';
import ArrayFieldItem from '../fields/ArrayFieldItem';

import { PropertyContext } from './OfferForm';

interface Context extends Omit<AIContext, 'field'> {
  model: string;
  field?: string;
}

interface Props {
  control: Control<any>;
  propertyContext: PropertyContext;
  hasLocalizedName: boolean;
  hasLocalizedDescription: boolean;
  hasLocalizedAdditionalDescription: boolean;
  isOfferTypeTour: boolean;
  isOfferTypeRental: boolean;
  isOfferTypeAccommodation: boolean;
  schema: any;
  setValue: (name: string, value: unknown, config?: object) => void;
  onShowTemplateModalClick: (templateType: string) => void;
  generatorContext: Context;
  importOfferInclusions: () => void;
  vendorId: string;
  onAppendLPFinePrint: (offerType: string) => void;
}

const OfferFieldsAccordion = ({
  control,
  propertyContext,
  hasLocalizedName,
  hasLocalizedDescription,
  hasLocalizedAdditionalDescription,
  isOfferTypeTour,
  isOfferTypeRental,
  isOfferTypeAccommodation,
  schema,
  setValue,
  onShowTemplateModalClick,
  generatorContext,
  importOfferInclusions,
  vendorId,
  onAppendLPFinePrint,
}: Props) => {
  const catalogueLink = `/vendors/${vendorId}/inclusions`;
  const [showLegacyInclusionField, setShowLegacyInclusionField] = useState(false);
  const [descriptionUsps, setDescriptionUsps] = useState<Array<string>>([]);
  const [locationUsps, setLocationUsps] = useState<Array<string>>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFinePrintTemplateSelection = (template: string) => {
    onAppendLPFinePrint(template);
    handleClose();
  };

  const locationsVisitedArray = useFieldArray({
    control,
    name: 'locations_visited',
  });
  const {
    field: { value: locationsVisitedValue, onChange: onChangeArray },
    fieldState: { error: locationsVisitedError },
  } = useController({
    control,
    name: 'locations_visited',
  });

  const sortableLocationsVisited = useMemo<UniqueIdentifier[]>(
    () => locationsVisitedArray.fields?.map((item, index) => `${item.id}-${index}`) ?? [],
    [locationsVisitedArray.fields],
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const oldIndex = sortableLocationsVisited.indexOf(active?.id);
      const newIndex = sortableLocationsVisited.indexOf(over?.id);
      const newArray = arrayMove(locationsVisitedValue, oldIndex, newIndex);
      setValue('locations_visited', newArray);
    }
  };

  const onLocationVisitedChange = (e, name, index) => {
    const newArray = [...locationsVisitedValue];
    const updatedValue = { ...newArray[index], [name]: e.target.value };
    newArray.splice(index, 1, updatedValue);
    setValue(e.target.name, e.target.value);
    onChangeArray(newArray);
  };

  const contentEnabled = featureToggle.availableToShow('ADDITIONAL_PROPERTY_CONTENT_ENABLED');

  const overridableContent = contentEnabled && isOfferTypeAccommodation;

  return (
    <Accordion defaultExpanded disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="offer-fields"
        id="offer-fields"
        sx={{
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Typography variant="h5">Offer page</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack mt={2} spacing={2}>
          <Input
            control={control}
            fullWidth
            helperText={
              hasLocalizedName && "Note: this field has market-specific localisation (check 'Localised Content' tab)"
            }
            label="Headline"
            name="name"
          />
          <AIGeneratorButton
            context={{
              model: generatorContext.model,
              field: 'headline',
              propertyName: generatorContext.name,
            }}
            content={generatorContext.name}
            buttonLabel={generatorContext.name ? 'Rewrite Headline' : 'Generate Headline'}
            onChoose={(value) => setValue('name', value)}
          />
          <Input control={control} fullWidth label="Name override for EDM" name="name_override_for_edm" />
          <Input control={control} fullWidth label="Name of property or Tour location" name="location" />
          {isOfferTypeTour && (
            <>
              <Input control={control} fullWidth label="Start location" name="start_location" />
              <Input control={control} fullWidth label="End location" name="end_location" />
              <Input control={control} fullWidth label="Vendor name" name="vendor_name" />
              <Input control={control} fullWidth label="Vendor vehicle" helperText="Ship Name" name="vendor_vehicle" />
            </>
          )}
          {!isOfferTypeRental && (
            <Input control={control} fullWidth label="Cabin types" helperText="Cruise cabin types" name="cabin_types" />
          )}
          <Input control={control} fullWidth helperText="Leave blank to auto-generate slug" label="Slug" name="slug" />
          <Input
            control={control}
            fullWidth
            helperText="Maximum 6 characters eg. boat, person or villa"
            label="Sale unit"
            name="sale_unit"
          />
          <Input control={control} fullWidth label="Email subject line" name="subject_line" />
          <Input control={control} fullWidth label="Email preheader" name="preheader" />
          {!isOfferTypeRental && (
            <>
              <Box width={'100%'}>
                {overridableContent && (
                  <OverridableRichText
                    label="Description"
                    offer={control}
                    field="description"
                    helperText={
                      hasLocalizedDescription &&
                      "Note: this field has market-specific localisation (check 'Localised Content' tab)"
                    }
                    propertyContext={propertyContext}
                  />
                )}
                {!overridableContent && (
                  <RichTextEditor
                    control={control}
                    helperText={
                      hasLocalizedDescription &&
                      "Note: this field has market-specific localisation (check 'Localised Content' tab)"
                    }
                    label="Description"
                    name="description"
                  />
                )}
              </Box>
              <AIGeneratorButton
                context={{
                  model: generatorContext.model,
                  field: 'description',
                  propertyName: generatorContext.name,
                }}
                content={generatorContext.description}
                buttonLabel={generatorContext.description ? 'Rewrite Description' : 'Generate Description'}
                onChoose={(value) => setValue('description', value)}
              >
                <AdditionalUSPComponent
                  contextKey="additionalUSP"
                  value={descriptionUsps}
                  onValueChange={setDescriptionUsps}
                />
              </AIGeneratorButton>
              <Box sx={{ width: '100%' }}>
                {overridableContent && (
                  <OverridableRichText
                    label="Additional Description"
                    offer={control}
                    field="additional_description"
                    helperText={
                      hasLocalizedDescription &&
                      "Note: this field has market-specific localisation (check 'Localised Content' tab)"
                    }
                    propertyContext={propertyContext}
                  />
                )}
                {!overridableContent && (
                  <RichTextEditor
                    control={control}
                    helperText={
                      hasLocalizedAdditionalDescription &&
                      "Note: this field has market-specific localisation (check 'Localised Content' tab)"
                    }
                    label="Additional Description"
                    name="additional_description"
                  />
                )}
              </Box>
              <Input control={control} fullWidth label="Offer inclusion heading" name="offer_tile_inclusion_heading" />
              {!isOfferTypeTour && (
                <OfferInclusionsSelect
                  control={control}
                  label="Offer inclusions"
                  name="offer_inclusions"
                  setValue={setValue}
                  importOfferInclusions={importOfferInclusions}
                />
              )}
              {!isOfferTypeTour && (
                <div>
                  <Button href={catalogueLink} target="_blank">
                    Inclusion catalogue
                  </Button>
                </div>
              )}
              {!isOfferTypeTour && (
                <div>
                  <Button size="small" onClick={() => setShowLegacyInclusionField(!showLegacyInclusionField)}>
                    {showLegacyInclusionField ? 'Hide' : 'Show'} legacy inclusions
                  </Button>
                </div>
              )}
              {(isOfferTypeTour || showLegacyInclusionField) && (
                <RichTextEditor
                  control={control}
                  label={`Offer tile inclusions${!isOfferTypeTour && ' (legacy)'}`}
                  name="offer_tile_inclusions"
                />
              )}
              <Input
                control={control}
                fullWidth
                helperText="Maximum 70 characters"
                inputProps={{ maxLength: 70 }}
                label="Offer inclusions summary"
                name="offer_inclusions_short"
              />
              <Box sx={{ width: '100%' }}>
                {overridableContent && (
                  <OverridableRichText
                    label="Highlights"
                    offer={control}
                    field="highlights"
                    propertyContext={propertyContext}
                  />
                )}
                {!overridableContent && <RichTextEditor control={control} label="Highlights" name="highlights" />}
              </Box>
              <Box sx={{ width: '100%' }}>
                {overridableContent && (
                  <OverridableRichText
                    label="Locations"
                    offer={control}
                    field="getting_there"
                    propertyContext={propertyContext}
                    canUseBrandTokens
                  />
                )}
                {!overridableContent && (
                  <RichTextEditor control={control} label="Locations" name="getting_there" canUseBrandTokens />
                )}
                <Button
                  variant="outlined"
                  startIcon={<BookmarksIcon />}
                  color="secondary"
                  onClick={() => onShowTemplateModalClick('location')}
                >
                  Show Templates
                </Button>
                <AIGeneratorButton
                  context={{
                    model: generatorContext.model,
                    field: 'location',
                    propertyName: generatorContext.name,
                  }}
                  content={generatorContext.getting_there}
                  buttonLabel={generatorContext.getting_there ? 'Rewrite location' : 'Generate location'}
                  onChoose={(value) => setValue('getting_there', value)}
                >
                  <AdditionalUSPComponent
                    contextKey="additionalUSP"
                    value={locationUsps}
                    onValueChange={setLocationUsps}
                  />
                </AIGeneratorButton>
              </Box>
              {isOfferTypeTour && (
                <Stack>
                  <Typography variant="h6">Locations visited</Typography>
                  <DndContext onDragEnd={handleDragEnd}>
                    <SortableContext items={sortableLocationsVisited}>
                      {!locationsVisitedArray.fields.length && (
                        <Box
                          sx={{
                            p: 2,
                            width: 1,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="body2">Empty</Typography>
                        </Box>
                      )}
                      {locationsVisitedArray.fields.map((item, index) => (
                        <FormControl
                          error={locationsVisitedError?.[index]}
                          key={item.id}
                          sx={{ display: 'flex', mb: 2, width: 1 }}
                        >
                          <ArrayFieldItem itemKey={item.id} index={index}>
                            <>
                              <Stack spacing={2} sx={{ width: 1 }}>
                                <Controller
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      onChange={(e) => onLocationVisitedChange(e, 'country', index)}
                                      fullWidth
                                    >
                                      {schema.properties.locations_visited.items.properties.country.enum.map(
                                        (option) => (
                                          <MenuItem key={option} value={option}>
                                            {option}
                                          </MenuItem>
                                        ),
                                      )}
                                    </Select>
                                  )}
                                  name={`locations_visited.${index}.country`}
                                  control={control}
                                />
                                <Controller
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      onChange={(e) => onLocationVisitedChange(e, 'cityOrPlaceName', index)}
                                      fullWidth
                                      label="locations_visited"
                                      variant="outlined"
                                    />
                                  )}
                                  name={`locations_visited.${index}.cityOrPlaceName`}
                                  control={control}
                                />
                              </Stack>
                              <Stack>
                                <IconButton onClick={() => locationsVisitedArray.remove(index)} color="error">
                                  <DeleteIcon fontSize="medium" />
                                </IconButton>
                              </Stack>
                            </>
                          </ArrayFieldItem>
                        </FormControl>
                      ))}
                      <Box
                        sx={{
                          width: 1,
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            locationsVisitedArray.append({
                              cityOrPlaceName: '',
                              country: '',
                            })
                          }
                          size="large"
                          color="primary"
                        >
                          <AddIcon fontSize="large" />
                        </IconButton>
                      </Box>
                    </SortableContext>
                  </DndContext>
                </Stack>
              )}
            </>
          )}
          <Box>
            {overridableContent && (
              <OverridableRichText
                label="Fine print"
                offer={control}
                field="fine_print"
                propertyContext={propertyContext}
                canUseBrandTokens
              />
            )}
            {!overridableContent && (
              <RichTextEditor control={control} label="Fine print" name="fine_print" canUseBrandTokens />
            )}
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                startIcon={<BookmarksIcon />}
                color="secondary"
                onClick={() => onShowTemplateModalClick('fine_print')}
              >
                Show Templates
              </Button>
              <Button variant="outlined" startIcon={<FinePrintIcon />} color="secondary" onClick={handleClick}>
                Append Lux Plus Fine Print
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => handleFinePrintTemplateSelection('flash')}>
                  Limited Time Lux Exclusive
                </MenuItem>
                <MenuItem onClick={() => handleFinePrintTemplateSelection('lpc')}>Lux Premium Collection</MenuItem>
              </Menu>
            </Stack>
          </Box>
          {!isOfferTypeRental && (
            <Box sx={{ width: '100%' }}>
              {overridableContent && (
                <OverridableRichText
                  label="Facilities"
                  offer={control}
                  field="facilities"
                  propertyContext={propertyContext}
                />
              )}
              {!overridableContent && <RichTextEditor control={control} label="Facilities" name="facilities" />}
            </Box>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default OfferFieldsAccordion;
