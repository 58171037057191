import React from 'react';

import { SortableContainer } from 'react-sortable-hoc';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, IconButton, ImageList, ImageListItem, Typography } from '@mui/material';

import Image from '~/components/Common/Image';

interface OfferImage {
  id: string;
  id_cloudinary_external: string;
  title?: string;
  order: number;
  hidden?: boolean;
  selected?: boolean;
}

interface Props {
  images: OfferImage[];
  toggleImageSelection: (string, boolean) => void;
}

const ApiListContainer = SortableContainer(({ images, toggleImageSelection }: Props) => (
  <ImageList cols={4} gap={10} sx={{ mt: 2 }}>
    {images.map((image) => (
      <ImageListItem
        key={image.id_cloudinary_external}
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.preventDefault();
          toggleImageSelection(image.id, image.selected);
        }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            position: 'absolute',
            right: 10,
            zIndex: 1,
          }}
        >
          <IconButton
            color="info"
            title={image.selected ? 'Unselect Image' : 'Select Image'}
            sx={{
              zIndex: 2,
            }}
          >
            {image.selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
          </IconButton>
        </Box>
        <Image
          className="img-responsive mx-auto"
          publicId={image.id_cloudinary_external}
          options={{
            width: 266,
            height: 200,
          }}
        />
        <Box m={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>{image.title}</Typography>
        </Box>
      </ImageListItem>
    ))}
  </ImageList>
));

export default ApiListContainer;
