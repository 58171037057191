import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import GridPagination from '~/components/Common/Elements/GridPagination';

import DepartureService from '~/services/cruises/DepartureService';

import { SAILING_STATUS } from './constants';

interface FormValues {
  status: CruisesContract.DepartureStatus;
}

interface Props {
  sailing: CruisesContract.DepartureByIdResponse;
}

const columns: GridColDef[] = [
  {
    field: 'dayNumber',
    headerName: 'Day',
    display: 'flex',
  },
  {
    field: 'portId',
    headerName: 'Port ID',
    display: 'flex',
  },
  {
    flex: 1,
    field: 'departureTime',
    headerName: 'Departure Time',
    display: 'flex',
  },
  {
    flex: 1,
    field: 'arrivalTime',
    headerName: 'Arrival Time',
    display: 'flex',
  },
  {
    flex: 3,
    field: 'description',
    headerName: 'Description',
    display: 'flex',
  },
  {
    flex: 2,
    field: 'createdAt',
    headerName: 'Created At',
    display: 'flex',
  },
  {
    flex: 2,
    field: 'updatedAt',
    headerName: 'Updated At',
    display: 'flex',
  },
];

export default function SailingDetailsForm(props: Props) {
  const { sailing } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [requestStatus, setRequestStatus] = useState<Utils.FetchingState>('idle');
  const [formValues, setFormValues] = useState<FormValues>({ status: 'INACTIVE' });

  const setSailingStatus = useCallback((status: CruisesContract.DepartureStatus) => {
    setFormValues({ status });
  }, []);

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.persist) event.persist();

      if (event?.target && event?.target?.name) {
        setFormValues((prev) => ({
          ...prev,
          [event.target.name]: event.target.value,
        }));
      }
    },
    [setFormValues],
  );

  const onSubmit = useCallback(async (): Promise<void> => {
    setRequestStatus('loading');

    try {
      const response = await DepartureService.updateById(sailing.id, formValues);

      setRequestStatus('success');

      if (response.status === 200) {
        enqueueSnackbar('Sailing updated successfully', { autoHideDuration: 5000, variant: 'success' });
      }
    } catch (error) {
      setRequestStatus('failed');
      enqueueSnackbar(JSON.stringify(error), { autoHideDuration: 5000, variant: 'error' });
    }
  }, [sailing.id, formValues, enqueueSnackbar]);

  const markets = useMemo(() => {
    if (!sailing?.markets || !sailing?.markets?.length) return '';

    return sailing.markets
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((market) => market.name)
      .join(', ');
  }, [sailing?.markets]);

  const itineraries = useMemo(() => {
    if (!sailing?.itineraries || !sailing?.itineraries?.length) return [];
    return sailing.itineraries.sort((a, b) => a?.dayNumber - b?.dayNumber);
  }, [sailing?.itineraries]);

  useEffect(() => {
    if (sailing?.status) setSailingStatus(sailing.status);
  }, [sailing?.status, setSailingStatus]);

  return (
    <Box>
      <Stack direction="column" spacing={4} mt={4}>
        <Stack spacing={2} direction="row">
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              fullWidth
              name="status"
              label="Sailing Status"
              value={formValues.status}
              onChange={handleChangeField}
              defaultValue={formValues.status}
            >
              {SAILING_STATUS.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField disabled fullWidth label="Sailing Name" value={sailing.name} />
          <TextField disabled fullWidth label="Sailing Plan" value={sailing.sailingPlan} />
          <TextField disabled fullWidth label="External Sailing ID" value={sailing.externalId} />
          <TextField disabled fullWidth label="Has Core Changes" value={sailing.hasCoreChanges} />
          <TextField disabled fullWidth label="Has Itinerary Changes" value={sailing.hasItineraryChanges} />
        </Stack>

        <Stack spacing={2} direction="row">
          <TextField disabled fullWidth label="Cruise Line ID" value={sailing.vendor.externalId} />
          <TextField disabled fullWidth label="Cruise Line" value={sailing.vendor.name} />
          <TextField disabled fullWidth label="Cruise Line Code" value={sailing.vendor.code} />
          <TextField disabled fullWidth label="Ship Name" value={sailing.ship.name} />
          <TextField disabled fullWidth label="Ship ID" value={sailing.ship.externalId} />
        </Stack>

        <Stack spacing={2} direction="row">
          <TextField disabled fullWidth label="Departure Date" value={sailing.departureDate} />
          <TextField disabled fullWidth label="Departure Port" value={sailing.offer.departurePortName} />
          <TextField disabled fullWidth label="Departure Port Code" value={sailing.offer.departurePortCode} />
          <TextField disabled fullWidth label="Return Port" value={sailing.offer.returnPortName} />
          <TextField disabled fullWidth label="Return Port Code" value={sailing.offer.returnPortCode} />
          <TextField disabled fullWidth label="Return Date" value={sailing.returnDate} />
        </Stack>

        <Stack spacing={2} direction="row">
          <TextField disabled label="Destination ID" value={sailing.destination?.externalId} />
          <TextField disabled label="Destination Name" value={sailing.destination?.name} />
          <TextField disabled fullWidth label="Markets" value={markets} />
          <Button
            onClick={onSubmit}
            variant="contained"
            style={{ minWidth: '200px' }}
            disabled={requestStatus === 'loading'}
            endIcon={requestStatus === 'loading' ? <CircularProgress size={16} /> : undefined}
          >
            Save Status
          </Button>
        </Stack>

        <Alert severity="info">
          <Typography variant="body1">
            <strong>NOTE:</strong> Changing the status of a SAILING will not affect the status of the OFFER. Also,
            changing the status will automatically update `require approval` to FALSE.
          </Typography>
        </Alert>

        <Box>
          <DataGrid
            autoHeight
            hideFooter
            columns={columns}
            disableColumnMenu
            disableColumnFilter
            rows={itineraries}
            rowCount={itineraries.length}
            disableRowSelectionOnClick
            hideFooterPagination
            getRowHeight={() => 'auto'}
            slots={{ pagination: GridPagination }}
          />
        </Box>
      </Stack>
    </Box>
  );
}
