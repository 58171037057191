import React, { SetStateAction, useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';
import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { CREDIT_EXTENSION_TYPES, DEFAULT_EXPIRY_IN_MONTHS } from '~/consts/credits';

import useCurrentUser from '~/hooks/useCurrentUser';

import { ExpireCreditsByAmountProps, changeCreditExpiryByAmount, changeExpiryDate } from '~/services/PaymentsService';
import { addMonths, endOfDay, formatDateLongISO } from '~/services/TimeService';

import { isAdmin } from '~/utils/adminPermission';

import UnspentCreditsTable from './UnspentCreditsTable';
import { CreditCommentMap, ReconciledCredits } from './types';

interface Props {
  currency: string;
  isModalOpen: boolean;
  isOnNewExpiryProcess: (brand: string) => boolean;
  reconciledCredits: ReconciledCredits;
  rerender: () => void;
  setChangeExpiryDateRequestState: (state: SetStateAction<Utils.FetchingState>) => void;
  setIsModalOpen: (state: boolean) => void;
  userId: string;
  creditCommentMap: CreditCommentMap;
}

export default function ChangeExpiryDateModal({
  currency,
  isModalOpen,
  isOnNewExpiryProcess,
  reconciledCredits,
  rerender,
  setChangeExpiryDateRequestState,
  setIsModalOpen,
  userId,
  creditCommentMap,
}: Props) {
  const [caseNumber, setCaseNumber] = useState<string>('');
  const [creditExtensionType, setCreditExtensionType] = useState<string>('');
  const [additionalContext, setAdditionalContext] = useState<string>('');
  const [expiresAt, setExpiresAt] = useState<string>(formatDateLongISO(endOfDay(addMonths(DEFAULT_EXPIRY_IN_MONTHS))));
  const [totalToExtend, setTotalToExtend] = useState<number>(0);
  const [creditsToExtend, setCreditsToExtend] = useState<GridRowSelectionModel>([]); // array of credit IDs
  const { user } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const handleChangeExpiryDate = useCallback(
    async (event) => {
      event.preventDefault();
      setChangeExpiryDateRequestState('loading');
      const object = {
        expires_at: expiresAt,
        comments: additionalContext,
        fk_member: userId,
        brand,
        currency,
      };

      object.comments += (object.comments.length ? ', ' : '') + creditExtensionType + ', ' + caseNumber.toString();

      if (!isOnNewExpiryProcess(brand)) {
        await changeExpiryDate(object);
        enqueueSnackbar('Expiry date updated', { variant: 'success' });
      } else {
        // validation for totalToExtend is elsewhere
        const expirePayload: ExpireCreditsByAmountProps = {
          memberId: userId,
          brand,
          currency,
          expirationDate: expiresAt,
          creditsAdjustmentAmount: totalToExtend,
          comments: additionalContext,
          creditIdsToAdjustExpiration: creditsToExtend as Array<string>,
        };
        await changeCreditExpiryByAmount(expirePayload);
      }

      setChangeExpiryDateRequestState('idle');
      setIsModalOpen(false);
      rerender();
    },
    [
      additionalContext,
      brand,
      caseNumber,
      creditExtensionType,
      creditsToExtend,
      currency,
      enqueueSnackbar,
      expiresAt,
      isOnNewExpiryProcess,
      rerender,
      setChangeExpiryDateRequestState,
      setIsModalOpen,
      totalToExtend,
      userId,
    ],
  );
  const onSubmit = () => {
    handleChangeExpiryDate(event);
  };

  const onCancelModal = () => {
    setIsModalOpen(false);
  };

  const handleCaseNumberChange = (event) => {
    setCaseNumber(event.target.value);
  };

  const handleCommentChange = (event) => {
    setAdditionalContext(event.target.value);
  };

  const handleChangeCreditType = (event) => {
    setCreditExtensionType(event.target.value);
  };

  return (
    <Dialog open={isModalOpen} onClose={onCancelModal} scroll="paper" fullWidth maxWidth="lg">
      <form onSubmit={onSubmit}>
        <DialogTitle>{isAdmin(user) ? 'Credit Extension' : 'View Active Credit'}</DialogTitle>
        <DialogContent>
          {isOnNewExpiryProcess(brand) && (
            <>
              <UnspentCreditsTable
                unspentCredits={reconciledCredits['unspentCredits']}
                creditCommentMap={creditCommentMap}
                totalToExtend={totalToExtend}
                setTotalToExtend={setTotalToExtend}
                creditsToExtend={creditsToExtend}
                setCreditsToExtend={setCreditsToExtend}
                currency={currency}
              />
              <PermissionedComponent>
                <Typography variant="h6">Details</Typography>
              </PermissionedComponent>
            </>
          )}
          <PermissionedComponent>
            <Stack direction="row" spacing={2} mt={2}>
              <Stack direction="column" spacing={2} mt={2} flexGrow={1} width={250}>
                <TextField
                  variant="outlined"
                  value={creditExtensionType}
                  onChange={handleChangeCreditType}
                  select
                  fullWidth
                  required
                >
                  {CREDIT_EXTENSION_TYPES.map((item, idx) => (
                    <MenuItem key={idx} value={item.creditExtensionType}>
                      {item.creditExtensionType}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction="column" spacing={2} mt={2} flexGrow={1}>
                <DateTimeWidget value={expiresAt} onChange={setExpiresAt} />
              </Stack>
              <Stack direction="column" spacing={2} mt={2} flexGrow={1}>
                <TextField
                  id="caseNumber"
                  label="Case number with approval"
                  placeholder="Case number with approval"
                  variant="outlined"
                  value={caseNumber}
                  onChange={handleCaseNumberChange}
                  inputProps={{
                    minLength: 2,
                  }}
                  fullWidth
                  required
                />
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2} mt={2}>
              <TextField
                label="Additional context"
                placeholder="Additional context"
                variant="outlined"
                value={additionalContext}
                onChange={handleCommentChange}
                rows={4}
                fullWidth
                multiline
              />
            </Stack>
          </PermissionedComponent>
        </DialogContent>
        <PermissionedComponent>
          <DialogActions sx={{ marginRight: 2, marginBottom: 2 }}>
            <Button onClick={onCancelModal} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              autoFocus
              disabled={isOnNewExpiryProcess(brand) && !creditsToExtend.length}
            >
              Submit
            </Button>
          </DialogActions>
        </PermissionedComponent>
      </form>
    </Dialog>
  );
}
