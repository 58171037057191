import React from 'react';

import { Link } from 'react-router-dom';

import { Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

export default function PropertyPane(props) {
  const property = props.property;
  return (
    <div id={property.id}>
      <PageSubheader title="Curated content">
        <div>
          <Button
            variant="text"
            component={Link}
            to={`/vendors/${props.vendorId}/properties/${property.id}/edit-curated-content`}
          >
            Edit
          </Button>
        </div>
      </PageSubheader>

      <Grid container spacing={2}>
        <Grid xs={12} md={6}>
          <Stack direction="column" spacing={4}>
            <Box>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Content Description
              </Typography>
              <Typography fontSize={16}>{property.content_description}</Typography>
            </Box>

            <Box>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Fine Print
              </Typography>
              <Typography fontSize={16}>{property.fine_print}</Typography>
            </Box>

            <Box>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Additional Description
              </Typography>
              <Typography fontSize={16}>{property.additional_description}</Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid xs={12} md={6}>
          <Stack direction="column" spacing={4}>
            <Box>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Highlights Description
              </Typography>
              <Typography fontSize={16}>{property.highlights_description}</Typography>
            </Box>

            <Box>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Locations
              </Typography>
              <Typography fontSize={16}>{property.locations_description}</Typography>
            </Box>

            <Box>
              <Typography variant="h6" fontSize={16} color="secondary" textTransform="uppercase">
                Facilities Description
              </Typography>
              <Typography fontSize={16}>{property.facilities_description}</Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
