import UsersService from '~/services/UsersService';

import { UserMapping } from '../types/types';

export const fetchReviewerMap = async (refundRequests: Array<RefundRequest>) => {
  // Only fetch reviewers from refund requests that are approved/rejected and have no refund option selected
  const reviewerIds = [
    ...new Set(
      refundRequests
        .filter(
          (refundRequest) =>
            (refundRequest.status === 'approved' || refundRequest.status === 'rejected') &&
            !refundRequest.refund_option,
        )
        .map((refundRequest) => refundRequest.fk_reviewer_id)
        .filter((id) => id !== null),
    ),
  ];

  // Fetch reviewer users
  const reviewerUsers: Array<App.User> = await Promise.all(reviewerIds.map((id) => UsersService.getUser(id)));

  // Id to User mapping
  const reviewerMap: UserMapping = reviewerUsers.reduce((acc, user) => {
    acc[user.id_member] = user;
    return acc;
  }, {});

  return reviewerMap;
};
