import { request } from './common';

function getBaseURL() {
  return `${window.configs.API_HOST}/api/insurance`;
}

export async function fetchInsuranceClaim(contractId: string): Promise<App.InsuranceClaim> {
  const url = `${getBaseURL()}/claim/${contractId}`;
  const { result } = await request(url);
  return result;
}
