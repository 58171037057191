import qs from 'qs';

import { actions } from '@luxuryescapes/contract-svc-membership';

import featureToggle from '~/utils/featureToggle';

import { request } from './common';

export type UpdateCustomerMembershipSubscriptionsParams =
  actions['customerSubscriptionsUpdate']['parameters']['body']['payload'];

const BASE_PATH = `${window.configs.API_HOST}/api/membership`;

interface GetCustomerMembershipSubscriptionsParams {
  customerId: string;
  brand?: string;
}

interface GetSubscriptionOffersParams {
  region: string;
  brand: string;
}

interface CreateCustomerSubscriptionForAdminsParams {
  customerId: string;
  region: string;
  currency: string;
  price: number;
  subscriptionOfferId: string;
  brand: string;
}

interface CancelPreviewPeriodParams {
  customerId: string;
  op: App.MembershipPreviewOperation;
}

const getCustomerMembershipSubscriptions = ({
  brand = 'luxuryescapes',
  customerId,
}: GetCustomerMembershipSubscriptionsParams) => {
  if (!customerId || !featureToggle.availableToShow('SHOW_SUBSCRIPTIONS')) return null;

  const queryParams = qs.stringify({ brand, customerId });
  const uri = `${BASE_PATH}/customer-subscriptions?${queryParams}`;

  return request(uri, { method: 'GET' });
};

const getSubscriptionOffers = async ({ brand, region }: GetSubscriptionOffersParams) => {
  const queryParams = qs.stringify({ brand, region });
  const uri = `${BASE_PATH}/subscription-offers?${queryParams}`;

  const res = await request(uri, { method: 'GET' });
  return res.result;
};

const createCustomerSubscriptionsForLuxPlusAdmins = ({
  customerId,
  region,
  currency,
  price,
  subscriptionOfferId,
  brand,
}: CreateCustomerSubscriptionForAdminsParams) => {
  if (!featureToggle.availableToShow('SHOW_SUBSCRIPTIONS')) return null;

  const uri = `${BASE_PATH}/admins/subscription-periods`;
  const bodyData = JSON.stringify({ customerId, region, currency, price, subscriptionOfferId, brand });

  return request(uri, {
    method: 'POST',
    body: bodyData,
    headers: { 'Content-Type': 'application/json' },
  });
};

const updateCustomerMembershipSubscriptions = ({
  customerId,
  op,
  reason,
}: UpdateCustomerMembershipSubscriptionsParams) => {
  if (!customerId || !featureToggle.availableToShow('SHOW_SUBSCRIPTIONS')) return null;

  const uri = `${BASE_PATH}/customer-subscriptions?without_brand=1`;
  const bodyData = JSON.stringify({ customerId, op, reason });

  return request(uri, {
    method: 'PUT',
    body: bodyData,
    headers: { 'Content-Type': 'application/json' },
  });
};

const cancelPreviewPeriod = ({ customerId, op }: CancelPreviewPeriodParams) => {
  if (!customerId || !featureToggle.availableToShow('SHOW_SUBSCRIPTIONS')) return null;

  const uri = `${BASE_PATH}/preview-periods?without_brand=1`;
  const bodyData = JSON.stringify({ customerId, op });

  return request(uri, {
    method: 'PUT',
    body: bodyData,
    headers: { 'Content-Type': 'application/json' },
  });
};

const hasActiveLuxPlusSubscriptionRenewal = (subscriptions: App.MembershipSubscriptions) => {
  return subscriptions?.renewalStatus === 'ALLOW_RENEWAL';
};

export {
  BASE_PATH,
  getCustomerMembershipSubscriptions,
  updateCustomerMembershipSubscriptions,
  hasActiveLuxPlusSubscriptionRenewal,
  cancelPreviewPeriod,
  getSubscriptionOffers,
  createCustomerSubscriptionsForLuxPlusAdmins,
};
