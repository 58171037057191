import React, { useEffect, useState } from 'react';

import { AutoGraph } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import CopyableField from '~/components/Common/CopyableField';
import DebugModal from '~/components/DebugModal/DebugModal';
import UserAndOrderId from '~/components/PromoV3/UserAndOrderId';

import { DEFAULT_PAGE_SIZES } from '~/consts/filters';

import { PromoFailureFilters, PromoMeta } from '~/services/PromoService';
import { formatDateLongMonthWithMeridiem, formatDateShort } from '~/services/TimeService';

import GridPagination from '../../Common/Elements/GridPagination';
import Spinner from '../../Common/Spinner';
import PromoTesterLink from '../PromoTester/PromoTesterLink';
import { PromoCodeName } from '../formatters/PromoCodeNameFormatter';
import usePromoFailures from '../hooks/usePromoFailures';

const sharedAttrs: Pick<GridColDef, 'sortable' | 'display' | 'flex'> = {
  sortable: false,
  display: 'flex',
  flex: 1,
};

const getColumns = (): Array<GridColDef> => [
  {
    field: 'created_at',
    valueFormatter: (value) => formatDateShort(value),
    renderCell: (params) => (
      <Box title={formatDateLongMonthWithMeridiem(params.row.created_at)}>{formatDateShort(params.row.created_at)}</Box>
    ),
    headerName: 'Logged at',
    ...sharedAttrs,
  },
  {
    field: 'code_name',
    renderCell: (params) => PromoCodeName({ codeName: params.row.code_name }),
    headerName: 'Code',
    ...sharedAttrs,
  },
  {
    field: 'User/Order',
    renderCell: (params) => UserAndOrderId({ orderId: params.row.id_order, userId: params.row.user_id }),
    ...sharedAttrs,
  },
  {
    field: 'error_level',
    headerName: 'Error Level',
    ...sharedAttrs,
  },
  {
    field: 'promo_failure_error_code',
    headerName: 'Error Code',
    ...sharedAttrs,
  },
  {
    field: 'error_message',
    headerName: 'Error Msg',
    ...sharedAttrs,
    flex: 4,
  },
  {
    field: 'debug',
    headerName: 'Raw',
    renderCell: (params) => (
      <CopyableField
        value={JSON.stringify(params.row, null, 4)}
        label={<DebugModal type="generic" data={params.row ?? { data: 'None' }} />}
      />
    ),
    ...sharedAttrs,
  },
  {
    field: 'reSimulate',
    headerName: 'Re-Sim',
    renderCell: (params) =>
      params.row.order_id
        ? PromoTesterLink({
            codeName: params.row.code_name,
            orderId: params.row.order_id,
          })
        : 'N/A',
    ...sharedAttrs,
    flex: 0,
  },
];

interface Props {
  initCodeName?: string;
  initUserId?: string;
  initPage?: number;
  initLimit?: number;
  brand: App.Brands | string;
  handleUserIdFilterSet?: (userId: string) => void;
  promoMeta: PromoMeta;
}

const DEFAULT_PAGE_SIZE = 10;

const getFilterTitle = (promoFailuresFilter: PromoFailureFilters) => {
  let title = '';
  if (promoFailuresFilter.code_name) {
    title += ` ${promoFailuresFilter.code_name}`;
  }
  if (promoFailuresFilter.user_id) {
    title += `, ${promoFailuresFilter.user_id}`;
  }

  return title.length > 0 ? 'Filtered to' + title : '';
};

function PromoFailures({ initCodeName, initUserId, initLimit, initPage, brand }: Props) {
  const { promoFailures, totalRows, isLoading, promoFailuresFilter, promoFailureMetrics, setPromoFailuresFilter } =
    usePromoFailures({
      initPage,
      initLimit: initLimit ?? DEFAULT_PAGE_SIZE,
      initCodeName,
      brand: brand.toString(),
      initUserId,
    });
  const [loadMetrics, setLoadMetrics] = useState(false);

  useEffect(() => {
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      code_name: initCodeName,
      brand: brand as definitions['Promo Brands'],
      user_id: initUserId,
    });
  }, [initCodeName, initUserId, brand, initPage, setPromoFailuresFilter]);

  const onPageChange = async (page, pageSize) => {
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      page,
      limit: pageSize,
    });
  };

  const handleLoadMetrics = () => {
    setLoadMetrics(!promoFailuresFilter.load_metrics);
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      load_metrics: !promoFailuresFilter.load_metrics,
    });
  };

  const filterTitle = getFilterTitle(promoFailuresFilter);

  return (
    <>
      {!isLoading ? (
        <>
          <DataGrid
            columns={getColumns()}
            autoHeight
            getRowId={(row) => row.uniqueKey}
            rowCount={totalRows}
            rows={promoFailures || []}
            pagination
            paginationMode="server"
            paginationModel={{ page: promoFailuresFilter.page, pageSize: promoFailuresFilter.limit }}
            onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
            slots={{ pagination: GridPagination }}
            pageSizeOptions={DEFAULT_PAGE_SIZES}
            getRowHeight={() => 'auto'}
          />
          {!promoFailuresFilter.load_metrics && (
            <Button
              variant="contained"
              onClick={handleLoadMetrics}
              sx={{ padding: '0 16px' }}
              title={loadMetrics ? 'Hide extended info' : 'Show extended info'}
            >
              <AutoGraph />
            </Button>
          )}
          {promoFailureMetrics && <DebugModal type="generic" title={filterTitle} data={promoFailureMetrics} />}
        </>
      ) : (
        <Spinner size={36} />
      )}
    </>
  );
}

export default PromoFailures;
