import { BOOKING_GUARANTEE } from '~/consts/offerFormDefaults';

const setDefaultIfUndefined = (value: any, defaultValue: any) => {
  return value === undefined ? defaultValue : value;
};

// TODO offer should have AccommodationOffer type, however this is currently missing too many fields to be useful here
export default (offer: any, schema: any): any => {
  const { flight_regions } = schema.properties;
  let flightRegions = offer.flight_regions;

  if (!flightRegions || !flightRegions.length) {
    flightRegions = flight_regions.items?.enum;
  }

  return {
    additional_description: offer.additional_description,
    booking_guarantee: BOOKING_GUARANTEE,
    booking_type: 'reservation',
    bundled_with_flights_only: offer.bundled_with_flights_only,
    campaigns: offer.campaigns,
    deposit_thresholds: offer.deposit_thresholds,
    description: offer.description,
    disable_best_price_guarantee: setDefaultIfUndefined(offer.disable_best_price_guarantee, false),
    disable_deposit: offer.disable_deposit,
    enable_customer_portal_date_change: setDefaultIfUndefined(offer.enable_customer_portal_date_change, false),
    facilities: offer.facilities,
    fine_print: offer.fine_print,
    flexible_nights: true,
    flight_destination_port: offer.flight_destination_port,
    flight_regions: flightRegions,
    flights_cache_disabled: offer.flights_cache_disabled,
    flights_enabled: offer.flights_enabled,
    flights_max_arrival_time: offer.flights_max_arrival_time,
    flights_min_returning_departure_time: offer.flights_min_returning_departure_time,
    flights_warning_enabled: offer.flights_warning_enabled,
    flights_warning_headline: offer.flights_warning_headline,
    flights_warning_popup_body: offer.flights_warning_popup_body,
    getting_there: offer.getting_there,
    highlights: offer.highlights,
    holiday_types: offer.holiday_types,
    ignore_vendor_email_notifications: !!offer.ignore_vendor_email_notifications,
    is_enable_deposit_thresholds: offer.is_enable_deposit_thresholds,
    is_partner_property: offer.is_partner_property,
    is_search_by_name_hidden: setDefaultIfUndefined(offer.is_search_by_name_hidden, false),
    is_search_hidden: setDefaultIfUndefined(offer.is_search_hidden, false),
    is_discount_pill_hidden: setDefaultIfUndefined(offer.is_discount_pill_hidden, false),
    location: offer.location,
    locations: offer.locations,
    locations_visited: offer.locations_visited,
    name: offer.name,
    name_override_for_edm: offer.name_override_for_edm,
    no_index: offer.no_index,
    offer_bundles: offer.offer_bundles.map((offerBundle) => offerBundle.id_salesforce_external),
    offer_inclusions: offer.offer_inclusions || [],
    offer_inclusions_short: offer.offer_inclusions_short,
    offer_inclusions_long: offer.offer_inclusions_long,
    offer_tile_inclusions: offer.offer_tile_inclusions,
    offer_tile_inclusion_heading: offer.offer_tile_inclusion_heading,
    vendor_vehicle: offer.vendor_vehicle,
    vendor_name: offer.vendor_name,
    start_location: offer.start_location,
    end_location: offer.end_location,
    cabin_types: offer.cabin_types,
    partnerships: offer.partnerships,
    preheader: setDefaultIfUndefined(offer.preheader, ''),
    sale_unit: offer.sale_unit,
    satisfaction_survey_link: offer.satisfaction_survey_link || '',
    schedule_change_robot_enabled: offer.schedule_change_robot_enabled,
    slug: offer.slug,
    staff_discount_enabled: setDefaultIfUndefined(offer.staff_discount_enabled, true),
    status: offer.status,
    subject_line: setDefaultIfUndefined(offer.subject_line, ''),
    surcharge_paid_direct_to_vendor: offer.surcharge_paid_direct_to_vendor,
    tour_flight_details_required: offer.tour_flight_details_required,
    type: offer.type,
    vendor_booking_email: offer.vendor_booking_email,
    what_we_like: offer.what_we_like,
    whitelisted_carrier_codes: offer.whitelisted_carrier_codes || [],
    force_bundle_id: offer.force_bundle_id,
  };
};
