import React from 'react';

import { Grid } from '@mui/material';

const BorderedGridItem = ({ children, ...props }) => (
  <Grid
    item
    xs
    sx={{ ...props.sx, border: '1px solid', borderColor: 'grey.400', padding: '1px', flexGrow: 1 }}
    {...props}
  >
    {children}
  </Grid>
);

export default BorderedGridItem;
