/* src/components/Refund/FlightModal.js */
import React, { Component, Fragment } from 'react';

import currencyFormatter from 'currency-formatter';
import PropTypes from 'prop-types';

import { Alert, Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

import {
  COVID_REASON,
  LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
  REFUND_METHOD_CREDITS,
  REFUND_METHOD_DEFAULT,
  REFUND_METHOD_MANUAL,
  STEP_ADDITIONAL_INFO,
  STEP_SUMMARY,
} from '../../consts/refund';

import RefundMethod from './RefundMethod';
import SubmitButton from './SubmitButton';

const DOMESTIC_REFUND_FEE = 80;
const INTERNATIONAL_REFUND_FEE = 200;

export default class FlightModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCovidReason: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.show || this.props.show;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      if (this.props.travellerId) {
        this.setState({
          step: STEP_ADDITIONAL_INFO,
          amount: undefined,
          error: null,
          fee: 0,
          refundMethod: undefined,
          ticketId: '',
        });
      } else {
        this.setState({
          step: STEP_SUMMARY,
          amount: this.props.refundableAmount,
          error: null,
          fee: 0,
          refundMethod: undefined,
          ticketId: '',
        });
      }
    }
  }

  toggleCovidReason = () => {
    this.setState((prevState) => ({ isCovidReason: !prevState.isCovidReason }));
  };

  render() {
    return (
      <Dialog open={this.props.show} onClose={this.props.onClose}>
        <DialogTitle>Issue Refund</DialogTitle>
        <DialogContent>
          {this.state.step === STEP_ADDITIONAL_INFO && (
            <>
              <Box id="refund_amount">
                <label>Amount Refunded By Airline:</label>
                <input
                  name="amount"
                  type="number"
                  value={this.state.amount}
                  onChange={(e) => this.setState({ amount: e.target.value })}
                  className="data-hj-whitelist"
                />
              </Box>

              <Box id="cancellation_fee">
                <label>Apply Cancellation Fee</label>
                <Box display="grid" gap={2} gridTemplateColumns="repeat(3, 1fr)">
                  <div>
                    <input
                      type="radio"
                      name="cancellation_fee"
                      value={0}
                      onChange={() => this.setState({ fee: 0 })}
                      checked={this.state.fee === 0}
                    />
                    <label>No Fee</label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      name="cancellation_fee"
                      value={DOMESTIC_REFUND_FEE}
                      onChange={() => this.setState({ fee: DOMESTIC_REFUND_FEE })}
                      checked={this.state.fee === DOMESTIC_REFUND_FEE}
                    />
                    <label>Domestic ${DOMESTIC_REFUND_FEE}</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="cancellation_fee"
                      value={INTERNATIONAL_REFUND_FEE}
                      onChange={() => this.setState({ fee: INTERNATIONAL_REFUND_FEE })}
                      checked={this.state.fee === INTERNATIONAL_REFUND_FEE}
                    />
                    <label>International ${INTERNATIONAL_REFUND_FEE}</label>
                  </div>
                </Box>
              </Box>

              <Box id="ticketId">
                <label>Case ID:</label>
                <input
                  name="ticketId"
                  value={this.props.ticketId}
                  onChange={(e) => this.setState({ ticketId: e.target.value })}
                  className="data-hj-whitelist"
                />
              </Box>

              <Alert severity="warning">
                Reminder: 7 day cooling off period is not applicable for flights bookings
              </Alert>

              <Button
                variant="contained"
                disabled={!this.state.amount || this.state.amount === 0 || !this.state.ticketId}
                onClick={() =>
                  this.setState({
                    step: STEP_SUMMARY,
                  })
                }
              >
                Continue
              </Button>
            </>
          )}
          {this.state.step === STEP_SUMMARY && (
            <>
              <Box display="grid" gap={2} gridTemplateColumns="repeat(4, 1fr)">
                <Box gridColumn="span 4">
                  <label>Summary</label>
                </Box>

                <Box gridColumn="span 3">Refund Amount</Box>
                <Box>
                  {currencyFormatter.format(this.state.amount, {
                    code: this.props.currency,
                  })}
                </Box>

                {this.state.fee > 0 && (
                  <>
                    <Box gridColumn="span 3">Cancellation Fee</Box>
                    <Box>
                      {currencyFormatter.format(-1 * this.state.fee, {
                        code: this.props.currency,
                      })}
                    </Box>
                  </>
                )}

                <Box gridColumn="span 3" className="type-heading">
                  <strong>Total Refund Amount</strong>
                </Box>
                <Box>
                  {currencyFormatter.format(this.state.amount - this.state.fee, {
                    code: this.props.currency,
                  })}
                </Box>

                <br />

                <Box gridColumn="span 4">
                  <Box id="refund_method">
                    <label>Select Method of Refund to Customer</label>
                    <RefundMethod
                      handleValueChange={(e) => this.setState({ refundMethod: e.target.value })}
                      methods={[REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL, REFUND_METHOD_DEFAULT]}
                      refund_method={this.state.refundMethod}
                      hasLatitudePayment={false}
                    />
                  </Box>
                </Box>

                {this.props.itemStatus !== 'cancelled' && (
                  <Box gridColumn="span 4">
                    <Box id="mark_cancelled">
                      <label>Should the booking be cancelled?</label>
                      <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr">
                        <Box>
                          <input
                            type="radio"
                            key="yes"
                            onChange={() => this.setState({ markCancelled: true })}
                            checked={this.state.markCancelled}
                          />
                          <label>Yes</label>
                        </Box>
                        <Box>
                          <input
                            type="radio"
                            key="no"
                            onChange={() => this.setState({ markCancelled: false })}
                            checked={this.state.markCancelled === false}
                          />
                          <label>No</label>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}

                <Box gridColumn="span 4">
                  <Box id="covid19_reason">
                    <label>Refund Reason is Covid 19?</label>
                    <Box>
                      <Box sm={6}>
                        <input
                          type="radio"
                          key="yes"
                          onChange={this.toggleCovidReason}
                          checked={this.state.isCovidReason}
                        />
                        <label>Yes</label>
                      </Box>
                      <Box sm={6}>
                        <input
                          type="radio"
                          key="no"
                          onChange={this.toggleCovidReason}
                          checked={!this.state.isCovidReason}
                        />
                        <label>No</label>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <SubmitButton
                type="flight"
                setError={(error) => this.setState({ error })}
                refund={{
                  currency_code: this.props.currency,
                  id_orders: this.props.orderId,
                  item_id: this.props.itemId,
                  traveller_id: this.props.travellerId,
                  reason: this.state.isCovidReason
                    ? COVID_REASON
                    : LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
                  send_refund_notification: false,
                  mark_cancelled: this.state.markCancelled,
                  refund_method: this.state.refundMethod,
                  comment: 'Flight refund comment',
                  ticket_id: this.state.ticketId,
                  item_metadata: {
                    accounting_amount: this.state.amount - this.state.fee,
                    cash_amount: this.state.amount - this.state.fee,
                    charge_component_key: this.props.itemId,
                    refund_fee: this.state.fee,
                    source: 'Default',
                  },
                }}
                totalRefund={() => this.state.amount - this.state.fee}
                hasRefundItem={() => true}
                hasRefundSurcharge={() => false}
                accountingMetaPayload={(p) => p}
                closeModal={this.props.onClose}
                itemStatus={this.props.itemStatus}
              />
            </>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

FlightModal.propTypes = {
  show: PropTypes.bool.isRequired,
  travellerId: PropTypes.string,
  refundableAmount: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  itemStatus: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
