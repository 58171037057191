import React from 'react';

import { Button } from '@mui/material';

import useReferralLogReset from '../hooks/useReferralLogReset';

type Props = {
  referralLogId: string;
};

const ResetReferralLogButton = ({ referralLogId }: Props) => {
  const { resetReferralLog } = useReferralLogReset({ referralLogId });

  return (
    <Button
      variant="contained"
      color="primary"
      title="When reset, a (non-redeemed) referral log will have the processing attempts set to 0, giving it another chance at being processed"
      onClick={() => resetReferralLog()}
    >
      Reset
    </Button>
  );
};

export default ResetReferralLogButton;
