export const saveCustomInclusionAsset = (data, vendorId) => {
  return {
    id: data.id,
    vendor_salesforce_id: data.vendor_salesforce_id || vendorId,
    name: data.name,
    description: data.description,
    currency: data.currency ?? null,
    type: data.type,
    category_id: data.category_id ?? null,
    unit: data.unit ?? null,
    rrp: data.rrp ?? null,
    is_hidden: data.is_hidden,
  };
};

export const saveSalesforceInclusionAsset = (data, vendorId) => {
  return {
    id: data.id,
    id_salesforce_external: data.id_salesforce_external,
    vendor_salesforce_id: data.vendor_salesforce_id || vendorId,
    name: data.name,
    type: data.type,
    unit: data.unit ?? null,
    category_id: data.category_id,
    is_hidden: data.is_hidden,
  };
};

export const savePackageInclusion = (data, vendorId) => {
  return {
    id: data.id,
    id_salesforce_external: data.id_salesforce_external,
    asset_salesforce_id: data.asset_salesforce_id,
    inclusion_id: data.inclusion_id,
    offer_id: data.offer_id,
    package_id: data.package_id,
    vendor_salesforce_id: data.vendor_salesforce_id || vendorId,
    package_name: data.package_name,
    opportunity_name: data.opportunity_name,
    name: data.name,
    sort_order: data.sort_order,
    style: data.style,
    lux_plus_tier: data.lux_plus_tier,
  };
};
