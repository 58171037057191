import React from 'react';

import { useHistory } from 'react-router';
import BedIcon from '~/icons/BedIcon';

import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import BorderedGridItem from '~/components/Common/BorderedGridItem';

import { getPromoProductDisplayName, sortPromoItemByCategoryAndId } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';
import { getCurrencyCodeByRegionCode } from '~/utils/getCurrencyCodeByRegionCode';

import TravellersList from '../PromoPage/TravellersList';

type PromoOrderTableCellProps = {
  order: definitions['Discount Request']['order'] | definitions['Discount Request']['preCheckoutOrder'] | null;
  meta: definitions['PromoMeta'];
};

function PromoOrderTableCell({ order, meta }: PromoOrderTableCellProps) {
  const history = useHistory();
  if (!order?.region) {
    return null;
  }

  const currencyCode = getCurrencyCodeByRegionCode(order.region);

  const handleGridClick = (item: PromoOrderTableCellProps['order']['items']['0']) => {
    if (item.categoryBK == 'subscription') return;
    history.push(`/offers/${item.offerId}`);
  };

  return (
    <Box width={'100%'} title={JSON.stringify(order, null, 4)}>
      {order.items.sort(sortPromoItemByCategoryAndId).map((item, i) => (
        <Grid container key={`${item.offerId}_${i}`} spacing={1} title={`${JSON.stringify(item, null, 4)}`}>
          <BorderedGridItem>
            {currencyFormatter(currencyCode, item.luxPlusPrice || item.discountableTotal)}
            {item.luxPlusPrice > 0 && (
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 300,
                  textDecoration: 'line-through',
                  marginLeft: 1,
                }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {currencyFormatter(currencyCode, item.discountableTotal)}
              </Typography>
            )}
          </BorderedGridItem>
          <BorderedGridItem>
            <Stack direction="column" onClick={() => handleGridClick(item)} title={JSON.stringify(item, null, 4)}>
              <Typography>
                <strong>Product Type: </strong>
                {meta && getPromoProductDisplayName(meta.promo_product_types, item.categoryBK, item.subCategoryBK)}
              </Typography>
              <Typography variant="body2">
                <strong>Travellers: </strong>
                <TravellersList travellers={item.travellers} />
              </Typography>
              <Typography>
                <strong>ReservationType: </strong>
                {item.reservationType ? `${item.reservationType}` : ''}
              </Typography>
            </Stack>
          </BorderedGridItem>
          <BorderedGridItem>
            <Stack direction="column" onClick={() => handleGridClick(item)} title={JSON.stringify(item, null, 4)}>
              <Typography>
                <strong>Item Country Code: </strong>
                {item.itemCountryCode && item.itemCountryCode}
              </Typography>
              <Typography>
                <strong>Number of Nights: </strong>
                {item.numberOfNights && item.numberOfNights}
              </Typography>
              <Typography>
                <strong>Adults: </strong>
                {item.numberOfAdults ? `${item.numberOfAdults}` : ''}
              </Typography>
              {item.numberOfChildren ? (
                <Typography>
                  <strong>Children: </strong>
                  {item.numberOfChildren ? ` ${item.numberOfChildren}` : ''}
                </Typography>
              ) : null}
              {order.clientOrderVersion && (
                <Typography>
                  <strong>v</strong>
                  {order.clientOrderVersion}
                </Typography>
              )}
              {order.isGiftOrder ?? (
                <Typography title="(Gift Order)">
                  <CardGiftcardIcon />
                </Typography>
              )}
              {order.hasBedbankPromotion && (
                <Box title="Has Bedbank Promotion">
                  <BedIcon />
                </Box>
              )}
            </Stack>
          </BorderedGridItem>
        </Grid>
      ))}
    </Box>
  );
}

export { PromoOrderTableCell };
