import React from 'react';

import currencyFormatter from 'currency-formatter';
import { Link } from 'react-router-dom';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import dateFormatter from '../../../../utils/dateFormatter';

interface Props {
  isLoading: boolean;
  payments: any;
}

const columns: GridColDef[] = [
  {
    field: 'date_of_payment',
    headerName: 'Date of Payment',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => dateFormatter(value),
    display: 'flex',
  },
  {
    field: 'cost_currency',
    headerName: 'Currency in which Vendor was paid',
    sortable: false,
    disableColumnMenu: true,
    width: 50,
    display: 'flex',
  },
  {
    field: 'cost_amount',
    headerName: 'Amount paid to Vendor',
    sortable: false,
    disableColumnMenu: true,
    valueGetter: (value, row) => {
      return currencyFormatter.format(value, { code: row.cost_currency });
    },
    width: 150,
    display: 'flex',
  },
  {
    field: 'entity_currency',
    headerName: 'LE Entity Currency',
    sortable: false,
    disableColumnMenu: true,
    width: 50,
    display: 'flex',
  },
  {
    field: 'entity_amount',
    headerName: 'LE Entity Amount',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (value, row) => {
      return currencyFormatter.format(value, { code: row.entity_currency });
    },
    display: 'flex',
  },
  {
    field: 'billing_currency',
    headerName: 'Billing Currency',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'billing_amount',
    headerName: 'Billing Amount',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (value, row) => {
      return currencyFormatter.format(value, { code: row.billing_currency });
    },
    display: 'flex',
  },
  {
    field: 'id_bank_transaction',
    headerName: 'Bank Transaction ID',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'fk_added_by',
    headerName: 'Added By',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: (_value, row) => row.fk_added_by?.fullName || '',
    display: 'flex',
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => dateFormatter(value),
    display: 'flex',
  },
  {
    field: 'has_attachments',
    headerName: 'Has Attachments',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => (value ? 'Yes' : 'No'),
    display: 'flex',
  },
  {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    align: 'center',
    renderCell: ({ row }) => {
      return (
        <Link to={`/finance/offers/${row.fk_offer}/offer-payments/${row.fk_vendor}/${row.id_offer_payment}`}>
          <ModeEditIcon />
        </Link>
      );
    },
    display: 'flex',
  },
];

export default function VendorOfferPaymentsList({ payments, isLoading }: Props) {
  const totalPaid = {
    amount: 0,
    currency: 'AUD',
  };

  if (payments && payments.length > 0) {
    const amount = payments.reduce(
      (previousValue, currentValue) => (parseFloat(previousValue) + parseFloat(currentValue.cost_amount)).toFixed(2),
      0,
    );
    totalPaid.amount = amount;
    totalPaid.currency = payments[0]?.cost_currency || 'AUD';
  }

  return (
    <div>
      <Typography component="p" variant="body1" fontSize={18} mb={1}>
        Total Paid to Vendor {totalPaid.currency}
        &nbsp;
        {currencyFormatter.format(totalPaid.amount, {
          code: totalPaid.currency,
        })}
      </Typography>

      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={payments || []}
        getRowId={(row) => row.id_offer_payment}
        slots={{ pagination: GridPagination }}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
      />
    </div>
  );
}
