import React, { useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ClearIcon, DatePicker } from '@mui/x-date-pickers-pro';

import {
  CommissionFormData,
  INITIAL_COMMISSION_CONDITIONS,
  RuleCondition,
  RuleConditionType,
  RuleConditions,
  ruleConditionsToTextMap,
} from '~/consts/agentHub';

import { createCommission } from '~/services/AgentHub/AgentService';
import { Dayjs, formatDateLongISO } from '~/services/TimeService';

import { CreateCommissionParams } from '~/types/services/agentHub';

import { buildRuleParam, getSortedRegions, groupByValue } from '~/utils/commission';

import AddNewConditionForm from './AgentHubCommissions/AddNewConditionForm';

interface Props {
  listCommissions: () => void;
}

function AgentHubCreateCommissionForm(props: Props) {
  const { listCommissions } = props;
  const [createdConditions, setCreatedConditions] = useState<RuleConditions>(INITIAL_COMMISSION_CONDITIONS);
  const [isNewConditionModalOpen, setIsNewConditionModalOpen] = useState<boolean>(false);
  const [regions, setRegions] = useState<Array<string>>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const commissionFormRef = useRef<HTMLFormElement>(null);

  const { enqueueSnackbar } = useSnackbar();

  function handleAddCondition() {
    setIsNewConditionModalOpen(true);
  }

  function handleCloseNewConditionModal() {
    setIsNewConditionModalOpen(false);
  }

  function addCondition(newCondition: RuleCondition) {
    setCreatedConditions((createdConditions) => {
      const conditions = createdConditions[newCondition.type];
      if (!conditions) {
        return { ...createdConditions, [newCondition.type]: [...newCondition.value] };
      }
      const currentConditions = conditions.reduce(groupByValue, {});
      const newConditions = newCondition.value.reduce(groupByValue, {});
      Object.assign(newConditions, currentConditions);
      const newConditionsForType = Object.entries(newConditions).map(([value, label]) => ({ value, label }));
      return {
        ...createdConditions,
        [newCondition.type]: newConditionsForType,
      };
    });
  }

  function resetForm() {
    setCreatedConditions(INITIAL_COMMISSION_CONDITIONS);
    setRegions([]);
    setStartDate(null);
    setEndDate(null);
    commissionFormRef.current?.reset();
  }

  async function saveCommission(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const isConditionsEmpty = Object.values(createdConditions).every((condition) => condition.length === 0);

    if (isConditionsEmpty) {
      enqueueSnackbar('Please add at least one condition', { variant: 'error' });
      return;
    }

    const formData = new FormData(event.currentTarget);
    const formObj: CommissionFormData = {
      description: formData.get('description') as string,
      startDate: formData.get('startDate') as string,
      endDate: formData.get('endDate') as string,
      regions: regions,
      discountType: formData.get('discountType') as string,
      discountAmount: parseInt(formData.get('discountAmount') as string),
    };

    const params: CreateCommissionParams = {
      description: formObj.description,
      regions: regions,
      commissionPercentage: formObj.discountAmount,
      type: formObj.discountType,
      startDate: startDate ? formatDateLongISO(startDate.toDate()) : formatDateLongISO(new Date()),
      endDate: endDate ? formatDateLongISO(endDate.toDate()) : undefined,
      rules: buildRuleParam(createdConditions),
    };

    createCommission(params)
      .then(() => {
        listCommissions();
        enqueueSnackbar('Commission rule created', { variant: 'success' });
        resetForm();
      })
      .catch(() => enqueueSnackbar('Failed to create commission rule', { variant: 'error' }));
  }

  function handleSelectRegion(event: SelectChangeEvent<string[] | string>) {
    setRegions(event.target.value as Array<string>);
  }

  function handleStartDateChange(date: Dayjs) {
    setStartDate(date);
  }

  function handleEndDateChange(date: Dayjs) {
    setEndDate(date);
  }

  function handleClearCondition(type: string) {
    setCreatedConditions((createdConditions) => {
      return {
        ...createdConditions,
        [type]: [],
      };
    });
  }

  return (
    <form ref={commissionFormRef} onSubmit={saveCommission}>
      <Stack direction={'column'} spacing={2}>
        <Typography variant="h6">Create Commission Rule</Typography>
        <Stack direction={'row'} gap={2}>
          <TextField name="description" label="Description" required fullWidth />
          <DatePicker
            value={startDate}
            onChange={handleStartDateChange}
            format="DD/MM/YYYY"
            disablePast
            name="startDate"
            label="Initial date"
          />
          <DatePicker
            value={endDate}
            onChange={handleEndDateChange}
            format="DD/MM/YYYY"
            disablePast
            name="endDate"
            label="End date"
          />
        </Stack>

        <Stack direction={'row'} gap={2}>
          <FormControl fullWidth>
            <InputLabel id="agent-hub-commissions-regions-input-label">Regions</InputLabel>
            <Select
              name="regions"
              labelId="agent-hub-commissions-regions-input-label"
              multiple
              value={regions}
              onChange={handleSelectRegion}
              input={<OutlinedInput label="Regions" />}
              renderValue={(selected: string[]) => selected.join(', ')}
            >
              {getSortedRegions().map((region) => (
                <MenuItem key={region.code} value={region.code}>
                  <Checkbox checked={regions.indexOf(region.code) > -1} />
                  <ListItemText primary={region.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField defaultValue="" name="discountType" select label="Discount type" required fullWidth>
            <MenuItem value={'base'}>Base</MenuItem>
            <MenuItem value={'additional'}>Additional</MenuItem>
          </TextField>

          <TextField
            name="discountAmount"
            InputProps={{ inputProps: { min: 1 } }}
            label="Discount amount"
            type="number"
            required
            fullWidth
          />
        </Stack>

        <Stack>
          <Typography variant="caption" color="secondary">
            Rule conditions:
          </Typography>

          {Object.entries(createdConditions)
            .filter(([_, value]: RuleConditionType) => value.length > 0)
            .map(([key, value]: RuleConditionType) => (
              <Stack key={key} direction={'row'} alignItems={'center'}>
                <Typography>
                  {ruleConditionsToTextMap.get(key)}: {value.map((condition) => condition.label).join(', ')}
                </Typography>
                <IconButton onClick={() => handleClearCondition(key)}>
                  <ClearIcon sx={{ fontSize: 16 }} color="secondary" />
                </IconButton>
              </Stack>
            ))}
        </Stack>

        <AddNewConditionForm
          isOpen={isNewConditionModalOpen}
          onClose={handleCloseNewConditionModal}
          addCondition={addCondition}
        />

        <Stack direction={'row'} gap={2}>
          <Button
            sx={{ flex: 4 }}
            size="small"
            variant="contained"
            onClick={handleAddCondition}
            startIcon={<AddIcon />}
          >
            Add condition
          </Button>

          <Button type="submit" size="small" variant="contained" sx={{ flex: 1 }}>
            Save Commission Rule
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default AgentHubCreateCommissionForm;
