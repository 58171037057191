import React, { useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { Box, Typography } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';

import LereService from '~/services/LereService';

import EditableSurchargeSuggestionsTable from './EditableSurchargeSuggestionsTable';
import SurchargeSuggestionsTable from './SurchargeSuggestionsTable';

export type SurchargeSuggestionDB = {
  id: string;
  offer_id: string;
  vendor_id: string;
  property_id: string;
  room_type_id: string;
  room_rate_id: string;
  offer_name: string;
  property_name: string;
  room_type_name: string;
  start_date: string;
  end_date: string;
  before_price: number;
  after_price: number;
  booked: number;
  allocated: number;
  created_at?: string;

  actioned_start_date: string;
  actioned_end_date: string;
  actioned_surcharge: number;
  status: 'accepted' | 'rejected';
  note?: string;
};

export type SurchargeSuggestionBQ = SurchargeSuggestionDB & {
  days_passed: number;
  days_remaining: number;
  sales_last_3_days: number;
  vendor_surcharge_total: number;
  internal_surcharge_total: number;
  nightly_price_aud: number;
  multiplier: number;
};

export type PaginatedSurchargeSuggestions<T> = {
  result: Array<T>;
  timestamp?: number;
  total: number;
};

interface Props {
  onLereError: (error: Error | unknown) => void;
}

export default function RevenueManagement({ onLereError }: Props) {
  const [surchargeSuggestionsData, setSurchargeSuggestionsData] = useState<
    PaginatedSurchargeSuggestions<SurchargeSuggestionDB>
  >({
    result: [],
    total: 0,
  });
  const [surchargeSuggestionsPaginationModel, setSurchargeSuggestionsPaginationModel] = useState<GridPaginationModel>({
    pageSize: 5,
    page: 0,
  });
  const prependChangeLog = (obj: SurchargeSuggestionDB) =>
    setSurchargeSuggestionsData((prev) => ({
      ...prev,
      result: [{ ...obj, id: uuid(), created_at: new Date().toISOString() }, ...prev.result].slice(
        0,
        surchargeSuggestionsPaginationModel.pageSize,
      ),
    }));

  useEffect(() => {
    LereService.getSurchargeSuggestions(
      surchargeSuggestionsPaginationModel.page + 1,
      surchargeSuggestionsPaginationModel.pageSize,
    )
      .then(setSurchargeSuggestionsData)
      .catch(onLereError);
  }, [surchargeSuggestionsPaginationModel, setSurchargeSuggestionsData, onLereError]);

  return (
    <Box>
      <Box>
        <Typography variant="h2" fontSize="2em" fontWeight="medium">
          Change Log
        </Typography>
        <Typography variant="body1">Surcharge suggestions that have been interacted with in the past.</Typography>
        <SurchargeSuggestionsTable
          data={surchargeSuggestionsData}
          paginationModel={surchargeSuggestionsPaginationModel}
          setPaginationModel={setSurchargeSuggestionsPaginationModel}
        />
      </Box>
      <Box>
        <EditableSurchargeSuggestionsTable onError={onLereError} prependChangeLog={prependChangeLog} />
      </Box>
    </Box>
  );
}
