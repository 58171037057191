export const getOrderDowngradeTypeLabel = (downgrade_type, downgradeTo, downgradeFrom) => {
  if (downgradeTo) {
    if (downgrade_type === 'bnbl') {
      return 'Converted to BNBL';
    }
    if (['package_change', 'extend_dates'].includes(downgrade_type)) {
      return 'Upgraded to';
    }
    if (['change_dates_bedbank', 'change_dates_tactical_ao_hotel'].includes(downgrade_type)) {
      return 'Changed dates to';
    }
  } else if (downgradeFrom) {
    if (downgrade_type === 'bnbl') {
      return 'BNBL converted from';
    }
    if (['package_change', 'extend_dates'].includes(downgrade_type)) {
      return 'Upgraded from';
    }
    if (['change_dates_bedbank', 'change_dates_tactical_ao_hotel'].includes(downgrade_type)) {
      return 'Changed dates from';
    }
  }
};
