import React, { useState } from 'react';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { updateOrderItem } from '~/services/OrdersService';

interface Props {
  order: App.Order;
  itemId: string;
  onClose: () => void;
  refreshData: () => void;
}

function ReconfirmModal(props: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const checkStatus = () => {
    setTimeout(() => {
      setLoading(false);
      props.refreshData();
    }, 10000);
  };

  const reconfirmItem = () => {
    setLoading(true);
    setError(null);

    updateOrderItem(props.order.id_orders, props.itemId, {
      op: 'reconfirm',
    })
      .then(() => {
        checkStatus();
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };

  if (loading) {
    return (
      <Dialog open={true}>
        <DialogContent>
          <Spinner />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={true} onClose={props.onClose}>
      <DialogTitle>
        <Stack spacing={2}>Reconfirm item {props.itemId}</Stack>
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2}>
          Item {props.itemId} is not confirmed. Are you sure to confirm that item?
        </Stack>
      </DialogContent>

      {error && <Alert severity="error">{error}</Alert>}

      <DialogActions>
        <Button variant="contained" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={reconfirmItem}>
          Consent
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReconfirmModal;
