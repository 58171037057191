import React from 'react';

import CopyableField from './CopyableField';

type Props = {
  userId: string;
  label: string;
};
const CopyableUserLink = ({ userId, label }: Props) => (
  <CopyableField value={userId} label=" ">
    <a href={`/users/${userId}`}>{label}</a>
  </CopyableField>
);

export default CopyableUserLink;
