import React from 'react';

import { Link, Stack } from '@mui/material';

import PromoCodeUser from '../Promo/formatters/PromoCodeUser';

type UserAndOrderIdProps = {
  orderId: string;
  userId: string;
};

function UserAndOrderId({ orderId, userId }: UserAndOrderIdProps) {
  return (
    <Stack>
      <PromoCodeUser userId={userId} />
      <Link noWrap href={`/purchases/${orderId}`} title={orderId}>
        {orderId}
      </Link>
    </Stack>
  );
}

export default UserAndOrderId;
